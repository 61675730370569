import { withStyles } from '@material-ui/styles';
import React, {Component} from 'react';
import ConfirmationDialog from '../Components/ConfirmationDialog';
import ConfirmUnsavedDialog from '../Components/ConfirmUnsavedDialog';
import WsHeader from '../Components/wsheader';
import AddPriceCard from './UI/AddPriceCard';
import BannerUploadCard from './UI/BannerUploadCard';
import CourseDurationCard from './UI/CourseDurationCard';
import DescriptionInputCard from './UI/DescriptionInputCard';
import DiscountToggleCard from './UI/DiscountToggleCard';
import FolderDetails from './UI/FolderDetails';
import LargeCTA from './UI/LargeCTA';
import StartingFromCard from './UI/StartingFromCard';
import StyledStepper from './UI/StyledStepper';
import TextInputCard from './UI/TextInputCard';

import moment from 'moment';
import SuccessDialog from '../Components/SuccessDialog';

const Servercalls = require('../Utils/Servercalls');

const styles = theme => ({
    root: {
        paddingBottom: theme.spacing(14.625),
    }
})

class AddLiveCourse extends Component {

    state = {
        activeIndex: 0,
        banner: [{
            mediaUrl: 'https://cdn.socionity.com/assets/build.socionity.com/welcome.jpeg', 
            mediaObject: {}
        }],
        
        title: '',
        titleError: false,

        description: `
        <b>Replace this with a course description to define your overall course </b>
        <p><b>[Day 1] : [Day 1 Date]</b><br />
        You can replace this text with the
        Description of the content you will
        Be teaching on day 1</p>`,
        descriptionError: false,

        duration: 0,
        presetDuration: 0,

        startDate: null,
        startTime: "15:00",
        endTime: "16:00",

        startDateError: false,

        repeat: 'Once',
        days: 1,
        daysOfWeek: {
            0: false,
            1: false,
            2: false,
            3: false,
            4: false,
            5: false,
            6: false,
        },
        validFor: 1,

        price: '',
        priceError: false,
        currency: 'INR',
        discountCheck: false,
        questionnaireLink: ""
    };

    bannerInputRef = React.createRef();

    componentDidMount() {
        const today = new Date();
        this.setState({startDate: moment(today).format('YYYY-MM-DD')});
    }

    async save() {
        console.log('saving')
        const self = this;
        
        // let confirmPublishingFreeFolder = () => new Promise(resolve => self.setState({confirmDialogOpen: true, confirmationResolver: resolve}))
        
        // if(this.state.priceList.length && !this.state.priceList[0].price) {
        //     const confirmation = await confirmPublishingFreeFolder();
        //     self.setState({confirmDialogOpen: false, confirmationResolver: null});
        //     if(!confirmation) {
        //         return;
        //     }
        // }

        self.setState({ saving: true });
        const { pluginId, itemId } = this.props.context;
        console.log(itemId, pluginId);

        const imageUrl = this.state.banner[0].mediaUrl;
        let url = null;
        if (imageUrl.indexOf('unsplash.com') > 0 || imageUrl === 'https://cdn.socionity.com/assets/build.socionity.com/welcome.jpeg') {
            url = {url: imageUrl}
        } else {
            url = await Servercalls.uploadToS3(this.state.banner[0].mediaObject)
        }
        if(!itemId) {
            const newBundle = await Servercalls.pluginpremier_addBundle(pluginId, null, this.state.title, this.state.description, url.url, "", "", true, "chronological", "", "", "", "", "" , "");
            const bundleData = newBundle.data;

            console.log(bundleData)

            await Servercalls.pluginpremier_updateBundle(pluginId, bundleData.itemId, "priceList", [{currency: this.state.currency, price: this.state.price}]);
            await Servercalls.pluginpremier_updateBundle(pluginId, bundleData.itemId, "liveSchedule", this.getLiveSchedule(bundleData.itemId));
            await Servercalls.pluginpremier_updateBundle(pluginId, bundleData.itemId, "shareDiscount", this.state.discountCheck);
            await Servercalls.pluginpremier_updateBundle(pluginId, bundleData.itemId, "questionnaireLink", this.state.questionnaireLink);
            await Servercalls.pluginpremier_updateBundle(pluginId, bundleData.itemId, "validFor", this.state.validFor);
        } else {

        //\ (pluginId, bundleId, name, description, imageUrl, price, currency, published, ordering, audience, validity, longDescription, tags, salesPage, pixels )

            // await Servercalls.pluginpremier_updateBundle(pluginId, itemId, "name", this.state.name);
            // await Servercalls.pluginpremier_updateBundle(pluginId, itemId, "description", this.state.description);
            // await Servercalls.pluginpremier_updateBundle(pluginId, itemId, "imageUrl", this.state.imageUrl);
            // await Servercalls.pluginpremier_updateBundle(pluginId, itemId, "priceList", this.state.availableForPurchase ? this.state.priceList : []);
            // await Servercalls.pluginpremier_updateBundle(pluginId, itemId, "published", this.state.publishOnSave);
        }

        // window.analytics.track('Folder Confirm Clicked', {'count_content_type' : this.state.contents.length});

        self.setState({ saving: false, saved: true });
        setTimeout(() => {
            self.props.back();
        }, 3000);

    }

    getLiveSchedule(itemId) {
        const date = this.state.startDate.split('T')[0];
        const time = this.state.startTime;

        const firstStart = new Date(date+'T'+time);
        const firstEnd = new Date(date+'T'+this.state.endTime);

        const durationMil = firstEnd - firstStart;
        let liveSchedule = [{
            title: 'Session 1',
            description: 'Today\'s session will be from '+ moment(firstStart).format('hh:mm a') + ' to ' + moment(firstEnd).format('hh:mm a'),
            startTime: firstStart.getTime(),
            endTime: firstStart.getTime() + durationMil,
            sessionId: `${itemId}-${firstStart.getTime()}`,
        }];
        
        if (this.state.repeat === 'Custom') {
            let a = [];
            let d = new Date(this.state.startDate);
            let startingDay = d.getDay();

            for (let index = 0; index < 7; index++) {
                if (this.state.daysOfWeek[(index+startingDay)%7])
                    a.push(index)
            }
            let b = [];
            for (let index = 0; index < a.length - 1; index++) {
                b.push(a[index+1]-a[index])
            }
            b.push(a[0]+7-a[a.length-1])

            const totalSessions = Object.values(this.state.daysOfWeek).filter(val => val).length * (this.state.days/7)

            for (let index = 0; index < totalSessions - 1; index++) {
                liveSchedule.push({
                    title: `Session ${index+2}`,
                    description: 'Today\'s session will be from '+ moment(firstStart).format('hh:mm a') + ' to ' + moment(firstEnd).format('hh:mm a'),
                    startTime: liveSchedule[liveSchedule.length-1].startTime + (b[index%b.length]*86400000),
                    endTime: liveSchedule[liveSchedule.length-1].endTime + (b[index%b.length]*86400000),
                    sessionId: `${itemId}-${liveSchedule[liveSchedule.length-1].startTime + (b[index%b.length]*86400000)}`,
                })            
            }
        }
        else if (this.state.repeat === 'Daily') {
            const totalSessions = this.state.days;
            for (let index = 0; index < totalSessions - 1; index++) {
                liveSchedule.push({
                    title: `Session ${index+2}`,
                    description: 'Today\'s session will be from '+ moment(firstStart).format('hh:mm a') + ' to ' + moment(firstEnd).format('hh:mm a'),
                    startTime: liveSchedule[liveSchedule.length-1].startTime + (86400000),
                    endTime: liveSchedule[liveSchedule.length-1].endTime + (86400000),
                    sessionId: `${itemId}-${liveSchedule[liveSchedule.length-1].startTime + (86400000)}`,
                })            
            }
        }
        return liveSchedule;
    }

    renderDialogs() {
        return (
            <>
                <ConfirmationDialog open={this.state.confirmDialogOpen} resolve={this.state.confirmationResolver}/>
                <ConfirmUnsavedDialog open={this.state.confirmUnsavedDialogOpen} resolve={this.state.confirmUnsavedResolver} />
                <SuccessDialog open={this.state.saved} text="Content updated successfully"/>
            </>
        )
    }

    renderDetails() {
        return (
            <>
                <BannerUploadCard
                    bannerUrl={this.state.banner[0].mediaUrl}
                    handleSubmit={event => {
                        const banner = Object.keys(event.target.files).map(key => {
                            const file = event.target.files[key];
                            return ({mediaUrl: URL.createObjectURL(file), mediaObject: file})
                        });
                        this.setState({banner});
                    }}
                    bannerInputRef={this.bannerInputRef}
                />

                <TextInputCard
                    title={'Course Title*'}
                    subtitle={'Add a catchy title to boost your sales'}
                    placeholder={'Title'}
                    value={this.state.title}
                    // onChange={e => this.setState({title: e.target.value, titleError: false})}
                    onChange={(e) => {
                        this.setState({ title: e.target.value, titleError: false });
                        const imageUrl = this.state.banner[0].mediaUrl;
                        if (imageUrl && (imageUrl.indexOf('unsplash.com') > 0 || imageUrl === 'https://cdn.socionity.com/assets/build.socionity.com/welcome.jpeg')) {
                            const self = this;
                            Servercalls.getThumbnails(e.target.value).then((response) => {
                                self.setState({ banner: [{
                                    mediaUrl: response.data[0],
                                    mediaObject: {},
                                }]});
                            });
                        }
                    }}
                    maxLength={120}
                    error={this.state.titleError}
                />

                <DescriptionInputCard
                    title={'Course Description*'}
                    subtitle={'Add a description to boost your sales by 2x'}
                    value={this.state.description}
                    onChange={e => this.setState({description: e, descriptionError: false})}
                    error={this.state.descriptionError}
                />

                <StartingFromCard
                    startDate={this.state.startDate}
                    setStartDate={e => this.setState({startDate: e, startDateError: false})}
                    startTime={this.state.startTime}
                    setStartTime={e => this.setState({startTime: e.target.value, startDateError: false})}
                    endTime={this.state.endTime}
                    setEndTime={e => this.setState({endTime: e.target.value, startDateError: false})}
                    error={this.state.startDateError}
                />

                <CourseDurationCard
                    duration={this.state.duration}
                    setDuration={e => this.setState({duration: e.target.value, presetDuration: 0})}
                    presetDuration={this.state.presetDuration}
                    setPresetDuration={day => this.setState({presetDuration: day, duration: ""})}
                    repeat={this.state.repeat}
                    setRepeat={(repeat) => this.setState({repeat})}
                    days={this.state.days}
                    setDays={(days) => this.setState({days})}
                    daysOfWeek={this.state.daysOfWeek}
                    setDaysOfWeek={(daysOfWeek) => this.setState({daysOfWeek})}
                    toggleDay={(day) => {
                        const {daysOfWeek} = this.state;
                        daysOfWeek[day] = !daysOfWeek[day];
                        this.setState({daysOfWeek});
                    }}
                    validFor={this.state.validFor}
                    setValidFor={(validFor) => this.setState({validFor})}
                />

                <TextInputCard
                    title={'Questionnaire Link'}
                    subtitle={'Add the link to Google Form'}
                    placeholder={'form'}
                    value={this.state.questionnaireLink}
                    onChange={(e) => {
                        this.setState({ questionnaireLink: e.target.value});
                    }}
                />
            </>
        )
    }

    renderPricing() {
        return (
            <>
                <AddPriceCard
                    title={'Add Price'}
                    value={this.state.price}
                    onChange={e => this.setState({price: e.target.value, priceError: false})}
                    currency={this.state.currency}
                    setCurrency={currency => this.setState({currency})}
                    error={this.state.priceError}
                />

                <DiscountToggleCard
                    discountCheck={this.state.discountCheck}
                    setDiscountCheck={(e) => this.setState({discountCheck: e})}
                />
            </>
        )
    }

    renderPreview() {

        

        return (
            <>
                <FolderDetails
                    bundle={{
                        imageUrl: this.state.banner[0].mediaUrl,
                        name: this.state.title,
                        description: this.state.description,
                    }}
                    liveSchedule={this.getLiveSchedule()}
                    startDate={this.state.startDate}
                    validFor={this.state.validFor}
                />
            </>
        )
    }

    renderContent() {
        const {activeIndex} = this.state;
        if (activeIndex === 0) {
            return this.renderDetails();
        }
        if (activeIndex === 1) {
            return this.renderPricing();
        }
        if (activeIndex === 2) {
            return this.renderPreview();
        }
    }

    checkError() {
        const {activeIndex} = this.state;
        if (activeIndex === 0) {
            let error = false;
            if (this.state.title === '') {
                error = true;
                this.setState({titleError: 'Course title is mandatory'})
            }
            if (this.state.description === '') {
                error = true;
                this.setState({descriptionError: 'Course Description is mandatory'})
            }
            if (!this.state.startDate || this.state.startTime === '' || this.state.endTime === '') {
                error = true;
                this.setState({startDateError: 'A Start Date is mandatory'})
            }
            if (error) return false
        }
        if (activeIndex === 1) {
            if (this.state.price === '') {
                this.setState({priceError: 'This field cannot be empty'});
                return false;
            }
        }
        return true
    }

    render() {
        return (
            <div className={this.props.classes.root}>
                <WsHeader 
                    back={async() => {
                        const self = this;
                        let confirmUnsaved = () => new Promise(resolve => self.setState({confirmUnsavedDialogOpen: true, confirmUnsavedResolver: resolve}))
                        const confirmation = await confirmUnsaved();
                        self.setState({confirmUnsavedDialogOpen: false, confirmUnsavedResolver: null});

                        if(!confirmation) {
                            return;
                        } else {
                            this.props.back()
                        }
                    }} 
                    title={this.props.context.title || "Add Live Course"}
                    style={{position: 'sticky'}}
                />

                <StyledStepper activeIndex={this.state.activeIndex} setActiveIndex={(activeIndex) => this.checkError() && this.setState({activeIndex})} />
                
                {window.innerWidth >= 800 ? (
                    <div style={{height: '56px'}} />
                ) : null}
                {this.renderContent()}

                <LargeCTA onClick={() => {
                    if (this.state.activeIndex === 2) {
                        this.save();
                        return;
                    }
                    if (!this.checkError()) return;
                    this.setState({activeIndex: this.state.activeIndex+1})
                }} 
                    label={this.state.activeIndex === 2 ? 'Publish Now' : 'Next'}
                    subtitle={this.state.activeIndex === 2 ? 'Start Earning from Day 1' : false}
                 />

                {this.renderDialogs()}
            </div>
        )
    }
}

export default withStyles(styles)(AddLiveCourse);