/* eslint-disable */
import React from 'react';
import WsHeader from '../Components/wsheader';
import Theme from '../Components/theme';
import { Stepper, Step, StepLabel, StepContent, Button, Typography, Dialog, DialogTitle, DialogContent, TextField, DialogActions, Icon, CircularProgress, Paper, MenuItem, Select, InputLabel, FormControl, StepConnector, MuiThemeProvider, createMuiTheme, Input, IconButton, Card, CardContent, LinearProgress } from '@material-ui/core';

import ReactPixel from 'react-facebook-pixel';
import amplitude from 'react-amplitude-js';
import Illustration from '../Components/illustration';
import RoundedButton from '../Components/roundedbutton';

import Stars from '../Assets/Pictures/stars.png';
import PhoneImage from '../Assets/Pictures/phone.png';
import SocionityWhite from '../Assets/Pictures/socionitywhite.png';
import StyledSelect from '../Components/StyledSelect';

ReactPixel.init('2108565599460395', {}, {autoConfig: true,debug: false});

const Servercalls = require('../Utils/Servercalls');
const Constants = require('../Utils/Constants');

class AnimatedDiv extends React.Component {
    state = {
        animating: false,
        circularProgressAnimating: false,
        checkCircleAnimating: false,
        cardCollapsing: false,
    }
    componentDidUpdate(prevProps) {
        if (!this.state.animating && !prevProps.animating && this.props.animating) {
            this.setState({animating: true, circularProgressAnimating: true}, () => {
                setTimeout(() => this.setState({circularProgressAnimating: false, checkCircleAnimating: true}, () => {
                    setTimeout(() => this.setState({checkCircleAnimating: false, cardCollapsing: true}, () => {
                        setTimeout(() => this.props.incrementAnimation(), 3000);
                    }), 1000);
                }), 500)
            })
        }
    }
    render() {
        return (
            <div style={{
                display: 'flex',
                backgroundColor: this.state.cardCollapsing ? 'transparent' : '#17132d',
                transition: 'background-color 2s, height 2s, transform 2s',
                border: this.state.cardCollapsing ? 'none' : 'solid 1px #251d41',
                borderRadius: '12px',
                color: 'white',
                padding: '12px 16px',
                alignItems: 'center',
                height: this.state.cardCollapsing ? '2.5vh' : '10vh',
                // justifyContent: 'center',
                width: '85%',
                margin: '0 auto 12px',
            }}>
                
                
                <div style={{display: 'flex', width: 'fit-content', alignItems: 'center', margin: '0 16px'}}>
                    <div style={{position: 'absolute', visibility: this.state.cardCollapsing ? 'visible' : 'hidden', transition: 'opacity 2s 1s, visibility 10s', opacity: this.state.cardCollapsing ? '1' : '0', display: 'flex'}}>
                    <Icon style={{
                            fontSize: '1.13em',
                            color: '#fff',
                            border: 'solid 2px rgb(37, 32, 64)',
                            borderRadius: '50%',
                            padding: '8px',
                            marginRight: '16px',
                            background: 'rgba(0, 0, 0, 0.08)'
                        }}>{this.props.icon}</Icon>
                        <div style={{display: 'flex', flexDirection: 'column', margin: '0 24px', justifyContent: 'center'}}>
                            <Typography variant='body2' style={{fontSize: '0.75rem'}}>{this.props.textPrimary}</Typography>
                        </div>
                    </div>
                    
                    <div style={{visibility: this.state.cardCollapsing ? 'hidden' : 'visible', transition: 'opacity 2s, visibility 10s', opacity: this.state.cardCollapsing ? '0' : '1', display: 'flex', alignItems: 'center'}}>
                        <Icon style={{
                                fontSize: '1.503em',
                                color: '#fff',
                                border: 'solid 1px #c1c1c1',
                                borderRadius: '50%',
                                padding: '8px',
                                marginRight: '16px',
                                background: 'rgba(0, 0, 0, 0.08)',
                            }}>{this.props.icon}</Icon>
                        <div style={{display: 'flex', flexDirection: 'column', margin: '20px 24px', alignItems: 'flex-start', textAlign: 'left'}}>
                            <Typography variant='body1' style={{fontSize: '0.875rem'}}>{this.props.textPrimary}</Typography>
                            <Typography variant='caption' style={{color: 'rgba(255, 255, 255, 0.7)', fontSize: '0.75rem'}}>{this.props.textSecondary}</Typography>
                        </div>
                    </div>
                </div>


                <div style={{height: '24px', width: '24px', marginLeft: 'auto'}}>
                    
                    {this.state.animating ? (<> 
                        {this.state.circularProgressAnimating || this.state.checkCircleAnimating ? 
                            <CircularProgress style={{
                                color: 'white',
                                height: '24px',
                                width: '24px',
                                position: 'relative',
                                top: '0px',
                                opacity: this.state.checkCircleAnimating ? 0 : 1,
                                transition: 'opacity 1s',
                            }}/> : 
                        null}
                        <Icon style={{
                            opacity: this.state.checkCircleAnimating || this.state.cardCollapsing ? 1 : 0,
                            transition: 'opacity 2s, transform 2s',
                            position: 'relative',
                            top: this.state.cardCollapsing ? '0' : '-28px',
                            left: this.state.cardCollapsing ? '0' : '0px',
                            transform: this.state.cardCollapsing ? 'scale(0.7)' : 'scale(1)',
                        }}>check_circle</Icon>
                    </>)
                    : <div style={{backgroundColor: 'rgb(25, 22, 44)', height: '24px', width: '24px', borderRadius: '50%', border: '1px solid black'}} />}
                </div>
                
            </div>
        )
    }
}

class Wizard extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            step: 1,
            onboardingIndex: 0,
            preWizard: false,
            showWizard: false,
            loading: true,
            loadingIndex : 1,
            name: `${this.props.name || 'Your Name'}-${parseInt(Math.random()*1000)}'s App`,
            logo: this.props.logo || "http://socionity.com/socionity_logo.png",
            phone: "",

            countryCode: '+91',
            industry: '',

            animatingElementIndex: -2,
        }
    }

    showLoading(index) {
        const self = this;
        return new Promise((r, x) => {
            self.setState({ loadingIndex : index });
            setTimeout(() => r(), 2000);
        })
    }

    componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
    }
      
    updateWindowDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    async componentDidMount() {
        const self = this;
        self.setState({ loading: true });
        Servercalls.getApp().then((response) => {
            if(response.data){
                self.setState({ loading: false });

                // empty function
                self.goToBuilder();
            }
            else {
                amplitude.getInstance().logEvent("wizard-open");

                window.analytics.track('Onboarding Form Viewed', {});
                self.setState({ preWizard: this.props.youtube?false: true });
            }            
        }).catch(e => {

            // @madhavan why are we logging this as wizard open
            amplitude.getInstance().logEvent("wizard-open");
            self.setState({ preWizard: this.props.youtube?false: true });
        })

        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    exit() {
        window.analytics.track('Onboarding Form Completed', {
            'app_name': this.state.name,
            'mobile_number': this.state.phone, 
            'industry': this.state.industry,
        });

        amplitude.getInstance().logEvent("end-wizard");
        ReactPixel.track('StartTrial');
        this.setState({ showWelcome: true, founder: Date.now() % 2 == 0 ? "madhavan" : "harsha" });
    }

    async createApp() {
        const self = this;
        let loadingAnimation = () => new Promise(resolve => self.setState({animatingElementIndex: 0, loadingResolver: resolve}))
        
        // @madhavan should we be using Promise.all() instead of multiple await in line
        window.analytics.track('Loading Screen Viewed', {
            'app_name': this.state.name,
            'mobile_number': this.state.phone, 
            'industry': this.state.industry,
        }); 

        await loadingAnimation();
        this.setState({ creatingApp : true });
        amplitude.getInstance().logEvent("end-wizard");
        let domain = this.state.name.toLowerCase().replace(/[^0-9a-z]/gmi,"");
        
        self.setState({ saving: true });
        try{
            await Servercalls.updateApp(this.state.name, this.state.logo,domain+".nity.app", 'NONE', false);
        }
        catch(e){
            this.setState({ nameError: "Please select another name for your app"});
            return;
        }
        try{
        const theme = { bg: "#020f13", fg: "#042229", primary: "#f3e100", accent: "#fff", mainfont: 'Poppins', headingfont: "Anton" };
        await Servercalls.addPlugin('Premium',"whatshot","premium");
        await Servercalls.addPlugin('One on One',"call","booking2");
        await Servercalls.addPlugin('Notifications',"notifications","user");    
        localStorage.setItem('onboarding_index', 0);
        localStorage.setItem('onboarding_stages', 'tabs_onlylivestream,onlylivestream_nextsession,onlylivestream_startstream,openapp,contact,appsettings');
        await Servercalls.updateBuilderPhone(this.state.phone);
        await Servercalls.updateAppTheme(theme);
        if(this.state.industry)
            await Servercalls.updateAppDetails("industry", this.state.industry);
        
        
        this.props.exit();

        }
        catch(e){
            this.setState({ creatingApp: false });
        }


    }

    applyCoupon() {
        if(!this.state.coupon || this.state.coupon === ""){
            return this.setState({ couponError: "Invalid Referral Code"});
        }
        const self = this;
        self.setState({ applyingCoupon: true });
        Servercalls.updateAppCoupon(this.state.coupon).then(response => {
            self.setState({ applyingCoupon: false });
            self.props.exit();
        }).catch(e => {self.setState({ couponError: "Invalid referral code", applyingCoupon: false })});
    }

    next = () => {
        const self = this;

        if (this.state.onboardingIndex === 1 && this.state.phone.length && (this.state.phone.length !== 10 || !/^\d{10}$/.test(this.state.phone))) {
            self.setState({phoneError: true})
            return;
        }

        if(this.state.onboardingIndex === 2)
            // this.createApp()
            setTimeout(() => self.setState({animatingElementIndex: -1}, () => {
                setTimeout(() => this.createApp(), 2000);
            }), 250);
        this.setState({ onboardingIndex: this.state.onboardingIndex + 1})
    }

    renderLoading(){
        if(this.state.loadingIndex){
            const loadingIndex = this.state.loadingIndex;
            let loadingText;
            let loadingIllustration;
            switch(loadingIndex){
                case 1:
                    loadingText = "Setting up your account and app builder";
                    loadingIllustration = "appbuild";
                    break;
                case 2:
                    loadingText = "Setting up community features";
                    loadingIllustration = "groupchat";
                    break;
                case 3: 
                    loadingText = "Setting up payment gateway";
                    loadingIllustration = "payment";
                    break;
                case 4:
                    loadingText = "Setting up analytics features ";
                    loadingIllustration = "analytics";
                    break;

            }
            // return <div style={{ paddingTop: 12 }}>
            //         <center>
            //             <Typography variant="h6">Hold tight while we are setting up your app! </Typography>
            //             <Typography variant="body1" style={{ marginTop: 12 }}>{loadingText}</Typography>                        
            //             <CircularProgress style={{ marginTop: 24 }} /><br />
            //             <Illustration image={loadingIllustration} style={{ width: '100%', borderTop: 24 }} />
            //             <br />
            //         </center>
            //     </div>
            return (
                <div style={{
                        display: 'flex',
                        flex: this.props.alwaysOpen ? '1' : '0.8',
                        justifyContent: this.props.alwaysOpen ? 'center' : 'flex-start',
                        flexDirection: 'column',
                        alignItems: 'center',
                        textAlign: 'center',
                        margin: '16px',
                        width: 'calc(100% - 36px)',
                        zIndex: '1000',
                        maxWidth: '500px'}}>
                    <img style={{borderRadius: '25px', zIndex: '10', boxShadow: 'rgba(0, 0, 0, 0.32) 0px 0px 16px 0px', width: 'calc(min(14.11vh, 92px)', height: 'calc(min(14.11vh, 92px)', objectFit: 'cover'}} src={this.state.logo} />
                    <Typography 
                        style={{fontWeight: 'bold', margin: '24.5px 0', lineHeight: '1.24', letterSpacing: 'normal', color: '#fff'}}
                        variant='h6'
                    > {this.state.name}</Typography>
                    <div style={{backgroundColor: 'white', width: '100%', height: '0.4em', borderRadius: '1em', marginBottom: '24.5px'}}>
                        <LinearProgress color='secondary' 
                            style={{
                                backgroundColor: 'transparent',
                                marginBottom: '32px',
                                width: '101%',
                                borderRadius: '1em',
                                zIndex: '10',
                                height: '100%',
                                marginLeft: '-1px',
                            }}
                            variant="determinate"
                            value={this.state.animatingElementIndex*33.33}
                        />
                    </div>
                    {/* transform: `translateY(-${3*this.state.animatingElementIndex}vh)` */}
                    <div style={{maxHeight: '15vh', width: '100%'}}>
                        <div style={{ transition: 'transform 2s', marginTop: '12px', display: 'flex', flexDirection: 'column'}}>
                            {[{
                                icon: 'person',
                                textPrimary: 'Account and app builder',
                                textSecondary: 'Setting up …'
                            }, {
                                icon: 'send',
                                textPrimary: 'Community features',
                                textSecondary: 'Setting up …'
                            }, {
                                icon: 'credit_card',
                                textPrimary: 'Payment and Analytics',
                                textSecondary: 'Setting up …'
                            }].map((item, index) => (
                                <AnimatedDiv 
                                    animating={this.state.animatingElementIndex === index}
                                    incrementAnimation={() => {
                                        if(index === 2) this.state.loadingResolver()
                                        this.setState({animatingElementIndex: index+1})
                                    }}
                                    icon={item.icon}
                                    textPrimary={item.textPrimary}
                                    textSecondary={item.textSecondary}
                                /> 
                            ))}
                        </div>
                    </div>
                </div>
            )
        }
    }

    // testing increased font size for bigger screen
    // renderAppNameInput() {
    //     return (
    //         <div style={{display: 'flex', flexDirection: 'row'}}>
    //             <div style={{display: 'flex', flex: '1', flexDirection: 'column', padding: '0 52.95px 99.75px 0', marginTop: 'calc(max(9%, 77.25px))', marginLeft: 'calc(max(58.5px, 6%))'}}>
    //                 <Typography style={{fontWeight: 'bold', fontSize: '1.695em', marginBottom: '47.25px', lineHeight: '1.2525'}} variant='body1'>A name for your app</Typography>
    //                 <Input 
    //                     defaultValue={this.state.name}
    //                     style={{marginRight: 'auto', marginBottom: '13.875px'}}
    //                     inputProps={{style: {
    //                         paddingTop: '0',
    //                         paddingBottom: '13.875px',
    //                         fontSize: '1.695em',
    //                         lineHeight: '1.2525',
    //                     }}} 
    //                     error={this.state.nameError}
    //                     placeholder='Enter a name for your app'
    //                     fullWidth={true}
    //                     onChange={event => this.setState({ name: event.target.value })} 
    //                 />
    //                 <Typography variant='caption' style={{marginBottom: '58.5px', fontSize: '0.945em', lineHeight: '2.25'}}>Eg: Your name, John’s Health tour...</Typography>
    //                 <Typography variant='caption' style={{fontSize: '0.945em', lineHeight: '2.775', color: ''}}>Tip : You can change or upload your own icon later</Typography>
    //             </div>
            
    //             {/* <img src={PhoneImage} style={{flex: '1', objectFit: 'contain'}} /> */}
    //             <div style={{flex: '0.71', display: 'flex', marginTop: '-2.655vw', marginRight: '15px', marginBottom: '7.5px', maxWidth: '417.375px', minWidth: '260.295px', height: '100%'}}>
    //                 <img src={PhoneImage} style={{flex: '1', maxWidth: '100%'}} />

    //                 <div style={{position: 'relative', top: '25%', left: '-88%', display: 'flex', flexDirection: 'column', alignItems: 'center', height: '13.06vw', minWidth: '25%'}}>
    //                     <img style={{ borderRadius: '13px', zIndex: '10', maxHeight: '100%', boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)' }} src={this.state.logo} />
    //                 </div>
    //             </div>
    //         </div>
    //     );
    // }

    renderAppNameInput() {
        return (
            <div style={{display: 'flex', flexDirection: 'row'}}>
                <div style={{display: 'flex', flex: '1', flexDirection: 'column', padding: '0 35.3px 66.5px 0', marginTop: this.props.alwaysOpen ? 'calc(max(9%, 51.5px))' : '35.5px', marginLeft: 'calc(max(39px, 6%))'}}>
                    <Typography style={{fontWeight: 'bold', fontSize: '1.13em', marginBottom: this.props.alwaysOpen ? '31.5px' : '24.5px', lineHeight: '0.835'}} variant='body1'>A name for your app</Typography>
                    
                    {this.props.alwaysOpen ? null : (
                        <div style={{display: 'flex', justifyContent: 'center', marginBottom: '24.5px'}}>
                            <img style={{ 
                                borderRadius: '13px',
                                zIndex: '10',
                                maxHeight: '100%',
                                boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)',
                                height: '10.7vh',
                            }} src={this.state.logo} />
                        </div>
                    )}
                    
                    <Input 
                        defaultValue={this.state.name}
                        style={{marginRight: 'auto', marginBottom: '9.25px'}}
                        inputProps={{style: {
                            paddingTop: '0',
                            paddingBottom: '9.25px',
                            fontSize: '1.13em',
                            lineHeight: '0.835',
                        }}} 
                        error={this.state.nameError}
                        placeholder='Enter a name for your app'
                        fullWidth={true}
                        onChange={event => this.setState({ name: event.target.value })} 
                    />
                    <Typography variant='caption' style={{marginBottom: '39px', fontSize: '0.73em', lineHeight: '1.5'}}>Eg: Your name, John’s Health tour...</Typography>
                    <Typography variant='caption' style={{fontSize: '0.73em', lineHeight: '1.85', color: ''}}>Tip : You can change or upload your own icon later</Typography>
                </div>
            
                {/* <img src={PhoneImage} style={{flex: '1', objectFit: 'contain'}} /> */}
                {!this.props.alwaysOpen ? null : <div style={{flex: '0.71', display: 'flex', marginTop: '-1.77vw', marginRight: '20px', marginBottom: '5px', maxWidth: '278.25px', minWidth: '173.53px'}}>
                    <img src={PhoneImage} style={{flex: '1', maxWidth: '100%'}} />

                    <div style={{position: 'relative', top: '25%', left: '-88%', display: 'flex', flexDirection: 'column', alignItems: 'center', height: '8.71vw', minWidth: '25%'}}>
                        <img style={{background: 'white', borderRadius: '13px', zIndex: '10', maxHeight: '100%', boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)' }} src={this.state.logo} />
                    </div>
                </div>}
            </div>
        );
    }

    renderPhoneNumberInput() {
        const countryCodeList = [{
            label: '+91',
            value: '+91',
        }, {
            label: '+92',
            value: '+92',
        }];
        return (
            <div style={{display: 'flex', flexDirection: 'row'}}>
                {this.props.alwaysOpen ? (
                    <IconButton
                        onClick={() => this.setState({onboardingIndex : 0, phoneError: false})}
                        style={{marginTop: '11.25px', marginLeft: '11.25px', position: 'absolute', padding: '8px', backgroundColor: '#e4ddff'}}
                    >
                        <Icon color='secondary' style={{fontSize: '1rem'}}>arrow_back</Icon>
                    </IconButton>
                ) : null }
                <div style={{display: 'flex', flex: '1', flexDirection: 'column', padding: '0 35.3px 66.5px 0', marginTop: this.props.alwaysOpen ? 'calc(max(9%, 51.5px))' : '35.5px', marginLeft: 'calc(max(39px, 6%))'}}>
                    <Typography style={{fontWeight: 'bold', fontSize: '1.13em', marginBottom: this.props.alwaysOpen ? '31.5px' : '24.5px', lineHeight: this.props.alwaysOpen ? '0.835em' : '1.25em'}} variant='body1'>Enter your Phone Number</Typography>
                    <div style={{display: 'flex', alignItems: 'baseline'}}>
                        <StyledSelect
                            itemList={countryCodeList}
                            value={this.state.countryCode}
                            onChange={(event) => this.setState({countryCode: event.target.value})}
                            variant='underlined'
                            style={{
                                overflow: 'visible',
                                padding: '10.5px 15.5px 8.5px 5px',
                                fontSize: '1.13em',
                                width: '5ch',
                                fontWeight: 'bold',
                            }}
                        />
                        <Input 
                            value={this.state.phone}
                            style={{marginRight: 'auto', marginBottom: '9.25px', marginLeft: '17.5px'}}
                            inputProps={{style: {
                                paddingTop: '0',
                                paddingBottom: '9.25px',
                                fontSize: '1.13em',
                                lineHeight: '0.835',
                                fontWeight: 'bold',
                                letterSpacing: '3.6px',
                            }}}
                            fullWidth={true}
                            placeholder='9808087098'
                            onChange={event => this.setState({ phone: event.target.value, phoneError: false })}
                        />
                    </div>
                    <Typography variant='caption' style={{marginBottom: '39px', fontSize: '0.73em', lineHeight: '1.5'}}>Don’t worry, We will not SPAM you. We need this only for verification purpose. </Typography>
                    <Typography variant='caption' style={{fontSize: '0.73em', lineHeight: '1.85', color: 'red'}}>{ this.state.phoneError ? 'Please enter a 10 digit phone number' : null}</Typography>
                </div>
            </div>
        )
    }

    renderIndustryInput() {
        const industryList = [{
            label: 'Education',
            value: 'Education',
        }, {
            label: 'Fitness',
            value: 'Fitness',
        }, {
            label: 'Entertainment',
            value: 'Entertainment',
        }, {
            label: 'Lifestyle',
            value: 'Lifestyle',
        }, {
            label: 'Other',
            value: 'Other',
        }];
        return (
            <div style={{display: 'flex', flexDirection: 'row'}}>
                {this.props.alwaysOpen ? <IconButton
                    onClick={() => this.setState({onboardingIndex : 1})}
                    style={{marginTop: '11.25px', marginLeft: '11.25px', position: 'absolute', padding: '8px', backgroundColor: '#e4ddff'}}
                >
                    <Icon color='secondary' style={{fontSize: '1rem'}}>arrow_back</Icon>
                </IconButton> : null}
                <div style={{display: 'flex', flex: '1', flexDirection: 'column', padding: '0 35.3px 66.5px 0', marginTop: this.props.alwaysOpen ? 'calc(max(9%, 51.5px))' : '35.5px', marginLeft: 'calc(max(39px, 6%))'}}>
                    <Typography style={{fontWeight: 'bold', fontSize: '1.13em', marginBottom: this.props.alwaysOpen ? '31.5px' : '24.5px', lineHeight: this.props.alwaysOpen ? '0.835em' : '1.25em'}} variant='body1'>Which industry will you be operating in?</Typography>
                    <div style={{display: 'flex', alignItems: 'baseline', marginBottom: '9.25px'}}>
                        <StyledSelect
                            itemList={industryList}
                            onChange={(event) => this.setState({industry: event.target.value})}
                            placeholder='Select an industry'
                            value={this.state.industry}
                            style={{
                                overflow: 'visible',
                                padding: '10.5px 15.5px 8.5px 15.5px',
                                fontSize: '1.13em',
                                width: '100%',
                                fontWeight: 'normal',
                            }}
                            formControlStyle={{
                                flex: '1',
                                borderRadius: '5px'
                            }}
                            iconStyle={{
                                right: '15.5px'
                            }}
                        />
                    </div>
                    <Typography variant='caption' style={{marginBottom: '39px', fontSize: '0.73em', lineHeight: '1.5'}}>This helps us suggest the best features suitable for your business</Typography>
                    <Typography variant='caption' style={{fontSize: '0.73em', lineHeight: '1.85', color: ''}}></Typography>
                </div>
            </div>
        )
    }

    renderStepper() {
        let buttonText = 'Next';
        let buttonColor = 'secondary';
        let disabled = false;
        if (this.state.phoneError) {
            disabled = true;
        } else if (
            (this.state.onboardingIndex === 0 && !this.state.name) ||
            (this.state.onboardingIndex === 1 && !this.state.phone) ||
            (this.state.onboardingIndex === 2 && !this.state.industry)
        ) {
            buttonText = 'Skip';
            buttonColor = 'default';
        }

        return (
            <div style={{display: 'flex', marginLeft: 'calc(max(39px, 6%))', paddingBottom: '25.5px', marginTop: '-48px'}}>
                <Stepper style={{ padding: 0, marginRight: 'auto'}} connector={<></>} activeStep={this.state.onboardingIndex} color="secondary">
                    <Step onClick={() => this.setState({ onboardingIndex : 0 })} style={{ padding: 0, cursor: 'pointer'}}>
                        <StepLabel></StepLabel>
                    </Step>
                    <Step onClick={() => this.setState({ onboardingIndex : 1 })} style={{ padding: 0}}>
                        <StepLabel></StepLabel>
                    </Step>
                    <Step onClick={() => this.setState({ onboardingIndex : 2 })} style={{ padding: 0}}>
                        <StepLabel></StepLabel>
                    </Step>
                </Stepper>

                <RoundedButton
                    disabled={disabled}
                    onClick={() => this.next()}
                    elevation={0}
                    variant="contained"
                    color={buttonColor}
                    style={{ minWidth: 100, float: 'right', marginRight: '31px', marginTop: '-6.25px' }}
                >{buttonText}</RoundedButton>
                
            </div>
        )
    }
    
    render() {
        const theme = createMuiTheme(Constants.theme);
        return (
            <MuiThemeProvider theme={theme}>
                <div style={{ 
                    position: 'absolute',
                    minHeight: '100%',
                    width: '100%',
                    background: `#000000 url(${Stars})`,
                    transition: 'background 250ms',
                    backgroundPosition: 'bottom',
                    backgroundSize: 'calc(max(100vmin, 1920px)) 100%',
                    backgroundRepeat: 'no-repeat',
                    justifyContent: 'center',
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    overflow: 'hidden',
                }}
                >   
                    <div style={{height: '100%', width: '100%', backgroundColor: '#110e21', opacity:  this.state.animatingElementIndex > -2 ? 0.5 : 0, position: 'absolute', transition: 'opacity 1s', pointerEvents: 'none'}} />
                    <header style={{ width: '100%', borderBottom: '1px solid rgba(255,255,255,0.15)', display: 'flex', justifyContent: 'center', marginBottom: 'auto'}}>
                        <div style={{display: 'flex', padding: theme.spacing(2), alignItems: 'center', width: '100%', maxWidth: '1280px'}}>
                        <img src={SocionityWhite} style={{marginBottom: theme.spacing(-0.5), marginRight: 'auto', display: 'flex', maxWidth: '12ch', cursor: 'pointer'}} alt="socionity logo" />
                        
                        {/* Sidenav disabled for now as links dont point anywhere */}
                        {/* {!this.props.alwaysOpen ? <Icon style={{color: '#fff'}}>menu</Icon> : <div style={{display: 'flex', whiteSpace: 'nowrap'}}>
                            <div style={{color: '#fff'}}>Features</div>
                            <div style={{color: '#fff', marginLeft: '24px', marginRight:'24px'}}>Pricing</div>
                            <div style={{color: '#fff'}}>Know More</div>
                        </div>}
                        <div class='side-nav open-menu'>
                            <img class="side-nav-menu white-filter" src="menu.svg" alt="open menu" />
                        </div> */}
                        </div>
                    </header>

                    {this.state.onboardingIndex < 3 ? (
                        <div style={{display: 'flex', flexDirection: 'column', width: this.props.alwaysOpen ? '54.95vw' : 'calc(100% - 32px)', maxWidth: '791.25px', margin: '24px 12px auto'}}>
                            <div style={{color: '#ffffff', position: 'absolute', marginTop: '-64px'}}>
                                <Typography 
                                    style={{fontWeight: 'bold', marginBottom: '6px', lineHeight: '1.24', letterSpacing: 'normal'}}
                                    variant='h6'
                                > Welcome {this.props.name?this.props.name.split(" ")[0]:""}, </Typography>
                                <Typography 
                                    style={{lineHeight: '1.24', letterSpacing: 'normal'}}
                                    variant='h6'
                                > Let's get started in 3 simple steps! </Typography>
                            </div>
                            <Card style={{overflow: 'visible', maxHeight: '431.25px', borderRadius: '5px', backgroundColor: '#fcfcfc'}}>
                                <CardContent style={{padding: '0', display: 'flex', flexDirection: 'column'}}>
                                    {this.state.onboardingIndex === 0 ? this.renderAppNameInput() : null}

                                    {this.state.onboardingIndex === 1 ? this.renderPhoneNumberInput() : null}

                                    {this.state.onboardingIndex === 2 ? this.renderIndustryInput() : null}

                                    {this.renderStepper()}
                                </CardContent>                             
                            </Card>
                        </div>
                    ) : this.renderLoading()}
                
                </div>
            </MuiThemeProvider>
        );

        
    }
}

export default Wizard;