/* eslint-disable */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import {
  Button, Icon, Typography, CardContent, Card, ExpansionPanel,
  ExpansionPanelSummary, ExpansionPanelDetails, ButtonBase, Menu,
} from '@material-ui/core';
import Theme from '../Components/theme';
import WsHeader from '../Components/wsheader';
import WsFooter from '../Components/wsfooter';
import MainCTA from '../Components/MainCTA';
import ContentItem from './contentitem';
import FileUploadButton from '../Components/fileuploadbutton';
import PriceInput from '../Components/priceinput';

import Info from '../Assets/Icons/Info.svg';
import Cross from '../Assets/Icons/Cross.svg';
import RoundedButton from '../Components/roundedbutton';
import StyledDrawer from '../Components/StyledDrawer';

import VimeoIcon from '../Assets/Icons/Vimeo.svg';
import VideoIcon from '../Assets/Icons/Video.svg';
import ZoomIcon from '../Assets/Icons/Zoom.svg';
import ImageIcon from '../Assets/Icons/Image.svg';
import AudioIcon from '../Assets/Icons/Audio.svg';
import PdfIcon from '../Assets/Icons/PDF.svg';
import QuizIcon from '../Assets/Icons/Quiz.svg';
import BlogIcon from '../Assets/Icons/Blog.svg';
import PlusIcon from '../Assets/Icons/PlusSecondary.svg';
import SuccessDialog from '../Components/SuccessDialog';
import StyledInput from '../Components/StyledInput';
import ConfirmationDialog from '../Components/ConfirmationDialog';
import ConfirmUnsavedDialog from '../Components/ConfirmUnsavedDialog';

const Servercalls = require('../Utils/Servercalls');

export default class WsPremiumAddBundle extends React.Component {
  // @dhairya saving props to state is wrong, will take care of it
  // while refactor (requires discussion)
  constructor(props) {
    super(props);
    const { context } = this.props;
    this.state = {
      name: context.name || '',
      description: context.description || '',

      pluginId: context.pluginId,
      itemId: context.itemId || null,
      saving: false,
      saved: false,
      contents: [],
      priceList: context.priceList || [
        {
          currency: 'INR', price: '', strikePrice: 0, subscriptionId: `subscription-price-${Date.now() + Math.random()}`, description: 'Access Pass', days: 30, cashback: 0,
        },
      ],
      availableForPurchase: (context.priceList && context.priceList.length > 0)
        || context.newCollection,
      imageUrl: context.imageUrl || 'https://cdn.socionity.com/assets/build.socionity.com/welcome.jpeg',
      publishOnSave: typeof context.published !== 'undefined' ? context.published : true,

      addContentNotVisible: true,
      addContentStickyButtonShadow: false,

      confirmDialogOpen: false,
      expansionPanelOpen: context.priceList && context.priceList.length !== 1,
    };
    this.uploadedContentCardRef = React.createRef();
    this.addContentCardRef = React.createRef();

    this.infoIconRef = React.createRef();
  }

  componentDidMount() {
    this.loadBundleContents();
    this.updateImage();

    this.checkAddContentInView();
    this.setAddContentInViewListener();
  }

  componentDidUpdate() {
    const { scrollListenerSet } = this.state;
    if (!scrollListenerSet) {
      this.setAddContentInViewListener();
    }
  }

  componentWillUnmount() {
  }

  onNameUpdate() {
    if (this.imageUpdateTimeout) clearTimeout(this.imageUpdateTimeout);
    this.imageUpdateTimeout = setTimeout(() => this.updateImage(), 2000);
  }

  setAddContentInViewListener() {
    const { addContentStickyButtonShadow, addContentNotVisible } = this.state;
    let buttonShadow = addContentStickyButtonShadow;

    const uploadedContentElement = this.uploadedContentCardRef.current;
    const addContentElement = this.addContentCardRef.current;

    const height = window.innerHeight;
    const offset = 128;

    if (uploadedContentElement && uploadedContentElement.offsetParent) {
      this.setState({ scrollListenerSet: true });
      uploadedContentElement.offsetParent.onscroll = () => {
        let inView = true;

        const uploadedContentDimensions = uploadedContentElement.getBoundingClientRect();
        const addContentDimensions = addContentElement.getBoundingClientRect();

        if (addContentDimensions.bottom > height) {
          inView = false;
        }

        if (uploadedContentDimensions.top < offset) {
          buttonShadow = true;
        } else {
          buttonShadow = false;
        }

        if (inView && addContentNotVisible) {
          this.setState({
            addContentNotVisible: false,
            addContentStickyButtonShadow: buttonShadow,
          });
        } else if (!inView && !addContentNotVisible) {
          this.setState({ addContentNotVisible: true, addContentStickyButtonShadow: buttonShadow });
        } else if (buttonShadow !== addContentStickyButtonShadow) {
          this.setState({ addContentStickyButtonShadow: buttonShadow });
        }
      };
    }
  }

  getContentButton(content, index) {
    const { changeWorkspace } = this.props;
    let workspace = 'contentdownloable';
    if (content.type === 'blog') {
      workspace = 'contentblog';
    } else if (content.type === 'embed') {
      workspace = 'contentembed';
    } else if (content.type === 'videolist') {
      workspace = 'contentvideolist';
    } else if (content.type === 'quiz') {
      workspace = 'contentquiz';
    } else if (content.type === 'livestream') {
      workspace = 'contentlivestream';
    } else if (content.type === 'zoom') {
      workspace = 'contentzoom';
    } else if (content.type === 'image') {
      workspace = 'contentimage';
    } else if (content.type === 'video' || content.fileUrl.toLowerCase().endsWith('.mov') || content.fileUrl.toLowerCase().endsWith('.mp4') || content.fileUrl.toLowerCase().endsWith('.webm')) workspace = 'contentvideo';
    else if (content.fileUrl.endsWith('.mp3') || content.fileUrl.endsWith('.ogg')) workspace = 'contentaudio';
    else if (content.fileUrl.endsWith('.pdf')) workspace = 'contentpdf';

    return <Button className={`content_open_${index}`} onClick={() => changeWorkspace(workspace, content.name, content)} color="primary" variant="contained" style={{ marginTop: 16, width: '100%' }}>OPen</Button>;
  }

  getContentOptionsComponent(cardStyle = {}, cardContentStyle = {}) {
    const { context } = this.props;
    // all content left align, doesnt look good on larger screen size
    if (window.innerWidth < 340) {
      return (
        <CardContent style={{
          display: 'flex', padding: '0', flexDirection: 'column', ...cardStyle,
        }}
        >
          <div style={{ margin: '0 -8px 0px', justifyContent: 'center' }}>
            {
                        [
                          { icon: <img alt="zoom" src={ZoomIcon} style={{ height: '100%' }} />, text: 'Zoom', type: 'zoom' },
                          { icon: <img alt="video" src={VideoIcon} style={{ height: '100%' }} />, text: 'Video', type: 'video' },
                          { icon: <img alt="blog" src={BlogIcon} style={{ height: '100%' }} />, text: 'Blog', type: 'blog' },
                          { icon: <img alt="vimeo" src={VimeoIcon} style={{ height: '100%' }} />, text: 'Vimeo', type: 'vimeo' },
                          // eslint-disable-next-line jsx-a11y/img-redundant-alt
                          { icon: <img alt="image" src={ImageIcon} style={{ height: '100%' }} />, text: 'Image', type: 'image' },
                          { icon: <img alt="audio" src={AudioIcon} style={{ height: '100%' }} />, text: 'Audio', type: 'audio' },
                          { icon: <img alt="pdf" src={PdfIcon} style={{ height: '100%' }} />, text: 'PDF', type: 'downloadable' },
                          { icon: <img alt="quiz" src={QuizIcon} style={{ height: '100%' }} />, text: 'Quiz', type: 'quiz' },
                        ].map((option) => (
                          <ButtonBase
                            className={`bundle_addcontent_${option.type}`}
                            onClick={() => {
                              this.addContent(option.type, option.text);
                            }}
                            style={{
                              padding: '6px 6.75px 6px',
                              flex: '1',
                              flexDirection: 'column',
                              maxWidth: '96px',
                            }}
                          >
                            <Card style={{
                              width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '0', maxWidth: '96px', textAlign: 'center', borderRadius: '8px',
                            }}
                            >
                              <Icon style={{
                                fontSize: '2.8em', margin: '14.5px', marginBottom: '12px', marginTop: '16.5px', color: '#ffbf3b',
                              }}
                              >
                                {option.icon}
                              </Icon>
                              <Typography style={{ color: '#999999', marginBottom: '12px' }} variant="caption">
                                {option.text}
                              </Typography>
                            </Card>
                          </ButtonBase>
                        ))
                    }
          </div>
        </CardContent>
      );
    }

    // fixed 4x4 and 1 row, breaks for smaller size
    return (
      <CardContent style={{
        display: 'flex', padding: '0', flexDirection: 'row', flexWrap: 'wrap', ...cardStyle, justifyContent: 'center',
      }}
      >
        {/* For screen less than 340, 2x4 */}
        <div style={{
          display: 'flex', justifyContent: window.innerWidth > 1265 ? 'flex-end' : 'center', flex: '1', ...cardContentStyle,
        }}
        >
          {
                        [
                          { icon: <img alt="zoom" src={ZoomIcon} style={{ height: '100%' }} />, text: 'Zoom', type: 'zoom' },
                          { icon: <img alt="video" src={VideoIcon} style={{ height: '100%' }} />, text: 'Video', type: 'video' },
                          { icon: <img alt="blog" src={BlogIcon} style={{ height: '100%' }} />, text: 'Blog', type: 'blog' },
                          { icon: <img alt="vimeo" src={VimeoIcon} style={{ height: '100%' }} />, text: 'Vimeo', type: 'vimeo' },
                        ].map((option) => (
                          <ButtonBase
                            className={`bundle_addcontent_${option.type}`}
                            onClick={() => { this.addContent(option.type, option.text); }}
                            style={{
                              padding: '6px 6.75px 6px',
                              flex: '1',
                              flexDirection: 'column',
                              maxWidth: '96px',
                            }}
                          >
                            <Card style={{
                              width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '0', maxWidth: '96px', textAlign: 'center', borderRadius: '8px',
                            }}
                            >
                              <Icon style={{
                                fontSize: context.alwaysOpen ? '3.8em' : '2.8em', margin: '14.5px', marginBottom: '12px', marginTop: '16.5px', color: '#ffbf3b',
                              }}
                              >
                                {option.icon}
                              </Icon>
                              <Typography style={{ color: '#999999', marginBottom: '12px' }} variant="caption">
                                {option.text}
                              </Typography>
                            </Card>
                          </ButtonBase>
                        ))
                    }
        </div>
        <div style={{
          display: 'flex', justifyContent: window.innerWidth > 1265 ? 'flex-start' : 'center', flex: '1', ...cardContentStyle,
        }}
        >
          {
                        [
                          // eslint-disable-next-line jsx-a11y/img-redundant-alt
                          { icon: <img alt="image" src={ImageIcon} style={{ height: '100%' }} />, text: 'Image', type: 'image' },
                          { icon: <img alt="audio" src={AudioIcon} style={{ height: '100%' }} />, text: 'Audio', type: 'audio' },
                          { icon: <img alt="pdf" src={PdfIcon} style={{ height: '100%' }} />, text: 'PDF', type: 'downloadable' },
                          { icon: <img alt="quiz" src={QuizIcon} style={{ height: '100%' }} />, text: 'Quiz', type: 'quiz' },
                        ].map((option) => (
                          <ButtonBase
                            className={`bundle_addcontent_${option.type}`}
                            onClick={() => { this.addContent(option.type, option.text); }}
                            style={{
                              padding: '6px 6.75px 6px',
                              flex: '1',
                              flexDirection: 'column',
                              maxWidth: '96px',
                            }}
                          >
                            <Card style={{
                              width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '0', textAlign: 'center', borderRadius: '8px',
                            }}
                            >
                              <Icon style={{
                                fontSize: context.alwaysOpen ? '3.8em' : '2.8em', margin: '14.5px', marginBottom: '12px', marginTop: '16.5px', color: '#ffbf3b',
                              }}
                              >
                                {option.icon}
                              </Icon>
                              <Typography style={{ color: '#999999', marginBottom: '12px' }} variant="caption">
                                {option.text}
                              </Typography>
                            </Card>
                          </ButtonBase>
                        ))
                    }
        </div>
      </CardContent>
    );
  }

  async save() {
    const {
      priceList, name, description, availableForPurchase, publishOnSave,
      imageUrl, contents, pluginId, itemId,
    } = this.state;
    const self = this;

    const confirmPublishingFreeFolder = () => new Promise((resolve) => {
      self.setState({ confirmDialogOpen: true, confirmationResolver: resolve });
    });

    if (priceList.length && !priceList[0].price) {
      const confirmation = await confirmPublishingFreeFolder();
      self.setState({ confirmDialogOpen: false, confirmationResolver: null });
      if (!confirmation) {
        return;
      }
    }

    self.setState({ saving: true });
    if (!itemId) {
      const newBundle = await Servercalls.pluginpremier_addBundle(pluginId, null, name, description, imageUrl, '', '', publishOnSave, 'chronological', '', '', '', '', '', '');
      const bundleData = newBundle.data;
      await Servercalls.pluginpremier_updateBundle(pluginId, bundleData.itemId, 'priceList', availableForPurchase ? priceList : []);
    } else {
      await Servercalls.pluginpremier_updateBundle(pluginId, itemId, 'name', name);
      await Servercalls.pluginpremier_updateBundle(pluginId, itemId, 'description', description);
      await Servercalls.pluginpremier_updateBundle(pluginId, itemId, 'imageUrl', imageUrl);
      await Servercalls.pluginpremier_updateBundle(pluginId, itemId, 'priceList', availableForPurchase ? priceList : []);
      await Servercalls.pluginpremier_updateBundle(pluginId, itemId, 'published', publishOnSave);
    }

    window.analytics.track('Folder Confirm Clicked', { count_content_type: contents.length });

    self.setState({ saving: false, saved: true });
    setTimeout(() => {
      self.props.back();
    }, 3000);
  }

  async addContent(type) {
    const { changeContext, changeWorkspace, context } = this.props;
    const {
      name, description, priceList, imageUrl, availableForPurchase, publishOnSave,
    } = this.state;

    const { pluginId, itemId } = this.state;
    await changeContext('name', name);
    await changeContext('description', description);

    window.analytics.track('Add Content Clicked', { content_type: type });

    changeWorkspace(`add${type}content`, `Add ${name}`,
      {
        itemId,
        pluginId,
        type,

        bundle_name: context.name || '',
        bundle_description: context.description || '',
        bundle_priceList: priceList,
        bundle_imageUrl: imageUrl,
        bundle_availableForPurchase: availableForPurchase,
        bundle_published: publishOnSave,
        getItemId: async () => {
          const newBundle = await Servercalls.pluginpremier_addBundle(pluginId, null, name, description, imageUrl, '', '', publishOnSave, 'chronological', '', '', '', '', '', '');
          const bundleData = newBundle.data;
          await Servercalls.pluginpremier_updateBundle(pluginId, bundleData.itemId, 'priceList', availableForPurchase ? priceList : []);
          return bundleData.itemId;
        },
      });
  }

  async editContent(type, mName, content) {
    const { changeWorkspace, changeContext, context } = this.props;
    const {
      name, description, priceList, imageUrl, availableForPurchase, publishOnSave,
    } = this.state;

    await changeContext('name', name);
    await changeContext('description', description);

    window.analytics.track('Edit Content Clicked', { content_type: type });

    changeWorkspace(`add${type}content`, `Add ${name}`,
      {
        itemId: context.itemId,
        pluginId: context.pluginId,
        ...content,

        bundle_name: context.name || '',
        bundle_description: context.description || '',
        bundle_priceList: priceList,
        bundle_imageUrl: imageUrl,
        bundle_availableForPurchase: availableForPurchase,
        bundle_published: publishOnSave,
      });
  }

  openContent(workspace, content) {
    const { changeWorkspace, context, fileType } = this.props;
    const {
      imageUrl, priceList, availableForPurchase, publishOnSave,
    } = this.state;
    if (workspace === 'contentedit') {
      this.editContent(content.type, content.name, content);
      return;
    }

    let ws = workspace;
    ws = 'contentdownloadable';

    if (content.type === 'blog') {
      ws = 'contentblog';
    }
    if (content.type === 'videolist') {
      ws = 'contentvideolist';
    } else if (content.type === 'quiz') {
      ws = 'contentquiz';
    } else if (content.type === 'videostream') {
      ws = 'contentvideostream';
    } else if (content.type === 'zoom') {
      ws = 'contentzoom';
    } else if (content.type === 'image') {
      ws = 'contentimage';
    } else if (content.fileUrl) {
      if (content.type === 'video' || content.fileUrl.toLowerCase().trim().endsWith('.mov') || content.fileUrl.toLowerCase().trim().endsWith('.mp4') || content.fileUrl.toLowerCase().trim().endsWith('.webm')) {
        ws = 'contentvideo';
      }
      if (content.fileUrl.endsWith('.mp3') || content.fileUrl.endsWith('.ogg')) {
        ws = 'contentaudio';
      }
      if (content.fileUrl.endsWith('.pdf')) {
        ws = 'contentpdf';
      }
      if (content.type === 'blog') {
        ws = 'contentblog';
      }
    }
    const finalWorkspace = workspace === 'contentedit' ? ws : workspace;
    // this.props.pushStack(content.name, o, finalWorkspace, finalWorkspace, content);
    changeWorkspace(finalWorkspace, content.name, {
      itemId: context.itemId,
      pluginId: context.pluginId,
      fileType,
      ...content,

      bundle_name: context.name || '',
      bundle_description: context.description || '',
      bundle_priceList: priceList,
      bundle_imageUrl: imageUrl,
      bundle_availableForPurchase: availableForPurchase,
      bundle_published: publishOnSave,
    });
  }

  async updateImage() {
    const { imageUrl, name } = this.state;
    if (imageUrl && imageUrl !== 'NONE' && imageUrl.indexOf('unsplash.com') < 0) return;
    const imagesResponse = await Servercalls.getThumbnails(name || 'welcome');
    this.setState({ imageUrl: imagesResponse.data[0] });
  }

  loadBundleContents() {
    const { itemId } = this.state;
    const { context } = this.props;
    const self = this;
    if (!itemId) return;
    Servercalls.pluginpremier_getBundleContents(context.pluginId, context.itemId).then((response) => {
      self.setState({ contents: response.data, loading: false });
    }).catch((_e) => self.setState({ loading: false }));
  }

  async move(contentId, direction) {
    const { context } = this.props;
    const newContents = await Servercalls.pluginpremier_moveContentInBundle(context.pluginId, context.itemId, contentId, direction);
    this.setState({ contents: newContents.data });
  }

  // need help, repeating code @madhavan
  checkAddContentInView() {
    const { contents, addContentNotVisible, addContentStickyButtonShadow } = this.state;
    if (!contents.length) return;
    let buttonShadow = addContentStickyButtonShadow;

    const uploadedContentElement = this.uploadedContentCardRef.current;
    const addContentElement = this.addContentCardRef.current;

    const height = window.innerHeight;
    const offset = 128;

    let inView = true;

    const uploadedContentDimensions = uploadedContentElement.getBoundingClientRect();
    const addContentDimensions = addContentElement.getBoundingClientRect();

    if (addContentDimensions.bottom > height) {
      inView = false;
    }

    if (uploadedContentDimensions.top < offset) {
      buttonShadow = true;
    } else {
      buttonShadow = false;
    }

    if (inView && addContentNotVisible) {
      this.setState({ addContentNotVisible: false, addContentStickyButtonShadow: buttonShadow });
    } else if (!inView && !addContentNotVisible) {
      this.setState({ addContentNotVisible: true, addContentStickyButtonShadow: buttonShadow });
    } else if (buttonShadow !== addContentStickyButtonShadow) {
      this.setState({ addContentStickyButtonShadow: buttonShadow });
    }
  }

  renderAvailableForPurchaseButtons() {
    const { context } = this.props;
    const { priceList, availableForPurchase } = this.state;
    return (
      <div style={{
        marginTop: context.alwaysOpen ? '10px' : '26px', display: 'flex', alignItems: 'center',
      }}
      >
        <Typography variant="body2" style={{ fontWeight: context.alwaysOpen ? 'normal' : 'bold', marginRight: context.alwaysOpen ? '32px' : 'auto', alignSelf: 'center' }}>
          Available for purchase
        </Typography>
        <div style={{ display: 'flex', alignItems: 'stretch' }}>
          <RoundedButton
            onClick={() => this.setState({
              availableForPurchase: true,
              priceList: priceList.length ? priceList : [
                {
                  currency: 'INR', price: '', strikePrice: 0, subscriptionId: `subscription-price-${Date.now() + Math.random()}`, description: 'Access Pass', days: 30, cashback: 0,
                },
              ],
            })}
            style={{ padding: '7px 8px', marginRight: '16px' }}
            color="secondary"
            variant={availableForPurchase ? 'contained' : 'outlined'}
          >
            <span style={{ fontSize: '0.88rem', lineHeight: '1rem' }}>Yes</span>
          </RoundedButton>
          <RoundedButton onClick={() => this.setState({ availableForPurchase: false, priceList: [] })} style={{ padding: '7px 16px', minWidth: '0' }} color="secondary" variant={!this.state.availableForPurchase ? 'contained' : 'outlined'}>
            <span style={{ fontSize: '0.88rem', lineHeight: '1rem' }}>No</span>
          </RoundedButton>
        </div>
      </div>
    );
  }

  renderPriceList() {
    const { context } = this.props;
    const {
      priceList, availableForPurchase,
    } = this.state;
    return (
      <>
        <div style={{ marginTop: context.alwaysOpen ? '24px' : '26px', display: 'flex', alignItems: 'center' }}>
          <Typography variant="body2" style={{ fontWeight: context.alwaysOpen ? 'normal' : 'bold' }}>
            Validity Period
          </Typography>
          <StyledInput
            disabled={priceList.length < 1}
            alwaysOpen={context.alwaysOpen} // TODO @dhairya : What is alwaysOpen??
            variant="outlined"
            type="number"
            placeholder="Plan Days"
            value={priceList.length > 0 ? priceList[0].days : null}
            onChange={(event) => { priceList[0].days = event.target.value; this.setState({ priceList }); }}
            style={{ marginLeft: '20px', fontSize: context.alwaysOpen ? '' : '0.875em', flex: 'initial' }}
          />
          <img alt="info" src={Info} ref={this.infoIconRef} onClick={() => this.setState({ message: true })} style={{ marginLeft: '10px', height: context.alwaysOpen ? '1.5em' : '1em', width: context.alwaysOpen ? '1.5em' : '1em' }} />

        </div>

        <div style={{ marginTop: context.alwaysOpen ? '24px' : '26px', display: 'flex', alignItems: 'center' }}>
          <Typography variant="body2" style={{ fontWeight: context.alwaysOpen ? 'normal' : 'bold' }}>
            Pricing Plans
          </Typography>
          {availableForPurchase && priceList.length === 1 ? (
            <RoundedButton
              style={{ padding: '8px 16px', borderRadius: '20px', marginLeft: '20px' }}
              color="secondary"
              variant="outlined"
              onClick={() => {
                priceList.push(
                  {
                    currency: 'INR', price: '', strikePrice: 0, subscriptionId: `subscription-price-${Date.now() + Math.random()}`, description: 'Access Pass', days: null, cashback: 0,
                  },
                );
                this.setState({ priceList });
              }}
            >

              <Icon style={{ color: '#562eff', fontSize: '0.875em', lineHeight: '0' }}>
                <img alt="add" src={PlusIcon} style={{ height: '100%' }} />
              </Icon>
              <Typography variant="body2" style={{ marginLeft: '6px', fontWeight: 'bold', color: '#562eff' }}>
                Add Plan
              </Typography>
            </RoundedButton>
          ) : null}
        </div>

        {availableForPurchase ? priceList.map((_price, i) => i !== 0 && (
        <>
          <div style={{
            display: 'flex', marginTop: context.alwaysOpen ? '24px' : '16px', alignItems: 'center', width: context.alwaysOpen ? '43.61%' : '',
          }}
          >
            <PriceInput
              disabled={i === 0}
              alwaysOpen={context.alwaysOpen}
              text="Price"
              currency={!availableForPurchase ? null : priceList[i].currency}
              price={!availableForPurchase ? null : priceList[i].price}
              onChange={({ currency, price }) => {
                priceList[i].currency = currency;
                priceList[i].price = price;
                this.setState({ priceList });
              }}
              style={{ flex: '1' }}
            />
            <StyledInput
              alwaysOpen={context.alwaysOpen}
              variant="outlined"
              type="number"
              placeholder="Plan Days"
              value={priceList[i].days}
              onChange={(event) => {
                priceList[i].days = event.target.value; this.setState({ priceList });
              }}
              style={{ marginLeft: '20px', fontSize: context.alwaysOpen ? '' : '0.875em' }}
            />
            <img
              alt="delete"
              onClick={() => {
                if (i === 0) {
                  priceList[i].days = null;
                } else {
                  priceList.splice(i, 1);
                }
                this.setState({ priceList });
              }}
              src={Cross}
              style={{
                marginLeft: context.alwaysOpen ? '26px' : '10px', marginRight: context.alwaysOpen ? '30px' : '0', height: '1em', width: '1em', cursor: 'pointer', visibility: i === 0 ? 'hidden' : 'visible',
              }}
            />
          </div>
        </>
        )) : (
          <div style={{
            display: 'flex', marginTop: context.alwaysOpen ? '24px' : '16px', alignItems: 'center', width: context.alwaysOpen ? '43.61%' : '',
          }}
          >
            <PriceInput
              disabled
              alwaysOpen={context.alwaysOpen}
              text="Price"
              currency={!availableForPurchase ? null : priceList[0].currency}
              price={!availableForPurchase ? null : priceList[0].price}
              style={{ flex: '1' }}
            />
            <StyledInput
              disabled
              alwaysOpen={context.alwaysOpen}
              variant="outlined"
              type="number"
              placeholder="Plan Days"
              style={{ marginLeft: '20px', fontSize: context.alwaysOpen ? '' : '0.875em' }}
            />
          </div>
        )}

        {availableForPurchase && priceList.length > 1 ? (
          <div style={context.alwaysOpen
            ? {
              display: 'flex', alignItems: 'center', position: 'relative', left: '43.61%', bottom: '40px', marginBottom: '-34px',
            }
            : { marginTop: '20px', display: 'flex', alignItems: 'center' }}
          >
            <RoundedButton
              style={{ padding: '8px 16px', borderRadius: '20px' }}
              color="secondary"
              variant="outlined"
              onClick={() => {
                priceList.push(
                  {
                    currency: 'INR', price: '', strikePrice: 0, subscriptionId: `subscription-price-${Date.now() + Math.random()}`, description: 'Access Pass', days: null, cashback: 0,
                  },
                );
                this.setState({ priceList });
              }}
            >

              <Icon style={{ color: '#562eff', fontSize: '0.875em', lineHeight: '0' }}><img alt="add" src={PlusIcon} style={{ height: '100%' }} /></Icon>
              <Typography variant="body2" style={{ marginLeft: '6px', fontWeight: 'bold', color: '#562eff' }}>
                Add Plan
              </Typography>
            </RoundedButton>
          </div>
        ) : null }
      </>
    );
  }

  renderSellingPriceInput() {
    const { availableForPurchase, priceList } = this.state;
    const { context } = this.props;
    return (
      <PriceInput
        disabled={!availableForPurchase}
        alwaysOpen={context.alwaysOpen}
        style={context.alwaysOpen ? { flex: '1', marginRight: '40px', marginTop: '12px' } : {}}
        currency={!availableForPurchase ? null : priceList[0].currency}
        price={!availableForPurchase ? null : priceList[0].price}
        text="Selling Price"
        onChange={({ currency, price }) => {
          priceList[0].currency = currency;
          priceList[0].price = price;
          this.setState({ priceList });
        }}
      />
    );
  }

  renderDiscountPriceInput() {
    const { availableForPurchase, discountCurrency, discountPrice } = this.state;
    const { context } = this.props;

    return (
      <PriceInput
        disabled={!availableForPurchase}
        alwaysOpen={context.alwaysOpen}
        text="Discount Price"
        currency={!availableForPurchase ? null : discountCurrency}
        price={!availableForPurchase ? null : discountPrice}
        onChange={({ currency, price }) => {
          this.setState({ discountCurrency: currency, discountPrice: price });
        }}
        style={context.alwaysOpen ? { marginRight: '40px', marginTop: '12px', flex: '1' } : {}}
      />
    );
  }

  renderContentItems() {
    const {
      contents,
    } = this.state;
    const { context } = this.props;

    return (
      <div style={{ display: 'flex', flexDirection: 'column', padding: '0' }}>

        <div style={context.alwaysOpen ? { display: 'flex', flexWrap: 'wrap' } : {}}>
          {contents.filter((c) => c.published).map((content, index) => (
            <ContentItem
              reload={() => this.loadBundleContents()}
              alwaysOpen={context.alwaysOpen}
              content={content}
              openContent={(workspace) => this.openContent(workspace, content)}
              move={(direction) => this.move(content.contentId, direction)}
              style={{ marginBottom: !context.alwaysOpen && index === contents.filter((c) => c.published).length - 1 ? '0' : '12px' }}
            />
          ))}
        </div>

        {contents.filter((c) => !c.published).length > 0 ? <Typography style={{ margin: '12px 0' }} variant="body2">Unpublished</Typography> : null}

        <div style={context.alwaysOpen ? { display: 'flex', flexWrap: 'wrap' } : {}}>
          {contents.filter((c) => !c.published).map((content, index) => (
            <ContentItem
              reload={() => this.loadBundleContents()}
              alwaysOpen={context.alwaysOpen}
              content={content}
              openContent={(workspace) => this.openContent(workspace, content)}
              move={(direction) => this.move(content.contentId, direction)}
              style={{ marginBottom: !context.alwaysOpen && index === contents.filter((c) => !c.published).length - 1 ? '0' : '12px' }}
            />
          ))}
        </div>
      </div>
    );
  }

  renderDialogsDrawerAndCTA() {
    const {
      saving, message, saved, name, description, addContentDrawerOpen,
      confirmDialogOpen, confirmationResolver, confirmUnsavedDialogOpen, confirmUnsavedResolver,
    } = this.state;
    const { context } = this.props;

    return (
      <>
        <MainCTA
          topRight
          alwaysOpen={context.alwaysOpen}
          square={!context.alwaysOpen}
          text="Publish"
          onCTAClick={() => this.save()}
          disabled={saving || description.length === 0 || name.length === 0}
          help={[
            { title: 'What is a title?', text: 'A title is what tells what this course or collection is about in one glance. Keep it less than 90 characters long. Be direct in communication avoid brand names.' },
            { title: 'What is a description?', text: "A description usually tells why the user should buy this collection or course. Try telling what will change in the users' life after buying this collection. Keep it to 200 characters." },
          ]}
          settings={[]}
          toast="Please add the title and description to proceed"
        />

        <StyledDrawer
          alwaysOpen={context.alwaysOpen}
          title="Select and add content to the folder"
          onClick={() => this.setState({ addContentDrawerOpen: false })}
          open={addContentDrawerOpen}
          anchor="bottom"
        >
          {this.getContentOptionsComponent({ margin: '24px', marginTop: '0' }, context.alwaysOpen ? { justifyContent: 'center' } : {})}
        </StyledDrawer>

        <SuccessDialog open={saved} />
        <ConfirmationDialog open={confirmDialogOpen} resolve={confirmationResolver} />

        <ConfirmUnsavedDialog open={confirmUnsavedDialogOpen} resolve={confirmUnsavedResolver} />

        <Menu
          anchorEl={this.infoIconRef.current}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          PaperProps={{
            style: {
              padding: '4px 8px', margin: '0 0 0 -6px', borderRadius: '4px', maxWidth: '160px',
            },
          }}
          open={message}
          onClose={() => this.setState({ message: null })}
        >
          <Typography variant="body2">
            Set the duration for which the the folder can be accessed by your users
          </Typography>
          {/* Add Arrow here */}
        </Menu>
      </>
    );
  }

  render() {
    const { context, back } = this.props;
    const {
      name, imageUrl, contents, addContentNotVisible,
      expansionPanelOpen, addContentStickyButtonShadow,
      description,
    } = this.state;
    // code is repeating but this page has completely different layout for web and mobile
    if (context.alwaysOpen) {
      return (
        <Theme>
          <WsHeader
            back={async () => {
              const self = this;
              const confirmUnsaved = () => {
                new Promise((resolve) => self.setState({ confirmUnsavedDialogOpen: true, confirmUnsavedResolver: resolve }));
              };
              const confirmation = await confirmUnsaved();
              self.setState({ confirmUnsavedDialogOpen: false, confirmUnsavedResolver: null });

              if (!confirmation) {
                // cannot save here as it will break design incase of incomplete input
                // this.save()
                back();

              } else {
                back();
              }
            }}
            title={context.title || 'Edit Folder'}
          />

          <Card style={{ margin: '68px 40px 20px', borderBottom: 'solid 1px #dbdbdb', borderRadius: '6px' }}>
            <CardContent style={{ padding: '0' }}>
              <div style={{ display: 'flex', marginBottom: '24px', padding: '40px 40px 0' }}>

                <div style={{
                  marginRight: 'auto', flex: '1', flexDirection: 'column', display: 'flex',
                }}
                >

                  <StyledInput
                    alwaysOpen
                    placeholder="Enter title"
                    value={name}
                    onChange={(e) => {
                      this.setState({ name: e.target.value });
                      if (imageUrl.indexOf('unsplash.com') > 0 || imageUrl === 'https://cdn.socionity.com/assets/build.socionity.com/welcome.jpeg') {
                        const self = this;
                        Servercalls.getThumbnails(name).then((response) => {
                          self.setState({ imageUrl: response.data[0] });
                        });
                      }
                    }}
                  />
                  <StyledInput alwaysOpen variant="outlined" style={{ marginTop: '24px', flex: '0' }} inputStyle={{ height: '2em', overflow: 'scroll' }} multiline placeholder="Enter description" value={description} onChange={(e) => this.setState({ description: e.target.value })} />

                  <div style={{
                    display: 'flex', marginTop: '12px', flexWrap: 'wrap', marginRight: '-40px',
                  }}
                  >
                    {this.renderSellingPriceInput()}
                    {this.renderDiscountPriceInput()}
                  </div>
                </div>
                <div style={{
                  marginLeft: '60px', display: 'flex', justifyContent: 'center', alignItems: 'center',
                }}
                >
                  <div style={{ position: 'relative' }}>
                    <img
                      alt="thumbnail"
                      style={{
                        borderRadius: '12px',
                        zIndex: '10',
                        backgroundColor: 'rgba(0,0,0,0.15)',
                        height: '23.611vw',
                        width: '41.975vw',
                        maxHeight: '188.88px',
                        maxWidth: '335.785px',
                      }}
                      src={imageUrl}
                    />
                    <FileUploadButton
                      style={{
                        position: 'relative',
                        height: '23.611vw',
                        width: '41.975vw',
                        maxHeight: '188.88px',
                        maxWidth: '335.785px',
                        backgroundColor: 'transparent',
                        borderRadius: '12px',
                        boxShadow: 'none',
                      }}
                      extensions={['png', 'jpg', 'jpeg']}
                      maxSizeMBs={2}
                      onFail={(e) => alert(e)}
                      onUpload={(url) => this.setState({ imageUrl: url })}
                      icon="photo_camera"
                      color="primary"
                      variant="contained"
                      containerStyles={{ top: '0' }}
                    />
                  </div>
                </div>
              </div>

              <ExpansionPanel expanded={expansionPanelOpen} onChange={() => this.setState({ expansionPanelOpen: !expansionPanelOpen }) || setTimeout(() => this.checkAddContentInView(), 300)} elevation={0} style={{ marginTop: '0px', borderTop: 'solid 1px #dbdbdb' }}>
                <ExpansionPanelSummary
                  expandIcon={<Icon onClick={() => this.setState({ expansionPanelOpen: !expansionPanelOpen })} style={{ fontWeight: 'bold' }}>expand_more</Icon>}
                  style={{ padding: '12px 40px' }}
                >
                  <Typography variant="body1" style={{ marginRight: 'auto', fontWeight: 'bold' }}>
                    Advanced Options
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{ display: 'flex', flexDirection: 'column', padding: '0 40px 24px' }}>
                  {this.renderAvailableForPurchaseButtons()}
                  {this.renderPriceList()}
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </CardContent>
          </Card>

          {contents.length
            ? (
              <>

                <Card ref={this.uploadedContentCardRef} style={{ margin: '0 40px 20px' }}>
                  <CardContent style={{ padding: '32px 40px' }}>
                    <div style={{
                      display: 'flex', alignItems: 'center', marginBottom: '24.5px', marginTop: '-7.5px',
                    }}
                    >
                      <Typography variant="body1" style={{ fontWeight: 'bold', marginRight: 'auto', padding: '7.5px 0' }}>Uploaded Content</Typography>
                      {addContentNotVisible
                        ? (
                          <RoundedButton style={{ padding: '8px 16px', borderRadius: '20px' }} color="secondary" variant="outlined" onClick={() => this.setState({ addContentDrawerOpen: true })}>
                            <Icon style={{ color: '#562eff', fontSize: '0.875em', lineHeight: '0' }}>
                              <img alt="add" src={PlusIcon} style={{ height: '100%' }} />
                            </Icon>
                            <Typography variant="body2" style={{ marginLeft: '6px', fontWeight: 'bold', color: '#562eff' }}>
                              Add Content
                            </Typography>
                          </RoundedButton>
                        )
                        : null }
                    </div>
                    {this.renderContentItems()}
                  </CardContent>
                </Card>
              </>
            )
            : null}

          <Card ref={this.addContentCardRef} style={{ margin: '0 40px 20px' }}>
            <CardContent style={{ padding: '32px 40px' }}>
              <Typography variant="body1" style={{ fontWeight: 'bold', marginBottom: '32px' }}>Select and add content to the folder</Typography>
              {this.getContentOptionsComponent()}
            </CardContent>
          </Card>

          {this.renderDialogsDrawerAndCTA()}
        </Theme>
      );
    }

    return (
      <Theme>
        <WsHeader
          back={async () => {
            const self = this;
            const confirmUnsaved = () => new Promise((resolve) => self.setState({ confirmUnsavedDialogOpen: true, confirmUnsavedResolver: resolve }));
            const confirmation = await confirmUnsaved();
            self.setState({ confirmUnsavedDialogOpen: false, confirmUnsavedResolver: null });

            if (!confirmation) {
              // cannot save here as it will break design incase of incomplete input
              // this.save()

            } else {
              back();
            }
          }}
          title={context.title || 'Enter your folder details'}
          description="A collection is where your content resides. Users pay to buy all content inside a collection"
          helpText="How do collections work?"
          helpVideo="a"
        />
        <Card style={{
          marginTop: '52px', boxShadow: 'none', borderBottom: 'solid 1px #dbdbdb', borderRadius: '0',
        }}
        >
          <CardContent style={{ padding: '0' }}>
            <div style={{ padding: '20px' }}>
              <div style={{ display: 'flex', marginBottom: '20px' }}>

                <div style={{
                  marginRight: 'auto', flex: '1', flexDirection: 'column', justifyContent: 'space-evenly', display: 'flex',
                }}
                >

                  <StyledInput
                    placeholder="Enter title"
                    value={name}
                    onChange={(e) => {
                      this.setState({ name: e.target.value });
                      if (imageUrl.indexOf('unsplash.com') > 0 || imageUrl === 'https://cdn.socionity.com/assets/build.socionity.com/welcome.jpeg') {
                        const self = this;
                        Servercalls.getThumbnails(name).then((response) => {
                          self.setState({ imageUrl: response.data[0] });
                        });
                      }
                    }}
                  />
                  <StyledInput style={{ marginTop: '20px' }} placeholder="Enter description" value={this.state.description} onChange={(e) => this.setState({ description: e.target.value })} />

                </div>
                <div style={{
                  marginLeft: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center',
                }}
                >
                  <div style={{ position: 'relative' }}>
                    <img
                      alt="thumbnail"
                      style={{
                        borderRadius: '12px',
                        zIndex: '10',
                        backgroundColor: 'rgba(0,0,0,0.15)',
                        height: '23.611vw',
                        width: '23.611vw',
                        maxHeight: '188.88px',
                        maxWidth: '188.88px',
                      }}
                      src={imageUrl}
                    />
                    <FileUploadButton
                      style={{
                        position: 'relative',
                        height: '23.611vw',
                        width: '23.611vw',
                        maxHeight: '188.88px',
                        maxWidth: '188.88px',
                        backgroundColor: 'transparent',
                        borderRadius: '12px',
                        boxShadow: 'none',
                      }}
                      extensions={['png', 'jpg', 'jpeg']}
                      maxSizeMBs={2}
                      onFail={(e) => alert(e)}
                      onUpload={(url) => this.setState({ imageUrl: url })}
                      icon="photo_camera"
                      color="primary"
                      variant="contained"
                      containerStyles={{ top: '0' }}
                    />
                  </div>
                </div>
              </div>
              {this.renderSellingPriceInput()}
            </div>
          </CardContent>
        </Card>
        <ExpansionPanel expanded={expansionPanelOpen} onChange={() => this.setState({ expansionPanelOpen: !this.state.expansionPanelOpen }) || setTimeout(() => this.checkAddContentInView(), 300)} elevation={0} style={{ marginTop: '12px', borderBottom: 'solid 1px #dbdbdb', borderTop: 'solid 1px #dbdbdb' }}>
          <ExpansionPanelSummary
            expandIcon={<Icon onClick={() => this.setState({ expansionPanelOpen: !expansionPanelOpen })} style={{ fontWeight: 'bold' }}>expand_more</Icon>}
            style={{ padding: '1.75px 22.5px' }}
          >
            <Typography variant="body1" style={{ marginRight: 'auto', fontWeight: 'bold' }}>
              Advanced Options
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails style={{ display: 'flex', flexDirection: 'column', padding: '20px' }}>
            {this.renderDiscountPriceInput()}
            {this.renderAvailableForPurchaseButtons()}
            {this.renderPriceList()}
          </ExpansionPanelDetails>
        </ExpansionPanel>

        {contents.length ? (
          <>
            {' '}
            <Card style={{
              zIndex: '100', marginTop: '12px', boxShadow: addContentStickyButtonShadow ? '0 0 16px 0 rgba(0, 0, 0, 0.16)' : 'none', padding: '12px 12px 0', position: this.state.addContentNotVisible ? 'sticky' : 'static', top: '0', borderTop: 'solid 1px #dbdbdb',
            }}
            >
              <CardContent style={{ display: 'flex', flexDirection: 'column', padding: '0' }}>
                <div style={{ margin: '8px 8px 20px', display: 'flex', alignItems: 'center' }}>
                  <Typography variant="body1" style={{ fontWeight: 'bold', marginRight: 'auto', padding: '7.5px 0' }}>Uploaded Content</Typography>
                  {addContentNotVisible
                    ? (
                      <RoundedButton style={{ padding: '8px 16px', borderRadius: '20px' }} color="secondary" variant="outlined" onClick={() => this.setState({ addContentDrawerOpen: true })}>
                        <Icon style={{ color: '#562eff', fontSize: '0.875em', lineHeight: '0' }}><img src={PlusIcon} style={{ height: '100%' }} /></Icon>
                        <Typography variant="body2" style={{ marginLeft: '6px', fontWeight: 'bold', color: '#562eff' }}>
                          Add Content
                        </Typography>
                      </RoundedButton>
                    )
                    : null }

                </div>
              </CardContent>
            </Card>

            <Card
              ref={this.uploadedContentCardRef}
              style={{
                padding: '0px 12px 12px', marginTop: '0', boxShadow: 'none', borderBottom: 'solid 1px #dbdbdb',
              }}
            >
              {this.renderContentItems()}
            </Card>
          </>
        ) : null}

        <Card
          ref={this.addContentCardRef}
          style={{
            marginTop: '12px', padding: '20px', marginBottom: '42px', boxShadow: 'none', borderBottom: 'solid 1px #dbdbdb', borderTop: 'solid 1px #dbdbdb',
          }}
        >
          <div style={{ display: 'flex', margin: '0', marginBottom: '20px' }}>
            <Typography variant="body1" style={{ marginRight: 'auto', fontWeight: 'bold' }}>
              Select and add content to the folder
            </Typography>
          </div>
          {this.getContentOptionsComponent()}
        </Card>

        <WsFooter text="Add a collection, set a price and add content to the collection. Then, start selling the collection." />
        {this.renderDialogsDrawerAndCTA()}
      </Theme>
    );
  }
}
