import { Dialog, Icon, Typography } from '@material-ui/core';
import React from 'react';
import RoundedButton from './roundedbutton';

const ConfirmUnsavedDialog = (props) => {
    return (
        <Dialog PaperProps={{ style: {borderRadius: '12px', maxWidth: '315px'} }} open={props.open} onClose={() => props.resolve(false)}>
            <div style={{padding: '32px 30px', display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center'}}>
                <Typography style={{fontWeight: 'bold'}} variant='body1'>{props.title || 'Your changes have not been saved!'}</Typography>
                <Typography style={{color: '#999999', marginTop: '20px'}} variant='body2'>{props.body || 'Any changes that you have made to this folder will not be saved. Do you wish to proceed without saving?'}</Typography>
                <div style={{display: 'flex', marginTop: '36px', width: '100%', justifyContent: 'center'}}>
                    <RoundedButton style={{whiteSpace: 'nowrap'}} color="secondary" variant="outlined" onClick={() => props.resolve(true)}>
                        Yes
                    </RoundedButton>
                    <RoundedButton style={{whiteSpace: 'nowrap', marginLeft: '24px'}} color="secondary" variant="contained" onClick={() => props.resolve(false)}>
                        No
                    </RoundedButton>
                </div>
            </div>
        </Dialog>
    )
}

export default ConfirmUnsavedDialog;