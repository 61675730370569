/* eslint-disable */
import React from 'react';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

import Snackbar from '@material-ui/core/Snackbar';

import Alert from '@material-ui/lab/Alert';
import Wizard from './Wizard';
import OnboardingHelper from './Components/onboardinghelper';
import BuilderNotifications from './Home/buildernotifications'

import { getParametersByName } from './Utils/utils';
import { AppUrl, WebAuthClientId } from './Utils/Constants';
import { setTimeout } from 'timers';

import WorkspaceHandler from './Home/workspacehandler';

import Stars from './Assets/Pictures/stars.png';
import Header from './Components/header';

const Servercalls = require('./Utils/Servercalls');
const Constants = require('./Utils/Constants');
const utils = require('./Utils/utils');
const LAYOUT_BREAK_POINT = 500;

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      showWizard: false,
    };
  }

  
  async componentDidMount() {
    const self = this;
    const ref = getParametersByName('ref');
    if(ref) {
      localStorage.setItem("ref", ref);
    }

    const prefill = getParametersByName('state');
    try {
      const prefills = prefill.split("__DELIM__");
      for(let i = 0 ; i < prefills.length; i+= 1){
        this.setState({ [prefills[i].split("__EQ__")[0]] : prefills[i].split("__EQ__")[1] });
      }
    }
    catch(e){
    }

    const WebAuthUrl = `${Constants.WebAuthUrl}?response_type=code&redirect_uri=${AppUrl}&client_id=${WebAuthClientId}&state=${prefill}`;
    if(window.location.href.indexOf("state=vimeo") > 0){
      const vimeoAccessToken = utils.getParametersByName('access_token', window.location.href.replace("#",""));
      localStorage.setItem("vimeoAccessToken", vimeoAccessToken);
      self.setState({ message: { text: "Vimeo Login successful!", type: "success"}});
      setTimeout(() => window.close(), 4000);
    }
    if(window.location.href.indexOf("state=zoom_") > 0){
      const zoomCode = utils.getParametersByName('code', window.location.href);
      const close = utils.getParametersByName('state', window.location.href).split("_")[1];
       await Servercalls.zoomGetAuthCode(zoomCode).then(response => {
         localStorage.setItem("zoomAccessToken", response.data.access_token);
         self.setState({ message: { text: "Zoom Login successful! You will be redirected shortly ...", type: "success" }});
         if(localStorage.getItem('zoomCode') !== zoomCode){
           setTimeout(() => window.close(), 4000);
         }
         localStorage.setItem('zoomCode', zoomCode);
       }).catch(e => {
        self.setState({ message: { text: "Zoom Login faled!", type: "error"}});
       });

    }
   
    if(utils.getParametersByName('code', window.location.href) && window.location.href.indexOf("state=zoom") === -1) {
      try {
        const authTokenResponse = await Servercalls.getAuthToken(utils.getParametersByName('code', this.openUrl))
        if(authTokenResponse.data.error){
          window.location.href = WebAuthUrl;
        }
        await utils.globalAuth(authTokenResponse.data);
        this.reload();
      }
      catch (e){
        console.log(e);
        await utils.globalGetLogin();
        self.reload();
      }
    }
    else {
      await utils.globalGetLogin();
      this.reload();

    }
  }

  reload() {
    const self = this;
    self.setState({ loading: true });
    Servercalls.initUser().then(response => {
      self.setState({ builderEmail: this.state.builderEmail || response.data.email, name: response.data.name, logo: response.data.picture }, () => {
        window.analytics.identify(localStorage.getItem('appId') || localStorage.getItem('userId') || this.state.builderEmail, {
          name: this.state.name,
          email: this.state.builderEmail
        });
      });
      Servercalls.getCurrencyConversionToLocalCurrency('INR').then(response => self.setState({ localCurrency: response.data.localCurrency, localCurrencyConversionRate: response.data.localCurrencyConversionRate }));
      Servercalls.getApp().then(response => {
        window.analytics.track('App Builder Loaded', {
          'creator id': this.state.builderEmail
        });

      if (!response.data  ) return self.setState({ loading: false, showWizard: true })
      localStorage.setItem('appId', response.data.appId);
      window.analytics.identify(localStorage.getItem('appId') || localStorage.getItem('userId') || this.state.builderEmail, {
        ...response.data
      })

      window.analytics.track('App Builder Viewed', {});
      self.setState({ app: response.data, loading: false, stack: [{ workspace: 'tabs', options: 'app', title: response.data.name, context: response.data }] });
      self.setState({ builderEmail: self.state.builderEmail || response.data.owner, appName: response.data.name || 'My App', appLogo: response.data.logo, currentPlan: response.data.currentPlan ? response.data.currentPlan : 0, domain: response.data.domain ? response.data.domain.replace(".nity.app", "") : response.data.name ? response.data.name.toLowerCase().replace(/[^a-z0-9]/gmi, "-") : `${Date.now()}`, video: response.data.video, allowAnon: response.data.allowAnon });
    }).catch(e => self.setState({ loading: false, showWizard: true }));

    }).catch(e => {
      utils.logout()
    });
  }

  getTheme() {
    return createMuiTheme(Constants.theme)
  }

  render() {
    
    const { width, app } = this.state;    
    let alwaysOpen = false;
    if (width > 800) alwaysOpen = true;
    const theme = this.getTheme();

    if (this.state.loading ) return (
      <MuiThemeProvider theme={theme}>
      <div style={{ position: 'absolute', minHeight: '100%', width: '100%', background: `#000000 url(${Stars})`, backgroundPosition: 'bottom', backgroundSize: 'calc(max(100vmin, 1920px)) 100%', backgroundRepeat: 'no-repeat', justifyContent: 'center', display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
      </div>
      </MuiThemeProvider>
  )

    if (this.state.showWizard) {
      return (<Wizard alwaysOpen={this.state.width > 600} name={this.state.name} logo={this.state.logo} youtube={this.state.youtube} template={this.state.template} exit={() => { this.setState({ showWizard: false, loading: true }); this.reload() }} />)
    }
    return (
      <MuiThemeProvider theme={theme}>
        <WorkspaceHandler app={app} />
        <OnboardingHelper />
        <BuilderNotifications app={app}/>
        {this.state.message?<Snackbar
          open={this.state.message}
          onClose={() => this.setState({ message: null })}
          autoHideDuration={6000}
        >
          <Alert variant="filled" severity={this.state.message.type}>{this.state.message.text}</Alert>
        </Snackbar> : null}
      </MuiThemeProvider>
    )
  }
}

export default App;
