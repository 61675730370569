import React from 'react';
import { createMuiTheme, MuiThemeProvider, withTheme } from '@material-ui/core';

import { theme } from '../Utils/Constants';

class Theme extends React.Component{

    render(){
        return(
            <div {...this.props}>  
                {this.props.children}
            </div>
        )
    }
}

export default withTheme(Theme);

