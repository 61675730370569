import { Icon, makeStyles, useMediaQuery } from '@material-ui/core';
import { withTheme } from '@material-ui/styles';
import React from 'react';

const useStyles = makeStyles(theme => ({
    'root': {
        display: 'flex',
        height: theme.spacing(5.25),
        alignItems: 'stretch',
        zIndex: 10,
        boxShadow: '3px 0 6px 0 rgba(0, 0, 0, 0.16)',
        marginLeft: '-21px',

        position: 'fixed',
        width: 'calc(100% + 14px)',
        top: '56px',

        '& > *': {
            flex: 1,
            backgroundColor: '#f2f2f2',
            fontSize: '13px',
            fontWeight: 'bold',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        }
    },
    rightArrow: {
        width: 0,
        height: 0, 
        borderTop: `${theme.spacing(2.625)}px solid transparent`,
        borderBottom: `${theme.spacing(2.625)}px solid transparent`,
        
        borderLeft: `${theme.spacing(2.625)}px solid red`,
        boxShadow: '3px 0 6px 0 rgba(0, 0, 0, 0.16)',
    },

    stepperContent: {
        margin: 'auto',
        zIndex: '13',
        background: '#f2f2f2',
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingLeft: '29px',

    },

    checkIcon: {
        fontSize: '15px',
        marginRight: '5.5px'
    },

    stepper: {
        position: 'relative',
        boxSizing: 'border-box',
        '&::after': {
            zIndex: 11,
            content: '""',
            position: 'absolute',
            width: 0,
            height: 0,
            marginLeft: 0,
            bottom: 0,
            top: 'calc(50% - 21.1px)',
            right: -28.5,
            boxSizing: 'border-box',
            
            border: '14.848px solid black',
            borderColor: '#f2f2f2 #f2f2f2 transparent transparent',
            transformOrigin: '0 0',
            transform: 'rotate(45deg)',

            boxShadow: '2px -2px 6px 0 rgba(0, 0, 0, 0.16)',
        }
    },

    stepperActive: {
        '&::after': {
            borderColor: '#562eff #562eff transparent transparent',
            boxShadow: '2px -2px 6px 0 rgba(0, 0, 0, 0.16)',
        },
        '& > span': {
            background: '#562eff',
            color: '#fff',
        }
    },

    stepperFinished: {
        '&::after': {
            borderColor: '#fff #fff transparent transparent',
            boxShadow: '2px -2px 6px 0 rgba(0, 0, 0, 0.16)',            
        },
        '& > span': {
            background: '#fff',
        }
    }
}))

const StyledStepper = (props) => {
    const classes = useStyles();
    const hideIcon = useMediaQuery('(max-width:360px)');

    const mobileView = useMediaQuery('(max-width:800px)');

    return (
        <div style={mobileView ? {} : {width: 'calc(calc(100% - 256px) + 33px)'}} className={classes.root}>

            {['Details', 'Price', 'Publish'].map((step,index) => {

                let className = '';
                if (index < props.activeIndex)
                    className = `${classes.stepper} ${classes.stepperFinished}`
                else if (index === props.activeIndex)
                    className = `${classes.stepper} ${classes.stepperActive}`
                else
                    className=classes.stepper

                let paddingLeft = '';
                if (index === 0)
                    paddingLeft = 31;
                else if (index === 3)
                    paddingLeft = 21

                return (
                    <div className={className} style={{zIndex: 10 + 5 - index}}>
                        <span onClick={() => props.setActiveIndex(index)} className={classes.stepperContent} style={{paddingLeft}}>
                            {/* Icon breaking design and ux */}
                            {((index >= props.activeIndex)) || <Icon className={classes.checkIcon}>check_circle_outline</Icon> }
                            {step}
                        </span>
                    </div>
                )
            })}
        </div>
    )
}

export default withTheme(StyledStepper)