import React from 'react';
import CommonAddContent from './ws-commonaddcontent';

export default class WsAddVideoContent extends React.Component {
    render() {
        return (
            <CommonAddContent
                title="Upload an PDF file"
                description="The PDF will open in the Secure Socionity PDF Reader that prevents download" 
                helpText="Know more about PDF content"
                helpVideo="a"
                filetype="pdf"
                {...this.props}
            />
        )
    }
}