import React from 'react';

export default class Illustration extends React.Component {
    render(){
        if(this.props.image)
            return (
                <img src={`https://cdn.socionity.com/assets/build.socionity.com/illustrations/${this.props.image}.png`} {...this.props} />
            )
        if(this.props.video)
            return (
                <video autoPlay playsInline muted src={`https://cdn.socionity.com/assets/build.socionity.com/illustrations/${this.props.video}.mp4`} {...this.props} />
            )
    }
}