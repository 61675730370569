import React from 'react';
import Theme from '../../Components/theme';
import WsHeader from '../../Components/wsheader';
import WsFooter from '../../Components/wsfooter';
import FileUploadButton from '../../Components/fileuploadbutton';
import { LinearProgress, Button, Typography, TextField, Icon, Card, CardContent, CardActions, Input, IconButton, List, ListItem } from '@material-ui/core';
import RoundedButton from '../../Components/roundedbutton';
import MainCTA from '../../Components/MainCTA';
import md5 from 'md5';
import StyledDrawer from '../../Components/StyledDrawer';

import DeleteIcon from '../../Assets/Icons/Delete.svg';
import ChangeIcon from '../../Assets/Icons/Change.svg';
import SuccessDialog from '../../Components/SuccessDialog';
import StyledInput from '../../Components/StyledInput';
import StyledListItems from '../../Components/StyledListItems';
import UploadContent from '../../Assets/Icons/UploadContent.svg';
import UploadContentWhite from '../../Assets/Icons/UploadContentWhite.svg';
import { ExitToApp } from '@material-ui/icons';
import ConfirmUnsavedDialog from '../../Components/ConfirmUnsavedDialog';

const Servercalls = require('../../Utils/Servercalls');

export default class CommonAddFileContent extends React.Component {
    constructor(props){
        super(props);

        const content = this.props.context;
        this.state = {
            pluginId: content.pluginId,
            itemId: content.bundleId || content.itemId,
            contentId: content.contentId || null,

            name: content.name || "",
            description: content.description || "",
            fileUrl: content.fileUrl || null,

            published: content.published || true,
            free: content.free || false,
            type: content.type,
            thumbnail: content.thumbnail || `https://www.gravatar.com/avatar/${md5((Date.now() + Math.random()) + " ")}?d=identicon`,
            longDescription: content.longDescription || "<p></p>",
            tags: content.tags || [],
            list: content.list || 'normal',

            uploadDone: 0,
            uploadTotal: -1,
            uploading: false,
        }

        this.openDrawerIconRef = React.createRef();
    }
    getExtensions() {
        const { filetype } = this.props;
        if(filetype === 'video') return ['mp4','webm', 'mov', 'avi'];
        if(filetype === 'audio') return ['mp3', 'ogg'];
        if(filetype === 'pdf') return ['pdf'];
        if(filetype === 'image') return ['png','jpg', 'jpeg', 'webp', 'gif']
        return null
    }

    async save(){
        const self = this;
        self.setState({ saving : true });

        let itemId = this.state.itemId;
        if(!itemId) {
            itemId = await this.props.context.getItemId();
        }

        const {pluginId, contentId, name, description, fileUrl, published, free, type, thumbnail, longDescription, tags, list} = this.state;
        Servercalls.pluginpremier_addContentToBundle(pluginId, itemId, contentId, name, description, fileUrl, published, free, type, thumbnail, longDescription, tags, list).then(response => {
            self.setState({ saving: false, saved: true, itemId});
            //  self.props.back()

            window.analytics.track('Content Confirm Clicked', {'content_type': type});
            setTimeout(() => {
                this.setContextAndBack();
            }, 3000);
        }).catch(e => { alert(e); self.setState({ saving: false, itemId })});
    }

    getUploadedElement(url, filename) {
        let component = null
        if(this.props.filetype === 'video'){
            component = <>
            <CardContent><video src={url} style={{ width: '100%' }} controls /></CardContent>
            <CardActions>
                <RoundedButton onClick={() => this.setState({ url: null, filename: null})} style={{ marginTop: 12 }} variant="outlined" color="secondary"><Icon>delete_forever</Icon></RoundedButton>
            </CardActions>
            </>

        }
        else if(this.props.filetype === 'audio'){
            component = <>
                <CardContent><audio src={url} style={{ width: '100%' }} controls /></CardContent>
                <CardActions>
                    <RoundedButton onClick={() => this.setState({ url: null, filename: null})} style={{ marginTop: 12 }} variant="outlined" color="secondary"><Icon>delete_forever</Icon></RoundedButton>
                </CardActions>
                </>
        }
        else {
            component = (
                <>
                <div style={{ padding: 24, float: 'left', background: "#fbfbfb" }}><Icon style={{ fontSize: 48 }}>open_in_new</Icon></div>
                <div style={{ float: 'left', width: `calc(100% - 124px)`, margin: 12}}>
                    <Typography variant="body2" color="primary">{filename}</Typography>
                    <RoundedButton style={{ marginTop: 12 }} variant="outlined" color="secondary">Open File</RoundedButton>
                    &nbsp;&nbsp;<RoundedButton style={{ marginTop: 12, marginLeft: 12 }} onClick={() => this.setState({ url: null, filename: null})} style={{ marginTop: 12 }} variant="outlined" color="secondary"><Icon>delete_forever</Icon></RoundedButton>
                </div>
                </>
            )
        }

        return (
            <Card square style={{margin: 12}}>
                {component}
            </Card>
        )
    }

    setContextAndBack() {
        this.props.changeParentContext('pluginId', this.props.context.pluginId);
        this.props.changeParentContext('itemId', this.state.itemId);
        
        if(this.props.context.bundle_name) {
            this.props.changeParentContext('name', this.props.context.bundle_name);
        }
        if(this.props.context.bundle_description) {
            this.props.changeParentContext('description', this.props.context.bundle_description);
        }
        
        if(this.props.context.bundle_priceList) {
            this.props.changeParentContext('priceList', this.props.context.bundle_priceList);
        }
        if(this.props.context.bundle_imageUrl) {
            this.props.changeParentContext('imageUrl', this.props.context.bundle_imageUrl);
        }
        if(this.props.context.bundle_availableForPurchase) {
            this.props.changeParentContext('availableForPurchase', this.props.context.bundle_availableForPurchase);
        }
        if(this.props.context.bundle_published) {
            this.props.changeParentContext('published', this.props.context.bundle_published);
        }
        this.props.changeParentContext('newCollection', false);
        this.props.back();
    }

    render() {
        return (
            <>
                <div style={{display: 'flex', flex: '1', marginBottom: '42px', flexDirection: 'column', height: this.props.context.alwaysOpen ? '' : 'calc(100% - 42px)'}}>
                    <WsHeader {...this.props} back={async() => {
                        const self = this;
                        let confirmUnsaved = () => new Promise(resolve => self.setState({confirmUnsavedDialogOpen: true, confirmUnsavedResolver: resolve}))
                        const confirmation = await confirmUnsaved();
                        self.setState({confirmUnsavedDialogOpen: false, confirmUnsavedResolver: null});

                        if(!confirmation) {
                            // cannot save here as it will break design incase of incomplete input
                            // this.save()
                            return;
                        } else {
                            this.setContextAndBack()
                        }
                    }} />
                    {this.props.context.alwaysOpen ? (
                        <Card style={{margin: '68px 40px 20px', borderBottom: 'solid 1px #dbdbdb', borderRadius: '6px'}}>
                            <CardContent style={{padding: '0'}}>
                                <div style={{display: 'flex', marginBottom: '24px', padding: '40px 40px 0'}}>
        
                                    <div style={{marginRight: 'auto', flex: '1', flexDirection: 'column', display: 'flex'}}>
                                        
                                        <StyledInput
                                            alwaysOpen
                                            placeholder="Enter title"
                                            fullWidth
                                            value={this.state.name}
                                            onChange={e => this.setState({ name: e.target.value })}
                                        />
                                        <StyledInput
                                            alwaysOpen
                                            variant='outlined'
                                            style={{marginTop: '24px', flex: '0'}}
                                            inputStyle={{height: '2em', overflow: 'scroll'}}
                                            multiline
                                            placeholder="Enter description"
                                            value={this.state.description}
                                            onChange={e => this.setState({ description: e.target.value})} />
                                        
                                        <Typography variant="body1" style={{margin: '24px 0', fontWeight: 'bold'}}>
                                            Selected {this.state.type === 'downloadable' ? 'PDF' : this.state.type} :
                                        </Typography>
        
                                        <div style={{position: 'relative'}}>
                                            {this.state.fileUrl ? <Card style={{
                                                display: 'flex',
                                                padding: '12px',
                                                borderRadius: '12px',
                                                boxShadow: '0 0 8px 0 rgba(0, 0, 0, 0.08)',
                                                border: 'solid 1px #dbdbdb',
                                                marginRight: '12px',
                                                width: 'calc(min(21.56vw, 276px))',
                                                minWidth: '276px',
                                                marginBottom: '12px',
                                                overflow: 'hidden'
                                                }}
                                            >
                                                <img style={{
                                                    borderRadius: '6px',
                                                    zIndex: '10',
                                                    height: '6vw',
                                                    width: '6vw',
                                                    maxHeight: '72px',
                                                    maxWidth: '72px',
                                                }} src={this.state.thumbnail || `https://gravatar.com/avatar/${Date.now()+Math.random()}?d=identicon`} />
                                                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flex: '1', marginLeft: '12px', overflow: 'hidden'}}>
                                                    <Typography variant='body1' style={{fontWeight: 'bold', maxHeight: '17.2px', overflow: 'hidden', textOverflow: 'ellipsis'}}>{this.state.name || 'Title Goes Here'}</Typography>
                                                    <Typography variant='body2' style={{marginTop: '6px', maxHeight: '34.4px', overflow: 'hidden'}}>{this.state.description || 'Description Goes Here'}</Typography>
                                                </div>
                                                <IconButton ref={this.openDrawerIconRef} style={{alignSelf: 'flex-start', padding: '4px', margin: '-4px', marginLeft: '12px'}} onClick={event => this.setState({ menuOpen: true})}><Icon>more_vert</Icon></IconButton>
                                                
                                            </Card> :
                                                
                                            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', flex: '1', width: 'fit-content'}}>
                                                <Icon color='secondary' style={{fontSize: '10em'}}><img src={UploadContentWhite} style={{height: '100%', marginLeft: '-40px'}}/></Icon>
                                                <FileUploadButton
                                                    extensions={this.getExtensions()}
                                                    color="secondary"
                                                    text={`Upload ${this.state.type}`}
                                                    maxSizeMBs={10000}
                                                    onFail={(e) => this.setState({ uploading: false })}
                                                    onUpload={(url, filename) => {
                                                        this.setState({ fileUrl: url, filename, uploading: false })
                                                    }}
                                                    onEnter={() => window.analytics.track('Upload Content Clicked ', {'content_type' : this.state.type})}
                                                    onUploadStart={() => this.setState({ uploading: true })}
                                                    uploadProgress={(uploadDone, uploadTotal) => this.setState({ uploadDone, uploadTotal })}
                                                    containerStyles={{position: 'static'}}
                                                    variant='contained'
                                                    style={{width: '100%'}}
                                                />
                                            </div> }
                                            
                                        </div>
                                    </div>
                                    <div style={{marginLeft: '60px', display: 'flex', justifyContent: 'center'}}>
                                        <div style={{position: 'relative'}}>
                                            <img style={{
                                                borderRadius: '12px',
                                                zIndex: '10',
                                                boxShadow: '0 0 16px 0 rgba(0, 0, 0, 0.16)',
                                                backgroundColor: 'rgba(0,0,0,0.15)',
                                                height: '23.611vw',
                                                width: '41.975vw',
                                                maxHeight: '188.88px',
                                                maxWidth: '335.785px',
                                                }} src={this.state.thumbnail}/>
                                            <FileUploadButton
                                                style={{ 
                                                    position: 'relative',
                                                    height: '23.611vw',
                                                    width: '41.975vw',
                                                    maxHeight: '188.88px',
                                                    maxWidth: '335.785px',
                                                    backgroundColor: 'transparent',
                                                    borderRadius: '12px',
                                                    boxShadow: 'none',
                                                }}
                                                extensions={['png','jpg','jpeg']}
                                                maxSizeMBs={2}
                                                onFail={e => alert(e)}
                                                onUpload={(url) => this.setState({ thumbnail: url })}
                                                icon="edit"
                                                color="primary"
                                                variant="contained"
                                                containerStyles={{top: '0'}}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                    ) : (
                        <Card style={{marginTop: '52px', boxShadow: 'none'}}>
                            <CardContent style={{padding: '0'}}>
                                <div style={{padding: '20px'}}>
                                    <div style={{display: 'flex'}}>

                                        <div style={{marginRight: 'auto', flex: '1', flexDirection: 'column', justifyContent: 'space-evenly', display: 'flex'}}>
                                            
                                            <StyledInput
                                                placeholder="Enter title"
                                                fullWidth
                                                value={this.state.name}
                                                onChange={e => this.setState({ name: e.target.value })}
                                            />
                                            <StyledInput
                                                placeholder="Enter description"
                                                fullWidth
                                                style={{marginTop: '16px'}}
                                                value={this.state.description}
                                                onChange={e => this.setState({ description: e.target.value })}
                                            />
                                            
                                        </div>
                                        <div style={{marginLeft: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                            <img style={{
                                                borderRadius: '12px',
                                                zIndex: '10',
                                                boxShadow: '0 0 16px 0 rgba(0, 0, 0, 0.16)',
                                                backgroundColor: 'red',
                                                height: '23.611vw',
                                                width: '23.611vw',
                                                maxHeight: '188.88px',
                                                maxWidth: '188.88px',
                                                }} src={this.state.thumbnail}/>
                                            <FileUploadButton
                                                style={{ 
                                                    position: 'relative',
                                                    height: '23.611vw',
                                                    width: '23.611vw',
                                                    maxHeight: '188.88px',
                                                    maxWidth: '188.88px',
                                                    backgroundColor: 'rgba(0,0,0,0.15)',
                                                    borderRadius: '12px',
                                                    boxShadow: 'none',
                                                }}
                                                extensions={['png','jpg','jpeg']}
                                                maxSizeMBs={2}
                                                onFail={e => alert(e)}
                                                onUpload={(url) => this.setState({ thumbnail: url })}
                                                icon="add_a_photo"
                                                color="primary"
                                                variant="contained"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                    )}

                    {this.props.context.alwaysOpen ? null : !this.state.fileUrl ? <div style={{ margin: 'auto 12px', display: 'flex', padding: '24px 0', flex: '1', marginBottom: '42px'}}>
                        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', flex: '1', alignItems: 'center'}}>
                            {/* <IconButton style={{padding: '22px', marginBottom: '16px', backgroundColor: '#efebff', border: '1px solid #d2caf5'}}>
                                <Icon color='secondary' style={{fontSize: '4em'}}><img src={UploadContent} style={{height: '100%'}}/></Icon>
                            </IconButton> */}
                            <Icon color='secondary' style={{fontSize: '10em'}}><img src={UploadContent} style={{height: '100%', marginLeft: '-40px'}}/></Icon>
                            <FileUploadButton
                                extensions={this.getExtensions()}
                                color="secondary"
                                text={`Upload ${this.state.type}`}
                                maxSizeMBs={10000}
                                onFail={(e) => this.setState({ uploading: false })}
                                onUpload={(url, filename) => {
                                    this.setState({ fileUrl: url, filename, uploading: false })
                                }}
                                onEnter={() => window.analytics.track('Upload Content Clicked ', {'content_type' : this.state.type})}
                                onUploadStart={() => this.setState({ uploading: true })}
                                uploadProgress={(uploadDone, uploadTotal) => this.setState({ uploadDone, uploadTotal })}
                                containerStyles={{position: 'static'}}
                                variant='contained'
                            />
                        </div>
                    </div> : <> <Card style={{zIndex: '100', marginTop: '12px', boxShadow: 'none', padding: '12px 12px 0'}}>
                        <CardContent style={{display: 'flex', flexDirection: 'column', padding: '0'}}>
                            <div style={{margin: '8px 8px 20px', display: 'flex', alignItems: 'center'}}>
                                <Typography variant="body1" style={{fontWeight: 'bold', marginRight: 'auto'}}>{this.state.type} Uploaded</Typography>
                            </div>
                        </CardContent>
                    </Card>

                    <Card style={{padding: '0px 12px 12px', marginTop: '0', boxShadow: 'none'}}>
                        <CardContent style={{display: 'flex', flexDirection: 'column', padding: '0'}}>
                            {/* <div style={{margin: '8px 8px 20px', display: 'flex', alignItems: 'center'}}>
                                <Typography variant="body1" style={{fontWeight: 'bold', marginRight: 'auto'}}>Uploaded Content</Typography>
                                {this.state.addContentNotVisible ? 
                                    <RoundedButton style={{whiteSpace: 'nowrap', fontSize: '12px', margin: '-8px 0 -8px auto'}} color="secondary" variant="outlined" onClick={() => this.setState({addContentDrawerOpen: true})}><Icon style={{marginRight: '8px', fontSize: '16px'}}>add</Icon> Add Content</RoundedButton>
                                : null }

                            </div> */}
                            <Card style={{display: 'flex', padding: '12px', borderRadius: '12px', boxShadow: '0 0 8px 0 rgba(0, 0, 0, 0.08)', border: 'solid 1px #dbdbdb'}}>
                                <img style={{
                                    borderRadius: '6px',
                                    zIndex: '10',
                                    height: '22.78vw',
                                    width: '22.78vw',
                                    maxHeight: '182.24px',
                                    maxWidth: '182.24px',
                                }} src={this.state.thumbnail} />
                                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flex: '1', marginLeft: '12px'}}>
                                    <Typography variant='body1' style={{fontWeight: 'bold', maxHeight: '38.4px', overflow: 'hidden'}}>{this.state.name}</Typography>
                                    <Typography variant='body2' style={{marginTop: '6px', maxHeight: '34.4px', overflow: 'hidden'}}>{this.state.description}</Typography>
                                    <Typography variant='body2' style={{marginTop: '6px', maxHeight: '34.4px', overflow: 'hidden'}}><a href={this.state.fileUrl} target="_blank">Open uploaded file</a></Typography>
                                </div>
                                <IconButton ref={this.openDrawerIconRef} onClick={event => this.setState({menuOpen: true})} style={{alignSelf: 'flex-start', padding: '4px', margin: '-4px', marginLeft: '12px', padding: '0'}} ><Icon>more_vert</Icon></IconButton>
                            </Card>
                        </CardContent>
                    </Card></> }

                <StyledDrawer alwaysOpen={this.props.context.alwaysOpen} anchorEl={this.openDrawerIconRef.current} title="Select and add content to the folder" onClose={() => this.setState({menuOpen: false, cursor: 'pointer'})} open={this.state.menuOpen} anchor='bottom'>
                    <StyledListItems listItems={[
                        {
                        icon: <img style={{height: '100%'}} src={DeleteIcon} />,
                        text: 'Delete this content',
                    }]} />
                        {/* <ListItem style={{paddingLeft: '20px'}} button onClick={() => this.setState({fileUrl: null, menuOpen: false}) }>
                            <Icon style={{color: '#666666', fontSize: '1.25em', marginRight: '14px'}}><img style={{height: '100%'}} src={DeleteIcon} /></Icon>
                            <Typography variant='body2' style={{color: '#666666'}}>Delete this content</Typography>
                        </ListItem> */}
                    <FileUploadButton
                        extensions={this.getExtensions()}
                        icon={<img src={ChangeIcon} style={{height: '100%'}} />}
                        style={{borderRadius: '0', width: '100%', padding: '8px 20px', color: 'rgb(102, 102, 102)', justifyContent: 'flex-start'}}
                        iconStyle={{fontSize: '1.25em', marginRight: '14px'}}
                        text='Change this content'
                        maxSizeMBs={10000}
                        onFail={(e) => this.setState({ uploading: false })}
                        onUpload={(url, filename) => {
                            this.setState({ fileUrl: url, filename, uploading: false })
                        }}
                        onEnter={() => window.analytics.track('Upload Content Clicked ', {'content_type' : this.state.type})}
                        onUploadStart={() => this.setState({ uploading: true })}
                        uploadProgress={(uploadDone, uploadTotal) => this.setState({ uploadDone, uploadTotal })}
                        containerStyles={{position: 'static'}}
                        variant='text'
                        listItem
                    />
                </StyledDrawer>
                
                <SuccessDialog open={this.state.saved} text="Content updated successfully"/>
                <ConfirmUnsavedDialog open={this.state.confirmUnsavedDialogOpen} resolve={this.state.confirmUnsavedResolver} />

                <WsFooter text="" />
                <MainCTA
                    toast='Please add the title, description and content to proceed'
                    topRight
                    alwaysOpen={this.props.context.alwaysOpen}
                    settings={[]}
                    help={[]}
                    text="Confirm"
                    onCTAClick={() => this.save()}
                    square={!this.props.context.alwaysOpen}
                    disabled={!this.state.name || !this.state.description || !this.state.fileUrl}
                />
            
            </div>
            </>
        )
    }
}