import React from 'react';
import { LinearProgress, TextField, Icon, Typography, Button, Dialog, DialogTitle, DialogContent, DialogActions, Card, CardContent } from '@material-ui/core';

import MainCTA from '../../Components/MainCTA';
import WsHeader from '../../Components/wsheader';
import WsFooter from '../../Components/wsfooter';
import RoundedButton from '../../Components/roundedbutton';

const FileDownload = require('js-file-download');

const Servercalls = require('../../Utils/Servercalls');


export default class Payments extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            loading: true,
            name: "",
            address : "",
            state:"",
            country: "",
            phone: "",
            tax:"N/A",
            accountNumber: "",
            accountNumber2: "",
            swift: "",
            saving: false
        }
    }
    componentDidMount() {
        this.reload();
    }


    reload(){
        const self = this;
        self.setState({ loading: true });
        Servercalls.getApp().then(response => {
            self.setState({ app : response.data });
        });
        Servercalls.getBuilder().then(response => {
            self.setState({
                loading: false, 
                ...response.data.paymentInfo
            });
        });
    }

    download() {
        const self = this;
        self.setState({ downloading: true });
        Servercalls.getAppPayments().then(response => {
            self.setState({ downloading: false });
            FileDownload(response.data, "payments.tsv");
        });
    }

    save() {
        const self = this;
        self.setState({ saving: true });
        const { name, address, state, country, phone, tax, accountNumber, swift } = this.state;
        Servercalls.updateBuilderPaymentInfo({name, address, state, country, phone, tax, accountNumber, swift}).then(() => {
            self.setState({ saved: true });
            setTimeout(() => self.setState({ saved: false }), 3000);
        })
    }

    apply() {
        const self = this;
        self.setState({ applying: true });
        Servercalls.applyInstantSettlement().then( () => {
            self.setState({ applied: true, applying: false, instantSettlementOpen: false});
            setTimeout(() => self.setState({ applied: false}), 3000);
        }).catch(e => {
            console.log(e);
            self.setState({ applying: false })
        });
    }


    render() {
        if(this.state.saved) return (
            <center style={{ margin: 64}}>
                <Icon style={{ fontSize: 120}} color="secondary">check</Icon>
                <br /><br />
                <Typography variant="body2">Payment information has been updated. You'll be redirected shortly</Typography>
            </center>
        )
        if(this.state.applied) return (
            <center style={{ margin: 64}}>
                <Icon style={{ fontSize: 120}} color="secondary">check</Icon>
                <br /><br />
                <Typography variant="body2">You have applied for instant payouts. You'll be redirected shortly</Typography>
            </center>
        )


        if(this.state.loading)
            return <LinearProgress />
        
            return (
                <Card style={{margin: 12 }}>
                    <CardContent>
                    <WsHeader title="Payouts" back={this.props.back} />
                    
                        {!this.state.app || !this.state.app.instantSettlement ? <RoundedButton onClick={() => this.setState({ instantSettlementOpen: true })} color="secondary" variant="outlined"> Apply to Instant Payouts - BETA </RoundedButton> : null}
                    
                    <Typography style={{marginTop: 24 }} variant="body1">Fill up these details to receive payouts. Your payouts will be made on 7th of every month.</Typography>
                    <TextField fullWidth style={{ marginTop: 12 }} label="Full Legal Name" value={this.state.name} onChange={e => this.setState({name: e.target.value})}/>
                    <TextField fullWidth style={{ marginTop: 12 }} label="Address" value={this.state.address} onChange={e => this.setState({address: e.target.value})}/>
                    <TextField fullWidth style={{ marginTop: 12 }} label="State" value={this.state.state} onChange={e => this.setState({state: e.target.value})}/>
                    <TextField fullWidth style={{ marginTop: 12 }} label="Country" value={this.state.country} onChange={e => this.setState({country: e.target.value})}/>
                    <TextField fullWidth style={{ marginTop: 12 }} label="Phone Number" value={this.state.phone} onChange={e => this.setState({phone: e.target.value})}/>
                    <TextField fullWidth style={{ marginTop: 12 }} label="TAX Number / GST Number" value={this.state.tax} onChange={e => this.setState({tax: e.target.value})}/>
                    <TextField fullWidth type="password" style={{ marginTop: 12 }} label="Account Number" value={this.state.accountNumber} onChange={e => this.setState({accountNumber: e.target.value})}/>
                    <TextField fullWidth style={{ marginTop: 12 }} label="Confirm Account Number" value={this.state.accountNumber2} onChange={e => this.setState({accountNumber2: e.target.value})}/>
                    <TextField fullWidth style={{ marginTop: 12 }} label="IFSC / SWIFT " value={this.state.swift} onChange={e => this.setState({swift: e.target.value})}/>
                    <WsFooter text="We know, this is your favorite part of the app"/>
                    <MainCTA icon="save" text="save" onCTAClick={() => this.save()} disabled={ this.state.name.length === 0 || this.state.address.length === 0 || this.state.state.length === 0 || this.state.country.length === 0 || this.state.phone.length === 0 || this.state.tax.length === 0 || this.state.accountNumber.length === 0 || this.state.accountNumber !== this.state.accountNumber2 || this.state.swift.length === 0 || this.state.saving } settings={[]} help={[]}/>

                    <Dialog open={this.state.instantSettlementOpen} onClose={() => this.setState({ instantSettlementOpen: false })}>
                        <DialogTitle>Apply to Instant Settlements - Beta</DialogTitle>
                        <DialogContent>
                            <Typography variant="body1">
                                You may apply to get instant payouts to your bank account as soon as someone makes a payment.
                                <br />
                                It is upto the sole discretion of Socionity to enable instant payouts to your bank account. However, we're giving priority access to users who meet the following criteria :
                                <ul>
                                    <li> Had high volume of transactions in the last calendar month</li>
                                    <li> Have subscribed to the PRO plan </li>
                                    <li> Provably belong to a low income group </li>
                                    <li> Your app is affiliated to a not-for-profit organization </li>
                                </ul>
                                You can apply by simply tapping on Apply Now below. We will contact you within 48 hours with the status of your application.
                            </Typography>
                        </DialogContent>
                        <DialogActions>
                            <RoundedButton color="primary" variant="outlined" onClick={() => this.setState({ instantSettlementOpen: false })}>Close</RoundedButton>
                            <RoundedButton color="primary" variant="contained" disabled={this.state.applying} onClick={() => this.apply()}>Apply Now</RoundedButton>
                        </DialogActions>
                    </Dialog>
                    </CardContent>
                </Card>
            )
    }
}