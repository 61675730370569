import React from 'react';
import Theme from '../../Components/theme';
import WsHeader from '../../Components/wsheader';
import WsFooter from '../../Components/wsfooter';
import MainCTA from '../../Components/MainCTA';
import { TextField, Button, Icon, Typography, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Card, CardContent, Dialog } from '@material-ui/core';

const Servercalls = require('../../Utils/Servercalls');

export default class WsContentTitle extends React.Component {

    constructor(props){
        super(props);
        const content = this.props.context;
        this.state = {
            visibility: content.free?"everyone":"paid",
            saving: false, 
            saved: false,
        }
    }

    save() {
        const content = this.props.context;
        const self = this;
        self.setState({ saving: true });
        let free = false;
        if(this.state.visibility === 'everyone'){
            free = true;
        }
        else if(this.state.visibility === 'paid'){
            free = false;
        }
        Servercalls.pluginpremier_addContentToBundle('todo',content.bundleId, content.contentId, content.name, content.description, content.fileUrl, content.published, free, content.type, content.thumbnail, content.longDescription, content.tags, content.list).then(response => {
            
            if(this.props.renderAsDialog){
                this.props.onClose();
                return;
            }
            
            self.props.changeContext('free', free);
            self.setState({ saving: false, saved: true});
            setTimeout(() => self.props.back(), 3000);            
        }).catch(e => { alert(e); self.setState({ saving: false })});
    }

    render(){
        if(this.state.saved) return (
            <center style={{ margin: 64}}>
                <Icon style={{ fontSize: 120}} color="secondary">check</Icon>
                <br /><br />
                <Typography variant="body2">Who can see this has been updated. You'll be redirected shortly</Typography>
            </center>
        )

        if(this.props.renderAsDialog) {
            return (
                <Dialog
                    open={this.props.open}
                    onClose={this.props.onClose}
                    PaperProps={{
                        style: {
                            borderRadius: '16px',
                            padding: '0 24px',
                        }
                    }}
                    style={{borderRadius: '16px'}}
                >
                    
                    <CardContent>
                        <Typography variant="body1">
                            If set to paid, people will be able to open the content only after paying. Select free if this is a sample for the collection.
                        </Typography>
                
                        <RadioGroup style={{ margin: 12}} aria-label="visibility" name="visibility" value={this.state.visibility} onChange={e => this.setState({ visibility: e.target.value })}>
                            <FormControlLabel value="everyone" control={<Radio />} label="Free" />
                            <FormControlLabel value="paid" control={<Radio />} label="Paid (recommended)" />
                        </RadioGroup>

                    </CardContent>  

                    <MainCTA 
                        disabled={this.state.saving} 
                        onCTAClick={() => this.save()} 
                        icon="save" 
                        text="Save visibility" 
                        settings={[]}
                        help={[
                            {title: "Why will I ever choose 'free'?", text: "This is a good tool to give away some of your content for free. This helps build trust. People want to see how the product looks before they pull out their cash. Also, when they see your free content, they'll need to sign in. That gives you their email. You can target your marketing at them if they don't purchase."}
                        ]}
                        style={{position: 'relative', width: 'calc(100% + 48px)', marginTop: '24px', marginLeft: '-24px'}}
                        square
                    />
                </Dialog>
            )
        }

        return(
            <Theme>
                <WsHeader 
                    back={this.props.back}
                    title={this.props.context.name}
                    description="You can control whether people can access this content with or without paying for the collection."
                    helpText="What should I choose here?"
                    helpVideo="a"
                />
                <Card style={{ margin: 12 }}>
                    <CardContent>
                    <Typography variant="h6">Paid or Free?</Typography>
                    <Typography variant="body1" style={{ margin: 12}}>If set to paid, people will be able to open the content only after paying. Select free if this is a sample for the collection. </Typography>
                    <RadioGroup aria-label="visibility" name="visibility" value={this.state.visibility} onChange={e => this.setState({ visibility: e.target.value })} style={{ marginTop: 12}}>
                        <FormControlLabel value="everyone" control={<Radio />} label="Free" />
                        <FormControlLabel value="paid" control={<Radio />} label="Paid (recommended)" />
                    </RadioGroup>
                    </CardContent>
                </Card>
                <WsFooter text="Select 'Everyone' if you want this content item to be available to users before buying. Use this to give users a look into a part of the content. It's a great way to help users make an informed buying choice." />
                <MainCTA 
                    settings={[]}
                    help={[
                        {title: "Why will I ever choose 'free'?", text: "This is a good tool to give away some of your content for free. This helps build trust. People want to see how the product looks before they pull out their cash. Also, when they see your free content, they'll need to sign in. That gives you their email. You can target your marketing at them if they don't purchase."}
                    ]}
                    onCTAClick={() => this.save()} 
                    disabled={this.state.saving} 
                    icon="save" 
                    text="Save visibility" 
                />
            </Theme>
        )
    }
}