import React from 'react';
import {
  LinearProgress, Button, Icon, Typography, TextField, Card, CardContent, CircularProgress, IconButton,
} from '@material-ui/core';
import { Editor as TinyEditor } from '@tinymce/tinymce-react';
import MomentUtils from '@date-io/moment';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import md5 from 'md5';
import copy from 'copy-to-clipboard';
import WsHeader from '../../Components/wsheader';
import WsFooter from '../../Components/wsfooter';
import MainCTA from '../../Components/MainCTA';
import RoundedButton from '../../Components/roundedbutton';
import FileUploadButton from '../../Components/fileuploadbutton';
import StyledInput from '../../Components/StyledInput';

import SuccessDialog from '../../Components/SuccessDialog';
import ConfirmUnsavedDialog from '../../Components/ConfirmUnsavedDialog';

const Servercalls = require('../../Utils/Servercalls');
const Constants = require('../../Utils/Constants');
const utils = require('../../Utils/utils');

export default class WsPdfContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: this.props.context.name || '',
      description: this.props.context.description || '',
      scheduledTime: this.props.context.fileUrl ? this.props.context.fileUrl.scheduledTime : moment().format('YYYY-MM-DDTHH:mm'),
      fileUrl: this.props.context.fileUrl || null,
      isZoomLoggedIn: false,
      webinars: [],
      thumbnail: this.props.context.thumbnail || `https://www.gravatar.com/avatar/${md5(`${Date.now() + Math.random()} `)}?d=identicon`,

      date: new Date(),
      datePickerOpen: false,

      itemId: this.props.context.itemId,

      contentId: this.props.context.contentId || null,
      published: this.props.context.published || true,
      free: this.props.context.free || false,
      type: this.props.context.type || 'zoom',
      longDescription: this.props.context.longDescription || '',
      tags: this.props.context.tags || [],
      list: this.props.context.list || 'normal',
    };

    this.openDrawerIconRef = React.createRef();
  }

  componentDidMount() {
    this.loadZoomLogin();
    this.reload();
  }

  reload() {
    if (!this.state.fileUrl || this.state.fileUrl === 'NONE') return;

    const self = this;
    self.setState({ loading: true });
    Servercalls.zoomGetWebinar(this.state.fileUrl.id).then((response) => {
      console.log('Existing', response.data);
      if (moment(response.data.created_at).add(6, 'hours') > moment()) {
        self.setState({ existingMeeting: response.data, loading: false });
      }
    });
  }

  createNewMeeting() {
    const self = this;
    const content = this.props.context;

    self.setState({ loading: true });
    Servercalls.zoomCreateWebinar(content.name, content.description).then((response) => {
      console.log(response.data);
      self.setState({ fileUrl: { ...response.data, scheduledTime: this.state.scheduledTime }, existingMeeting: response.data, loading: false });
    }).catch((e) => {
      console.log(e);
    });
  }

  loadZoomLogin() {
    const self = this;
    self.setState({ loading: true });

    Servercalls.zoomGetUser().then((response) => {
      self.setState({
        loading: false, isZoomLoggedIn: true, webinars: response.data.meetings, waitingForLogin: false,
      });
    }).catch((e) => {
      self.setState({ loading: false, isZoomLoggedIn: false });
    });
  }

  async save() {
    const content = this.props.context;
    const self = this;
    self.setState({ saving: true });

    let { itemId } = this.state;
    if (!itemId) {
      itemId = await this.props.context.getItemId();
    }

    const {
      contentId, name, description, published, free, type, thumbnail, longDescription, tags, list, fileUrl,
    } = this.state;
    Servercalls.pluginpremier_addContentToBundle(this.props.context.pluginId, itemId, contentId, name, description, fileUrl, published, free, type, thumbnail, longDescription, tags, list).then((response) => {
      window.analytics.track('Content Confirm Clicked', { content_type: type });
      self.setState({ saving: false, saved: true, itemId });
      //  self.props.back()
      setTimeout(() => {
        this.setContextAndBack();
      }, 3000);
    }).catch((e) => { alert(e); self.setState({ saving: false, itemId }); });

    // Servercalls.pluginpremier_addContentToBundle('todo',content.bundleId, content.contentId, content.name, content.description, fileUrl, content.published, content.free, content.type, content.thumbnail, content.longDescription, content.tags, content.list).then(response => {
    //     self.setState({ saving: false, saved: true});
    //     setTimeout(() => self.setState({ saved: false}), 3000);
    //     self.reload();
    // }).catch(e => { alert(e); self.setState({ saving: false })});
  }

  checkZoomLogin() {
    const self = this;
    self.setState({ waitingForLogin: true });
    Servercalls.zoomGetUser().then((response) => {
      self.setState({ waitingForLogin: false });
      clearInterval(self.loginRetryInterval);
      self.loadZoomLogin();
    }).catch((e) => {
    });
  }

  login() {
    if (this.loginRetryInterval) clearInterval(this.loginRetryInterval);
    this.loginRetryInterval = setInterval(() => this.checkZoomLogin(), 5000);
    this.setState({ waitingForLogin: true });
    window.open(`https://zoom.us/oauth/authorize?response_type=code&client_id=${Constants.ZoomClientId}&redirect_uri=${Constants.AppUrl}&state=zoom_close`, '_blank');
  }

  setContextAndBack() {
    this.props.changeParentContext('pluginId', this.props.context.pluginId);
    this.props.changeParentContext('itemId', this.state.itemId);

    if (this.props.context.bundle_name) {
      this.props.changeParentContext('name', this.props.context.bundle_name);
    }
    if (this.props.context.bundle_description) {
      this.props.changeParentContext('description', this.props.context.bundle_description);
    }

    if (this.props.context.bundle_priceList) {
      this.props.changeParentContext('priceList', this.props.context.bundle_priceList);
    }
    if (this.props.context.bundle_imageUrl) {
      this.props.changeParentContext('imageUrl', this.props.context.bundle_imageUrl);
    }
    if (this.props.context.bundle_availableForPurchase) {
      this.props.changeParentContext('availableForPurchase', this.props.context.bundle_availableForPurchase);
    }
    if (this.props.context.bundle_published) {
      this.props.changeParentContext('published', this.props.context.bundle_published);
    }
    this.props.changeParentContext('newCollection', false);
    this.props.back();
  }

  getLoginAction() {
    if (this.state.waitingForLogin) {
      return (
        <div style={{
          width: this.props.context.alwaysOpen ? '' : '100%', zIndex: '3500', display: 'flex', flexWrap: 'wrap', flexDirection: this.props.context.alwaysOpen ? 'row' : 'column', alignItems: this.props.context.alwaysOpen ? 'center' : 'flex-start', flex: '1',
        }}
        >
          <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            <Typography variant="body1" style={{ margin: this.props.context.alwaysOpen ? '16px 0' : '0 0 12px', fontWeight: 'bold' }}>
              Waiting for Login
            </Typography>
            <CircularProgress style={{
              margin: this.props.context.alwaysOpen ? '0 0 0 24px' : '0 0 12px 12px', height: this.props.context.alwaysOpen ? '40px' : '20px', width: this.props.context.alwaysOpen ? '40px' : '20px', display: 'inline-block',
            }}
            />
          </div>
          <RoundedButton style={{ margin: this.props.context.alwaysOpen ? '16px 24px' : '0 auto 12px' }} onClick={() => this.login()} variant="outlined" color="secondary">Retry</RoundedButton>
        </div>
      );
    }
    return !this.state.isZoomLoggedIn ? (
      <div style={{
        width: this.props.context.alwaysOpen ? '' : '100%', zIndex: '3500', display: 'flex', flexWrap: 'wrap', flexDirection: this.props.context.alwaysOpen ? 'row' : 'column', alignItems: this.props.context.alwaysOpen ? 'center' : 'flex-start',
      }}
      >
        <Typography variant="body1" style={{ margin: this.props.context.alwaysOpen ? '16px 0' : '0 0 12px', fontWeight: 'bold' }}>
          Login with Zoom to continue
        </Typography>
        <RoundedButton style={{ margin: this.props.context.alwaysOpen ? '8px 24px' : '0 auto 12px' }} onClick={() => this.login()} variant="contained" color="secondary">Login with Zoom</RoundedButton>
      </div>
    ) : (
      <div style={{
        width: this.props.context.alwaysOpen ? '' : '100%', zIndex: '3500', display: 'flex', flexWrap: 'wrap', flexDirection: this.props.context.alwaysOpen ? 'row' : 'column', alignItems: this.props.context.alwaysOpen ? 'center' : 'flex-start',
      }}
      >
        <Typography variant="body1" style={{ margin: this.props.context.alwaysOpen ? '16px 0' : '0 0 12px', fontWeight: 'bold' }}>
          You have not created a zoom session yet
        </Typography>
        <RoundedButton style={{ margin: this.props.context.alwaysOpen ? '8px 24px' : '0 auto 12px' }} onClick={() => this.createNewMeeting()} variant="outlined" color="secondary">Create a Zoom Session</RoundedButton>
      </div>
    );
  }

  share() {
    window.analytics.track('Share CTA Clicked', { content_shared: 'zoom' });

    if (navigator.share) {
      navigator.share({
        url: this.state.existingMeeting.start_url,
        title: 'Zoom Meeting',
        text: 'Check out my zoom meeting',
      });
    } else {
      copy(this.state.existingMeeting.start_url);
      this.setState({ copied: true });

      setTimeout(() => this.setState({ copied: false }), 3000);
    }
  }

  getStartAction() {
    return (
      <div style={{
        zIndex: '3500', display: 'flex', flexWrap: 'wrap', flexDirection: this.props.context.alwaysOpen ? 'column' : 'column', alignItems: this.props.context.alwaysOpen ? 'center' : 'flex-start',
      }}
      >

        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <Typography variant="body1" style={{ margin: this.props.context.alwaysOpen ? '16px 0' : '0 0 12px', fontWeight: 'bold' }}>
            Meeting Link
          </Typography>
          <RoundedButton
            style={{ margin: this.props.context.alwaysOpen ? '0 24px 0 24px' : '0 12px 12px' }}
            onClick={() => {
              if (this.state.existingMeeting.status === 'started') {
                window.open(this.state.existingMeeting.join_url, '_blank');
              } else {
                window.open(this.state.existingMeeting.start_url, '_blank');
              }
            }}
            variant="contained"
            color="secondary"
          >
            Launch Meeting
          </RoundedButton>
          {this.state.copied ? (
            <Typography variant="caption" style={{ margin: this.props.context.alwaysOpen ? '0 24px 0 24px' : '0 auto 12px' }}>
              Copied Link
            </Typography>
          ) : (
            <RoundedButton
              style={{ margin: this.props.context.alwaysOpen ? '0 24px 0 0' : '0 0 12px' }}
              onClick={() => this.share()}
              variant="outlined"
              color="secondary"
            >
              Share
            </RoundedButton>
          )}
        </div>
        <Typography
          variant="body2"
          style={{ margin: '0', wordBreak: 'break-all' }}
          onClick={() => {
            if (this.state.existingMeeting.status === 'started') {
              window.open(this.state.existingMeeting.join_url, '_blank');
            } else {
              window.open(this.state.existingMeeting.start_url, '_blank');
            }
          }}
          variant="contained"
          color="secondary"
        >
          {this.state.existingMeeting.join_url}
        </Typography>
      </div>
    );
  }

  renderContentNameInput() {
    return (
      <StyledInput
        alwaysOpen={this.props.context.alwaysOpen}
        placeholder="Enter title"
        fullWidth
        value={this.state.name}
        onChange={(e) => this.setState({ name: e.target.value })}
      />
    );
  }

  renderContentDescriptionInput() {
    if (this.props.context.alwaysOpen) {
      return (
        <StyledInput
          alwaysOpen
          variant="outlined"
          style={{ marginTop: '24px', flex: '0' }}
          inputStyle={{ height: '2em', overflow: 'scroll' }}
          multiline
          placeholder="Enter description"
          value={this.state.description}
          onChange={(e) => this.setState({ description: e.target.value })}
        />
      );
    }
    return (
      <StyledInput
        placeholder="Enter description"
        fullWidth
        style={{ marginTop: '16px' }}
        value={this.state.description}
        onChange={(e) => this.setState({ description: e.target.value })}
      />
    );
  }

  renderThumbnailInput() {
    return (
      <div style={this.props.context.alwaysOpen ? { position: 'relative' } : {
        marginLeft: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center',
      }}
      >
        <img
          style={{
            borderRadius: '12px',
            zIndex: '10',
            boxShadow: '0 0 16px 0 rgba(0, 0, 0, 0.16)',
            backgroundColor: 'rgba(0,0,0,0.15)',
            height: this.props.context.alwaysOpen ? '23.611vw' : '23.611vw',
            width: this.props.context.alwaysOpen ? '41.975vw' : '23.611vw',
            maxHeight: this.props.context.alwaysOpen ? '188.88px' : '188.88px',
            maxWidth: this.props.context.alwaysOpen ? '335.785px' : '188.88px',
          }}
          src={this.state.thumbnail}
        />
        <FileUploadButton
          style={{
            position: 'relative',
            height: '23.611vw',
            width: this.props.context.alwaysOpen ? '41.975vw' : '23.611vw',
            maxHeight: '188.88px',
            maxWidth: this.props.context.alwaysOpen ? '335.785px' : '188.88px',
            backgroundColor: 'transparent',
            borderRadius: '12px',
            boxShadow: 'none',
          }}
          extensions={['png', 'jpg', 'jpeg']}
          maxSizeMBs={2}
          onFail={(e) => alert(e)}
          onUpload={(url) => this.setState({ thumbnail: url })}
          icon="edit"
          color="primary"
          variant="contained"
          containerStyles={this.props.context.alwaysOpen ? { top: '0' } : {}}
        />
      </div>
    );
  }

  renderDateTimePicker() {
    return (
      <>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="body1" style={{ margin: '9px 0 8px', fontWeight: 'bold' }}>
            {moment(this.state.date).format('dddd, MMMM Do YYYY	')}
          </Typography>

          <RoundedButton style={{ marginLeft: 'auto' }} onClick={() => this.setState({ datePickerOpen: true })} variant="outlined" color="secondary">Change</RoundedButton>

          <MuiPickersUtilsProvider utils={MomentUtils}>
            <DateTimePicker style={{ display: 'none' }} onClose={() => this.setState({ datePickerOpen: false })} open={this.state.datePickerOpen} color="secondary" value={this.state.date} onChange={(date) => this.setState({ date, datePickerOpen: false })} />
          </MuiPickersUtilsProvider>
        </div>
        <div>
          <Typography variant="body2" style={{ margin: '0 0 24px', color: '#999999' }}>
            {moment(this.state.date).format('hh:mm a')}
          </Typography>
        </div>
      </>
    );
  }

  render() {
    return (
      <>
        <div style={{
          display: 'flex', flex: '1', marginBottom: '42px', flexDirection: 'column',
        }}
        >
          <WsHeader
            title="Zoom Meeting"
            back={async () => {
              const self = this;
              const confirmUnsaved = () => new Promise((resolve) => self.setState({ confirmUnsavedDialogOpen: true, confirmUnsavedResolver: resolve }));
              const confirmation = await confirmUnsaved();
              self.setState({ confirmUnsavedDialogOpen: false, confirmUnsavedResolver: null });

              if (!confirmation) {
                // cannot save here as it will break design incase of incomplete input
                // this.save()

              } else {
                this.setContextAndBack();
              }
            }}
          />
          {this.props.context.alwaysOpen ? (
            <Card style={{ margin: '68px 40px 20px', borderBottom: 'solid 1px #dbdbdb', borderRadius: '6px' }}>
              <CardContent style={{ padding: '0' }}>
                <div style={{ display: 'flex', marginBottom: '24px', padding: '40px 40px 0' }}>

                  <div style={{
                    marginRight: 'auto', flex: '1', flexDirection: 'column', display: 'flex',
                  }}
                  >

                    {this.renderContentNameInput()}
                    {this.renderContentDescriptionInput()}

                    {this.renderDateTimePicker()}

                    {!this.state.existingMeeting ? this.getLoginAction() : this.getStartAction()}

                    {/* <div style={{position: 'relative'}}> */}
                    {/* {this.renderContentCard()} */}
                    {/* {!this.state.existingMeeting? this.getLoginAction() : this.getStartAction()} */}
                    {/* </div> */}
                  </div>
                  <div style={{ marginLeft: '60px', display: 'flex', justifyContent: 'center' }}>
                    {this.renderThumbnailInput()}
                  </div>
                </div>
              </CardContent>
            </Card>
          ) : (
            <Card style={{ marginTop: '52px', boxShadow: 'none' }}>
              <CardContent style={{ padding: '0' }}>
                <div style={{ padding: '20px' }}>
                  <div style={{ display: 'flex' }}>

                    <div style={{
                          marginRight: 'auto', flex: '1', flexDirection: 'column', justifyContent: 'space-evenly', display: 'flex',
                        }}
                        >

                          {this.renderContentNameInput()}
                          {this.renderContentDescriptionInput()}

                        </div>
                    {this.renderThumbnailInput()}
                  </div>
                </div>
              </CardContent>
            </Card>
          )}

          {this.props.context.alwaysOpen ? null : (
            <Card style={{
              zIndex: '100', marginTop: '12px', boxShadow: 'none', padding: '12px 12px 0',
            }}
            >
              <CardContent style={{ display: 'flex', flexDirection: 'column', padding: '0' }}>
                {this.renderDateTimePicker()}
              </CardContent>
            </Card>
          )}

          {this.props.context.alwaysOpen ? null : (
            <Card style={{
              zIndex: '100', marginTop: '12px', boxShadow: 'none', padding: '12px 12px 0',
            }}
            >
              <CardContent style={{ display: 'flex', flexDirection: 'column', padding: '0' }}>
                <div style={{ margin: '8px 8px 20px', display: 'flex', alignItems: 'center' }}>
                  {!this.state.existingMeeting ? this.getLoginAction() : this.getStartAction()}
                </div>
              </CardContent>
            </Card>
          )}

          <SuccessDialog open={this.state.saved} text="Content updated successfully" />
          <ConfirmUnsavedDialog open={this.state.confirmUnsavedDialogOpen} resolve={this.state.confirmUnsavedResolver} />

          <WsFooter text="" />
          <MainCTA
            toast="Please add the title, description and meeting to proceed"
            topRight
            alwaysOpen={this.props.context.alwaysOpen}
            settings={[]}
            help={[]}
            text="Confirm"
            onCTAClick={() => this.save()}
            square={!this.props.context.alwaysOpen}
            disabled={!this.state.name || !this.state.description || !this.state.fileUrl}
          />

        </div>
      </>
    );
  }
}
