import React from 'react';
import CommonAddContent from './ws-commonaddcontent';

export default class WsAddVideoContent extends React.Component {
    render() {
        return (
            <CommonAddContent
                title="Upload a PDF file"
                description="The file can be downloaded. Beware, the user will be able to share this file after downloading." 
                helpText="Know more about Downloadable content"
                helpVideo="a"
                filetype="downloadable"
                {...this.props}
            />
        )
    }
}