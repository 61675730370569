import React from 'react';
import { withTheme } from '@material-ui/styles';
import { Button } from '@material-ui/core';

class RoundedButton extends React.Component {
    render() {
        return (
            <Button disableElevation={!this.props.enableElevation} {...this.props} style={{ borderRadius: 18, textTransform: 'none', ...this.props.style,}}>
                {this.props.children}
            </Button>
        )
    }
}

export default withTheme(RoundedButton);