import React from 'react';
import Theme from '../../Components/theme';
import WsHeader from '../../Components/wsheader';
import WsFooter from '../../Components/wsfooter';
import FileUploadButton from '../../Components/fileuploadbutton';
import MainCTA from '../../Components/MainCTA';
import { Typography, TextField, Checkbox, FormControlLabel, Button, Icon, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
const Servercalls = require('../../Utils/Servercalls');

export default class WsBundleSalespage extends React.Component {

    constructor(props){
        super(props);
        const bundle = this.props.context;
        this.state = {
            salesPage: bundle.salesPage,
        }
    }

    async save() {
        const self = this;
        const bundle = this.props.context;
        self.setState({ saving: true });
        try{
            await Servercalls.pluginpremier_updateBundle(bundle.pluginId, bundle.itemId, "salesPage", self.state.salesPage);
            self.props.changeParentContext('salesPage', self.state.salesPage);
            self.setState({ saving: false, saved: true });
            setTimeout(() => {
                self.props.back();
                self.setState({ saved: false });
            }, 3000);
        }
        catch(e){ alert(e); self.setState({ saving: false })};
    }

    render() {
        if(this.state.saved) return (
            <center style={{ margin: 64}}>
                <Icon style={{ fontSize: 120}} color="secondary">check</Icon>
                <br /><br />
                <Typography variant="body2">Sales Banner has been saved. You'll be redirected shortly</Typography>
            </center>
        )
        return (
            <Theme>
                <WsHeader back={this.props.back} icon="title" title="Sales Banner" description='This image is shown to the users before they make a purchase of your collection. This pamphlet contains vital information for the user to make a buy decision' helpText="What's a sales pamphlet?" helpVideo="a" />
                <div style={{ margin: 32 }}>
                    <Typography style={{ marginTop: 8}} variant="body2">
                        Make sure your sales banner has the following
                        <ol>
                            <li>Your name</li>
                            <li>Your credentials</li>
                            <li>Your experience</li>
                            <li>Whom is this collection for</li>
                            <li>Pre-requisites for viewing this content, if any</li>
                            <li>What a user can expect to get from this collection</li>
                            <li>What change will come to the user's life after buying and viewing this collection</li>
                            <li>What are other people who have bought this collection saying?</li>
                        </ol>
                        Not a designer? Use <a href="https://canva.com" target="_blank">Canva</a> to design the pamphlet.
                        <br />
                        <Button onClick={() => this.setState({ sampleOpen: true })} style={{ marginTop: 32, marginBottom: 32 }} color="secondary">See sample pamphlet</Button>

                    </Typography>

                    {
                        this.state.salesPage? <img src={ this.state.salesPage } style={{ width: '100%' }} /> : <Typography variant="h6"> You haven't added a salespage for the collection </Typography>
                    }
                    <FileUploadButton
                        style={{ marginTop: 32 }}
                        extensions={['png','jpg','jpeg','webp']}
                        maxSizeMBs={5}
                        onFail={e => alert(e)}
                        onUpload={(url) => this.setState({ salesPage: url })}
                        icon="add_a_photo"
                        text={this.state.salesPage? `Change Sales Banner`: `Upload a sales Banner`}
                        color="secondary"
                    />
                    <Dialog 
                        open={this.state.sampleOpen}
                    >
                        <DialogTitle>
                            Sample Sales Banner
                        </DialogTitle>
                        <DialogContent>
                            <Typography variant="body1">This is a sample from <a href="https://cip.nity.app" target="_blank">cip.nity.app</a></Typography>
                            <br />                                        
                            <img src="https://cdn.socionity.com/1572329581618.8997-06757caa228fa165b060b3d4657803e2.jpg" style={{ width: '100%'}} />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => this.setState({ sampleOpen: false })}> Close </Button>
                        </DialogActions>
                    </Dialog>
    
                </div>
                <WsFooter text="A Sales Banner is critical for a user to buy your collection. Spend some time getting this right." />
                <MainCTA icon="save" text="Save sales banner" disabled={this.state.saving} onClick={() => this.save()}  />
            </Theme>
        )
    }
}