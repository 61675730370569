import React from 'react';
import { Card, CardContent, Typography, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Icon,TextField, List, ListItem, ListItemText, ListItemSecondaryAction, IconButton, ListItemIcon, CardHeader, CardActions, Avatar } from '@material-ui/core';

import WsHeader from '../../Components/wsheader';
import { withTheme } from '@material-ui/styles';
import Illustration from '../../Components/illustration';
import RoundedButton from '../../Components/roundedbutton';

const Servercalls = require('../../Utils/Servercalls');

class AppMarketing extends React.Component{
    constructor(props){
        super(props);
        this.state = {

        }
    }
    componentDidMount() {
        this.reload();
    }

    reload(){
        const self = this;
        self.setState({ loading: true });
        Servercalls.getApp().then((response) => {
            if(response.data.marketing)
                self.setState({ ...response.data.marketing, loading: false });
        }).catch(() => {
            self.setState({ loading: false })
        });
    }   


    save(key, value){
        const self = this;
        self.setState({ loading: true });
        Servercalls.putAppMarketing(key, value).then(() => {
            self.setState({ loading: false });
            self.setState({ saved: true });
            setTimeout(() => self.setState({ saved: false}), 3000);
        }).catch(e => self.setState({loading: false}));
    }

    extractFBCode(string){

        if(string.indexOf("fbq") > 0){
            console.log(string.match(/fbq\(\'init\', \'(.*?)\'\)/));
            return string.match(/fbq\(\'init\', \'(.*?)\'\)/)[1];
        }
        return string;
    }

    extractGACode(string){

        if(string.indexOf("gtag") > 0){
            console.log(string.match(/gtag\(\'config\', \'(.*?)\'\)/));
            return string.match(/gtag\(\'config\', \'(.*?)\'\)/)[1];
        }
        return string;
    }
    render(){
        if(this.state.saved) return (
            <center style={{ margin: 64}}>
                <Icon style={{ fontSize: 120}} color="secondary">check</Icon>
                <br /><br />
                <Typography variant="body2">Saved. You'll be redirected shortly</Typography>
            </center>
        )


        return (
            <div style={{ margin : 12}}>
            <WsHeader back={this.props.back} title="Marketing Tools" description="This is the identity of your app. When a user installs your app on their device, this is the name and logo they'll see on their home screen" helpText="Why should I customize this?" helpVideo="a" />
                <Typography style={{ marginTop: 12}} variant="h6">Personal Growth Advisor</Typography>                        
                <Card style={{marginTop: 12}}>
                    <CardHeader 
                        avatar={<Avatar src="https://socionity.com/assets/uploads/c.jpeg?p=By08uSkp"/>}
                        title="Chitrak Gangrade"
                        subheader="Growth Advisor"
                    />
                    <CardContent >
                        <Typography variant="body2">Contact your personal growth advisor and get detailed strategies that will work for you.</Typography>
                        <Typography style={{marginTop: 12}} variant="subtitle2" color="textSecondary">
                            chitrak@socionity.com
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <RoundedButton variant="outlined" color="primary" onClick={() => window.open("mailto:chitrak@socionity.com")}><Icon>mail</Icon>&nbsp;Email</RoundedButton>
                    </CardActions>
                </Card>

                <Typography style={{ marginTop: 12}} variant="h6">Getting started</Typography>                
                <ExpansionPanel style={{marginTop: 12}}>
                    <ExpansionPanelSummary
                    expandIcon={<Icon>expand_more</Icon>}
                    >
                    <Typography style={{fontSize: this.props.theme.typography.pxToRem(15),fontWeight: this.props.theme.typography.fontWeightRegular,}}>Before launch</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <div>
                            <Typography variant="body1">Here are a few links must go through before starting your marketing</Typography>
                            <Typography style={{ marginTop: 12 }}>
                                <List>
                                    <ListItem button onClick={() => window.open("https://blog.socionity.com/2020/05/18/even-before-you-launch-your-app/","_blank")}>
                                        <ListItemIcon>
                                            <Icon>search</Icon>
                                        </ListItemIcon>
                                        <ListItemText primary="Even before launching" secondary="How to research before building your app" />
                                        <ListItemSecondaryAction>
                                            <Icon>exit_to_app</Icon> 
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                    <ListItem button onClick={() => window.open("https://blog.socionity.com/2020/05/18/marketing-your-app-content/","_blank")}>
                                        <ListItemIcon>
                                            <Icon>people</Icon>
                                        </ListItemIcon>
                                        <ListItemText primary="First steps to marketing" secondary="Simple steps before you adopt marketing techniques" />
                                        <ListItemSecondaryAction>
                                            <Icon>exit_to_app</Icon> 
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                </List>
                            </Typography>

                        </div>
                    </ExpansionPanelDetails>
                </ExpansionPanel>

                <Typography style={{ marginTop: 12}} variant="h6">Connect tools</Typography>
                <ExpansionPanel style={{marginTop: 12}}>
                    <ExpansionPanelSummary
                    expandIcon={<Icon>expand_more</Icon>}
                    >
                    <Typography style={{fontSize: this.props.theme.typography.pxToRem(15),fontWeight: this.props.theme.typography.fontWeightRegular,}}>Facebook Pixel</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <div>
                            <TextField  label="Pixel Code" style={{ margin: 12}} onChange={(event)=> this.setState({ fbPixel: this.extractFBCode(event.target.value) })} defaultValue={this.state.fbPixel} value={this.state.fbPixel} />
                            <RoundedButton onClick={() => this.save('fbPixel', this.state.fbPixel)} variant="contained" color="primary" style={{margin: 12}}>Save</RoundedButton>
                            <br />
                            <RoundedButton variant="outlined" onClick={() => window.open("https://www.youtube.com/watch?v=GAmlaeiB-4M", "_blank")} color="secondary" style={{ margin: 12 }}>How to find your FB pixel</RoundedButton>
                        </div>
                    </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel>
                    <ExpansionPanelSummary
                    expandIcon={<Icon>expand_more</Icon>}
                    >
                    <Typography style={{fontSize: this.props.theme.typography.pxToRem(15),fontWeight: this.props.theme.typography.fontWeightRegular,}}>Google Analytics</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <div>
                            <TextField helperText="Usually starts with 'UA-'" label="GA code" style={{ margin: 12}} onChange={(event)=> this.setState({ gaCode: this.extractGACode(event.target.value) })} defaultValue={this.state.gaCode} value={this.state.gaCode} />
                            <RoundedButton onClick={() => this.save('gaCode', this.state.gaCode)} variant="contained" color="primary" style={{margin: 12}}>Save</RoundedButton>
                            <br />
                            <RoundedButton variant="outlined" onClick={() => window.open("https://support.google.com/analytics/answer/1008080?hl=en", "_blank")} color="secondary" style={{ margin: 12 }}>How to find your Google Analytics code</RoundedButton>
                        </div>
                    </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel>
                    <ExpansionPanelSummary
                    expandIcon={<Icon>expand_more</Icon>}
                    >
                    <Typography style={{fontSize: this.props.theme.typography.pxToRem(15),fontWeight: this.props.theme.typography.fontWeightRegular,}}>Google Tag Manager</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <div>
                            <TextField helperText="Usually starts with 'GTM-'" label="GTM code" style={{ margin: 12}} onChange={(event)=> this.setState({ gaCode: this.extractGTMCode(event.target.value) })} defaultValue={this.state.gtmCode} value={this.state.gtmCode} />
                            <RoundedButton onClick={() => this.save('gaCode', this.state.gtmCode)} variant="contained" color="primary" style={{margin: 12}}>Save</RoundedButton>
                            <br />
                            <RoundedButton variant="outlined" onClick={() => window.open("https://support.google.com/analytics/answer/1008080?hl=en", "_blank")} color="secondary" style={{ margin: 12 }}>How to find your Google Analytics code</RoundedButton>
                        </div>
                    </ExpansionPanelDetails>
                </ExpansionPanel>

                <Typography style={{ marginTop: 12}} variant="h6">Product Loops</Typography>
                <ExpansionPanel style={{marginTop: 12}}>
                    <ExpansionPanelSummary
                    expandIcon={<Icon>expand_more</Icon>}
                    >
                    <Typography style={{fontSize: this.props.theme.typography.pxToRem(15),fontWeight: this.props.theme.typography.fontWeightRegular,}}>Referral Program</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <div>
                            <Typography style={{ marginBottom: 12 }} variant="body1">If you enable referral program, your paying customers can invite their friends to your app. When they invite, their friends get a 30% off on their purchase and the user who invites gets 30% off on their next purchase.</Typography>
                            {this.state.disableReferral?(
                                <div>
                                    <Typography variant="h6">Referral program is DISABLED </Typography>
                                    <RoundedButton onClick={() => this.save('disableReferral', false)} variant="contained" color="primary">Enable Referral</RoundedButton>
                                </div>):(
                                <div>
                                    <Typography variant="h6">Referral program is ENABLED </Typography>
                                    <RoundedButton onClick={() => this.save('disableReferral', true)} variant="outlined" color="secondary" >Disable Referral</RoundedButton>
                                </div>)}
                        </div>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel disabled>
                    <ExpansionPanelSummary
                    expandIcon={<Icon>expand_more</Icon>}
                    >
                    <Typography style={{fontSize: this.props.theme.typography.pxToRem(15),fontWeight: this.props.theme.typography.fontWeightRegular,}}>Coupon Codes (Coming soon)</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <div>
                        </div>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel disabled>
                    <ExpansionPanelSummary
                    expandIcon={<Icon>expand_more</Icon>}
                    >
                    <Typography style={{fontSize: this.props.theme.typography.pxToRem(15),fontWeight: this.props.theme.typography.fontWeightRegular,}}>Affiliate Marketing (Coming soon)</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <div>
                        </div>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel disabled>
                    <ExpansionPanelSummary
                    expandIcon={<Icon>expand_more</Icon>}
                    >
                    <Typography style={{fontSize: this.props.theme.typography.pxToRem(15),fontWeight: this.props.theme.typography.fontWeightRegular,}}>Funnel Optimization (Coming soon)</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <div>
                        </div>
                    </ExpansionPanelDetails>
                </ExpansionPanel>



                <Typography style={{ marginTop: 12}} variant="h6">Free Marketing</Typography>
                <ExpansionPanel style={{ marginTop: 12}} disabled>
                    <ExpansionPanelSummary
                    expandIcon={<Icon>expand_more</Icon>}
                    >
                    <Typography style={{fontSize: this.props.theme.typography.pxToRem(15),fontWeight: this.props.theme.typography.fontWeightRegular,}}>Search Engine Optimization (Coming soon)</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <div>
                        </div>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel disabled>
                    <ExpansionPanelSummary
                    expandIcon={<Icon>expand_more</Icon>}
                    >
                    <Typography style={{fontSize: this.props.theme.typography.pxToRem(15),fontWeight: this.props.theme.typography.fontWeightRegular,}}>Content Marketing (Coming soon)</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <div>
                        </div>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel disabled>
                    <ExpansionPanelSummary
                    expandIcon={<Icon>expand_more</Icon>}
                    >
                    <Typography style={{fontSize: this.props.theme.typography.pxToRem(15),fontWeight: this.props.theme.typography.fontWeightRegular,}}>Email Marketing (Coming soon)</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <div>
                        </div>
                    </ExpansionPanelDetails>
                </ExpansionPanel>



                <Typography style={{ marginTop: 12}} variant="h6">Paid Marketing</Typography>
                <ExpansionPanel>
                    <ExpansionPanelSummary
                    expandIcon={<Icon>expand_more</Icon>}
                    >
                    <Typography style={{fontSize: this.props.theme.typography.pxToRem(15),fontWeight: this.props.theme.typography.fontWeightRegular,}}>Basics</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <div>
                            <List>
                                <ListItem button onClick={() => window.open("https://www.notion.so/How-to-run-ads-on-Instagram-and-FB-4fd6bdf8df1f4c77a48ccf528e739db7","_blank")}>
                                    <ListItemIcon>
                                        <Icon>search</Icon>
                                    </ListItemIcon>
                                    <ListItemText primary="How to run Facebook and Instagram ads" secondary="You will see tools to run ads on this page very soon" />
                                    <ListItemSecondaryAction>
                                        <Icon>exit_to_app</Icon> 
                                    </ListItemSecondaryAction>
                                </ListItem>
                            </List>

                        </div>
                    </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel disabled>
                    <ExpansionPanelSummary
                    expandIcon={<Icon>expand_more</Icon>}
                    >
                    <Typography style={{fontSize: this.props.theme.typography.pxToRem(15),fontWeight: this.props.theme.typography.fontWeightRegular,}}>Facebook Ads (Coming soon)</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <div>
                        </div>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel disabled>
                    <ExpansionPanelSummary
                    expandIcon={<Icon>expand_more</Icon>}
                    >
                    <Typography style={{fontSize: this.props.theme.typography.pxToRem(15),fontWeight: this.props.theme.typography.fontWeightRegular,}}>Google Search Ads (Coming soon)</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <div>
                        </div>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel disabled>
                    <ExpansionPanelSummary
                    expandIcon={<Icon>expand_more</Icon>}
                    >
                    <Typography style={{fontSize: this.props.theme.typography.pxToRem(15),fontWeight: this.props.theme.typography.fontWeightRegular,}}>Instagram Ads (Coming soon)</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <div>
                        </div>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel disabled>
                    <ExpansionPanelSummary
                    expandIcon={<Icon>expand_more</Icon>}
                    >
                    <Typography style={{fontSize: this.props.theme.typography.pxToRem(15),fontWeight: this.props.theme.typography.fontWeightRegular,}}>Twitter Ads (Coming soon)</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <div>
                        </div>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel disabled>
                    <ExpansionPanelSummary
                    expandIcon={<Icon>expand_more</Icon>}
                    >
                    <Typography style={{fontSize: this.props.theme.typography.pxToRem(15),fontWeight: this.props.theme.typography.fontWeightRegular,}}>Media Coverage and PR (Coming soon)</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <div>
                        </div>
                    </ExpansionPanelDetails>
                </ExpansionPanel>






            <Card style={{ marginTop: 12 }}>
                <CardContent>
                    <Typography variant="h6">More tools coming soon... </Typography>
                    <Illustration image="underconstruction" style={{ width: '100%'}} />
                </CardContent>
            </Card>
            </div>
        )
    }
}

export default withTheme(AppMarketing);