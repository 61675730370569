import React from 'react';
import { withTheme } from '@material-ui/styles';
import RoundedButton from '../Components/roundedbutton';
import { Icon, MenuItem, Menu, Dialog, DialogTitle, DialogActions, DialogContent, Typography } from '@material-ui/core';
import StyledListItems from '../Components/StyledListItems';

const Servercalls = require('../Utils/Servercalls');
class TabSettingsButton extends React.Component {
    constructor(props){
        super(props);
        this.state = {

        }
    }
    
    moveToTop() {
        const { tab } = this.props;
        const self = this;
        self.setState({ moving: true });
        Servercalls.movePluginToTop(tab.pluginId).then(() => {  self.props.reloadTabs(); self.setState({moving: false, settingsEl: null}); self.props.setCurrentTab(0);});
    }


    render() {
        const { tab } = this.props;   
        if(!tab) return null;     
        return(
            <div {...this.props} onContextMenu={(event) => {
                event.preventDefault();
                if (this.props.disableContextMenu) {
                    return;
                } 
                this.setState({ settingsEl: event.currentTarget});
            }} style={{position: 'absolute', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%'}}>
                {/* <RoundedButton  variant="outlined" color="secondary"><Icon>settings</Icon>&nbsp;Tab Settings</RoundedButton> */}
                <Icon style={this.props.iconStyle} >{this.props.icon}</Icon>
                {/* <Icon onClick={(event) => this.setState({ settingsEl: event.currentTarget})} >edit</Icon> */}
                <Menu
                    open={this.state.settingsEl}
                    anchorEl={this.state.settingsEl}
                    onClose={() => this.setState({ settingsEl: null })}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    <StyledListItems listItems={[{
                        onClick: ()=>this.props.changeWorkspace('tabchangename','Change Tab name & icon', tab),
                        icon: 'title',
                        text: 'Change tab name & icon',
                    }, {
                        onClick: () => this.props.changeWorkspace('tabchangebanner','Change Tab Banner', tab),
                        icon: 'add_photo',
                        text: 'Change tab cover-image',
                    }, {
                        onClick: () => this.moveToTop(),
                        icon: 'chevron_left',
                        text: 'Move tab to first position',
                    }, {
                        onClick: () => this.setState({ showDeleteConfirmation: true }),
                        icon: 'delete',
                        text: 'Delete tab'
                    }]} />
                </Menu>
                <Dialog
                open={this.state.showDeleteConfirmation}
                >
                <DialogTitle>
                    Delete '{tab.name}'?
                </DialogTitle>
                <DialogContent>
                    <Typography variant="body1">Are you absolutely sure? You cannot recover the contents of a deleted tab</Typography>
                </DialogContent>
                <DialogActions>
                    <RoundedButton vartiant="outlined" onClick={() => this.setState({ showDeleteConfirmation: null })}> Don't delete </RoundedButton>
                    <RoundedButton variant="contained" color="secondary" style={{ marginLeft: 12 }} disabled={this.state.deleting} onClick={() => {
                        this.setState({ deleting : true})
                        const self = this;
                        Servercalls.deletePlugin(tab.pluginId).then(response => {
                            self.setState({ deleting: false, showDeleteConfirmation: false, settingsEl: null}, () => {
                                self.props.reloadTabs();
                                self.props.setCurrentTab(0);
                            })
                        })
                    }}> Delete </RoundedButton>
                </DialogActions>
            </Dialog>
            </div>
        )
    }
}

export default withTheme(TabSettingsButton);