import React from 'react';
import Theme from '../../Components/theme';
import WsHeader from '../../Components/wsheader';
import WsFooter from '../../Components/wsfooter';
import { Typography, TextField, Checkbox, FormControlLabel, Button, Icon, RadioGroup, Radio, Card, CardContent, Dialog } from '@material-ui/core';
import MainCTA from '../../Components/MainCTA';
import { currencies } from '../../Utils/Constants';

const Servercalls = require('../../Utils/Servercalls');

export default class WsBundleAudience extends React.Component {

    constructor(props){
        super(props);
        const bundle = this.props.context;
        this.state = {
            audience: bundle.audience || '',
            visibility: !bundle.published? "unpublished" : (bundle.audience && bundle.audience.trim().length > 0) ? "specific" : "all",
        }
    }

    async save() {
        const self = this;
        const bundle = this.props.context;
        self.setState({ saving: true });

        let published = this.state.visibility !== 'unpublished';
        let audience = this.state.visibility === 'all'? false: this.state.audience;
        if(audience==="")
            audience = false;
        try{
            await Servercalls.pluginpremier_updateBundle(bundle.pluginId, bundle.itemId, 'audience', audience);
            await Servercalls.pluginpremier_updateBundle(bundle.pluginId, bundle.itemId, 'published', published);

            if(this.props.renderAsDialog){
                this.props.onClose();
                return;
            }
            
            self.props.changeParentContext('audience', audience);
            self.props.changeParentContext('published', published);
            self.setState({ saving: false, saved: true });
            setTimeout(() => {
                self.props.back();
                self.setState({ saved: false });
            }, 3000);
        }
        catch(e){
            alert(e);
            self.setState({ saving: false });
        }
    }

    render() {
        if(this.state.saved) return (
            <center style={{ margin: 64}}>
                <Icon style={{ fontSize: 120}} color="secondary">check</Icon>
                <br /><br />
                <Typography variant="body2">Audience for this collection has been updated. You'll be redirected shortly</Typography>
            </center>
        )

        // keeping original design also so that other screens donot break
        if(this.props.renderAsDialog) {
            return (
                <Dialog
                    open={this.props.open}
                    onClose={this.props.onClose}
                    PaperProps={{
                        style: {
                            borderRadius: '16px',
                            padding: '0 24px',
                        }
                    }}
                    style={{borderRadius: '16px'}}
                >
                    
                        <CardContent>
                        <RadioGroup aria-label="visibility" name="visibility" value={this.state.visibility} onChange={e => this.setState({ visibility: e.target.value })}>
                            <FormControlLabel value="all" control={<Radio />} label="Everyone" />
                            <FormControlLabel value="specific" control={<Radio />} label="Specific People Only" />
                            <FormControlLabel value="unpublished" control={<Radio />} label="No one (Unpublish)" />
                        </RadioGroup>

                        {this.state.visibility === 'specific'? (
                            <div style={{margin: 20}}>
                                <Typography variant="body2">Only these users will be able to see this collection : </Typography>
                                <TextField fullWidth label="Email addresses of users " value={this.state.audience} onChange={e => this.setState({ audience: e.target.value})} helperText="Seperate email addresses with a comma (,)" />
                            </div>
                        ) : null}
                        </CardContent>  

                        <MainCTA 
                            disabled={this.state.saving} 
                            onCTAClick={() => this.save()} 
                            icon="save" 
                            text="Save visibility" 
                            settings={[]}
                            help={[]}
                            style={{position: 'relative', width: 'calc(100% + 48px)', marginTop: '24px', marginLeft: '-24px'}}
                            square
                        />          
                </Dialog>
            )
        }
        return (
            <Theme>
                <WsHeader back={this.props.back} title="Visibility" description='Provide email addresses of people who can view this collection. Only these people will be able to see this collection.' helpText="How does this work?" helpVideo="a" 
                    
                />
                <Card style={{ margin: 12 }}>
                    <CardContent>
                    <RadioGroup aria-label="visibility" name="visibility" value={this.state.visibility} onChange={e => this.setState({ visibility: e.target.value })}>
                        <FormControlLabel value="all" control={<Radio />} label="Everyone" />
                        <FormControlLabel value="specific" control={<Radio />} label="Specific People Only" />
                        <FormControlLabel value="unpublished" control={<Radio />} label="No one (Unpublish)" />
                    </RadioGroup>

                    {this.state.visibility === 'specific'? (
                        <div style={{margin: 20}}>
                            <Typography variant="body2">Only these users will be able to see this collection : </Typography>
                            <TextField fullWidth label="Email addresses of users " value={this.state.audience} onChange={e => this.setState({ audience: e.target.value})} helperText="Seperate email addresses with a comma (,)" />
                        </div>
                    ) : null}    
                    </CardContent>                                    
                </Card>
                <WsFooter text="Choose 'Everyone' if you want anybody to be able to buy" />
                <MainCTA 
                    disabled={this.state.saving} 
                    onCTAClick={() => this.save()} 
                    icon="save" 
                    text="Save visibility" 
                    settings={[]}
                    help={[]}
                />
            </Theme>
        )
    }
}