import { ButtonBase, Icon, Typography } from '@material-ui/core';
import { makeStyles, withTheme } from '@material-ui/styles';
import React from 'react';
import StyledButton from './StyledButton';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(1.5, 2.5),
        // height: theme.spacing(7),
        textAlign: 'left',
        width: 'calc(100% - 40px)',
        display: 'flex',
        alignItems: 'center',
    },
    title: {
        marginLeft: theme.spacing(1.5),
       whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
    subtitle: {
        marginLeft: theme.spacing(1.5),
        color: '#999990',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
    icon: {
        height: theme.spacing(6),
        width: theme.spacing(6),
        borderRadius: '50%',
        lineHeight: 0,
        '& > *': {
            width: '100%',
            objectFit: 'cover',
        }
    },
    divider: {
        height: '1px',
        backgroundColor: '#eeeeee',
        margin: theme.spacing(0, 2.5),
    }
}))

const MembersList = (props) => {
    const classes = useStyles();

    return(
        <>
            {props.users.map(user => (
                <>
                    <div className={classes.divider} />
                    <div onClick={() => user.onClick()} className={classes.root}>
                        <Icon className={classes.icon}>{user.icon}</Icon>
                        <div style={{flex: '1', overflow: 'hidden', textOverflow: 'hidden'}}>
                            <Typography className={classes.title} variant='body1'>{user.name}</Typography>
                            <Typography className={classes.subtitle} variant='caption'>{user.email}</Typography>
                        </div>
                        <StyledButton style={{marginLeft: props.theme.spacing(2)}} text={user.action} onClick={user.onClick} color='secondary' variant={user.action === 'Remove' ? 'outlined' : 'contained'}/>
                    </div>
                </>
            ))}
            <div className={classes.divider} />
        </>
    )
}

export default withTheme(MembersList);