import React from 'react';
import Theme from '../Components/theme';
import WsHeader from '../Components/wsheader';
import WsFooter from '../Components/wsfooter';
import RoundedButton from '../Components/roundedbutton';
import MainCTA from '../Components/MainCTA';
import { TextField, Button, Icon, Typography, IconButton, Menu, MenuItem, CardContent, Card } from '@material-ui/core';


const Servercalls = require('../Utils/Servercalls');
const {allowedIcons} = require('../Utils/Constants');
export default class WsTabChangeName extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            saving: false,
            name: this.props.context.name,
            title: this.props.context.title,
            icon: this.props.context.icon
        }
    }

    save() {
        const self = this;
        const plugin = this.props.context;
        self.setState({ saving: true });
        Servercalls.updatePlugin(plugin.pluginId, this.state.name, this.state.icon, plugin.images || [], this.state.title, plugin.weight, plugin.longDescription, plugin.tags).then(response => {
            self.setState({ saving: false, saved: true });
            self.props.changeParentContext({ ...self.props.context, name: this.state.name });
            setTimeout(() => self.props.back(), 3000);
        }).catch(e => {alert(e); self.setState({ saving: false})});
    }

    render() {
        if(this.state.saved) return (
            <center style={{ margin: 64}}>
                <Icon color="secondary" style={{fontSize: 120}}>check</Icon>
                <br /><br />
                <Typography variant="body1">Tab name and icon has been changed </Typography>
            </center>
        )

        return(
            <Theme>
                <WsHeader back={this.props.back} title="Change Tab Name" description="The tab name helps your users understand where they are in the app. Have a tab name that denote what users can find on this tab" helpText="What is a tab name?" helpVideo="a" />
                <Card style={{ margin: 12 }}>
                    <CardContent>
                    <TextField fullWidth helperText="Keep it less than 14 characters" label="Tab name" value={this.state.name} onChange={(e) => this.setState({ name: e.target.value })} />
                    <br /><br />
                    <Typography variant="body2">Tab Icon : </Typography>
                    <IconButton
                        style={{ marginBottom: 24 }}
                        aria-owns={this.state.anchorEl ? 'simple-menu' : undefined}
                        aria-haspopup="true"
                        onClick={(e) => this.setState({ anchorEl: e.currentTarget})}
                    >

                        <Icon fontSize="large">{this.state.icon}</Icon>
                    </IconButton>
                    <Menu
                        id="simple-menu"
                        anchorEl={this.state.anchorEl}
                        open={Boolean(this.state.anchorEl)}
                        onClose={() => this.setState({ anchorEl: null })}
                        >
                        {allowedIcons.map(icon => (
                            <MenuItem key={icon} selected={this.state.selectedIcon === icon} onClick={() => this.setState({ icon: icon, anchorEl: null})}><Icon>{icon}</Icon></MenuItem>
                        ))}
                    </Menu>
                    </CardContent>
                </Card>
                <WsFooter text="" />
                <MainCTA 
                    icon="save"
                    text="save"
                    onCTAClick={() => this.save()}
                    disabled={this.state.saving}
                    help={[]}
                    settings={[]}
                />
            </Theme>
        )
    }
}