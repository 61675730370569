import React from 'react';
import { Icon, IconButton, LinearProgress, Typography, Card, CardContent, List, ListItem, ListItemText, ListItemSecondaryAction } from '@material-ui/core';
import moment from 'moment';
import Illustration from '../../Components/illustration';
import WsHeader from '../../Components/wsheader';
import WsFooter from '../../Components/wsfooter';
import MainCTA from '../../Components/MainCTA';

const Servercalls = require('../../Utils/Servercalls');

export default class WsAppUsersNotificationsList extends React.Component {
    constructor(props){
        super(props);
        this.state = { 
            scheduledNotifications: [],
            loading: true
        }
    }
    componentDidMount() {
        this.reload();
    }
    async deleteNotification(notification){
        this.setState({ loading: true });
        await Servercalls.deleteScheduledMessage(notification.utchhmm, notification.notificationId);
        this.reload();
    }
    async reload(){
        this.setState({ loading: true });
        const response = await Servercalls.getScheduledMessages();
        this.setState({ scheduledNotifications: response.data, loading: false });
    }
    render () {
        if(this.state.loading)
            return <LinearProgress />
        return (
            <div>
                <WsHeader back={this.props.back} title="Scheduled Notifications"/>

                <Typography variant="h6" style={{ margin: 12 }}></Typography>
                <Card square style={{ margin: 12 }}>
                    <CardContent>
                        <Typography variant="h6">Upcoming Notifications</Typography>
                        {this.state.scheduledNotifications.length === 0? <>
                            <Typography variant="h6">No Upcoming notifications in the next 24 hours</Typography>
                            <Illustration image="push_notifications" style={{ width: '100%' }} />
                        </>: null}
                        <List>
                            {this.state.scheduledNotifications.map(n => (
                                <ListItem>
                                    <ListItemText primary={n.title} secondary={`${moment(n.sendTime).format("Do MMM hh a")} (${moment(n.sendTime).fromNow()})`} />
                                    <ListItemSecondaryAction>
                                        <IconButton onClick={() => this.deleteNotification(n)}><Icon>delete</Icon></IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            ))}
                        </List>
                    </CardContent>
                </Card>
                <WsFooter />
                <MainCTA 
                    settings={[]}
                    help={[]}
                    text="Send a notification"
                    icon="queue"
                    onCTAClick={() => { this.props.changeWorkspace('appusersschedulenotifications', "New Notification", {})}}
                />
            </div>
        )
    }
}