import React from 'react';
import { Button, Card, CardContent, LinearProgress, List, ListItem, ListItemAvatar, Avatar, ListItemText, ListItemSecondaryAction, IconButton, Icon, TextField, Typography, CircularProgress } from '@material-ui/core';

import WsHeader from '../../Components/wsheader';
import RoundedButton from '../../Components/roundedbutton';
import StyledInput from '../../Components/StyledInput';
import MainCTA from '../../Components/MainCTA';
import WsFooter from '../../Components/wsfooter';
import SuccessDialog from '../../Components/SuccessDialog';
import FileUploadButton from '../../Components/fileuploadbutton';
import md5 from 'md5';
import ConfirmUnsavedDialog from '../../Components/ConfirmUnsavedDialog';

const Servercalls = require('../../Utils/Servercalls');
const Constants = require('../../Utils/Constants');

export default class WsAddVimeo extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            isVimeoLoggedIn: false,
            loading: true,
            videos: [],
            uri: this.props.context.fileUrl,
            name: this.props.context.name || "Add Title",
            description: this.props.context.description || "Add Description",
            searchTerm: null,
            thumbnail: this.props.context.thumbnail || `https://www.gravatar.com/avatar/${md5((Date.now() + Math.random()) + " ")}?d=identicon`,

            itemId: this.props.context.itemId,

            contentId: this.props.context.contentId || null,
            published: this.props.context.published || true,
            free: this.props.context.free || false,
            type: this.props.context.type || 'vimeo',
            longDescription: this.props.context.longDescription || "",
            tags: this.props.context.tags || [],
            list: this.props.context.list || 'normal',

        }
    }

    componentDidMount(){
        this.loadVimeo();
    }

    loadVimeo() {
        const self = this;
        Servercalls.vimeoVerifyLogin().then(() => {
            self.setState({ isVimeoLoggedIn: true});
            self.loadVideos();
        }).catch(e =>  {
            self.setState({ isVimeoLoggedIn: false, loading: false });
        })
        return false;
    }

    login() {
        this.setState({ waitingForLogin: true });
        window.open(`https://api.vimeo.com/oauth/authorize?response_type=token&client_id=12e07aab8a335359fb710bb7d267b7f1fbeb35cc&redirect_uri=${Constants.AppUrl}&state=vimeo&scope=public,private`,"_blank")
        this.loginInterval = setInterval(async () => {
            try {
                await Servercalls.vimeoVerifyLogin();
                this.setState({ waitingForLogin: false });
                clearInterval(this.loginInterval);
                this.loadVimeo();
            }
            catch(e){

            }
        }, 5000);
    }



    loadVideos() {
        const self = this;
        self.setState({ loading: true })
        Servercalls.vimeoGetVideos(this.state.searchTerm).then(response => {
            
            self.setState({ loading: false, videos: response.data.data });
        });
    }


    async save(){
        const self = this;
        self.setState({ saving : true });

        let itemId = this.state.itemId;
        if(!itemId) {
            itemId = await this.props.context.getItemId();
        }

        const {contentId, name, description, published, free, type, thumbnail, longDescription, tags, list, uri} = this.state;
        Servercalls.pluginpremier_addContentToBundle(this.props.context.pluginId, itemId, contentId, name, description, uri, published, free, type, thumbnail, longDescription, tags, list).then(response => {
            
            window.analytics.track('Content Confirm Clicked', {'content_type': type});
            self.setState({ saving: false, saved: true, itemId});
            //  self.props.back()
            setTimeout(() => {
                this.setContextAndBack();
            }, 3000);
        }).catch(e => { alert(e); self.setState({ saving: false, itemId})});
    }

    setContextAndBack() {
        this.props.changeParentContext('pluginId', this.props.context.pluginId);
        this.props.changeParentContext('itemId', this.state.itemId);
        
        if(this.props.context.bundle_name) {
            this.props.changeParentContext('name', this.props.context.bundle_name);
        }
        if(this.props.context.bundle_description) {
            this.props.changeParentContext('description', this.props.context.bundle_description);
        }
        
        if(this.props.context.bundle_priceList) {
            this.props.changeParentContext('priceList', this.props.context.bundle_priceList);
        }
        if(this.props.context.bundle_imageUrl) {
            this.props.changeParentContext('imageUrl', this.props.context.bundle_imageUrl);
        }
        if(this.props.context.bundle_availableForPurchase) {
            this.props.changeParentContext('availableForPurchase', this.props.context.bundle_availableForPurchase);
        }
        if(this.props.context.bundle_published) {
            this.props.changeParentContext('published', this.props.context.bundle_published);
        }
        this.props.changeParentContext('newCollection', false);
        this.props.back();
    }


    renderContentNameInput() {
        return (
            <StyledInput
                alwaysOpen={this.props.context.alwaysOpen}
                placeholder="Enter title"
                fullWidth
                value={this.state.name}
                onChange={e => this.setState({ name: e.target.value })}
            />
        )
    }

    renderContentDescriptionInput() {
        if(this.props.context.alwaysOpen) {
            return (
                <StyledInput
                    alwaysOpen
                    variant='outlined'
                    style={{marginTop: '24px', flex: '0'}}
                    inputStyle={{height: '2em', overflow: 'scroll'}}
                    multiline
                    placeholder="Enter description"
                    value={this.state.description}
                    onChange={e => this.setState({ description: e.target.value})} 
                />
            )
        }
        return (
            <StyledInput
                placeholder="Enter description"
                fullWidth
                style={{marginTop: '16px'}}
                value={this.state.description}
                onChange={e => this.setState({ description: e.target.value })}
            />
        ) 
    }

    
    renderThumbnailInput() {
        return (
            <div style={this.props.context.alwaysOpen ? {position: 'relative'} : {marginLeft: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <img style={{
                    borderRadius: '12px',
                    zIndex: '10',
                    boxShadow: '0 0 16px 0 rgba(0, 0, 0, 0.16)',
                    backgroundColor: 'rgba(0,0,0,0.15)',
                    height: this.props.context.alwaysOpen ? '23.611vw' : '23.611vw',
                    width: this.props.context.alwaysOpen ? '41.975vw' : '23.611vw',
                    maxHeight: this.props.context.alwaysOpen ? '188.88px' : '188.88px',
                    maxWidth: this.props.context.alwaysOpen ? '335.785px' : '188.88px',
                    }} src={this.state.thumbnail}/>
                <FileUploadButton
                    style={{ 
                        position: 'relative',
                        height: '23.611vw',
                        width: this.props.context.alwaysOpen ? '41.975vw' : '23.611vw',
                        maxHeight: '188.88px',
                        maxWidth: this.props.context.alwaysOpen ? '335.785px' : '188.88px',
                        backgroundColor: 'transparent',
                        borderRadius: '12px',
                        boxShadow: 'none',
                    }}
                    extensions={['png','jpg','jpeg']}
                    maxSizeMBs={2}
                    onFail={e => alert(e)}
                    onUpload={(url) => this.setState({ thumbnail: url })}
                    icon="edit"
                    color="primary"
                    variant="contained"
                    containerStyles={this.props.context.alwaysOpen ? {top: '0'} : {}}
                />
            </div>
        )
    }

    getLoginAction() {
        if(this.state.waitingForLogin) {
    
            return (
                <div style={{width: this.props.context.alwaysOpen ? '' : '100%', zIndex: '3500', display: 'flex', flexWrap: 'wrap', flexDirection: this.props.context.alwaysOpen ? 'row' : 'column', alignItems: this.props.context.alwaysOpen ? 'center' : 'flex-start', flex: '1'}}>
                    <div style={{display: 'flex', alignItems: 'center', width: '100%'}}>
                        <Typography variant="body1" style={{margin: this.props.context.alwaysOpen ? '16px 0' : '0 0 12px', fontWeight: 'bold'}}>
                            Waiting for Login
                        </Typography>
                        <CircularProgress style={{margin: this.props.context.alwaysOpen ? '0 0 0 24px' : '0 0 12px 12px', height: this.props.context.alwaysOpen ? '40px' : '20px', width: this.props.context.alwaysOpen ? '40px' : '20px', display: 'inline-block' }} />
                    </div>
                    <RoundedButton style={{margin: this.props.context.alwaysOpen ? '16px 24px' : '0 auto 12px'}} onClick={() => this.login()} variant="outlined" color="secondary">Retry</RoundedButton>
                </div>
            )
        }
        return (
            <div style={{width: this.props.context.alwaysOpen ? '' : '100%', zIndex: '3500', display: 'flex', flexWrap: 'wrap', flexDirection: this.props.context.alwaysOpen ? 'row' : 'column', alignItems: this.props.context.alwaysOpen ? 'center' : 'flex-start'}}>
                <Typography variant="body1" style={{margin: this.props.context.alwaysOpen ? '16px 0' : '0 0 12px', fontWeight: 'bold'}}>
                    Login with Vimeo to continue
                </Typography>
                <RoundedButton style={{margin: this.props.context.alwaysOpen ? '8px 24px' : '0 auto 12px'}} onClick={() => this.login()} variant="contained" color="secondary">Login with Vimeo</RoundedButton>
            </div>
        )
    }

    getStartAction() {
        return (
            <div style={{display: 'flex', flexDirection: 'column', width: this.props.context.alwaysOpen ? '' : 'calc(100%)'}}>
                <Typography variant="body1" style={{margin: this.props.context.alwaysOpen ? '16px 0' : '0 0 12px', fontWeight: 'bold'}}>
                    Select a Video
                </Typography>
                <div style={{ margin: '-12px 0 24px', display: 'flex', flexWrap: 'wrap', marginBottom: '24px' }}>
                    
                    <StyledInput
                        fullWidth
                        alwaysOpen={this.props.context.alwaysOpen}
                        variant='outlined'
                        style={{marginTop: '12px', flex: '1'}}
                        inputStyle={{minWidth: '20ch'}}
                        placeholder="Search Videos"
                        value={this.state.searchTerm}
                        onChange={event => this.setState({ searchTerm: event.target.value }, () => this.loadVideos())} 
                    />
                    <RoundedButton style={{ margin: '12px 12px 0', whiteSpace: 'nowrap' }} onClick={() => window.open("https://vimeo.com/upload", "_blank")} variant="contained" color="primary"><Icon>add</Icon>&nbsp;Upload new video</RoundedButton>
                </div>
                {/* <List>
                {
                    this.state.videos.map(v => (
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar src={v.pictures.sizes[0].link} />
                            </ListItemAvatar>
                            <ListItemText primary={v.name} secondary={v.description} />
                            <ListItemSecondaryAction>
                                <IconButton onClick={() => this.setState({ name: v.name, uri: v.uri, description: v.description, thumbnail: v.pictures.sizes[0].link})} color="primary"><Icon>add</Icon></IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                    ))
                }

                </List> */}
                <div style={{display: 'flex', flexWrap: 'wrap', flexDirection: this.props.context.alwaysOpen ? 'row' : 'column'}}>
                    {this.state.videos.map(v => this.renderContentItem(v.name, v.description ,v.pictures.sizes[0].link, () => this.setState({ name: v.name, uri: v.uri, description: v.description}), v.uri == this.state.uri))}
                </div>
                
            </div>
        )    
    }

    renderContentItem(title, desc, image, onClick, selected) {
        if(this.props.context.alwaysOpen) {
            return (
                <Card onClick={() => onClick()} style={{
                    display: 'flex',
                    padding: '12px',
                    borderRadius: '12px',
                    boxShadow: '0 0 8px 0 rgba(0, 0, 0, 0.08)',
                    border: selected ? 'solid 1px #562eff' : 'solid 1px #dbdbdb',
                    marginRight: '12px',
                    width: 'calc(min(21.56vw, 276px))',
                    minWidth: '276px',
                    marginBottom: '12px',
                    overflow: 'hidden',
                    cursor: 'pointer',
                    }}
                >
                    <img style={{
                        borderRadius: '6px',
                        zIndex: '10',
                        height: '6vw',
                        width: '6vw',
                        maxHeight: '72px',
                        maxWidth: '72px',
                    }} src={image} />
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flex: '1', marginLeft: '12px', overflow: 'hidden'}}>
                        <Typography variant='body1' style={{fontWeight: 'bold', maxHeight: '17.2px', overflow: 'hidden', textOverflow: 'ellipsis'}}>{title}</Typography>
                        <Typography variant='body2' style={{marginTop: '6px', maxHeight: '34.4px', overflow: 'hidden'}}>{desc}</Typography>
                    </div>                    
                </Card>
            )
        }

        return (
            <Card onClick={() => onClick()} style={{cursor: 'pointer', display: 'flex', padding: '12px', borderRadius: '12px', boxShadow: '0 0 8px 0 rgba(0, 0, 0, 0.08)', border: selected ? 'solid 1px #562eff' : 'solid 1px #dbdbdb', maxWidth: 'calc(100% - 26px)'}}>
                <img style={{
                    borderRadius: '6px',
                    zIndex: '10',
                    height: '22.78vw',
                    width: '22.78vw',
                    maxHeight: '182.24px',
                    maxWidth: '182.24px',
                }} src={image} />
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flex: '1', marginLeft: '12px', overflow: 'hidden'}}>
                    <Typography variant='body1' style={{fontWeight: 'bold', maxHeight: '38.4px', overflow: 'hidden'}}>{title}</Typography>
                    <Typography variant='body2' style={{marginTop: '6px', maxHeight: '34.4px', overflow: 'hidden'}}>{desc}</Typography>
                </div>
            </Card>
        )
    }

    render () {
        
        return (

            <>
                <div style={{display: 'flex', flex: '1', marginBottom: '42px', flexDirection: 'column'}}>
                    <WsHeader title={'Vimeo Video'} back={async() => {
                        const self = this;
                        let confirmUnsaved = () => new Promise(resolve => self.setState({confirmUnsavedDialogOpen: true, confirmUnsavedResolver: resolve}))
                        const confirmation = await confirmUnsaved();
                        self.setState({confirmUnsavedDialogOpen: false, confirmUnsavedResolver: null});

                        if(!confirmation) {
                            // cannot save here as it will break design incase of incomplete input
                            // this.save()
                            return;
                        } else {
                            this.setContextAndBack()
                        }
                    }} />
                    {this.props.context.alwaysOpen ? (
                        <Card style={{margin: '68px 40px 20px', borderBottom: 'solid 1px #dbdbdb', borderRadius: '6px'}}>
                            <CardContent style={{padding: '0'}}>
                                <div style={{display: 'flex', marginBottom: '24px', padding: '40px 40px 0'}}>
        
                                    <div style={{marginRight: 'auto', flex: '1', flexDirection: 'column', display: 'flex'}}>
                                        
                                        {this.renderContentNameInput()}
                                        {this.renderContentDescriptionInput()}
                                        

                                        {!this.state.isVimeoLoggedIn? this.getLoginAction() : this.getStartAction()}
        
                                        {/* <div style={{position: 'relative'}}> */}
                                            {/* {this.renderContentCard()} */}
                                            {/* {!this.state.existingMeeting? this.getLoginAction() : this.getStartAction()} */}
                                        {/* </div> */}
                                    </div>
                                    <div style={{marginLeft: '60px', display: 'flex', justifyContent: 'center'}}>
                                        {this.renderThumbnailInput()}
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                    ) : (
                        <Card style={{marginTop: '52px', boxShadow: 'none'}}>
                            <CardContent style={{padding: '0'}}>
                                <div style={{padding: '20px'}}>
                                    <div style={{display: 'flex'}}>

                                        <div style={{marginRight: 'auto', flex: '1', flexDirection: 'column', justifyContent: 'space-evenly', display: 'flex'}}>
                                            
                                            {this.renderContentNameInput()}
                                            {this.renderContentDescriptionInput()}
                                            
                                        </div>
                                        {this.renderThumbnailInput()}
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                    )}

                    {this.props.context.alwaysOpen ? null : (
                        <Card style={{zIndex: '100', marginTop: '12px', boxShadow: 'none', padding: '12px 12px 0'}}>
                            <CardContent style={{display: 'flex', flexDirection: 'column', padding: '0'}}>
                                <div style={{margin: '8px 8px 20px', display: 'flex', alignItems: 'center'}}>
                                    {!this.state.isVimeoLoggedIn? this.getLoginAction() : this.getStartAction()}
                                </div>
                            </CardContent>
                        </Card>
                    )}

                
                <SuccessDialog open={this.state.saved} text="Vimeo Video updated successfully"/>
                <ConfirmUnsavedDialog open={this.state.confirmUnsavedDialogOpen} resolve={this.state.confirmUnsavedResolver} />

                <WsFooter text="" />
                <MainCTA
                    toast='Please add the title, description and vimeo video to proceed'
                    topRight
                    alwaysOpen={this.props.context.alwaysOpen}
                    settings={[]}
                    help={[]}
                    text="Confirm"
                    onCTAClick={() => this.save()}
                    square={!this.props.context.alwaysOpen}
                    disabled={!this.state.name || !this.state.description || !this.state.uri}
                />
            
            </div>
            </>
        )
    }
}