import { withStyles } from '@material-ui/styles';
import { Dialog, Slide } from '@material-ui/core';

import React, {Component} from 'react';
import ConfirmationDialog from '../Components/ConfirmationDialog';
import ConfirmUnsavedDialog from '../Components/ConfirmUnsavedDialog';
import WsHeader from '../Components/wsheader';
import AddPriceCard from './UI/AddPriceCard';
import BannerUploadCard from './UI/BannerUploadCard';
import CourseDurationCard from './UI/CourseDurationCard';
import DescriptionInputCard from './UI/DescriptionInputCard';
import DiscountToggleCard from './UI/DiscountToggleCard';
import FolderDetails from './UI/FolderDetails';
import LargeCTA from './UI/LargeCTA';
import StartingFromCard from './UI/StartingFromCard';
import StyledStepper from './UI/StyledStepper';
import TextInputCard from './UI/TextInputCard';
import Livestream from '../Components/Livestream'
import RoundedButton from '../Components/roundedbutton';


import moment from 'moment';
import SuccessDialog from '../Components/SuccessDialog';
import LivestreamResource from './LivestreamResource';

const Servercalls = require('../Utils/Servercalls');

const styles = theme => ({
    root: {
        paddingBottom: theme.spacing(9),
    }
})

  
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


class AddLiveCourse extends Component {
  
    
    state = {
        activeIndex: 0,
        banner: [{
            mediaUrl: this.props.context.imageUrl, 
            mediaObject: {}
        }],
        
        title: this.props.context.name,
        titleError: false,

        description: this.props.context.description,
        descriptionError: false,

        startDate: null,
        startTime: "15:00",
        endTime: "16:00",

        startDateError: false,

        repeat: 'Once',
        days: this.props.context.liveSchedule.length,
        daysOfWeek: {
            0: false,
            1: false,
            2: false,
            3: false,
            4: false,
            5: false,
            6: false,
        },
        validFor: this.props.context.validFor || 1,

        price: this.props.context.priceList[0].price,
        priceError: false,
        currency: this.props.context.priceList[0].currency,
        discountCheck: this.props.context.shareDiscount,
        open: false,
        questionnaireLink: this.props.context.questionnaireLink
    };

    bannerInputRef = React.createRef();

    handleClickOpen = () => {
        this.setState({open:true});
    }
    
    handleClose = () => {
        this.setState({open:false});
    }

    componentDidMount() {
        const todayStart = new Date(this.props.context.liveSchedule[0].startTime);
        const todayEnd = new Date(this.props.context.liveSchedule[0].endTime);
        this.setState({startDate: moment(todayStart).format('YYYY-MM-DD'), startTime: moment(todayStart).format('HH:mm'), endTime: moment(todayEnd).format('HH:mm')});

        if (this.props.context.liveSchedule.length === 1) {
            this.setState({repeat: 'Once', days: 1})
        }
        else {
            const {daysOfWeek} = this.state;
            for (let index = 0; index < 7; index++) {
                const d = new Date(parseInt(this.props.context.liveSchedule[index].startTime));
                daysOfWeek[d.getDay()] = true;
            }
            let totalDays = Object.values(daysOfWeek).filter(val => val).length;
            if (totalDays === 7) {
                this.setState({repeat: 'Daily'})
            } else {
                this.setState({repeat: 'Custom', daysOfWeek, days: this.props.context.liveSchedule.length/totalDays * 7});
            }
        }
    }

    async save() {
        const self = this;
        
        // let confirmPublishingFreeFolder = () => new Promise(resolve => self.setState({confirmDialogOpen: true, confirmationResolver: resolve}))
        
        // if(this.state.priceList.length && !this.state.priceList[0].price) {
        //     const confirmation = await confirmPublishingFreeFolder();
        //     self.setState({confirmDialogOpen: false, confirmationResolver: null});
        //     if(!confirmation) {
        //         return;
        //     }
        // }

        self.setState({ saving: true });
        const { pluginId, itemId } = this.props.context;
        console.log(itemId, pluginId);
        
        const imageUrl = this.state.banner[0].mediaUrl;
        let url = null;
        if (imageUrl) {
            url = {url: imageUrl}
        } else {
            url = await Servercalls.uploadToS3(this.state.banner[0].mediaObject)
        }

        if(!itemId) {
            const newBundle = await Servercalls.pluginpremier_addBundle(pluginId, null, this.state.title, this.state.description, url.url, "", "", true, "chronological", "", "", "", "", "" , "");
            const bundleData = newBundle.data;
            await Servercalls.pluginpremier_updateBundle(pluginId, bundleData.itemId, "priceList", [{currency: this.state.currency, price: this.state.price}]);
            await Servercalls.pluginpremier_updateBundle(pluginId, bundleData.itemId, "liveSchedule", this.getLiveSchedule(bundleData.itemId));
            await Servercalls.pluginpremier_updateBundle(pluginId, itemId, "shareDiscount", this.state.discountCheck);
            await Servercalls.pluginpremier_updateBundle(pluginId, bundleData.itemId, "validFor", this.state.validFor);
        } else {

        //\ (pluginId, bundleId, name, description, imageUrl, price, currency, published, ordering, audience, validity, longDescription, tags, salesPage, pixels )
            

            await Servercalls.pluginpremier_updateBundle(pluginId, itemId, "name", this.state.title);
            await Servercalls.pluginpremier_updateBundle(pluginId, itemId, "description", this.state.description);
            await Servercalls.pluginpremier_updateBundle(pluginId, itemId, "imageUrl", url.url);
            await Servercalls.pluginpremier_updateBundle(pluginId, itemId, "priceList", [{currency: this.state.currency, price: this.state.price}]);
            await Servercalls.pluginpremier_updateBundle(pluginId, itemId, "liveSchedule", this.getLiveSchedule(itemId));
            await Servercalls.pluginpremier_updateBundle(pluginId, itemId, "shareDiscount", this.state.discountCheck);
            await Servercalls.pluginpremier_updateBundle(pluginId, itemId, "questionnaireLink", this.state.questionnaireLink);
            await Servercalls.pluginpremier_updateBundle(pluginId, itemId, "validFor", this.state.validFor);
        }

        // window.analytics.track('Folder Confirm Clicked', {'count_content_type' : this.state.contents.length});

        self.setState({ saving: false, saved: true });
        setTimeout(() => {
            self.props.back();
        }, 3000);

    }

    getLiveSchedule(itemId) {
        const date = this.state.startDate.split('T')[0];
        const time = this.state.startTime;

        const firstStart = new Date(date+'T'+time);
        const firstEnd = new Date(date+'T'+this.state.endTime);

        const durationMil = firstEnd - firstStart;
        let liveSchedule = [{
            title: 'Session 1',
            description: 'Today\'s session will be from '+ moment(firstStart).format('hh:mm a') + ' to ' + moment(firstEnd).format('hh:mm a'),
            startTime: firstStart.getTime(),
            endTime: firstStart.getTime() + durationMil,
            sessionId: `${itemId}-${firstStart.getTime()}`,
        }];
        
        if (this.state.repeat === 'Custom') {
            let a = [];
            let d = new Date(this.state.startDate);
            let startingDay = d.getDay();

            for (let index = 0; index < 7; index++) {
                if (this.state.daysOfWeek[(index+startingDay)%7])
                    a.push(index)
            }
            let b = [];
            for (let index = 0; index < a.length - 1; index++) {
                b.push(a[index+1]-a[index])
            }
            b.push(a[0]+7-a[a.length-1])

            const totalSessions = Object.values(this.state.daysOfWeek).filter(val => val).length * (this.state.days/7)

            for (let index = 0; index < totalSessions - 1; index++) {
                liveSchedule.push({
                    title: `Session ${index+2}`,
                    description: 'Today\'s session will be from '+ moment(firstStart).format('hh:mm a') + ' to ' + moment(firstEnd).format('hh:mm a'),
                    startTime: liveSchedule[liveSchedule.length-1].startTime + (b[index%b.length]*86400000),
                    endTime: liveSchedule[liveSchedule.length-1].endTime + (b[index%b.length]*86400000),
                    sessionId: `${itemId}-${liveSchedule[liveSchedule.length-1].startTime + (b[index%b.length]*86400000)}`,
                })            
            }
        }
        else if (this.state.repeat === 'Daily') {
            const totalSessions = this.state.days;
            for (let index = 0; index < totalSessions - 1; index++) {
                liveSchedule.push({
                    title: `Session ${index+2}`,
                    description: 'Today\'s session will be from '+ moment(firstStart).format('hh:mm a') + ' to ' + moment(firstEnd).format('hh:mm a'),
                    startTime: liveSchedule[liveSchedule.length-1].startTime + (86400000),
                    endTime: liveSchedule[liveSchedule.length-1].endTime + (86400000),
                    sessionId: `${itemId}-${liveSchedule[liveSchedule.length-1].startTime + (86400000)}`,
                })            
            }
        }
        return liveSchedule;
    }


    async addContent(type) {
        const { changeContext, changeWorkspace, context } = this.props;
        const {
          name, description, priceList, imageUrl, availableForPurchase, publishOnSave,
        } = this.state;
    
        const { pluginId, itemId } = this.props.context;
        await changeContext('name', name);
        await changeContext('description', description);
    
        window.analytics.track('Add Content Clicked', { content_type: type });
    
        changeWorkspace(`add${type}content`, `Add ${name}`,
          {
            itemId,
            pluginId,
            type,
            livestream: true,
            prevContext: context,
            prevState: this.state,
          });
      }

    renderDialogs() {
        return (
            <>
                <ConfirmationDialog open={this.state.confirmDialogOpen} resolve={this.state.confirmationResolver}/>
                <ConfirmUnsavedDialog open={this.state.confirmUnsavedDialogOpen} resolve={this.state.confirmUnsavedResolver} />
                <SuccessDialog open={this.state.saved} text="Content updated successfully"/>
            </>
        )
    }

    renderDetails() {
        return (
            <>
                <BannerUploadCard
                    bannerUrl={this.state.banner[0].mediaUrl}
                    handleSubmit={event => {
                        const banner = Object.keys(event.target.files).map(key => {
                            const file = event.target.files[key];
                            return ({mediaUrl: URL.createObjectURL(file), mediaObject: file})
                        });
                        this.setState({banner});
                    }}
                    bannerInputRef={this.bannerInputRef}
                />

                <TextInputCard
                    title={'Course Title*'}
                    subtitle={'Add a catchy title to boost your sales'}
                    placeholder={'Title'}
                    value={this.state.title}
                    onChange={(e) => {
                        this.setState({ title: e.target.value, titleError: false });
                        const imageUrl = this.state.banner[0].mediaUrl;
                        if (imageUrl && (imageUrl.indexOf('unsplash.com') > 0 || imageUrl === 'https://cdn.socionity.com/assets/build.socionity.com/welcome.jpeg')) {
                            const self = this;
                            Servercalls.getThumbnails(e.target.value).then((response) => {
                                self.setState({ banner: [{
                                    mediaUrl: response.data[0],
                                    mediaObject: {},
                                }]});
                            });
                        }
                    }}
                    maxLength={120}
                    error={this.state.titleError}
                />

                <DescriptionInputCard
                    title={'Course Description*'}
                    subtitle={'Add a description to boost your sales by 2x'}
                    value={this.state.description}
                    onChange={e => this.setState({description: e, descriptionError: false})}
                    error={this.state.descriptionError}
                />

                <StartingFromCard
                    startDate={this.state.startDate}
                    setStartDate={e => this.setState({startDate: e, startDateError: false})}
                    startTime={this.state.startTime}
                    setStartTime={e => this.setState({startTime: e.target.value, startDateError: false})}
                    endTime={this.state.endTime}
                    setEndTime={e => this.setState({endTime: e.target.value, startDateError: false})}
                    error={this.state.startDateError}
                />

                <CourseDurationCard
                    duration={this.state.duration}
                    setDuration={e => this.setState({duration: e.target.value, presetDuration: 0})}
                    presetDuration={this.state.presetDuration}
                    setPresetDuration={day => this.setState({presetDuration: day, duration: ""})}
                    repeat={this.state.repeat}
                    setRepeat={(repeat) => this.setState({repeat})}
                    days={this.state.days}
                    setDays={(days) => this.setState({days})}
                    daysOfWeek={this.state.daysOfWeek}
                    setDaysOfWeek={(daysOfWeek) => this.setState({daysOfWeek})}
                    toggleDay={(day) => {
                        const {daysOfWeek} = this.state;
                        daysOfWeek[day] = !daysOfWeek[day];
                        this.setState({daysOfWeek});
                    }}
                    validFor={this.state.validFor}
                    setValidFor={(validFor) => this.setState({validFor})}
                />
            </>
        )
    }

    renderPricing() {
        return (
            <>
                <AddPriceCard
                    title={'Add Price'}
                    value={this.state.price}
                    onChange={e => this.setState({price: e.target.value, priceError: false})}
                    currency={this.state.currency}
                    setCurrency={currency => this.setState({currency})}
                    error={this.state.priceError}
                />

                <DiscountToggleCard
                    discountCheck={this.state.discountCheck}
                    setDiscountCheck={(e) => this.setState({discountCheck: e})}
                />
            </>
        )
    }

    renderDialog() {
        return (
            <div style={{  display: "flex", justifyContent: "center", alignContent : "center"}}>

                {(localStorage.getItem('livestreamSecret') === 'ilevtsermaeSrcte' || this.props.context.pluginId === '1569061272207.838') ? (
                    <RoundedButton onClick={() => this.setState({livestreamResourceOpen: true})}>Add Video Content</RoundedButton>
                ) : null }

                {(localStorage.getItem('livestreamSecret') === 'ilevtsermaeSrcte' || this.props.context.pluginId === '1569061272207.838') ? (
                    <RoundedButton onClick={() => this.setState({livestreamResourceOpen2: true})}>Add PDF Content</RoundedButton>
                ) : null }

                <RoundedButton
                style={{alignSelf : "center", marginTop : "10px", marginBottom: "2px"}}
                className="startlive"
                color="secondary"
                variant="contained"
                onClick={this.handleClickOpen}
                >
                    START LIVE SESSION
                </RoundedButton>

                <Dialog fullScreen disableEscapeKeyDown open={this.state.open} onClose={this.handleClose} TransitionComponent={Transition}>
          
                <Livestream ref="livestream" setLivestreamStatus={async(message) => await Servercalls.pluginpremier_updateBundle(this.props.context.pluginId, this.props.context.itemId, "livestreamStatus", message)}  channel={this.props.context.itemId} handleClose={this.handleClose} onStart={() => this.setState({ started: true, connecting: false })} onConnecting={() => {this.setState({ connecting: true }); }} onEnd={() => this.setState({ ended: true, connecting: false, showLivestream: false, started: false })} style={{ height: '100%', width: '100%', position: 'absolute', top: 0, left: 0}}/>
                </Dialog>
            </div>
        )
    }

    renderQuestionnaire() {
        return (
            <TextInputCard
                    title={'Questionnaire Link'}
                    subtitle={'Add the link the Google Form'}
                    placeholder={'form'}
                    value={this.state.questionnaireLink}
                    onChange={(e) => {
                        this.setState({ questionnaireLink: e.target.value});
                    }}
                />
        )
    }

    renderContent() {
        return (
            <>
                {this.renderDialog()}
                {this.renderDetails()}
                {this.renderPricing()}
                {this.renderQuestionnaire()}
            </>
        );
    }

    checkError() {
        let error = false;
        if (this.state.title === '') {
            error = true;
            this.setState({titleError: 'Course title is mandatory'})
        }
        if (this.state.description === '') {
            error = true;
            this.setState({descriptionError: 'Course Description is mandatory'})
        }
        if (!this.state.startDate || this.state.startTime === '' || this.state.endTime === '') {
            error = true;
            this.setState({startDateError: 'A Start Date is mandatory'})
        }
        if (this.state.price === '') {
            this.setState({priceError: 'This field cannot be empty'});
            error = true;
        }

        if (error) return false;
        return true
    }

    render() {
        const {itemId, pluginId} = this.props.context;
        if (this.state.livestreamResourceOpen) {
            return (
                <LivestreamResource alwaysOpen={false} filetype={'video'} type='video' back={() => this.setState({livestreamResourceOpen: false})} pluginId={pluginId} itemId={itemId} />
            )
        }

        if (this.state.livestreamResourceOpen2) {
            return (
                <LivestreamResource alwaysOpen={false} filetype={'pdf'} type='downloadable' back={() => this.setState({livestreamResourceOpen2: false})} pluginId={pluginId} itemId={itemId} />
            )
        }

        return (
            <div className={this.props.classes.root}>
                <WsHeader 
                    back={async() => {
                        const self = this;
                        let confirmUnsaved = () => new Promise(resolve => self.setState({confirmUnsavedDialogOpen: true, confirmUnsavedResolver: resolve}))
                        const confirmation = await confirmUnsaved();
                        self.setState({confirmUnsavedDialogOpen: false, confirmUnsavedResolver: null});

                        if(!confirmation) {
                            return;
                        } else {
                            this.props.back()
                        }
                    }} 
                    title={this.props.context.title || "Add Live Course"}
                    style={{position: 'sticky'}}
                />

                {this.renderContent()}

                <LargeCTA onClick={() => {
                    if (!this.checkError())return;
                    this.save();
                }} 
                    label={'Save and Publish'}
                 />

                {this.renderDialogs()}
            </div>
        )
    }
}

export default withStyles(styles)(AddLiveCourse);