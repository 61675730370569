import { Button, Card, Icon, InputBase, Typography } from '@material-ui/core';
import { makeStyles, withTheme } from '@material-ui/styles';
import { duration } from 'moment';
import React from 'react';

import CalendarIcon from '../../Assets/Icons/Calendar.svg';
import ClockIcon from '../../Assets/Icons/Clock.svg';

import moment from 'moment';

const useStyles = makeStyles(theme => ({
    root: {
        margin: theme.spacing(1),
        borderRadius: theme.spacing(1.25),
        display: 'flex',
        padding: theme.spacing(2.5, 2.25, 1.25),
        boxShadow: 'none',
        flexDirection: 'column',
    },
    title: {
        fontWeight: 'bold',
        fontSize: theme.spacing(2.25),
        marginBottom: theme.spacing(2),
    },
    subtitle: {
        fontWeight: 'bold',
        marginBottom: theme.spacing(1.5),
    },
    subtitle2: {
        fontWeight: 'bold',
        whiteSpace: 'nowrap',
    },
    subtitle2Container: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacing(2.375),
    },
    buttons: {
        display: 'flex',
        marginBottom: theme.spacing(2.25),
        '& > *': {
            flex: 1,
            marginRight: theme.spacing(1),
            textTransform: 'none',
            borderRadius: theme.spacing(0.75),
            fontWeight: 'bold',
        },
        '& > *:last-child': {
            marginRight: 0,
        }
    },
    hr: {
        width: '100%',
        height: '1px',
        background: '#707070',
        opacity: '0.1',
        marginLeft: '13.6px',
    },
    inputBase: {
        border: '1px solid #eaeaea',
        background: '#fafafa',
        borderRadius: theme.spacing(0.75),
        width: '100%',
        padding: theme.spacing(1.5, 2.25),
        marginLeft: theme.spacing(1.5),
        position: 'absolute',
        top: 0,
    },
    inputBaseDatePicker: {
        width: '100%',
        padding: theme.spacing(1.5, 2.25),
        marginLeft: theme.spacing(1.5),
        zIndex: 100,
        background: 'transparent',
        color: 'transparent',
        position: 'absolute',
        top: 0,
        '&::placeholder': {
            color: 'transparent',
        },
        userSelect: 'none',
    },
    input: {
        fontSize: theme.spacing(1.75),
        padding: 0,
        '&:focus': {
            outline: 'none',
            outlineStyle: 'none',
        },
        '&::-webkit-calendar-picker-indicator': {
            background: 'transparent',
            position: 'absolute',
            width: '100%',
            left: 0,
        }
    },
    inputBase2: {
        flex: 1,
        border: '1px solid #eaeaea',
        background: '#fafafa',
        borderRadius: theme.spacing(0.75),
        padding: theme.spacing(1.5, 2.25),
        marginLeft: theme.spacing(1.5),
        minWidth: 0,
    },
    row: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacing(1),
    },
}))

const StartingFromCard = (props) => {
    const classes = useStyles();
    return (
        <Card className={classes.root}>
            <Typography className={classes.title} variant='h6'>{'Starting From'}</Typography>            

            <div className={classes.row}>
                <Icon><img src={CalendarIcon} /></Icon>
                <div style={{position: 'relative', flex: 1, height: props.theme.spacing(5.325)}}>
                    <InputBase
                        className={classes.inputBaseDatePicker}
                        inputProps={{
                            className: classes.input,
                            type: 'date'
                        }}
                        value={props.startDate}
                        onChange={e => props.setStartDate(e.target.value)}
                    />
                    <InputBase
                        className={classes.inputBase}
                        inputProps={{
                            className: classes.input,
                        }}
                        value={moment(props.startDate).format('DD MMMM')}
                    />
                </div>
            </div>

            <div className={classes.row}>
                <Icon><img src={ClockIcon} /></Icon>
                <InputBase
                    className={classes.inputBase2}
                    inputProps={{
                        className: classes.input,
                        type: 'time'
                    }}
                    value={props.startTime}
                    onChange={e => props.setStartTime(e)}
                />
                <Typography variant='caption' style={{marginLeft: props.theme.spacing(1.25)}}>to</Typography>
                <InputBase
                    className={classes.inputBase2}
                    inputProps={{
                        className: classes.input,
                        type: 'time'
                    }}
                    value={props.endTime}
                    onChange={e => props.setEndTime(e)}
                />
            </div>

            <div style={{display: 'flex'}}>
                <Typography style={{color: '#f02e2e', marginRight: 'auto', marginTop: props.theme.spacing(1)}} variant='body2'>{props.error}</Typography>
            </div>
        </Card>
    )
}

export default withTheme(StartingFromCard);