import React from 'react';
import { Card, CardContent, Typography, CardActions, Button, LinearProgress, Dialog, DialogTitle, DialogContent, TextField, DialogActions, Icon, InputAdornment, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import moment from 'moment';
import MainCTA from '../../Components/MainCTA';
import WsHeader from '../../Components/wsheader';
import WsFooter from '../../Components/wsfooter';
import Illustration from '../../Components/illustration';
import { withTheme } from '@material-ui/styles';
import RoundedButton from '../../Components/roundedbutton';

const FileDownload = require('js-file-download');

const Servercalls = require('../../Utils/Servercalls');

class PaymentReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            payments : [],
            loading: false,
            downloading: false,
            refundReason: "",
            openRefundPayment: null,
            validityExtension: 0,
            refundType: 'credits',
            refundFailedMessage: null,
            cursor: null,
            unsettledPayments: [],
            loadingAll: true,
            searchTerm: "",
        }
    }

    componentDidMount(){
        this.reload();
    }

    reload(){
        const self = this;
        self.setState({ loading: true, cursor: null });
        Servercalls.getApp().then(response => {
            self.setState({ app: response.data, loading: false });
        })
        /*Servercalls.getAppPayments("get", this.state.cursor).then(response => {
            self.setState({ payments: response.data.payments, loading: false, hasMore: response.data.cursor?true: false, cursor: response.data.cursor?response.data.cursor.logId: null });
        })
        Servercalls.getAppPayments("unsettled", this.state.cursor).then(response => {
            self.setState({ unsettledPayments: response.data.payments, hasMore: response.data.cursor?true: false, cursor: response.data.cursor?response.data.cursor.logId: null });
        })*/
        this.loadAll();
    }

    loadMore(){
        const self = this;
        self.setState({ loadingMore: true });
        Servercalls.getAppPayments("get", this.state.cursor).then(response => {
            self.setState({ payments: this.state.payments.concat(response.data.payments), loading: false, hasMore: response.data.cursor?true: false, cursor: response.data.cursor?response.data.cursor.logId : null, loadingMore: false });
        })
    }

    async loadAll() {
        this.setState({ loadingAll: true });
        let cursor = null;
        while(true){
            const response = await Servercalls.getAppPayments("get", cursor);
            console.log(response.data);
            this.setState({ payments: this.state.payments.concat(response.data.payments)});
            if(response.data.cursor)
                cursor = response.data.cursor.logId;
            else 
                break;
        }
        this.setState({ loadingAll : false });
    }

     getUnsettledPayments(){
        const amounts = {};
        this.state.unsettledPayments.forEach(p =>{
            //if(p.currency.length > 5) return;
            if(!amounts[p.currency])
                amounts[p.currency] = 0

                amounts[p.currency] += parseInt(p.price);
        });
        return Object.keys(amounts).map(k => ({ currency: k, price: amounts[k]}));
    }

    download() {
        const self = this;
        let fileContents = '"Payment ID", "User Email Address", "User Phone Number","Currency","Amount","Payment For","Payment Date & Time","Valid upto Date & Time","Payment Mode"\n';
        this.state.payments.sort((a,b) => b.timestamp - a.timestamp).sort((a,b) => a.paymentFor < b.paymentFor ? 1 : -1).forEach(p => {
            fileContents += `"${p.logId}","${p.email}","'${p.phone}'","${p.currency}",${!p.paymentDetails || p.paymentDetails.method==='credits'?0:p.price},"${p.paymentFor}","${moment(p.timestamp).format("Do MMMM YYYY hh:mm a")}","${p.validUpto > 0 ? moment(p.validUpto).format("Do MMMM YYYY hh:mm a") : "Forever"}","${!p.paymentDetails || p.paymentDetails.method === 'credits'? "Pass or Credits": "Online Payment"}"\n`
        })
        FileDownload(fileContents, "payments.csv");
    }

    refund(payment) {
        const self = this;
        self.setState({ refunding: true });
        Servercalls.paymentsRefund(payment.logId, this.state.refundReason, this.state.refundType).then(() => {
            self.setState({ refunded: true, refunding: false, openRefundPayment: null  });
            setTimeout(() => {self.setState({ refunded: false }); self.reload()}, 3000);
        }).catch(e =>{
            self.setState({ refunding: false, refundFailedMessage: e.response.data });
        })
    }

    extendValidity(payment){
        const self = this;
        self.setState({ extending: true });
        Servercalls.paymentsExtend(payment.logId, this.state.validityExtension).then(() => {
            self.setState({ extended: true, extending: false, openValidityPayment: null  });
            setTimeout(() => {self.setState({ extended: false }); self.reload()}, 3000);
        }).catch(e =>{
            self.setState({ extending: false});
            alert("Extending Validity didn't go through please try again. " + e);
        })

    }


    render() {
        if(this.state.loading) return <LinearProgress />
        if(this.state.refunded) return (
            <center style={{ margin: 64}}>
                <Icon style={{ fontSize: 120}} color="secondary">check</Icon>
                <br /><br />
                <Typography variant="body2">Refund successful. The user will shortly receive an email with instructions. You'll be redirected shortly</Typography>
            </center>
        )
        if(this.state.extended) return (
            <center style={{ margin: 64}}>
                <Icon style={{ fontSize: 120}} color="secondary">check</Icon>
                <br /><br />
                <Typography variant="body2">Validity of subscription extended. You'll be redirected shortly</Typography>
            </center>
        )


        return (
            <div style={{ margin: 12 }}>
                <WsHeader title="Payouts" back={this.props.back} />
                <Card style={{ backgroundColor: this.props.theme.palette.primary.main, color: this.props.theme.palette.primary.contrastText }}>
                        <CardContent>
                            <Typography variant="h6">Unsettled Amount</Typography>
                            {
                                this.getUnsettledPayments().map(p => <Typography variant="body1">{p.currency} {p.price}</Typography>)
                            }
                            <Typography style={{ marginTop : 24 }} variant="body2">Will be settled on the 7th of the coming month</Typography>
                            <Typography style={{ marginTop: 12}} variant="body2">{this.state.app?`You'll receive ${100*(1 - (this.state.app.socionityCut || 0.1))}% of all collections` : null}</Typography>
                            <RoundedButton onClick={() => this.props.changeWorkspace("apppayments", "apppayments", {})} variant="contained" color="secondary" style={{ marginTop: 24 }}>Edit Bank Details</RoundedButton>
                        </CardContent>
                </Card>
                {!this.state.loadingAll && this.state.payments.length === 0? <Card style={{ marginTop: 12}}>
                        <CardContent>
                            <Typography variant="h6">Waiting for the first payment</Typography>
                            <Typography variant="body1">There have been no payments yet, but we're all ready to start accepting payments. Distrubte your app to potential subscribers!</Typography>
                            <Illustration image="piggybank" style={{ width: '100%'}} />
                        </CardContent>
                    </Card>:<div style={{ margin: 12 }}>
                        <TextField fullWidth label="Search" onChange={(event)=> this.setState({ searchTerm : event.target.value })} />
                        {this.state.loadingAll?<LinearProgress fullWidth/> : null}
                    </div>}
                {this.state.payments.filter( p => p.email.toLowerCase().indexOf(this.state.searchTerm) > -1 || moment(p.timestamp).format("DD MMM YYYY hh:mm a").toLowerCase().indexOf(this.state.searchTerm) > -1 || p.price == this.state.searchTerm || (`${p.currency} ${p.price}`).toLowerCase() == this.state.searchTerm).sort((a,b) => b.timestamp - a.timestamp).map(p => (
                    <Card style={{ marginTop: 8 }}>
                        <CardContent>
                            {p.paymentDetails && p.paymentDetails.method && p.paymentDetails.method === 'credits'? <Typography variant="h3">{p.currency} 0</Typography> : <Typography variant="h3">{p.currency} {p.price}</Typography>}
                {p.paymentDetails && p.paymentDetails.method && p.paymentDetails.method === 'credits'? <Typography variant="body1">Paid using pass or credits ({p.currency} {p.price})</Typography>: null}
                            <Typography variant="body2" style={{ color: "#444" }}><small>#{p.logId}</small></Typography>
                            <Typography variant="h6">{p.email}</Typography>
                            <Typography variant="body2">Paid on : {moment(p.timestamp).format("DD MMM YYYY hh:mm a")}</Typography>
                            <Typography variant="body2">Paid for : {p.paymentFor}</Typography>
                            <Typography variant="body1">Valid Upto : {p.validUpto > 0 ? moment(p.validUpto).format("DD MMM YYYY hh:mm a"): "Forever"}</Typography>
                        </CardContent>
                        <CardActions>
                            <Button disabled={p.timestamp < (Date.now() - (5 * 24 * 60 * 60 * 1000))} onClick={() => this.setState({ openRefundPayment: p })} variant="contained" color="secondary">Refund</Button>
                            <Button disabled={p.validUpto == 0} onClick={() => this.setState({ openValidityPayment: p })} variant="contained" color="primary">Extend Validity</Button>
                        </CardActions>
                    </Card>
                ))}
                {this.state.hasMore?<center style={{ margin: 16}}> <Button onClick={() => this.loadMore()} disabled={this.state.loadingMore} color="secondary">LOAD MORE</Button> </center>: null}
                {this.state.openRefundPayment? (
                    <Dialog
                        open={true}
                    >
                        <DialogTitle>Refund {this.state.openRefundPayment.currency} {this.state.openRefundPayment.price}?</DialogTitle>
                        <DialogContent>
                            <Typography variant="body1">This operation cannot be un-done. The user will receive credits they can spend on your app.</Typography>
                            <TextField style={{marginTop: 12 }} label="Reason of refund" fullWidth onChange={(event) => this.setState({ refundReason : event.target.value })} />
                            <FormControl component="fieldset" style={{marginTop: 12 }}>
                                <FormLabel component="legend">How do you want to refund?</FormLabel>
                                <RadioGroup value={this.state.refundType} onChange={event => this.setState({ refundType: event.target.value })}>
                                    <FormControlLabel value="credits" control={<Radio />} label="App Credits" />
                                    <FormControlLabel value="cash" control={<Radio />} label="Bank Refund" />
                                </RadioGroup>
                            </FormControl>    
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => this.setState({ openRefundPayment: null })} color="secondary">Cancel</Button>
                            <Button disabled={this.state.refunding || this.state.refundReason === ''} onClick={() => this.refund(this.state.openRefundPayment)} color="primary" variant="contained">Confirm Refund</Button>
                        </DialogActions>
                    </Dialog>
                ) : null}

                {this.state.refundFailedMessage? (
                    <Dialog
                        open={true}
                    >
                        <DialogTitle>Refund Failed!</DialogTitle>
                        <DialogContent>
                            <Typography variant="body1">{this.state.refundFailedMessage}</Typography>    
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => this.setState({ refundFailedMessage: null })} color="secondary">Close</Button>
                        </DialogActions>
                    </Dialog>
                ) : null}


                {this.state.openValidityPayment? (
                    <Dialog
                        open={true}
                    >
                        <DialogTitle>Extend validity</DialogTitle>
                        <DialogContent>
                            <Typography variant="body1">Current Validity Upto : {moment(this.state.openValidityPayment.validUpto).format("ddd DD MMMM YYYY hh:mm a")} </Typography>
                            <TextField InputProps={{endAdornment: <InputAdornment position="end">Days</InputAdornment>}} style={{marginTop: 12 }} label="Extend validity by" fullWidth onChange={(event) => this.setState({ validityExtension : event.target.value })} type="number"/>

                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => this.setState({ openValidityPayment: null })} color="secondary">Cancel</Button>
                            <Button disabled={this.state.extending || !this.state.validityExtension} onClick={() => this.extendValidity(this.state.openValidityPayment)} color="primary" variant="contained">Extend Validity</Button>
                        </DialogActions>
                    </Dialog>
                ) : null}
                <WsFooter text="We know, this is your favorite part of the app"/>
                <MainCTA 
                    disabled={this.state.downloading} 
                    icon="save_alt" 
                    text="Download" 
                    settings={[]}
                    help={[]}
                    onCTAClick={() => this.download()} />
            </div>
        )
    }
}

export default withTheme(PaymentReport);