import React from 'react';
export default class TnC extends React.Component {
    render() {
        return (
        <div class="c5 c12">
        <h1>Terms and conditions</h1>
            <p class="c1"><span class="c14">&zwj;</span><span class="c11">Welcome, and thank you for your interest in Socionity Software Solutions Private Limited (&ldquo;Us,&rdquo; &ldquo;We,&rdquo; &ldquo;Socionity,&rdquo; or the &ldquo;Company&rdquo;), our websites at </span><span class="c4"><a class="c16" href="https://www.google.com/url?q=https://www.socionity.com/&amp;sa=D&amp;ust=1565075906670000">https://www.socionity.com/</a></span><span class="c11">, </span><span class="c4"><a class="c16" href="https://www.google.com/url?q=https://build.socionity.com/&amp;sa=D&amp;ust=1565075906671000">https://build.socionity.com/</a></span><span class="c2">&nbsp;and various related services (collectively, the &ldquo;Platform&rdquo;). The Platform and its features are provided to you subject to your compliance with all the terms, conditions, and notices contained or referenced herein, (as well as any other written agreement between us and you).</span></p>
            <p class="c1"><span class="c2">&zwj;</span></p>
            <p class="c1"><span class="c0">1. User&rsquo;s Acknowledgment and Acceptance of Terms</span></p>
            <p class="c1"><span class="c0">&zwj;</span></p>
            <p class="c1"><span class="c2">You are permitted to use the Platform only if you: (1) Represent that you are able to form a binding contract in your jurisdiction; (2) Comply with our Terms of Service; (3) Will not copy or distribute any part of any part of the Platform in any medium without Company&rsquo;s prior written authorization except as permitted through the Platform&rsquo;s functionality and under these Terms of Service; (4) Provide accurate and complete information when creating an account; (5) Acknowledge you are solely responsible for the activity that occurs while signed in to or while using the Platform; (6) Don&rsquo;t collect any personally identifiable information, including full names, physical addresses, or e-mail addresses, for commercial purposes; and (7) Acknowledge your sole responsibility for your content submissions, including discussion posts, profile information and links, pictures, and other such content.</span></p>
            <p class="c1"><span class="c2">&zwj;</span></p>
            <p class="c1"><span class="c2">The Service is available only for individuals aged 13 years or older. If you are age 13 or older but under the age of 18, or the legal age of majority where you reside if that jurisdiction has an older age of majority, then you agree to review these Terms of Service with your parent or guardian to make sure that both you and your parent or guardian understand and agree to these Terms of Service. You agree to have your parent or guardian review and accept these Terms of Service on your behalf. If you are a parent or guardian agreeing to these Terms of Service for the benefit of a child over the age of 13, then you agree to and accept full responsibility for that child&rsquo;s use of the Service, including all financial charges and legal liability that he or she may incur. We may, in our sole discretion, refuse to offer the Service to any person or entity and change the eligibility criteria for using the Service at any time. The right to access the Service is revoked where these Terms of Service or use of the Service is prohibited or to the extent offering, sale, or provision of the Service conflicts with any applicable law, rule or regulation.</span></p>
            <p class="c1"><span class="c2">&zwj;</span></p>
            <p class="c1"><span class="c2">YOUR USE OF THE PLATFORM CONSTITUTES YOUR AGREEMENT TO BE BOUND BY THESE TERMS OF SERVICE. IF YOU DO NOT WISH TO BE BOUND BY THE THESE TERMS OF SERVICE, PLEASE EXIT THE PLATFORM NOW. YOUR SOLE REMEDY FOR DISSATISFACTION WITH THE PLATFORM, OR ANY PRODUCTS, SERVICES, CONTENT, OR OTHER INFORMATION AVAILABLE ON OR THROUGH THE PLATFORM, IS TO STOP USING THE PLATFORM AND/OR THOSE PARTICULAR PRODUCTS OR SERVICES. YOUR AGREEMENT WITH US REGARDING COMPLIANCE WITH THESE TERMS OF SERVICE BECOMES EFFECTIVE IMMEDIATELY UPON COMMENCEMENT OF YOUR USE OF THE PLATFORM.</span></p>
            <p class="c1"><span class="c2">&zwj;</span></p>
            <p class="c1"><span class="c2">In these Terms of Service, we use the terms &ldquo;you&rdquo; and &ldquo;your&rdquo; to mean any person using our Platform, and any organization or person using the Platform on an organization&rsquo;s behalf. As used in these Terms of Service, &ldquo;User(s)&rdquo; refers to any individual using the Platform. &ldquo;Service(s)&rdquo; refers to applications created for Users through the Platform.</span></p>
            <p class="c1"><span class="c0">2. Account Information and Security</span></p>
            <p class="c1"><span class="c0">&zwj;</span></p>
            <p class="c1"><span class="c3">&zwj;</span><span class="c2">When you register, you provide us with some basic information, which may include an e-mail address, user ID, and password (&ldquo;Account Information&rdquo;). To use the Platform, you agree to provide Socionity with true, accurate, current, and complete information about yourself, and to keep your Account Information current and accurate. You agree to not allow others to access your account. You alone are responsible for anything that happens from your failure to maintain security and confidentiality, such as by sharing your Account Information with others, and we will not be liable for any loss or damage resulting from your failure to comply with these obligations. If someone is using your account, notify us immediately. You agree to immediately notify us of any unauthorized use of either your password or account or any other breach of security. Additionally, you acknowledge that internet transmissions are never completely private or secure and you understand that any message or information you send to the Platform may be read or intercepted by others, even if it is encrypted.</span></p>
            <p class="c1"><span class="c3">3</span><span class="c11">. </span><span class="c0">Your Content and Licenses</span></p>
            <p class="c1"><span class="c0">&zwj;</span></p>
            <p class="c1"><span class="c3">&zwj;</span><span class="c2">Socionity does NOT claim ANY ownership rights in the text, files, codes, images, photos, video, sounds, or musical works (collectively, &ldquo;Your Content&rdquo;) that you upload on or through the Platform. You represent and warrant that: (i) you own or have the right to reproduce Your Content uploaded by you on or through the Platform, (ii) the uploading and use of Your Content on or through the Platform does not violate the privacy rights, publicity rights, copyrights, contract rights, intellectual property rights or any other rights of any person, and (iii) the uploading of Your Content on the Platform does not result in a breach of contract between you and a third party. (iv) User expressly agrees not to host, display, upload, modify, publish, transmit, update or share any information on app that&mdash; </span></p>
            <p class="c7"><span class="c2">a) belongs to another person and to which the user does not have any right to; </span></p>
            <p class="c7"><span class="c2">b) is grossly harmful, harassing, blasphemous defamatory, obscene, pornographic, paedophilic, libelous, invasive of another&#39;s privacy, hateful, or racially, ethnically objectionable, disparaging, relating or encouraging money laundering or gambling, or otherwise unlawful in any manner whatever; </span></p>
            <p class="c7"><span class="c2">c) harm minors in any way; </span></p>
            <p class="c7"><span class="c2">d) infringes any patent, trademark, copyright or other proprietary rights; </span></p>
            <p class="c7"><span class="c2">e) violates any law for the time being in force; </span></p>
            <p class="c7"><span class="c2">f) deceives or misleads the addressee about the origin of such messages or communicates any information which is grossly offensive or menacing in nature; </span></p>
            <p class="c7"><span class="c2">g) impersonate another person; </span></p>
            <p class="c7"><span class="c2">h) contains software viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer resource; </span></p>
            <p class="c7"><span class="c2">i) threatens the unity, integrity, defense, security or sovereignty of India, friendly relations with foreign states, or public order or causes incitement to the commission of any cognizable offence or prevents investigation of any offence or is insulting any other nation.</span></p>
            <p class="c1"><span class="c2">&nbsp;</span></p>
            <p class="c1"><span class="c2">Socionity may be used to reproduce materials so long as such use is limited to reproduction of non-copyrighted materials, materials in which you own the copyright, or materials you are authorized or legally permitted to reproduce. Except as and only to the extent expressly permitted in these Terms of Service or by applicable law, you may not copy, decompile, reverse engineer, disassemble, modify, or create derivative works of Socionity or any part thereof. Socionity performs technical functions necessary to offer the Platform, including but not limited to reformatting Your Content to allow its use through the Platform and using Your Content to make improvements to the Platform and you hereby grant Socionity a non-exclusive, perpetual, transferable, fully paid, worldwide license to use, copy, prepare derivative works of Your Content to provide these services and in accordance with this Agreement. If you post any content on a public portion of the Platform (such as comments or a message board), you grant Socionity a non-exclusive, irrevocable, perpetual, transferable, fully paid, worldwide license to use, copy, prepare derivative works of (including excerpting, in whole or in part), distribute and publicly display that content on the Platform.</span></p>
            <p class="c1"><span class="c11">&zwj;</span><span class="c3">4</span><span class="c11">. </span><span class="c0">Socionity Intellectual Property Rights</span></p>
            <p class="c1 c8"><span class="c0"></span></p>
            <p class="c1"><span class="c3">&zwj;</span><span class="c2">As between Socionity and you, Socionity or its licensors own and reserve all right, title and interest in and to the Service and all hardware, software and other items used to provide the Service, other than the rights explicitly granted to you to use the Service in accordance with this Terms of Service. No title to or ownership of any proprietary rights related to the Service is transferred to you pursuant to these Terms of Service. All rights not explicitly granted to you are reserved by Socionity. In the event that you provide comments, suggestions, paid enhancements to the Service as part of a statement of work, or recommendations to Socionity with respect to the Service (including, without limitation, with respect to modifications, enhancements, improvements and other changes to the Service) (collectively, &ldquo;Feedback&rdquo;) or where you choose to share an application built with the Service in the Socionity Application Store (collectively &ldquo;Shared Applications&rdquo;), you hereby grant to Socionity a world-wide, royalty free, irrevocable, perpetual license to use and otherwise incorporate any Feedback or Shared Applications in connection with the Service.</span></p>
            <p class="c1"><span class="c0">5. Feedback</span></p>
            <p class="c1 c8"><span class="c0"></span></p>
            <p class="c1"><span class="c3">&zwj;</span><span class="c2">We always appreciate feedback and are always on the lookout for ways to improve. For feedback, comments, questions, or concerns, you can contact us at contact@socionity.com and we will return your e-mail at the soonest opportunity possible.</span></p>
            <p class="c1"><span class="c11">&zwj;</span><span class="c0">6. Guarantee and Warranty; Limitation of Liability</span></p>
            <p class="c1 c8"><span class="c0"></span></p>
            <p class="c1"><span class="c3">&zwj;</span><span class="c2">THE PLATFORM AND ALL INFORMATION, CONTENT, MATERIALS, PRODUCTS AND OTHER SERVICES INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH THE PLATFORM ARE PROVIDED BY AN &ldquo;AS IS&rdquo; AND &ldquo;AS AVAILABLE&rdquo; BASIS, UNLESS OTHERWISE SPECIFIED IN WRITING. SOCIONITY MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION OF PLATFORM, OR THE PRODUCTS, INFORMATION, CONTENT, MATERIALS, OR OTHER SERVICES INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH THE PLATFORM, UNLESS OTHERWISE SPECIFIED IN WRITING. YOU EXPRESSLY AGREE THAT YOUR USE OF THE PLATFORM IS AT YOUR SOLE RISK.</span></p>
            <p class="c1"><span class="c2">&zwj;</span></p>
            <p class="c1"><span class="c2">TO THE FULL EXTENT PERMISSIBLE BY LAW, SOCIONITY DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. SOCIONITY DOES NOT WARRANT THAT THE PLATFORM, PRODUCTS, INFORMATION, CONTENT, MATERIALS, OR OTHER SERVICES INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH THE PLATFORM OR ELECTRONIC COMMUNICATIONS SENT FROM SOCIONITY ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. TO THE FULL EXTENT PERMISSIBLE BY LAW, SOCIONITY WILL NOT BE LIABLE FOR ANY DAMAGES OF ANY KIND ARISING FROM THE USE OF THE PLATFORM, OR FROM ANY PRODUCTS, INFORMATION, CONTENT, MATERIALS, OR OTHER SERVICES INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH THE PLATFORM, INCLUDING, BUT NOT LIMITED TO DIRECT, INDIRECT, INCIDENTAL, PUNITIVE, AND CONSEQUENTIAL DAMAGES, UNLESS OTHERWISE SPECIFIED IN WRITING.</span></p>
            <p class="c1"><span class="c2">&zwj;</span></p>
            <p class="c1"><span class="c2">IN NO EVENT WILL SOCIONITY BE LIABLE TO ANY PARTY FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES OF ANY TYPE WHATSOEVER RELATED TO OR ARISING FROM THE PLATFORM OR ANY USE OF THE PLATFORM, OR OF ANY SITE OR RESOURCE LINKED TO, REFERENCED, OR ACCESSED THROUGH THE PLATFORM, OR FOR THE USE OR DOWNLOADING OF, OR ACCESS TO, ANY MATERIALS, INFORMATION, PRODUCTS, OR SERVICES, INCLUDING, WITHOUT LIMITATION, ANY LOST PROFITS, BUSINESS INTERRUPTION, LOST SAVINGS OR LOSS OF PROGRAMS OR OTHER DATA, EVEN IF SOCIONITY IS EXPRESSLY ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THIS EXCLUSION AND WAIVER OF LIABILITY APPLIES TO ALL CAUSES OF ACTION, WHETHER BASED ON CONTRACT, WARRANTY, TORT, OR ANY OTHER LEGAL THEORIES.</span></p>
            <p class="c1"><span class="c2">&zwj;</span></p>
            <p class="c1"><span class="c2">TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL THE MAXIMUM AGGREGATE LIABILITY OF SOCIONITY ARISING OUT OF OR IN ANY WAY RELATED TO THE PLATFORM OR PRODUCTS OR SERVICES EXCEED THE AMOUNT PAID FOR SUCH PRODUCTS/SERVICES OR EXCEED THE GREATER OF Rs.5000 OR THE AMOUNTS PAID BY YOU TO SOCIONITY IN CONNECTION WITH THE SERVICES IN THE PRECEDING TWO (2) MONTH PERIOD. THE FOREGOING SHALL CONSTITUTE SOCIONITY&rsquo;S SOLE LIABILITY AND OBLIGATION, REGARDLESS OF THE FORM OF ACTION, WHETHER BASED IN CONTRACT, TORT, OR ANY OTHER LEGAL OR EQUITABLE THEORY.</span></p>
            <p class="c1"><span class="c2">&zwj;</span></p>
            <p class="c1"><span class="c2">TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, YOU, ON BEHALF OF YOUR HEIRS, EXECUTORS, ADMINISTRATORS, LEGAL AND PERSONAL REPRESENTATIVES, HEREBY RELEASE, WAIVE, ACQUIT AND FOREVER DISCHARGE SOCIONITY FROM AND AGAINST, AND COVENANT NOT TO SUE, FOR ALL CLAIMS YOU HAVE OR MAY HAVE ARISING OUT OF OR IN ANY WAY RELATED TO THESE TERMS OF SERVICES, THE PLATFORM, AND THE PRODUCTS AND/OR SERVICES. IF YOU ARE A CALIFORNIA RESIDENT, YOU HEREBY WAIVE YOUR RIGHTS UNDER CALIFORNIA CIVIL CODE 1542, WHICH STATES &ldquo;A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE TIME OF EXECUTING THE RELEASE, WHICH IF KNOWN BY HIM OR HER MUST HAVE MATERIALLY AFFECTED HIS OR HER SETTLEMENT WITH THE DEBTOR.&rdquo;</span></p>
            <p class="c9"><span class="c0 c5">7. Dispute Resolution and Arbitration</span></p>
            <p class="c9 c8"><span class="c0 c5"></span></p>
            <p class="c9"><span class="c3 c5">&zwj;</span><span class="c2 c5">In the interest of resolving disputes between you and Socionity in the most expedient and cost effective manner, you and Socionity agree that every dispute arising in connection with these Terms of Service will be resolved by binding arbitration. Arbitration uses a neutral arbitrator instead of a judge or jury, may allow for more limited discovery than in court, and is subject to very limited (if any) review by courts. Arbitrators can award the same damages and relief that a court can award. This agreement to arbitrate disputes includes all claims arising out of or relating to any aspect of these Terms, whether based in contract, tort, statute, fraud, misrepresentation, or any other legal theory, and regardless of whether a claim arises during or after the termination of these Terms. YOU UNDERSTAND AND AGREE THAT, BY ENTERING INTO THESE TERMS, YOU AND SOCIONITY ARE EACH WAIVING THE RIGHT TO A TRIAL OR TO PARTICIPATE IN A CLASS, COLLECTIVE, OR REPRESENTATIVE ACTION. </span></p>
            <p class="c9"><span class="c2 c5">&zwj;</span></p>
            <p class="c9"><span class="c2 c5">Despite the provisions of the preceding paragraph, nothing in these Terms of Service will be deemed to waive, preclude, or otherwise limit the right of either party to: (a) bring an individual action in small claims court; (b) pursue an enforcement action through the applicable central, state, or local agency if that action is available; (c) seek injunctive relief in a court of law; or (d) to file suit in a court of law to address an intellectual property infringement claim. The Terms of Service shall be governed by and interpreted in accordance with the laws of the Telangana State and the laws of India applicable therein, without regard to principles of conflicts of laws. The parties irrevocably and unconditionally submit to the exclusive jurisdiction of the courts of the Telangana state, India with respect to any dispute or claim arising out of or in connection with the Terms of Service. The United Nations Convention on Contracts for the International Sale of Goods will not apply to these Terms of Service and is hereby expressly excluded.</span></p>
            <p class="c1"><span class="c2">&nbsp;&zwj;</span></p>
            <p class="c1"><span class="c6">Arbitrator</span></p>
            <p class="c1"><span class="c2">Any arbitration between you and Socionity will be settled under the Indian Arbitration Act, </span></p>
            <p class="c1"><span class="c6">Notice; Process</span></p>
            <p class="c1"><span class="c2">A party who intends to seek arbitration must first send a written notice of the dispute to the other party by certified India Post or by Federal Express (signature required) or, only if such other party has not provided a current physical address, then by electronic mail (&ldquo;Notice&rdquo;). Socionity&rsquo;s address for Notice is: Socionity Software Solutions Pvt Limited, C-6003 Luxor Apartments, Nr. Chirec School,Kondapur, Serilingampally, Hyderabad, Telangana, India - 500084. The Notice must: (a) describe the nature and basis of the claim or dispute; and (b) set forth the specific relief sought (&ldquo;Demand&rdquo;). The parties will make good faith efforts to resolve the claim directly, but if the parties do not reach an agreement to do so within 30 days after the Notice is received, you or Socionity may commence an arbitration proceeding. During the arbitration, the amount of any settlement offer made by you or Socionity must not be disclosed to the arbitrator until after the arbitrator makes a final decision and award, if any. If the dispute is finally resolved through arbitration in your favour, Socionity will pay you the highest of the following: (i) the amount awarded by the arbitrator, if any; (ii) the last written settlement amount offered by Socionity in settlement of the dispute prior to the arbitrator&rsquo;s award; or (iii) Rs.5,000. &nbsp;</span></p>
            <p class="c1"><span class="c2">&zwj;</span></p>
            <p class="c1"><span class="c6">Fees and Procedure</span></p>
            <p class="c1"><span class="c2">The parties to the arbitration shall each pay an equal share of the costs and expenses of such arbitration, and each party shall separately pay for its respective counsel fees and expenses; provided, however, that the arbitrator may award attorneys&rsquo; fees and costs to the prevailing party, except as prohibited by law. If you commence arbitration in accordance with these Terms of Services, Socionity will reimburse you for your payment of the filing fee, unless your claim is for more than Rs.10,00,000 in which case the payment of any fees will be decided by the governing rules. Any arbitration hearing will take place at a location to be agreed upon in Hyderabad, India, but if the claim is for Rs.10,00,000 or less, you may choose whether the arbitration will be conducted: (a) solely on the basis of documents submitted to the arbitrator; (b) through a non-appearance based telephone hearing; or (c) by an in-person hearing as established by the Rules in the county (or parish) of your billing address. If the arbitrator finds that either the substance of your claim or the relief sought in the Demand is frivolous or brought for an improper purpose, then the payment of all fees will be governed by the rules. In that case, you agree to reimburse Socionity for all monies previously disbursed by it that are otherwise your obligation to pay under the rules. Regardless of the manner in which the arbitration is conducted, the arbitrator must issue a reasoned written decision sufficient to explain the essential findings and conclusions on which the decision and award, if any, are based. The arbitrator may make rulings and resolve disputes as to the payment and reimbursement of fees or expenses at any time during the proceeding and upon request from either party made within 14 days of the arbitrator&rsquo;s ruling on the merits. &nbsp;</span></p>
            <p class="c1"><span class="c2">&zwj;</span></p>
            <p class="c1"><span class="c2">The arbitrator may grant injunctions and other relief. The arbitrator shall administer and conduct any arbitration in accordance with the law of the jurisdiction in which the dispute arose, including civil procedure rules, and the arbitrator shall apply the substantive and procedural law of the jurisdiction in which the dispute arose. To the extent that the rules conflict with local law, local law shall take preference. The decision of the arbitrator shall be final, conclusive, and binding on the parties to the arbitration. The parties agree that the prevailing party in any arbitration shall be entitled to injunctive relief in any court of competent jurisdiction to enforce the arbitration award.</span></p>
            <p class="c1"><span class="c2">&zwj;</span></p>
            <p class="c1"><span class="c6">No Class Actions</span></p>
            <p class="c1"><span class="c2">YOU AND SOCIONITY AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY AND NOT AS A PLAINTIFF OR MEMBER OF ANY CLASS, COLLECTIVE, OR REPRESENTATIVE PROCEEDING except that users may bring a proceeding as a private attorney general, if and as allowed by law. Further, unless both you and Socionity agree otherwise, the arbitrator may not consolidate more than one person&rsquo;s claims, and may not otherwise preside over any form of a representative or class proceeding. Nothing in this Agreement infringes upon any rights a User may have under the Sarbanes-Oxley Act, including any rights prohibiting compulsory arbitration.</span></p>
            <p class="c1"><span class="c2">&zwj;</span></p>
            <p class="c1"><span class="c6">Modifications to this Arbitration Provision</span></p>
            <p class="c1"><span class="c2">If Socionity makes any future change to this arbitration provision, other than a change to Socionity&rsquo;s address for Notice, you may reject the change by sending us written notice within 30 days of the change to Socionity&rsquo;s address for Notice, in which case your account with Socionity will be immediately terminated and this arbitration provision, as in effect immediately prior to the changes you rejected will survive.</span></p>
            <p class="c1"><span class="c2">&zwj;</span></p>
            <p class="c1"><span class="c6">Enforceability</span></p>
            <p class="c1"><span class="c2">If the &ldquo;No Class Actions&rdquo; section is found to be unenforceable or if the entirety of this Section 8 is found to be unenforceable, then the entirety of this Section 8 will be null and void and, in that case, the parties agree that the exclusive jurisdiction and venue described in Section 9 will govern any action arising out of or related to these Terms of Service.</span></p>
            <p class="c1"><span class="c2">&zwj;</span></p>
            <p class="c1"><span class="c6">Right to Opt Out of Arbitration</span></p>
            <p class="c1"><span class="c2">You may submit a statement notifying Socionity that you wish to opt out and not be subject to arbitration under this section. Should you desire to opt out, you must notify Socionity of your intention to opt out by submitting a written notice, which may be via email to contact@socionity.com, stating that you are opting out of this section. In order to be effective, your opt-out notice must be provided within thirty (30) days of your agreeing to these Terms. Should you timely opt out of this section, you may pursue available legal remedies and will not be required to arbitrate claims.</span></p>
            <p class="c1"><span class="c0">8. Choice of Law</span></p>
            <p class="c1"><span class="c0">&zwj;</span></p>
            <p class="c1"><span class="c3">&zwj;</span><span class="c2">These Terms are governed by and construed in accordance with the laws of the Telangana State, India, without giving effect to any conflict of law principles, except as may be otherwise provided in supplemental terms applicable to your region. Any dispute or claim arising out of or in connection with this Agreement shall be adjudicated in Hyderabad, Telangana, India.</span></p>
            <p class="c1"><span class="c0">9. Termination of Service</span></p>
            <p class="c1"><span class="c0">&zwj;</span></p>
            <p class="c1"><span class="c3">&zwj;</span><span class="c2">Socionity may terminate your privilege to use or access the Platform immediately and without notice for any reason whatsoever. Upon such termination, you must immediately cease accessing or using the Platform and agree not access or make use of, or attempt to use, the Platform. Furthermore, you acknowledge that Socionity reserves the right to take action -- technical, legal or otherwise -- to block, nullify or deny your ability to access the Platform. You understand that Socionity may exercise this right in its sole discretion, and this right shall be in addition to and not in substitution for any other rights and remedies available to Socionity. All provisions of these Terms of Service which by their nature should survive termination shall survive the termination of your access to the Platform, including, without limitation, provision regarding ownership, warranty disclaimers, indemnity, and limitations of liability. <br />You shall have the right to terminate the app and discontinue this agreement by sending a request to discontinue using electronic mail from the registered address. Upon receipt of such a request Socionity will respond to the request and terminate the distribution of the app linked to the email address from which the termination request was sent and the content uploaded from the said account, within 15 working days. </span></p>
            <p class="c1"><span class="c0">10. Intellectual Property</span></p>
            <p class="c1"><span class="c0">&zwj;</span></p>
            <p class="c1"><span class="c3">&zwj;</span><span class="c2">The entire content and materials contained on the Platform, including, but not limited to, audio, video, images, text, user interface,code, scores, logos, the selection and arrangement of the Platform and other intellectual property (the &ldquo;Content&rdquo;) are owned by or licensed to Socionity to the fullest extent under the copyright laws of the India and other countries. Images of people or places displayed on the Platform are either the property of, or used with permission by, Socionity. You may not reproduce, republish, transmit, upload, distribute, copy or publicly display any of the Content without our prior written consent. We neither warrant nor represent that your use of materials displayed on the Platform will not infringe rights of third parties not owned by or affiliated with the Company. We may redesign the Platform in our sole discretion at any time.</span></p>
            <p class="c1"><span class="c0">11. Restrictions on Use</span></p>
            <p class="c1"><span class="c0">&zwj;</span></p>
            <p class="c1"><span class="c3">&zwj;</span><span class="c2">You may not use the Platform or contents set forth therein for any illegal purpose or in any manner inconsistent with the Terms of Service. You agree not to use, transfer, distribute, or dispose of any information contained in the Platform in any manner that could compete with the business of Company. You may not copy, reproduce, recompile, disassemble, decompile, reverse engineer, distribute, modify, publish, display, perform, upload to, create derivative works from, frame transmit, or in any way exploit any part of the service, you may not recirculate, redistribute or publish the analysis and presentation included in the service without Company&rsquo;s prior written consent.</span></p>
            <p class="c1"><span class="c2">&zwj;</span></p>
            <p class="c1"><span class="c2">Modification of Company&rsquo;s content is a violation of the copyrights and other proprietary rights of Company or its subsidiaries. Additionally, you may not monetize or offer any part of the service for sale or distribute it over any other medium including but not limited to a computer network or hyperlink framing on the internet without the prior written consent of Company. The Platform and the information contained therein may not be used to construct a database of any kind. The Platform and content contained therein may not be stored (in its entirety or in any part) in databases for access by you or any third party or to distribute.</span></p>
            <p class="c1"><span class="c2">&zwj;</span></p>
            <p class="c1"><span class="c2">In the absence of a contrary agreement, you may not use any of the trademarks, trade names, service marks, copyrights, or logos of Company or its subsidiaries suppliers or affiliates in any manner which creates the impression that such items belong to or are associated with you or, except as otherwise provided herein, are used with Company&rsquo;s consent, and you acknowledge that you have no ownership rights in and to any of such items. You will not use the service or the information contained therein in unsolicited mailings or spam material. You will not use any trademarks, trade names, service marks, copyrights, or logos of Company or its subsidiaries in unsolicited mailings or spam material. You will not spam or send unsolicited mailings to any person or entity using the Platform.</span></p>
            <p class="c1"><span class="c11">&zwj;</span><span class="c0">12. No Agency</span></p>
            <p class="c1"><span class="c0">&zwj;</span></p>
            <p class="c1"><span class="c3">&zwj;</span><span class="c2">No agency, partnership, joint venture, employee-employer or franchiser-franchisee relationship between you and Socionity is intended or created by this Agreement. Users are not Socionity&rsquo;s representative or agent, and may not enter into an agreement on Socionity&rsquo;s behalf.</span></p>
            <p class="c1"><span class="c0">13. Links to Other Sites and Materials</span></p>
            <p class="c1"><span class="c0">&zwj;</span></p>
            <p class="c1"><span class="c3">&zwj;</span><span class="c2">As part of using the Platform, Socionity may provide you with links to third party website(s) (&ldquo;Third Party Sites&rdquo;) as well as content or items belonging to or originating from third parties (the &ldquo;Third Party Applications, Software or Content&rdquo;). Socionity has no control over Third Party Sites and Third Party Applications, Software, or Content or the promotions, materials, information, goods or services available on these Third Party Sites or Third Party Applications, Software, or Content. Such Third Party Sites and Third Party Applications, Software, or Content are not investigated, monitored or checked for accuracy, appropriateness, or completeness by Socionity, and Socionity is not responsible for any Third Party Sites accessed through the Platform or any Third Party Applications, Software, or Content posted on, available through or installed from the Platform, including the content, accuracy, offensiveness, opinions, reliability, privacy practices or other policies of or contained in the Third Party Sites or the Third Party Applications, Software or Content. Inclusion of, linking to, or permitting the use or installation of any Third Party Site or any Third Party Applications, Software, or Content does not imply approval or endorsement thereof by Socionity. If you decide to leave the Platform and access the Third Party Sites or to use or install any Third Party Applications, Software or, Content, you do so at your own risk and you should be aware that our terms and policies no longer govern. You should review the applicable terms and policies, including privacy and data gathering practices, of any site to which you navigate from the Platform or relating to any applications you use or install from the site.</span></p>
            <p class="c1"><span class="c0">14. Privacy</span></p>
            <p class="c1"><span class="c0">&zwj;</span></p>
            <p class="c1"><span class="c3">&zwj;</span><span class="c2">Socionity&rsquo;s current privacy policy is available on the Platform and at our website (socionity.com/privacy) (the &ldquo;Privacy Policy&rdquo;), which is incorporated by this reference. In accordance with the Privacy Policy, we may collect information about the location of your device each time you use the Platform, or when you otherwise consent to the collection of this information. We strongly recommend that you review the Privacy Policy closely.</span></p>
            <p class="c1"><span class="c0">15. Electronic Communications</span></p>
            <p class="c1"><span class="c0">&zwj;</span></p>
            <p class="c1"><span class="c3">&zwj;</span><span class="c2">The communications between you and Company use electronic means, whether you use the Platform or send us emails, or whether Company posts notices on the service or communicates with you via email. For contractual purposes, you (a) consent to receive communications from Company in an electronic form; and (b) agree that all terms and conditions, agreements, notices, disclosures, and other communications that Company provides to you electronically satisfy any legal requirement that such communications would satisfy if it were in writing. </span></p>
            <p class="c1"><span class="c0">16. Payment of fees</span></p>
            <p class="c9"><span class="c2 c5">You will pay the Fees applicable to your subscription to the Platform (&ldquo;Subscription Fees&rdquo;) and any other applicable fees, including but not limited to applicable fees relating to the value of sales made through your app referred to as &ldquo;Transaction Fees&rdquo;. Together, the Subscription Fees, Transaction Fees and the Additional Fees are referred to as the &ldquo;Fees&rdquo;. We shall be charging a transaction fee of upto { this.props.percent || "thirty"} percent on all the monetary transactions made on the app and the webpage created for accessing the content on the app. Subscription fees and Transaction fees shall vary based on the plan adopted by the user.</span></p>
            <p class="c9"><span class="c2 c5">User must keep a valid credit card or debit cards allowed by us on file with us to pay for all incurred and recurring Fees. Socionity will charge applicable Fees to the credit card account that you authorize (&ldquo;Authorized Card&rdquo;), and Socionity will continue to charge the Authorized Card (or any replacement card) for applicable Fees until the Services are terminated, and any and all outstanding Fees have been paid in full. Unless otherwise indicated, all Fees and other charges are in INR, and all payments shall be in INR.</span></p>
            <p class="c9"><span class="c2 c5">Subscription Fees, if any are to be paid in advance and will be billed in 30 day intervals (each such date, a &ldquo;Billing Date&rdquo;). Transaction Fees and Additional Fees will be charged from time to time based on the transactions. You will be charged on each Billing Date for all outstanding Fees that have not previously been charged. Fees will appear on an invoice, which will be sent to the Account Owner via the email provided. Users have approximately two weeks to bring up and settle any issues with the billing of Subscription Fees.</span></p>
            <p class="c9"><span class="c2 c5">If we are not able to process payment of Fees using the Authorized Card, we will make a second attempt to process payment using the Authorized Card 3 days later. If the second attempt is not successful, we will make a final attempt 3 days following the second attempt. If our final attempt is not successful, we may suspend and revoke access to your app. Your Account will be reactivated upon your payment of any outstanding Fees, plus the Fees applicable to your next Billing Cycle. You will not be able to access your Account during any period of suspension. If the outstanding Fees remain unpaid for 60 days following the date of suspension, Socionity reserves the right to terminate your app.</span></p>
            <p class="c9"><span class="c2 c5">All Fees are exclusive of applicable federal, provincial, state, local or other governmental sales, goods and services, harmonized or other taxes, fees or charges now in force or enacted in the future (&ldquo;Taxes&rdquo;).</span></p>
            <p class="c9"><span class="c2 c5">You are responsible for all applicable Taxes that arise from or as a result of your subscription to or purchase of Socionity&rsquo;s products and services. These Taxes are based on the rates applicable to the billing address you provide to us. Such amounts are in addition to the Fees for such products and services and will be billed to your Authorized Card. If you are exempt from payment of such Taxes, you must provide us with evidence of your exemption, which in some jurisdictions includes an original certificate that satisfies applicable legal requirements attesting to tax-exempt status. Tax exemption will only apply from and after the date we receive evidence satisfactory to Socionity of your exemption. If you are not charged Taxes by Socionity, you are responsible for determining if Taxes are payable, and if so, self-remitting Taxes to the appropriate tax authorities in your jurisdiction.</span></p>
            <p class="c9"><span class="c2 c5">Any and all applicable taxes, levies etc. on the sale of content, product or service provided through the app by the user, shall be sole responsibility and liability of the user to be duly discharged. User understands that it is not the responsibility of Socionity and user shall not hold Socionity liable to pay such taxes. If not explicitly collected by the user, the amounts collected are to be assumed as inclusive of taxes and it is user&rsquo;s obligation to pay, remit, deposit the relevant and applicable taxes.</span></p>
            <p class="c9"><span class="c2 c5">You must keep informed Socionity of &nbsp;your current location and promptly update your location if you move jurisdictions. If you change jurisdictions you must promptly update your location by mailing contact@socionity.com.Socionity does not provide refunds.Any payment towards third party services including but not limited to video chat, live streaming, shipping partners etc. integrated on your app shall be in payable by user in addition to the subscription fees.The collections from the monetary transactions in the app and on the webpage shall be shared with the user after deducting the transaction charge of upto { this.props.percent || "thirty" } percent and applicable taxes and duties, on a monthly nightly basis. </span></p>
            <p class="c1"><span class="c0">&nbsp;</span></p>
            <p class="c10"><span class="c0">17. Notice</span></p>
            <p class="c10"><span class="c0">&zwj;</span></p>
            <p class="c10"><span class="c3">&zwj;</span><span class="c2">Communications made through the Service&rsquo;s e-mail and messaging system, will not constitute legal notice to Company or any of its officers, employees, agents or representatives in any situation where notice to Company is required by contract or any law or regulation.</span></p>
            <p class="c10"><span class="c2">&zwj;</span></p>
            <p class="c10"><span class="c2">Any such notice must be sent to:</span></p>
            <p class="c10"><span class="c2">&zwj; Socionity Software Solutions Pvt Limited, </span></p>
            <p class="c10"><span class="c2">&nbsp;C-6003 Luxor Apartments, </span></p>
            <p class="c10"><span class="c2">&nbsp;Nr. Chirec School,Kondapur, Serilingampally, </span></p>
            <p class="c10"><span class="c2">&nbsp;Hyderabad, Telangana, India - 500084</span></p>
            <p class="c10"><span class="c2">&zwj;</span></p>
            <p class="c10"><span class="c0">18. Changes and Amendments to Terms</span></p>
            <p class="c10"><span class="c0">&zwj;</span></p>
            <p class="c10"><span class="c3">&zwj;</span><span class="c2">These Terms of Service are effective as of the &ldquo;Last Modified&rdquo; date identified at the top of this page. We expressly reserve the right to change these Terms of Service from time to time without notice to you. You acknowledge and agree that it is your responsibility to review the Platform and these Terms of Service from time to time and to familiarize yourself with any modifications. Your continued use of the Platform after such modifications will constitute acknowledgement of the modified Terms of Service and agreement to abide and be bound by the modified Terms of Service. However, for any material modifications to the Terms of Service or in the event that such modifications materially alter your rights or obligations hereunder, such amended Terms of Service will automatically be effective upon the earlier of (i) your continued use of the Platform with actual knowledge of such modifications, or (ii) 30 days from publication of such modified Terms of Service on the Platform. Notwithstanding the foregoing, the resolution of any dispute that arises between you and us will be governed by the Terms of Service in effect at the time such dispute arose.</span></p>
            <p class="c1"><span class="c0">&zwj;</span></p>
            <p class="c10"><span class="c0">19. General Terms</span></p>
            <p class="c10"><span class="c0">&zwj;</span></p>
            <p class="c10"><span class="c3">&zwj;</span><span class="c2">If any part of this Agreement is held invalid or unenforceable, that portion of the Agreement will be construed consistent with applicable law. The remaining portions will remain in full force and effect. Any failure on the part of Company to enforce any provision of this Agreement will not be considered a waiver of our right to enforce such provision. Our rights under this Agreement will survive any termination of this Agreement.</span></p>
            <p class="c10"><span class="c2">&zwj;</span></p>
            <p class="c10"><span class="c2">Company may assign or delegate these Terms of Service and/or Company&rsquo;s Privacy Policy, in whole or in part, to any person or entity at any time with or without your consent, including, but not limited, to a subsidiary or an acquirer of assets. You may not assign or delegate any rights or obligations under the Terms of Service or Privacy Policy without Company&rsquo;s prior written consent, and any unauthorized assignment and delegation by you is void.</span></p>
            <p class="c17"><span class="c2 c5">The user hereby agrees to indemnify, defend and hold harmless the Company (including its officers, directors, employees, servants, agents, advisors and representatives) against any and all actions, claims, law suits, damages and liabilities, including reasonable attorney fees, that arise from or relate to any breach by the user, of any of the representations, warranties, assurances and obligations herein; or the user&#39;s use or misuse of, or access to, Socionity; or otherwise from the user&#39;s Content; or any infringement by the user of any Intellectual Property Rights or other right of any third person or entity.</span></p>
            <p class="c9"><span class="c2 c5">The user expressly acknowledges and accepts that Socionity is merely providing the development platform for building the application. Socionity shall not be liable or accountable whatsoever for any violation or circumvention or other non-compliance of the Applicable Laws by the user. </span></p>
            <p class="c9"><span class="c2 c5">The playback resolution and quality of the Digital Content users of the app shall receive will depend on a number of factors, including the type of Compatible Device on which they are accessing the Digital Content and their bandwidth, which may increase or decrease over the course of their viewing. If we detect that Digital Content being streamed may be interrupted or may otherwise not play properly due to bandwidth constraints or other factors, we may decrease the resolution and file size of the streamed Digital Content in an effort to provide an uninterrupted viewing experience. While we strive to provide you a high quality viewing experience, we make no guarantee as to the resolution or quality of the Digital Content your users will receive when streaming.</span></p>
            <p class="c9"><span class="c2 c5">&nbsp;</span></p>
            <p class="c9"><span class="c2 c5">We reserve the right to provide our services to your competitors and make no promise of exclusivity in any particular market segment. You further acknowledge and agree that Socionity employees and contractors may also be Socionity customers/creators and that they may compete with you, although they may not use your confidential information in doing so.</span></p>
            <p class="c9"><span class="c11 c5">You understand that your content (not including credit card information), may be transferred unencrypted and involve (a) transmissions over various networks; and (b) changes to conform and adapt to technical requirements of connecting networks or devices. Credit Card information is always encrypted during transfer over networks.</span><span class="c5">&nbsp;</span><span class="c2 c5">Socionity does not pre-screen content and it is in our sole discretion to refuse or remove any content from the Service.</span></p>
            <p class="c9"><span class="c2 c5">The platform allow you to send certain communications to your customers by short message service (SMS) messaging (for example, sending order confirmation notifications via SMS) (the &quot;SMS Services&quot;) and in app notifications. You will only use these Services in compliance with this agreement and the laws of the jurisdiction from which you send messages, and in which your messages are received.</span></p>
            <p class="c9"><span class="c2 c5">Prices for using the Services are subject to change upon 30 days&rsquo; notice from Socionity. Such notice may be provided at any time by posting the changes to the Socionity Site (Socionity.com).Socionity reserves the right at any time, and from time to time, to modify or discontinue, the Service (or any part thereof) with or without notice.Socionity shall not be liable to you or to any third party for any modification, price change, suspension or discontinuance of the Service</span></p>
            {this.props.additional? <p class="c9"><span class="c2 c5">{this.props.additional}</span></p>:null}
            <p class="c9"><span class="c2 c5">&zwj;</span></p>
            <p class="c9"><span class="c0 c5">You acknowledge that you have read these Terms of Service, understand the Terms of Service, and will be bound by these terms and conditions. You further acknowledge that these Terms of Service together with the Privacy Policy represent the complete and exclusive statement of the agreement between us and that it supersedes any proposal or prior agreement oral or written, and any other communications between us related to the subject matter contained in the Terms of Service and Privacy Policy.</span></p>
            <p class="c9"><span class="c0 c5">&zwj;</span></p>
            <p class="c15"><span class="c13">&nbsp;</span></p>
            <p class="c8 c15"><span class="c13"></span></p>
         </div>
        )
    }
}