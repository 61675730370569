/* eslint-disable */
import React from 'react';
import Theme from '../../Components/theme';
import WsHeader from '../../Components/wsheader';
import WsFooter from '../../Components/wsfooter';
import FileUploadButton from '../../Components/fileuploadbutton';
import { LinearProgress, Button, Typography, TextField, Icon, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Card, CardContent } from '@material-ui/core';
import { Editor as TinyEditor } from '@tinymce/tinymce-react';
import RoundedButton from '../../Components/roundedbutton';
import WsQuizContent from './ws-quiz';

const Servercalls = require('../../Utils/Servercalls');
const utils = require('../../Utils/utils');

export default class CommonAddFileContent extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            title: "",
            description: "",
            uploadDone: 0,
            uploadTotal: -1,
            uploading: false,
            url: null
        }
    }
    save(type){
        const bundle = this.props.context;
        const self = this;
        self.setState({ saving : true });
        
        const quiz = {
            answerTimes: this.state.answerTimes || false,
            manualGrading: this.state.manualGrading || false,
        }

        Servercalls.pluginpremier_addContentToBundle(bundle.pluginId, bundle.itemId, null, this.state.title, this.state.description, quiz, true, type==='free', 'quiz', null, "<p></p>", [], 'normal').then(response => {
            self.setState({ saving: false, saved: true });
            setTimeout(() => self.props.back(), 3000);
        }).catch(e => {alert(e); self.setState({ saving: false })});
    }

    render() {
        if(this.props.context.publishTime) {
            return (
                <WsQuizContent pluginId={this.props.context.pluginId} bundleId={this.props.context.bundleId} {...this.props} />
            )
        }

        if(this.state.saved)
            return (
                <center style={{ margin: 64}}>
                    <Icon color="secondary" style={{fontSize: 120}}>check</Icon>
                    <br /><br />
                    <Typography variant="body1">Added content to your bundle. Redirecting you shortly ...</Typography>
                </center>
            )
        return (
            <Theme>
                <WsHeader 
                    back={this.props.back}
                    helpText="What is a quiz"
                    helpVideo="a"
                    zIndex={0}
                />
                <Card style={{ margin: 12 }}>
                    <CardContent>
                    <TextField fullWidth label={`Title of the quiz`} value={this.state.title} onChange={e => this.setState({ title: e.target.value })} />
                    <TextField style={{ marginTop: 12}} fullWidth label={`Description for this quiz`} value={this.state.description} onChange={e => this.setState({ description: e.target.value })} />
                    <FormControl style={{ marginTop: 12}} component="fieldset" >
                        <FormLabel component="legend">How many time can a user take this quiz?</FormLabel>
                        <RadioGroup aria-label="times" name="times" value={this.state.times} onChange={(event) => this.setState({ answerTimes: event.target.value })}>
                        <FormControlLabel value="once" control={<Radio />} label="Only once" />
                        <FormControlLabel value="multiple" control={<Radio />} label="Multiple times" />
                        </RadioGroup>
                    </FormControl>

                    <br /><br />

                    <FormControl component="fieldset" >
                        <FormLabel component="legend">Does this quiz need to be evaluated manually?</FormLabel>
                        <RadioGroup aria-label="times" name="times" value={this.state.manualGrading?'manual': 'automatic'} onChange={(event) => this.setState({ manualGrading: event.target.value === 'manual' })}>
                        <FormControlLabel value="manual" control={<Radio />} label="Yes" />
                        <FormControlLabel value="automatic" control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl>

                    
                    <Typography variant="body1" style={{ marginTop: 24}}>You can add the questions in the next step after adding the quiz to the collection</Typography>

                    <Typography variant="h6" style={{ marginTop: 24}}>Add {this.props.filetype} to collection : </Typography>
                    <Typography variant="body1" style={{ marginTop: 12}}>When can people view this {this.props.filetype}?</Typography>
                    <div className="bundle_addcontent_publish">
                    <RoundedButton variant="outlined" onClick={() => this.save('free')} fullWidth style={{ marginTop: 32}} color="secondary" disabled={this.state.saving || !(this.state.title.length > 0 && this.state.description.length > 0 )}>Before paying</RoundedButton>
                    <RoundedButton onClick={() => this.save('paid')} fullWidth style={{ marginTop: 32, marginBottom: 64}} variant="contained" color="secondary" disabled={this.state.saving || !(this.state.title.length > 0 && this.state.description.length > 0 )}>Only after paying</RoundedButton>
                    </div>
                    </CardContent>
                </Card>
                <WsFooter text="Copy paste the link to the page you want to show here" />
            </Theme>
        )
    }
}