import React from 'react';
import Theme from '../../Components/theme';
import WsHeader from '../../Components/wsheader';
import TnC from './TermsAndConditions';
import { Typography, CircularProgress, Card, CardActionArea, Button, Icon } from '@material-ui/core';

const Servercalls = require('../../Utils/Servercalls');

export default class WsAppTnc extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            loading: true,
            socionityCut: 0.1,
            agreeing: false, 
            done: false,
        }
    }

    async componentDidMount(){
        const app = await Servercalls.getApp();
        let socionityCut = 0.1;
        
        if(app.data.socionityCut)
            socionityCut = app.data.socionityCut;
        this.setState({ socionityCut, loading: false, additional: app.data.additionalTnC });

    }

    agree() {
        const self = this;
        self.setState({ agreeing: true });
        Servercalls.agreeTnC().then(x => {
            self.setState({ agreeing: false, done: true });
            setTimeout(() => {
                self.setState({ done: false });
            }, 3000);
        }).catch(e => { alert(e); self.setState({ agreeing: false })})
    }

    render() {
        if(this.state.done) return (
            <center style={{ margin: 64}}>
                <Icon style={{ fontSize: 120}} color="secondary">check</Icon>
                <br /><br />
                <Typography variant="body2">Terms and Conditions accepted. You'll be redirected shortly</Typography>
            </center>
        );



        if(this.state.loading) return <center style={{marginTop: 64}}><CircularProgress color="primary"/></center>
        return(
            <Theme style={{position: 'relative'}}>
                <WsHeader back={this.props.back} title="Terms and Conditions" description="These are the terms and conditions for using Socionity. By publishing, you agree to these terms and conditions." />
                <div style={{ marginLeft: 32,  height: 'calc(100% - 64px)', overflow: 'auto' }}>
                    <br /><br />
                    <Typography variant="body1">Here's a short summary of what we think is most important in the Terms and Conditions. However you <b>must</b> read the entire terms and conditions before accepting them.</Typography>
                    <ul>
                        <li><Typography variant="body1">Socionity <b>cannot claim</b> ownership of the content you upload on your app</Typography></li>
                        <li><Typography variant="body1">You are responsible for making sure the content you upload doesn't infringe any copyrights</Typography></li>
                        <li><Typography variant="body1">Socionity charges {this.state.socionityCut * 100}% fees on any transactions that happen on your app, including payment gateway charges</Typography></li>
                        <li><Typography variant="body1">Socionity will make payouts of collections on a monthly basis</Typography></li>
                        <li><Typography variant="body1">Socionity may change the prices and terms with a notice period of 30 days</Typography></li>
                        <li><Typography variant="body1">Socionity has the right to terminate the app if found guilty of breaching legal or moral agreements</Typography></li>
                        <li><Typography variant="body1">Socionity has the right to terminate the app if any pending bills are not paid within 30 days of notice</Typography></li>                    
                    </ul>
                    <br />
                    <br />
                    <Typography variant="h5">Terms and conditions</Typography>
                    <TnC percent={this.state.socionityCut * 100} additional={this.state.additional} />
                    <br /><br /><br />
                    {/*<Typography variant="h5">Privacy Policy</Typography>
                    <TnC />*/}
                    <br /><br /><br /><br /><br />
                </div>
                {/*<MainCTA disabled={this.state.agreeing} onClick={() => this.agree()} icon="check" text="Agree T & C" />*/}
            </Theme>
        )
    }
}