import React from 'react';
import { withTheme } from '@material-ui/styles';
import { Card, CardMedia, Box, Typography, CardActionArea, Icon, Badge, Menu, ListItem, IconButton, CardContent, Button, List } from '@material-ui/core';

import RoundedButton from '../Components/roundedbutton';
import MenuIcon from '../Assets/Icons/Menu.svg';
import StyledDrawer from '../Components/StyledDrawer';
import DeleteIcon from '../Assets/Icons/Delete.svg';
import UnpublishIcon from '../Assets/Icons/Unhide.svg';
import StyledListItems from '../Components/StyledListItems';
import WsBundleAudience from './Bundle/ws-bundleaudience';
import FileUploadButton from '../Components/fileuploadbutton';

const { currencies } = require('../Utils/Constants');

class BundleItem extends React.Component {


    constructor(props){
        super(props);
        this.state = {
            bundleAudienceOpen: false,
        }

        this.openDrawerIconRef = React.createRef();
    }

    getSrc(url){
        
        if(!url || !url.toLowerCase || url.toLowerCase().indexOf("gravatar") > 0)
            return "https://cdn.socionity.com/assets/build.socionity.com/illustrations/nobannerimage.png";
        return url;
    }

    hasLivestream(){
        if(!this.props.bundle.contents)
            return false;
        let livestreamCount = 0;
        for(let i = 0 ; i < this.props.bundle.contents.length; i+=1){
            if(this.props.bundle.contents[i].type==="livestream")
                livestreamCount += 1;
        }
        if(livestreamCount === 1)
            return true;
        return false
    }

    getSettingsCount() {
        let count = 0;
        if(!this.props.bundle.imageUrl || this.props.bundle.imageUrl.toLowerCase().indexOf("gravatar") > 0)
            count+=1
        if(!this.props.bundle.priceList || this.props.bundle.priceList.length === 0)
            count+= 1
        return count;
    }

    getCurrencySymbol(value) {
        const currency = currencies.find(option => option.value === value);
        console.log(currencies.find(option => option.value === value))
        return currency.label;
    }

    getListItems() {
        const listItems= [{
            onClick: () => this.props.bundle.liveSchedule ? (
                this.props.changeWorkspace('premiumeditlive', "Enter your course details", {...this.props.bundle, title: 'Edit your course details'})
            ) : (
                this.props.changeWorkspace('premiumaddbundle', "Enter your folder details", {...this.props.bundle, title: 'Edit your folder details'}) || window.analytics.track('Folder Edit Menu Clicked', {'option_clicked' : 'Edit Content'})
            ),
            icon: 'edit',
            text: 'Edit Content',
        }, {
            onClick: null,
            icon: <img style={{height: '100%'}} src={DeleteIcon} />,
            text: 'Delete this content',
        }, {
            onClick: () => { this.setState({ editContentDialogOpen: false, bundleAudienceOpen: true}); window.analytics.track('Folder Edit Menu Clicked', {'option_clicked' : 'Publish'})},
            icon: this.props.bundle.published? <img src={UnpublishIcon} style={{height: '100%'}} /> :"visibility",
            text: this.props.bundle.published?"Unpublish":"Publish",
        }, {
            onClick: () => { this.setState({ editContentDialogOpen: false}); this.props.changeWorkspace('bundlefaqs', 'bundlefaqs', this.props.bundle); window.analytics.track('Folder Edit Menu Clicked', {'option_clicked' : 'SetFAQs'})},
            icon: 'help',
            text: 'Set FAQs',
        }, {
            onClick: () => { this.setState({ editContentDialogOpen: false}); window.analytics.track('Folder Edit Menu Clicked', {'option_clicked' : 'Collection to Top'}); this.props.moveToTop()},
            icon: 'vertical_align_top',
            text: this.props.alwaysOpen ? 'Move Collection To Front' : 'Move Collection To Top',
            iconStyle: {transform: this.props.alwaysOpen ? 'rotate(-90deg)' : 'rotate(0deg)'},
        }]
        return <StyledListItems listItems={listItems} />
    }

    render() {
        return (
            <>
            <Card style={{margin: '0', borderRadius: '12px', maxWidth: this.props.alwaysOpen ? '239px' : '100%', marginRight: this.props.alwaysOpen ? '16px' : '0', marginBottom: this.props.alwaysOpen ? '16px' : '12px', ...this.props.style}}>
                <CardContent style={{padding: '0'}}>
                        <>
                            <div style={{display: 'flex'}}>
                                <img onContextMenu={(e) => e.preventDefault()} onClick={() => {
                                    if (this.props.bundle.liveSchedule) {
                                        this.props.changeWorkspace('premiumeditlive', "Enter your course details", {...this.props.bundle, title: 'Edit your course details'})
                                        return;
                                    }
                                    this.props.changeWorkspace('premiumaddbundle', "Enter your folder details", {...this.props.bundle, title: 'Edit your folder details'});
                                }} style={{
                                    borderRadius: '0',
                                    zIndex: '1',
                                    backgroundColor: 'rgba(0,0,0,0.15)',
                                    height: 'calc( 56.25vw - 18px)',
                                    width: this.props.alwaysOpen ? '100%' : 'calc(100vw - 32px)',
                                    maxWidth: this.props.alwaysOpen ? '239px' : '784px',
                                    maxHeight: this.props.alwaysOpen ? '134.34px' : '441px',
                                    objectFit: 'cover',
                                    cursor: 'pointer',
                                    }} 
                                    alt={this.props.bundle.name}
                                    onError={() => this.setState({imageUrl: "https://cdn.socionity.com/assets/build.socionity.com/illustrations/nobannerimage.png"})}
                                    src={this.getSrc(this.props.bundle.imageUrl)}
                                />
                                { (this.getSrc(this.props.bundle.imageUrl) === 'https://cdn.socionity.com/assets/build.socionity.com/illustrations/nobannerimage.png' ||
                                    this.getSrc(this.props.bundle.imageUrl) === 'https://cdn.socionity.com/assets/build.socionity.com/welcome.jpeg') ? 
                                <FileUploadButton
                                    style={{ 
                                        position: 'relative',
                                        height: 'calc( 56.25vw - 18px)',
                                        width: this.props.alwaysOpen ? '100%' : 'calc(100vw - 32px)',
                                        maxWidth: this.props.alwaysOpen ? '239px' : '784px',
                                        maxHeight: this.props.alwaysOpen ? '134.34px' : '441px',
                                        backgroundColor: 'transparent',
                                        borderRadius: '12px',
                                        boxShadow: 'none',
                                    }}
                                    extensions={['png','jpg','jpeg']}
                                    maxSizeMBs={2}
                                    onFail={e => alert(e)}
                                    onUpload={(url) => this.props.addThumbnail(url)}
                                    icon="add_a_photo"
                                    color="primary"
                                    variant="contained"
                                    containerStyles={{
                                        position: 'relative',
                                        top: '0',
                                        height: 'calc( 56.25vw - 18px)',
                                        width: this.props.alwaysOpen ? '100%' : 'calc(100vw - 32px)',
                                        maxWidth: this.props.alwaysOpen ? '239px' : '784px',
                                        maxHeight: this.props.alwaysOpen ? '134.34px' : '441px',
                                        marginLeft: '-100%',
                                    }}
                                /> : null}
                            </div>
                        
                        
                        <div style={{padding: this.props.alwaysOpen ? '0 16px 16px' : '0 20px 20px', opacity: this.props.bundle.priceList && this.props.bundle.priceList.length && this.props.bundle.published ? 1 : 0.5,}}>
                            <div style={{display: 'flex', margin: '16px 0 8px', alignItems: 'center'}}>
                                <Typography variant="body2" style={{fontWeight: 'bold', lineHeight: 'normal', fontSize: this.props.alwaysOpen ? '0.875rem' : '1.25em', marginRight: 'auto', overflow: 'hidden', maxHeight: this.props.alwaysOpen ? '35px' : '48px', textOverflow: 'ellipsis'}}>
                                    {this.props.bundle.name && this.props.bundle.name.trim().length ? this.props.bundle.name : 'Title not set'}
                                </Typography>
                                <IconButton ref={this.openDrawerIconRef} disableRipple style={{padding: '0', margin: '-8px 0'}}><Icon style={{fontSize: '0.8em'}} onClick={event => this.setState({ editContentDialogOpen: true}) || window.analytics.track('Folder Edit Menu Open', {})}><img src={MenuIcon} style={{height: '100%'}}/></Icon></IconButton>
                            </div>
                            <div style={{display: 'flex', alignItems: 'baseline', whiteSpace: 'nowrap', flexWrap: 'wrap'}}>
                                <Typography variant="body1" style={{fontWeight: 'bold', lineHeight: 'normal', fontSize: this.props.alwaysOpen ? '1rem' : '1.25em', marginRight: '12px', marginBottom: '6px', width: this.props.alwaysOpen ? '100%' : 'auto'}}>
                                    {this.props.bundle.name && this.props.bundle.description && this.props.bundle.priceList && this.props.bundle.priceList.length > 0 ? (
                                        !isNaN(this.props.bundle.priceList[0].price) ? (
                                            this.props.bundle.priceList[0].price ? (
                                                `${this.getCurrencySymbol(this.props.bundle.priceList[0].currency)} ${this.props.bundle.priceList[0].price}`
                                            ) : "Free"
                                        ) : 'Incorrect'
                                    ) : 'Unavailable'
                                    }
                                </Typography>

                                <br />

                                {this.props.bundle.priceList && this.props.bundle.priceList.length > 0 && this.props.bundle.priceList[0].days ? (
                                    <div style={{marginRight: '8px', marginTop: '2px', backgroundColor: '#efebff', border: '1px solid #d2caf5', padding: '2px 8px', borderRadius: '12.25px', lineHeight: '0'}}>
                                        <Typography variant="caption" style={{lineHeight: 'normal'}}>valid {this.props.bundle.priceList[0].days} day{this.props.bundle.priceList[0].days === 1?"":"s"}</Typography>
                                    </div>
                                ) : null }

                                <div style={{marginTop: '2px', backgroundColor: '#efebff', border: '1px solid #d2caf5', padding: '2px 8px', borderRadius: '12.25px', lineHeight: '0'}}>
                                    {this.props.bundle.liveSchedule ? (
                                        <Typography variant="caption" style={{lineHeight: 'normal'}}>Live Course</Typography>
                                    ) : (
                                        <Typography variant="caption" style={{lineHeight: 'normal'}}>{this.props.bundle.contents.length} item{this.props.bundle.contents.length === 1?"":"s"}</Typography>
                                    )}
                                </div>
                            </div>
                            <div style={{display: 'flex', marginTop: '10px'}}>
                                <Typography variant="body2" style={{lineHeight: 'normal', fontSize: '0.75em', height: this.props.alwaysOpen ? '28.8px' : '', maxHeight: '28.8px', overflow: 'hidden'}}>
                                    {this.props.bundle.description && this.props.bundle.description.trim().length ? this.props.bundle.liveSchedule ? <div dangerouslySetInnerHTML={{__html: this.props.bundle.description || ''}} /> : this.props.bundle.description : 'Description not set'}
                                    
                                </Typography>
                            </div>
                            
                        </div>
                        </>
                    {/* <div style={{padding: '16px', marginTop: '0px', display: 'flex', justifyContent: 'center', borderTop: '1px solid #dbdbdb'}}>
                        <RoundedButton style={{marginLeft: 'auto', marginRight: '16px'}} color="secondary" variant="outlined" onClick={() => this.props.changeWorkspace('premiumaddbundle',this.props.bundle.name, this.props.bundle)}>Edit Content</RoundedButton> 
                        <RoundedButton style={{marginRight: 'auto'}} color="secondary" variant="outlined"> <Icon>videocam</Icon> Video</RoundedButton> 
                        {
                            this.hasLivestream() ? (
                                <RoundedButton style={{margin: '0 4'}} color="secondary" variant="contained" onClick={() => this.props.changeWorkspace('bundlecontents',this.props.bundle.name, {...this.props.bundle, action:'livestream'})} ><Icon>videocam</Icon>&nbsp; Livestream</RoundedButton>
                            ) : null
                        }
                        <Button style={{position: 'absolute', right: '8px', minWidth: '0'}} color="secondary" onClick={event => this.setState({ settingsAnchorEl: event.currentTarget})}><Icon>settings</Icon></Button>
                        <Badge color="secondary" style={{marginLeft: 4}}><RoundedButton color="secondary" variant="outlined" onClick={event => this.setState({ settingsAnchorEl: event.currentTarget})}><Icon>settings</Icon>&nbsp;Settings</RoundedButton></Badge>
                    </div> */}
                </CardContent>
            </Card>

                <StyledDrawer anchorEl={this.openDrawerIconRef.current} alwaysOpen={this.props.alwaysOpen} open={this.state.editContentDialogOpen} anchor='bottom' title='Select an Item' onClose={() => this.setState({editContentDialogOpen: false, cursor: 'pointer'})}>
                    {this.getListItems()}
                </StyledDrawer>

                <WsBundleAudience renderAsDialog context={this.props.bundle} open={this.state.bundleAudienceOpen} onClose={() => this.setState({bundleAudienceOpen: false}) || this.props.reload()}/>
            </>
        )        
    }
}

export default withTheme(BundleItem);