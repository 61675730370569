import { ButtonBase, Card, Icon, Typography } from '@material-ui/core';
import { makeStyles, withTheme } from '@material-ui/styles';
import React from 'react';

const useStyles = makeStyles(theme => ({
    root: {
        margin: theme.spacing(1),
        borderRadius: theme.spacing(1.25),
        display: 'flex',
        padding: theme.spacing(2.5, 2.25, 3),
        boxShadow: 'none',
    },
    image: {
        height: theme.spacing(14),
        width: theme.spacing(14),
        borderRadius: '50%',
    },
    editIconContainer: {
        height: theme.spacing(4.75),
        width: theme.spacing(4.75),
        position: 'absolute',
        marginTop: theme.spacing(-4.75),
        marginLeft: theme.spacing(9.25),
        border: `${theme.spacing(0.375)}px solid ${theme.palette.background.paper}`,
        background: theme.palette.secondary.main,
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxSizing: 'border-box',
    },
    editIcon: {
        // background: theme.palette.secondary.main,
        // borderRadius: '50%',
        color: theme.palette.secondary.contrastText,
        height: theme.spacing(2),
        width: theme.spacing(2),
        fontSize: theme.spacing(2),
    },
    container: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        marginLeft: theme.spacing(3.375),
    },
    title: {
        fontWeight: 'bold',
        fontSize: theme.spacing(2.25),
        marginBottom: theme.spacing(1),
    }
}))

const BannerUploadCard = (props) => {
    const classes = useStyles();
    return (
        <Card className={classes.root}>
            <div>
                <img src={props.bannerUrl} className={classes.image} alt='BannerImage' />
                <ButtonBase
                    className={classes.editIconContainer}
                    onClick={() => props.bannerInputRef.current.click()}
                >
                    <Icon className={classes.editIcon}>edit_outlined</Icon>
                </ButtonBase>
                <input multiple onChange={props.handleSubmit} id="files" style={{display: 'none'}} type="file" ref={props.bannerInputRef}/>
            </div>
            <div className={classes.container}>
                <Typography className={classes.title} variant='h6'>Display Picture</Typography>
                <Typography variant='body2'>Upload a nice picture for your audience</Typography>
            </div>
        </Card>
    )
}

export default withTheme(BannerUploadCard)