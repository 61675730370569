import React from 'react';
import Theme from '../../Components/theme';
import WsHeader from '../../Components/wsheader';
import WsFooter from '../../Components/wsfooter';
import MainCTA from '../../Components/MainCTA';
import RoundedButton from '../../Components/roundedbutton';
import FileUploadButton from '../../Components/fileuploadbutton';
import OnboardingHelper from '../../Components/onboardinghelper';
import md5 from 'md5';
import { LinearProgress, CircularProgress, Card, Typography, Button, Icon, IconButton, Dialog, DialogContent, DialogTitle, DialogActions, CardContent } from '@material-ui/core';
import ContentItem from './contentitem';
import Illustration from '../../Components/illustration';

const Servercalls = require('../../Utils/Servercalls');

export default class WsBundleContents extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            contents: [],
            loading: true,
            bundle: this.props.context,
            action: this.props.context.action,
        }
    }

    componentDidMount() {
        this.reload();
    }

    reload() {
        const self = this;
        Servercalls.pluginpremier_getBundleContents(this.props.context.pluginId, this.props.context.itemId).then(response => {
            if( this.state.bundle.ordering === 'antichronological')
                self.setState({ contents: response.data.reverse()});
            else{
                self.setState({ contents: response.data });
            }
            if(self.state.action) {
                if(self.state.action === "livestream") {
                    for(let i = 0 ; i < response.data.length; i += 1){
                        const content = response.data[i];
                        if(content.type === 'livestream'){
                            self.openContent("contentlivestream", content);
                        }
                    }
                }
                self.setState({ action: null });
                self.props.changeParentContext("action", null);
                
            }

            self.setState({ contents: response.data, loading: false });
        }).catch(e => self.setState({loading: false}));

    }

    openContent(workspace, content) {
        let ws = workspace;
        let o = 'contentdownloadable';
        ws = "contentdownloadable";  
                
        if(content.type === 'blog'){
            o = "contentblog";
            ws = "contentblog"
        }
        if(content.type === 'videolist'){
            o = "contentvideolist";
            ws = "contentvideolist"
        }
        else if(content.type === 'quiz'){
            ws = 'contentquiz';
        }
        else if(content.type === 'videostream'){
            ws = 'contentvideostream';
        }
        else if(content.type === 'zoom'){
            ws = 'contentzoom';
        }
        else if(content.type === 'image'){
            ws = 'contentimage';
        }

        else if(content.fileUrl){     
            if(content.type === "video" || content.fileUrl.toLowerCase().trim().endsWith(".mov") || content.fileUrl.toLowerCase().trim().endsWith(".mp4") || content.fileUrl.toLowerCase().trim().endsWith(".webm")){
                ws = "contentvideo"
                o = "contentvideo"
            }
            if(content.fileUrl.endsWith(".mp3") || content.fileUrl.endsWith(".ogg")){
                ws = "contentaudio"
                o = "contentaudio"
            }
            if(content.fileUrl.endsWith(".pdf")){
                ws = "contentpdf"
                o = "contenpdf"
            }
            if(content.type === 'blog'){
                ws = "contentblog"
                o = "contentblog"
            }
        }
        const finalWorkspace = workspace === 'contentedit' ? ws : workspace
        //this.props.pushStack(content.name, o, finalWorkspace, finalWorkspace, content);
        this.props.changeWorkspace(finalWorkspace, content.name, content);
    }


    getContentButton(content, index){
        let workspace = 'contentdownloable';
        if(content.type === 'blog'){
            workspace = 'contentblog'
        }
        else if(content.type === 'embed'){
            workspace = "contentembed";
        }
        else if(content.type === 'videolist'){
            workspace = "contentvideolist";
        }
        else if(content.type === 'quiz'){
            workspace = 'contentquiz';
        }
        else if(content.type === 'livestream'){
            workspace = 'contentlivestream';
        }
        else if(content.type === 'zoom'){
            workspace = 'contentzoom';
        }
        else if(content.type === 'image'){
            workspace = 'contentimage';
        }


        else if( content.type === "video" || content.fileUrl.toLowerCase().endsWith('.mov') || content.fileUrl.toLowerCase().endsWith('.mp4') || content.fileUrl.toLowerCase().endsWith('.webm')) workspace = 'contentvideo';
        else if( content.fileUrl.endsWith('.mp3') || content.fileUrl.endsWith('.ogg')) workspace = 'contentaudio';
        else if( content.fileUrl.endsWith('.pdf')) workspace = 'contentpdf';

        return <Button className={`content_open_${index}`} onClick={() => this.props.changeWorkspace(workspace, content.name, content )} color="primary" variant="contained" style={{marginTop:16, width: '100%'}}>OPen</Button>
    }

    editNameAndBanner() {
        this.props.changeWorkspace('bundlename','Collection Name & Banner',this.props.context);
    }

    editSalesPage() {
        this.props.changeWorkspace('bundlesalespage','Sales Banner',this.props.context);
    }

    editPrice() {
        this.props.changeWorkspace('bundleprice', 'Price of Collection', this.props.context );
    }

    getVisibilityText() {
        const bundle = this.props.context;
        if(!bundle.published) return "This collection is not visible to public"
        if(!bundle.audience || bundle.audience.trim().length === 0) return "This collection is available to everyone"
        let audience = bundle.audience.split(',');
        audience = audience.filter(a => a.trim().length > 0);
        if(audience.length === 1) return `This collection is visible only to ${audience[0]}`;
        if(audience.length === 2) return `This collection is visible only to ${audience[0]} and one other`
        return `This collection is visible only to ${audience[0]} and ${audience.length - 1} others`;
    }

    addContent(type, name){
        this.props.changeWorkspace(`add${type}content`, `Add ${name}`, this.props.context);
    }

    getAddContentTable(options ) {
        const rows = []
        let row = [];
        for( let i = 0 ; i < options.length; i += 1 ){
            if(i % 2 === 0){
                rows.push(row);
                row = [];
            }
            row.push(options[i])
        }
        if(row.length > 0)
            rows.push(row);
        return (
            <table>
                {rows.map(row => (
                    <tr>
                        {row.map(option => (
                            <td className={`bundle_addcontent_${option.type}`} onClick={() => {this.addContent(option.type, option.text); this.setState({ selected: option.workspace})}} style={{ padding: 8, width:'33%',cursor: 'pointer'}}>
                                <center>
                                    <Icon color="inherit">{option.icon}</Icon>
                                    <Typography color="inherit" variant="body2">{option.text}</Typography>
                                </center>
                            </td>
                        
                        ))}
                    </tr>
                ))}
            </table>
        )
    }

    async move(contentId, direction) {
        this.setState({ updatingPositions: true });
        const newContents = await Servercalls.pluginpremier_moveContentInBundle(this.props.context.pluginId, this.props.context.itemId, contentId, direction);
        this.setState({ updatingPositions: false, contents: newContents.data });
    }

    render() {
        const bundle = this.props.context;
        if(this.state.loading)
            return (
                <LinearProgress fullWidth />
            )
        return(
            <Theme>
                <WsHeader 
                    title={this.props.context.name}
                    options={[
                        { icon: 'add', text: 'Add content', onClick: () => this.setState({ addContentOpen: true })},
                        { icon: 'title', text: 'Edit Name', onClick: () => this.editNameAndBanner()},
                        { icon: 'crop_free', text: 'Edit Display Banner', onClick: () => this.editNameAndBanner()},
                        { icon: 'assignment', text: 'Edit Sales Banner', onClick: () => this.editSalesPage()},
                        { icon: 'remove_red_eye', text: "Edit who can see this", onClick: () => this.props.changeWorkspace('bundleaudience','Visibility', this.props.context)},
                        { icon: 'arrow_upward', text: "Position and Importance", onClick: () => this.props.changeWorkspace('bundleposition','Importance', this.props.context)}
                    ]}
                    back={this.props.back}
                />
                {
                    this.state.contents.length === 0 ? (
                        <Card style={{ margin: 12 }}>
                            <CardContent>
                                <Typography variant="h6">Let's go! Add content to this collection</Typography>
                                <Typography variant="body1" style={{ marginTop: 12 }}>You can add livestream, videos, quizzes and more! Create your course or your exclusive content collection.</Typography>
                                <Illustration image="filesync" style={{width: '100%'}} />
                            </CardContent>
                        </Card>
                    )
                    :<div>
                            {this.state.contents.filter(c => c.published).map((content, index)=>(
                                <ContentItem content={content} openContent={(workspace) => this.openContent(workspace,content)} move={(direction) => this.move(content.contentId, direction)}/>
                            ))}


                            {this.state.contents.filter(c => !c.published).length > 0? <Typography style={{margin: 16 }} variant="h6">Unpublished</Typography>: null}
                            {this.state.contents.filter(c => !c.published).map((content, index)=>(
                                <ContentItem content={content} openContent={(workspace) => this.openContent(workspace,content)} move={(direction) => this.move(content.contentId, direction)}/>
                            ))}

                            {
                                this.state.contents.filter(c => c.free).length === 0? <Card style={{ margin: 12 }}>
                                <CardContent>
                                    <Typography variant="h6">People love freebies!</Typography>
                                    <Typography variant="body1" style={{ marginTop: 12 }}>All your content can be accessed only if people pay. Give a sneak peek with a sample video or trailer. That way your users can make an informed decision on whether they want to purchase it. Creating a course? Give away few chapters for free. Selling a movie? Give the trailer for free. You get the point.</Typography>
                                    <Illustration image="birthday" style={{width: '100%'}} />
                                </CardContent>
                            </Card> : null
                            }

                            {
                                this.state.contents.filter(c => !c.free).length === 0? <Card style={{ margin: 12 }}>
                                <CardContent>
                                    <Typography variant="h6">What's the point?</Typography>
                                    <Typography variant="body1" style={{ marginTop: 12 }}>All your content can be accessed without paying. Add content that can be accessed only after paying. Else why will anyone pay, right?</Typography>
                                    <Illustration image="transfermoney" style={{width: '100%'}} />
                                </CardContent>
                            </Card> : (
                                
                                !this.props.context.priceList || this.props.context.priceList.length === 0? <Card style={{ margin: 12}}><CardContent>
                                <Typography variant="h6">You haven't set a price yet</Typography>
                                <Typography variant="body1" style={{ marginTop: 12 }}>Set a price for buying access to the content in this collection</Typography>
                                <RoundedButton variant="contained" color="primary" onClick={()=> this.props.changeWorkspace('bundleprice', 'bundleprice', this.props.context)} style={{marginTop: 12}}>Add a price</RoundedButton>
                                <Illustration image="vault" style={{width: '100%', marginTop: 12}} />
                            </CardContent></Card> : null
                            )
                            }


                    </div>
                }

                <Dialog open={this.state.addContentOpen}>
                        <DialogTitle>What kind of content do you want to add </DialogTitle>
                        <DialogContent>
                            {
                                this.getAddContentTable([
                                    {icon: <img src="https://seeklogo.com/images/V/vimeo-icon-logo-441934AEB1-seeklogo.com.png" style={{ height: 20 }} />, text: 'Vimeo Video', type: 'vimeo'},
                                    {icon: <img src="https://seeklogo.com/images/Z/zoom-icon-logo-C552F99BAC-seeklogo.com.png" style={{ height: 20 }} />, text: 'Zoom Webinar', type: 'zoom'},
                                    {icon: 'videocam', text: 'Video', type: 'video'},
                                    {icon: 'videocam', text: 'Live Video', type: 'livestream'},
                                    //{icon: 'video_library', text: 'Video List', type: 'videolist'},
                                    {icon: 'add_a_photo', text: 'Image', type: 'image'},
                                    {icon: 'headset', text: 'Audio', type: 'audio'},
                                    {icon: 'archive', text: 'Downloadable', type: 'downloadable'},
                                    {icon: 'format_align_left', text: 'Blog', type: 'blog'},
                                    {icon: 'link', text: 'Embed iFrame', type: 'embed'},
                                    {icon: 'help', text: 'Quiz', type: 'quiz'},
                                ])
                            }
                        </DialogContent>
                        <DialogActions>
                            <Button color="primary" onClick={() => this.setState({ addContentOpen: false })}>Close</Button>
                        </DialogActions>
                </Dialog>

                <WsFooter 
                    workspace="bundlecontents"
                    text="This is your collection. Your users can buy a collection. You can add contents and set the price of the collection here."
                />
                <MainCTA 
                    className="bundle_addcontent" 
                    icon="add" 
                    text="Add content" 
                    onCTAClick={() => this.setState({ addContentOpen: true })}  
                    help={[]}
                    settings={[]}
                />
            </Theme>
        )
    }
}