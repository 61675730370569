/* eslint-disable */
import React from 'react';
import { withTheme } from '@material-ui/styles';
import { Card, Icon, Typography, IconButton, LinearProgress, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, CircularProgress } from '@material-ui/core';

const { detect } = require('detect-browser');
const browser = detect();

const Chat = require('twilio-chat');
const Servercalls = require('../../Utils/Servercalls');

class Chatroom extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            message: "",
            messages: [],
            loading: true,
            failed: false,
            inputStatic: false,
            errorMessage : null
        }
        this.chat = {};
    }

    componentDidMount() {
        this.setState({ loading: true });
        const self = this;
        Servercalls.pluginchat_getChatToken(this.props.chatroomId, this.props.userId).then(async (response) => {
            self.chat.client = new Chat.Client(response.data);
            self.chat.client.initialize().then(init => {
                self.findRoom();
            }).catch(e => {
                self.setState({ errorMessage: "Unable to join chat. Please try again", loading: false});
                self.props.onFinishLoading(self.systemMessage, self.chat);
            });
        }).catch(e => {
            self.setState({ errorMessage : "Your session expired. Please logout and login again."});
        });
    }

    async componentWillUnmount() {
        try{
            if(this.chat.channel)
              await this.chat.channel.leave();
            //if(this.props.isAdmin)
            //    this.chat.channel.delete();
        }
        catch(e){

        }
        
    }

    findRoom() {
        const self = this;
        self.chat.client.getChannelByUniqueName(this.props.chatroomId).then(channel => {
            self.joinRoom(channel);
        }).catch(e => {
            self.chat.client.createChannel({
                uniqueName: self.props.chatroomId,
                friendlyName: self.props.chatroomId,
                isPrivate: false,
            }).then(createdChannel => {
                self.joinRoom(createdChannel);
            });
        })
    }

    async joinRoom(channel) {
        try {
            await channel.leave();
        }
        catch(e) {

        }
        const self =this;
        channel.join().then(() => {
            self.setState({ joined: true, loading: false }, () => self.props.onFinishLoading(self.systemMessage, self.chat));
            self.chat.channel = channel;
            channel.getMessages(50).then(messages => {
                for(let i = 0 ; i < messages.items.length; i += 1) {
                    self.addRemoteMessage(messages.items[i], false);
                }
            });
            channel.on('messageAdded', message => {
                self.addRemoteMessage(message, true);
            })
        }).catch(e => {
            self.setState({ joined: false, failed: true, loading: false }, () => self.props.onFinishLoading(self.systemMessage, self.chat));
            self.setTimeout(() => {
                self.setState({ failed: false });
                self.props.onFailed();
            }, 3000);
        })
    }

    addRemoteMessage(message, isNew) {
        if (message.attributes && message.attributes.systemMessage) return;
        if(isNew && this.props.onNewMessage)
            this.props.onNewMessage();
        this.addMessage(message.author, message.attributes.senderName, message.body, message.attributes.senderPicture);
    }


    systemMessage(message, chat) {
        const self = this;
        console.log(message)
        if(chat && chat.channel){
            console.log(message)
            chat.channel.sendMessage(message, { systemMessage: 'livestream' });
        }
    }


    sendMessage() {
        if(this.props.onNewMessage)
            this.props.onNewMessage();
        const { userId, userName, userPicture }  = this.props;
        //todo
        if(this.chat.channel){
            this.chat.channel.sendMessage(this.state.message, { senderName: userName, senderPicture: userPicture });
            if(this.props.onMessageSent)
                this.props.onMessageSent(this.state.message);
            this.setState({ message: "" });
        }
        

    }

    addMessage(senderUserId, senderName, message, picture) {
        const self = this;
        this.setState({ loading: false, joined: true }, () => self.props.onFinishLoading(self.systemMessage, self.chat));
        let messages = this.state.messages;
        messages.push({ senderUserId, senderName, message, picture});
        messages = messages.slice(messages.length - 50 ,messages.length);
        this.setState({ messages }, () => {
            setTimeout(() => {
                const messagesEle = document.getElementById("messages");
                messagesEle.scrollTop = messagesEle.scrollHeight;
            }, 100);
        });
    }

    mute(userId) {
        const self = this;
        self.setState({ blocking: true });
        Servercalls.updateUserStatus(userId, "mute").then(() => {
            self.setState({ blocked: true });
            setTimeout(() => self.setState({ blocked: false, blocking: false, openMessage: null }), 2000);
        }).catch(() => self.setState({ blocking: false }));
    }

    render() {
        const { theme } = this.props;
        return (
            <div style={{...this.props.style}}>
                <div style={{ height: '100%', width: '100%', position: 'relative'}}>
                    
                    <div onClick={() => this.setState({ inputStatic: false })} id="messages" style={{height:'calc(100% - 48px)', width: '100%', position: 'absolute', top: 0, left: 0, overflowY: 'scroll'}}>
                        {
                            this.state.messages.map(m => (
                                <div onClick={() => this.setState({ openMessage: m})} style={{ width: `calc(100% - 16}px`, margin: 8, clear: 'both'}}>
                                    <img src={m.picture} style={{height: 30, width: 30, borderRadius: 15, float: 'left', marginRight: 8}} />
                                    <div style={{ width: 'calc(100% - 38px)', paddingLeft: 8 }}>
                                        <Typography variant="body2" style={{color: '#fff', textShadow: '1px 1px #555', ...this.props.usernameStyle}}><b>{m.senderName}</b></Typography>
                                        <Typography variant="body2" style={{color: '#fff', textShadow: '1px 1px #555', ...this.props.messageStyle}}><i>{m.message}</i></Typography>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    <Card  square style={{height:48, position: 'absolute', width: '100%', bottom: 0, left: 0, backgroundColor: "#fff", color: "#000"}}>
                        {this.state.loading? <LinearProgress fullWidth /> : null}
                        <input disabled={(browser.os === 'Android OS' || browser.os === 'android' || browser.os === 'iOS')} onKeyUp={key => { if(key.keyCode === 13) this.sendMessage() }} onClick={() => this.setState({ inputStatic: true })}  disabled={this.state.loading} value={this.state.message} border={0} placeholder="Type a message..." style={{ border: 0, marginLeft: 4, height: 40, marginTop: 4, width: 'calc(100% - 70px)'}} onChange={e => this.setState({ message: e.target.value})}/>
                        <IconButton disabled={this.state.loading} onClick={() => this.sendMessage()} disabled={this.state.message.length===0}><Icon style={{color: "#000"}}>send</Icon></IconButton>
                    </Card>
                </div>
                <Dialog
                    open={this.state.failed}
                >
                    <DialogTitle>Chatroom is full</DialogTitle>
                    <DialogContent>Please wait for someone to leave and try again in some time</DialogContent>
                    <DialogActions>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.inputStatic && (browser.os === 'Android OS' || browser.os === 'android' || browser.os === 'iOS')}
                    fullScreen
                >
                    <DialogTitle>Send message</DialogTitle>
                    <DialogContent>
                        <TextField autoFocus fullWidth onChange={event => this.setState({ message: event.target.value })} value={this.state.message} />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.setState({ inputStatic: false, message: "" })} color="primary">Cancel</Button>
                        <Button onClick={() => {this.sendMessage(); this.setState({ inputStatic: false })}} color="primary" variant="contained"><Icon>send</Icon>Send</Button>
                    </DialogActions>
                </Dialog>

                {this.state.openMessage?<Dialog
                    open={this.state.openMessage}
                >
                    <DialogTitle>{this.state.openMessage.senderName}</DialogTitle>
                    <DialogContent>
                        <center><img src={this.state.openMessage.picture} style={{ height: 100, width: 100, borderRadius: 50}} /></center>
                        <br />
                        {this.state.openMessage.message}
                    </DialogContent>
                    <DialogActions>
                        <Button color="secondary" onClick={() => this.setState({ openMessage: null, blocked: false, blocking: false })}>Close</Button>
                        <Button disabled={this.state.blocking} color="secondary" variant="contained" onClick={() => this.mute(this.state.openMessage.senderUserId)}>{!this.state.blocked?`mute ${this.state.openMessage.senderName} for 24 hours`:'Blocked'}</Button>

                    </DialogActions>
                </Dialog>:null}

                <Dialog
                    open={this.state.errorMessage}
                >
                    <DialogTitle>Error in initializing chat</DialogTitle>
                    <DialogContent>
                        {this.state.errorMessage}. You can close the chat and re-open to try again.
                    </DialogContent>
                    <DialogActions>
                        <Button color="secondary" onClick={() => this.setState({ errorMessage: null }) || this.props.onBack()}>Dismiss</Button>
                    </DialogActions>
                </Dialog>


            </div>
        );
    }
}

export default withTheme(Chatroom);