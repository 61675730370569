import React from 'react';
import Theme from '../../Components/theme';
import WsHeader from '../../Components/wsheader';
import WsFooter from '../../Components/wsfooter';
import MainCTA from '../../Components/MainCTA';
import RoundedButton from '../../Components/roundedbutton';
import Illustration from '../../Components/illustration';
import { Typography, TextField, Checkbox, FormControlLabel, Button, Icon, Card, CardContent, CardActionArea, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core';

import { currencies } from '../../Utils/Constants';
import { withTheme } from '@material-ui/styles';
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

const Servercalls = require('../../Utils/Servercalls');

class WsBundlePrice extends React.Component {

    constructor(props){
        super(props);
        const bundle = this.props.context;
        this.state = {
            expiry: bundle.expiry,
            currency: bundle.currency,
            price: bundle.price,
            hasExpiry: bundle.expiry && bundle.expiry > 0,
            priceList : bundle.priceList || [],
        }
    }

    save() {
        const self = this;
        const bundle = this.props.context;
        self.setState({ saving: true });
        console.log(this.state.priceList);
        Servercalls.pluginpremier_updateBundle(bundle.pluginId, bundle.itemId,"priceList", this.state.priceList ).then(response => {
            self.props.changeParentContext('priceList', self.state.priceList);
            self.setState({ saving: false, saved: true });
            setTimeout(() => {
                self.props.back();
                self.setState({ saved: false });
            }, 3000);
        }).catch(e =>{ alert(e); self.setState({ saving: false })});
    }

    render() {
        if(this.state.saved) return (
            <center style={{ margin: 64}}>
                <Icon style={{ fontSize: 120}} color="secondary">check</Icon>
                <br /><br />
                <Typography variant="body2">Price has been saved. You'll be redirected shortly</Typography>
            </center>
        )
        return (
            <Theme>
                <WsHeader back={this.props.back} title="Set collection price" description='Your customers will have to pay to view the paid content in this collection.' helpText="What is this price for?" helpVideo="a" />
                {!this.state.priceList || this.state.priceList.length === 0? (
                    <Card style={{margin:12}}>
                        <CardContent>
                            <Typography variant="h6">Set a price for your work!</Typography>
                            <Typography variant="body2" style={{marginTop: 12}}>When in doubt, double the price</Typography>
                            <Illustration image="cc" style={{width: '100%', marginTop: 12}}/>
                        </CardContent>
                        <CardActionArea style={{padding: 12}}>
                            <RoundedButton variant="contained" className="bundle_price_add" onClick={() => this.setState({ priceList: [ ...this.state.priceList, { currency: 'INR', price: 0, strikePrice: 0, subscriptionId: `subscription-price-${Date.now()+Math.random()}`, description: "Access Pass", days: 30}]})} color="secondary" >Add a pricing option</RoundedButton>
                        </CardActionArea>
                    </Card>
                )
                :<div style={{ margin: 12 }}>
                    
                    
                    {this.state.priceList.map((p, i) => (
                        <Card style={{ width: '100%', marginTop: 8}}>
                            <CardContent>
                                <TextField
                                    select
                                    label="Currency"
                                    value={p.currency}
                                    SelectProps={{
                                        native: true,
                                    }}
                                    onChange={event => { const priceList = this.state.priceList; priceList[i].currency = event.target.value; this.setState({ priceList })}}
                                    margin="normal"
                                    style={{ marginRight: 8 }}
                                    >
                                    {currencies.map(option => (
                                        <option key={option.value} value={option.value}>
                                        {option.label}
                                        </option>
                                    ))}
                                </TextField>                                    
                                <TextField style={{marginTop: 12}} label="Offer Price" fullWidth value={p.price} type='number' onChange={event => { const priceList = this.state.priceList; priceList[i].price = event.target.value; this.setState({ priceList })}}/>
                                <TextField style={{marginTop: 12, marginBottom: 24}} label="Validity in days" fullWidth value={p.days} type='number' onChange={event => { const priceList = this.state.priceList; priceList[i].days = event.target.value; this.setState({ priceList })}}/>
                                <ExpansionPanel onChange={(event, isExpanded) => this.setState({ [`expanded-${i}`] : isExpanded })} expanded={ this.state[`expanded-${i}`]}>
                                    <ExpansionPanelSummary
                                        expandIcon={<Icon>expand_more</Icon>}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                    >
                                        <Typography style={{fontSize: this.props.theme.typography.pxToRem(15), flexBasis: '33.3%', flexShring: 0}}>Advanced Options</Typography>
                                        <Typography style={{color: this.props.theme.palette.text.secondary}}>Offer validity, tips & more payment options</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        <div>
                                        <Typography variant="h6">Subscription Details</Typography>                                            
                                        <TextField error={p.description.length === 0} helperText="Explain the subscription details in a few words" style={{marginTop: 12}} label="Description" fullWidth value={p.description} onChange={event => { const priceList = this.state.priceList; priceList[i].description = event.target.value; this.setState({ priceList })}} />

                                        <Typography variant="h6" style={{marginTop: 24}}>Offer</Typography>
                                        <Typography variant="body1" style={{color: this.props.theme.palette.text.secondary}}>The offer price will be replaced by the actual price when the offer ends</Typography>
                                        <TextField style={{marginTop: 12}} label="Actual Price" fullWidth value={p.strikePrice} type='number' onChange={event => { const priceList = this.state.priceList; priceList[i].strikePrice = event.target.value; this.setState({ priceList })}} helperText="Leave as 0 if you don't want to show a striked off price"/>
                                        <MuiPickersUtilsProvider style={{ marginTop: 12}} utils={MomentUtils}><DateTimePicker label="Offer expiry" value={p.offerEnd} onChange={m => {const priceList = this.state.priceList; priceList[i].offerEnd = m.format("YYYY-MM-DDTHH:mm") ; this.setState({ priceList })}} /></MuiPickersUtilsProvider>
                                     
                                        <Typography variant="h6" style={{marginTop: 24}}>Donations</Typography>
                                        <FormControlLabel
                                            style={{ marginTop: 12 }}
                                            control={
                                            <Checkbox
                                                checked={p.payAsYouWish}
                                                onChange={(event) => {const priceList = this.state.priceList; priceList[i].payAsYouWish = event.target.checked; this.setState({ priceList })}} 
                                                name="checkedB"
                                                color="primary"
                                            />
                                            }
                                            label="Enable pay as you wish"
                                        />
                                        <Typography variant="h6" style={{marginTop: 24}}>Cash Back</Typography>
                                        <TextField style={{marginTop: 12}} label={`Cashback in ${p.currency}`} fullWidth value={p.cashback} type='number' onChange={event => { const priceList = this.state.priceList; priceList[i].cashback = event.target.value; this.setState({ priceList })}} helperText="Will be awarded to user as credits upon purchase"/>
                                        </div>

                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                            </CardContent>   
                            <CardActionArea style={{ padding: 12 }} >
                                <RoundedButton variant="outlined" onClick={() => { const priceList = this.state.priceList.filter(p1 => p1.subscriptionId != p.subscriptionId); this.setState({ priceList }) }} color="secondary">DELETE</RoundedButton>
                            </CardActionArea>
                        </Card>
                    ))}
                    <RoundedButton variant="contained" className="bundle_price_add" onClick={() => this.setState({ priceList: [ ...this.state.priceList, { currency: 'INR', price: 0, strikePrice: 0, subscriptionId: `subscription-price-${Date.now()+Math.random()}`, description: "Access Pass", days: 30, cashback: 0}]})} color="secondary" style={{marginTop: 12 }}>{this.state.priceList.length > 0? "Add Another Pricing Option": "Add pricing option"}</RoundedButton>
                                                         
                </div>}
                <WsFooter text="Tip: Most people underprice. When in doubt, double the price." />
                <MainCTA 
                    disabled={this.state.saving} 
                    onCTAClick={() => this.save()} 
                    icon="save" 
                    text="save pricing"
                    settings={[]}
                    help={[
                        {title:"Can I add multiple pricing?", text: "You can add multiple pricing options. You can use this to give discounts for buying longer subscriptions. 3 months for Rs. 5000, 1 year for Rs. 15,000" },
                        { title: "What is Validity?", text: "This tells for how many days will the subscription be valid from the date of purchase. If validity is 30 days, and the user buys the subscription today, it will be invalid on the 31 day from today. On the 31st day, the user must purchase the subscription again."},
                        {title: "What is Description?", text: "The description tells the user more information about the subscription. What are the advantages of picking this subscription over the others."}
                    ]}
                />
            </Theme>
        )
    }
}

export default withTheme(WsBundlePrice);