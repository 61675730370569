import React from 'react';
import Theme from '../Components/theme';
import WsHeader from '../Components/wsheader';
import WsFooter from '../Components/wsfooter';
import FileUploadButton from '../Components/fileuploadbutton';
import { Button, Icon, Typography, Card,CardContent } from '@material-ui/core';
import { FilePicker } from 'react-file-picker';
import RoundedButton from '../Components/roundedbutton';
import MainCTA from '../Components/MainCTA';

const Servercalls = require('../Utils/Servercalls');

export default class WsTabChangeBanner extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            images: this.props.context.images || [],
            saving: false,
            samples: []
        }
    }

    componentDidMount(){
        const self = this;
        Servercalls.getPluginBannerSamples(this.props.context.pluginId).then(response => {
            self.setState({ samples: response.data });
        })
    }

    deleteImage(url) {
        this.setState({ images: []})
    }

    save() {
        const plugin = this.props.context;
        const self = this;
        self.setState({ saving: true });
        Servercalls.updatePlugin(plugin.pluginId, plugin.name, plugin.icon, this.state.images, plugin.title, plugin.weight, plugin.longDescription, plugin.tags).then(response => {
            self.setState({ saving: false, saved: true });
            self.props.changeParentContext({ ...self.props.context, images: this.state.images });
            setTimeout(() => self.props.back(), 3000);
        }).catch(e => {alert(e); self.setState({ saving: false})})
    }

    render(){ 
        if(this.state.saved) return (
            <center style={{ margin: 64}}>
                <Icon color="secondary" style={{fontSize: 120}}>check</Icon>
                <br /><br />
                <Typography variant="body1">Banner Images have been saved </Typography>
            </center>
        )
        return (
            <Theme>
                <WsHeader title="Tab Banner" description={`A banner image is a large poster shown to the user on the top when they open the tab '${this.props.context.name}'. You can add multiple images. The images will show up as a carousel.`} helpText="Why use a banner?" helpVideo="a" back={this.props.back}/>
                <Card style={{ margin: 12 }}>
                    <CardContent>
                        <div style={{ position: 'relative', width: '100%', height: 0, paddingTop: '33%', overflow: 'hidden'}}>
                            <img src={this.state.images[0]} style={{ width: '100%', position: 'absolute', top: 0}} />
                        </div>
                        <RoundedButton style={{marginTop: 12 }} variant="outlined" onClick={() => this.setState({ images: [] })} color="secondary"><Icon>delete</Icon> &nbsp; Delete Tab banner</RoundedButton>
                        
                        <FileUploadButton
                            extensions={['png','jpg','jpeg','webp']}
                            maxSizeMBs={2}
                            onUpload={ url => this.setState({ images: [ url]})}
                            onFail = {e => alert(e)}
                            icon="cloud_upload"
                            text="upload image"
                            color="secondary"
                            variant="contained"
                            style={{ marginTop: 12 }}
                        />
                        <Typography style={{marginBottom: 24, color: "#999"}} variant="body2">Dimensions: 1000x300 px</Typography>

                        {!this.state.showTemplates?<RoundedButton variant="outlined" color="secondary" onClick={() =>this.setState({ showTemplates: true})}>Use a ready to use tab banner</RoundedButton>: <div>
                        {this.state.samples.map(url => (
                                <img src={url} onClick={() => this.setState({ images: [url] })} style={{ height: 172, margin: 4, display: 'inline-block'}} />
                        ))}
                        </div>}

                    </CardContent>
                </Card>
                <WsFooter text="The tab banner is displayed on the top of the tab. Use it to give out the most important information relevant to this tab." />
                <MainCTA
                    icon="save"
                    text="SAVE"
                    onCTAClick={() => this.save()}
                    disabled={this.state.saving}
                    settings={[]}
                    help={[]}
                />
            </Theme>
        )
    }
}