import { Dialog, Icon, Typography } from '@material-ui/core';
import React from 'react';
import RoundedButton from './roundedbutton';

const ConfirmationDialog = (props) => {
    return (
        <Dialog PaperProps={{ style: {borderRadius: '12px', maxWidth: '276px'} }} open={props.open}>
            <div style={{padding: '32px 30px', display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center'}}>
                <Typography style={{fontWeight: 'bold'}} variant='body1'>You havent set selling price!</Typography>
                <Typography style={{color: '#999999', marginTop: '20px'}} variant='body2'>If you chose not to enter a selling price then the folder will be set as free</Typography>
                <div style={{display: 'flex', marginTop: '36px', width: '100%', justifyContent: 'center'}}>
                    <RoundedButton style={{whiteSpace: 'nowrap'}} color="secondary" variant="outlined" onClick={() => props.resolve(true)}>
                        Not Now
                    </RoundedButton>
                    <RoundedButton style={{whiteSpace: 'nowrap', marginLeft: '12px'}} color="secondary" variant="contained" onClick={() => props.resolve(false)}>
                        Set Now
                    </RoundedButton>
                </div>
            </div>
        </Dialog>
    )
}

export default ConfirmationDialog;