import React from 'react';
import {
  Icon, CircularProgress, Card, CardContent, Typography, CardActions, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails,
} from '@material-ui/core';
import { withTheme } from '@material-ui/styles';
import { Editor as TinyEditor } from '@tinymce/tinymce-react';
import md5 from 'md5';
import Chat from '../Components/Chat';

import PlusIcon from '../Assets/Icons/PlusSecondary.svg';
import RoundedButton from '../Components/roundedbutton';
import FileUploadButton from '../Components/fileuploadbutton';
import MainCTA from '../Components/MainCTA';
import ContainerHeader from '../Testing/UI/ContainerHeader';
import ChatGroupsList from '../Testing/UI/ChatGroupsList';
const Servercalls = require('../Utils/Servercalls');

const defaultManifesto = `
    <h1>The Manifesto </h1>
    <br />
    <h2>I believe in TODO:ENTER_YOUR_VISION_HERE</h2>
    <br />
    <p>Todo : In 2 - 3 sentences explain what you believe in and why you believe in what you believe in. Why are you building this community? What do you care about?</p>
    <br />
    <h2>Welcome TODO:GIVE_A_NAME_TO THE_COMMUNITY_MEMEBERS !</h2>
    <br />
    <p>Give a catchy name to the members of the group. Ex. supporters of the Arsenal Football club call themselves the Gunners.</p>
    <p>Todo: What defines the members of the community? Why are they all together here? ex. We're the fitness freaks. We are here to work out every single day. No excuses. Ever.</p>
    <br />
    <h2>Why you should join in</h2>
    <br />
    <p>When you join in you get access to this amazing community and a regular stream of goodies and gifts from brands I endorse</p>
    <br />
    <br />
    <i><center><h1>Join in now!</h1></center></i>
`;

const defaultRules = `
    <h1>Rules</h1>
`;

class ChatTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      manifesto: this.props.tab.info.manifesto || defaultManifesto,
      rules: this.props.tab.info.rules || defaultRules,
      chatBackground: this.props.tab.info.chatBackground || 'https://cdn.socionity.com/',
    };
  }

  componentDidMount() {
    const self = this;
  }


  async saveCommunity() {
    this.setState({ saving: true });
    await Servercalls.updatePluginInfo(this.props.tab.pluginId, { manifesto: this.state.manifesto, rules: this.state.rules, chatBackground: this.state.chatBackground });
    this.setState({ saving: false, saved: true });
    setTimeout(() => this.setState({ saved: false }), 3000);
  }

    renderGroups() {
        const self = this;
        return (
            <ChatGroupsList chatrooms={this.props.tab.info.chatrooms.map(chatroom => ({
                ...chatroom,
                icon: <img src={chatroom.chatroomLogoUrl} />,
                onClick: () => this.props.changeWorkspace('editchat', 'Edit Group', {chatroom, appId: this.props.tab.appId, pluginId: this.props.tab.pluginId}),
            }))} />
        )

        return (
            <div style={{display: 'flex', flexDirection: 'column', padding: '0'}}>
                
                <div style={this.props.context.alwaysOpen ? {display: 'flex', flexWrap: 'wrap'} : {}}>
                    {this.props.tab.info.chatrooms.map((chatroom, index)=>(
                        <>
                            <div style={{width: '100%', height: '1px', background: '#d9d9d9', marginBottom: '16px'}} />
                            {this.props.context.alwaysOpen ? (
                                <Card style={{
                                    display: 'flex',
                                    boxShadow: 'none',
                                    // border: 'solid 1px #dbdbdb',
                                    marginRight: '12px',
                                    width: 'calc(min(21.56vw, 276px))',
                                    minWidth: '276px',
                                    marginBottom: '12px',
                                    overflow: 'hidden',
                                    }}
                                >
                                    <img
                                        onContextMenu={(e) => e.preventDefault()}
                                        onClick={() => {
                                            this.props.changeWorkspace('editchat', 'Edit Group', {chatroom, appId: this.props.tab.appId, pluginId: this.props.tab.pluginId})
                                        }} 
                                        style={{
                                            borderRadius: '6px',
                                            zIndex: '10',
                                            height: '6vw',
                                            width: '6vw',
                                            maxHeight: '72px',
                                            maxWidth: '72px',
                                            cursor: 'pointer',
                                        }} src={chatroom.chatroomLogoUrl || `https://gravatar.com/avatar/${md5(chatroom.chatroomId)}?d=identicon`} />
                                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flex: '1', marginLeft: '12px', overflow: 'hidden'}}>
                                        <Typography 
                                            onClick={() => {
                                                this.props.changeWorkspace('editchat', 'Edit Group', {chatroom, appId: this.props.tab.appId, pluginId: this.props.tab.pluginId})
                                            }}
                                            variant='body1' style={{cursor: 'pointer', fontWeight: 'bold', maxHeight: '3em', overflow: 'hidden', textOverflow: 'ellipsis', wordBreak: 'break-all'}}>
                                                {chatroom.chatroomName}
                                        </Typography>
                                        <Typography variant='body2' style={{maxHeight: '2.86em', overflow: 'hidden', wordBreak: 'break-all', color: '#999990'}}>{chatroom.chatManifesto}</Typography>
                                    </div>
                                </Card>

                            ) : (
                                <Card style={{
                                        display: 'flex',
                                        boxShadow: 'none',
                                        // border: 'solid 1px #dbdbdb',
                                        marginBottom: '12px'
                                    }}
                                >
                                    <img
                                    onContextMenu={(e) => e.preventDefault()} 
                                    onClick={() => {
                                        this.props.changeWorkspace('editchat', 'Edit Group', {chatroom, appId: this.props.tab.appId, pluginId: this.props.tab.pluginId})
                                    }} 
                                    style={{
                                        borderRadius: '6px',
                                        zIndex: '10',
                                        height: '22.78vw',
                                        width: '22.78vw',
                                        maxHeight: '46px',
                                        maxWidth: '46px',
                                        cursor: 'pointer',
                                    }} src={chatroom.chatroomLogoUrl || `https://gravatar.com/avatar/${md5(chatroom.chatroomId)}?d=identicon`} />
                                    <div 
                                        onClick={() => this.props.changeWorkspace('editchat', 'Edit Group', {chatroom, appId: this.props.tab.appId, pluginId: this.props.tab.pluginId})}
                                        style={{cursor: 'pointer', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flex: '1', marginLeft: '12px'}}
                                    >
                                        <Typography variant='body1' style={{fontWeight: 'bold', maxHeight: '3em', overflow: 'hidden', wordBreak: 'break-all'}}>{chatroom.chatroomName}</Typography>
                                        <Typography variant='body2' style={{maxHeight: '2.86em', overflow: 'hidden', wordBreak: 'break-all', color: '#999990'}}>{chatroom.chatManifesto}</Typography>
                                    </div>
                                </Card>
                            )}
                            
                        </>
                    ))}
                    <div style={{width: '100%', height: '1px', background: '#d9d9d9'}}/>
                </div>
            </div>
        )
    }

    render(){

        if (!this.props.tab.info || !this.props.tab.info.chatrooms || this.props.tab.info.chatrooms.length === 0) 
            return (
                <center style={{ margin: 64}}>
                    <Typography variant="body2">This plugin has been discontinued, please add a new chat plugin from the new builder to continue using group chat.</Typography>
                </center>
            )


        if(this.state.saved) return (
            <center style={{ margin: 64}}>
                <Icon style={{ fontSize: 120}} color="secondary">check</Icon>
                <br /><br />
                <Typography variant="body2">Saved changes to the landing. You'll be redirected shortly</Typography>
            </center>
        )

        return (
            <>
                <Card style={{margin: '0', minHeight: '100%'}}>
                    <CardContent style={{padding: 0}}>
                        <ContainerHeader title='Your Groups' onClick={() => this.props.changeWorkspace('createchat', 'Create Group', {...this.props.context, pluginId: this.props.tab.pluginId, appId: this.props.tab.appId})}/>
                        {this.renderGroups()}
                    </CardContent>
                </Card>

            </>
        )
    }
}

export default withTheme(ChatTab);
