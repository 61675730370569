/* eslint-disable */
import React from 'react';
import {
  LinearProgress, Button, Icon, Typography, TextField, Dialog, DialogTitle, DialogContent, DialogActions, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, CircularProgress, ListItem, ListItemAvatar, Avatar, ListItemText, ListItemSecondaryAction, IconButton, List, TableHead, TableBody, TableRow, Card, CardContent, InputBase, Input,
} from '@material-ui/core';
import AgoraRTC from 'agora-rtc-sdk';
import moment from 'moment';
import AgoraRTM from 'agora-rtm-sdk';
import { withTheme } from '@material-ui/styles';
import Chat from '../Chat';
import { currencies } from '../../Utils/Constants';
import RoundedButton from '../roundedbutton';
import Illustration from '../illustration';

const { detect } = require('detect-browser');
const Servercalls = require('../../Utils/Servercalls');
const utils = require('../../Utils/utils');

const browser = detect();

class Livestream extends React.Component {
  constructor(props) {
    super(props);
    const { channel } = this.props;
    this.state = {
      loading: true,
      source: 'video',
      startingStream: false,
      errorMessage: null,
      wifiColor: '#999',
      wifiStatus: 'X',
      viewers: [],
      started: false,
      counter: null,
      cohost: null,
      startTime: Date.now(),
      maxViewers: 0,
      chatMessageCount: 0,
    };
    this.rtc = {
      client: null,
      joined: false,
      published: false,
      localStream: null,
      remoteStreams: [],
      params: {},
    };

    this.option = {
      appID: '838d72f9c54a4f158426aca30f912987',
      channel,
      uid: null,
    };
    const self = this;
  }

  restartStream() {
    const self = this;
    self.setState({ playbackError: false, startingStream: true });
    setTimeout(() => self.setState({ startingStream: false }), 30000);
    this.stopStream().then(() => {
      self.startStream();
    }).catch((e) => self.setState({ playbackError: true }));
  }

  startStream() {
    this.setState({ forceCloseConnecting: false, sourceSelectorOpen: true });
    return;


    if (browser.os === 'Android OS' || browser.os === 'android' || browser.os === 'iOS') {
      this.setState({ source: 'video' }, () => this._startStream());
    } else {
      this.setState({ sourceSelectorOpen: true });
    }
  }

  async _startStream() {
    const self = this;

    if (self.state.source === 'vimeo') {
      self.setState({vimeoIframe: true, sourceSelectorOpen: false, started: true});
      self.state.systemMessageFunction(`START ${self.state.vimeoSource}`, self.state.chat);

      await this.props.setLivestreamStatus({
        message: `START ${self.state.vimeoSource}`,
      });
      return;
    }

    if (self.state.source === 'zoom') {
      self.setState({vimeoIframe: true, sourceSelectorOpen: false, started: true});
      self.state.systemMessageFunction(`ZOOM ${self.state.zoomSource}`, self.state.chat);

      await this.props.setLivestreamStatus({
        message: `ZOOM ${self.state.zoomSource}`,
      })
      return;
    }


    self.setState({
      startTime: Date.now(), stopped: false, sourceSelectorOpen: false, connecting: true, startingStream: true, vimeoIframe: false,
    });
    if (self.props.onConnecting) self.props.onConnecting();
    self.refreshCounter();
    this.refresher = setInterval(() => this.refreshCounter(), 3000);

    this.rtc.client = AgoraRTC.createClient({ mode: 'live', codec: 'vp8' });
    this.rtc.client.setClientRole('host');

    self.rtc.client.on('stream-added', (evt) => {
      const remoteStream = evt.stream;
      const id = remoteStream.getId();
      console.log('stream-added', id);
      if (self.state.cohost && id.split('>>')[0] === self.state.cohost.split('>>')[0]) {
        self.rtc.client.subscribe(remoteStream, (err) => {
        });
      }
    });

    self.rtc.client.on('peer-leave', (evt) => {
      self.stopCohost();
    });

    self.rtc.client.on('stream-subscribed', (evt) => {
      const remoteStream = evt.stream;
      const id = remoteStream.getId();
      self.remoteStream = remoteStream;
      self.startCohostStream(remoteStream);
    });

    self.rtc.client.on('stream-removed', (e) => {
      self.stopCohost();
    });

    this.rtc.client.on('error', (e) => {
      self.setState({ playbackError: true });
    });
    this.rtc.client.on('network-quality', (stats) => {
      console.log('NET QUALITY', stats);
      if (stats.uplinkNetworkQuality == '0') {
        self.setState({ wifiColor: '#999', wifiStatus: 'Unknown' });
      }
      if (stats.uplinkNetworkQuality == '1' || stats.uplinkNetworkQuality == '2') {
        self.setState({ wifiColor: '#0f0', wifiStatus: 'Good' });
      }
      if (stats.uplinkNetworkQuality == '3' || stats.uplinkNetworkQuality == '4') {
        self.setState({ wifiColor: '#ff0', wifiStatus: 'Weak' });
      }
      if (stats.uplinkNetworkQuality == '5' || stats.uplinkNetworkQuality == '6') {
        self.setState({ wifiColor: '#f00', wifiStatus: 'Poor' });
      }
    });
    this.rtc.client.on('connection-state-change', (stats) => {
      self.setState({ wifiColor: '#999', wifiStatus: 'X' });
    });

    this.rtc.client.init(this.option.appID, () => {
      self.rtc.client.join(null, self.option.channel, `${localStorage.getItem('appId')}>>${Date.now()}`, (uid) => {
        self.rtc.params.uid = uid;
        self.rtmClient = AgoraRTM.createInstance('838d72f9c54a4f158426aca30f912987');
        self.rtmClient.login({ uid: `${uid}${Math.random()}` }).then(() => {
          self.rtc.rtmChannel = self.rtmClient.createChannel(self.option.channel);
          self.rtc.rtmChannel.join();
        }).catch((err) => {
          self.setState({ startingStream: false, errorMessage: `Unable to establish data connection with the server${err}` });
        });

        self.rtc.localStream = AgoraRTC.createStream({
          streamID: self.rtc.params.uid,
          audio: true,
          video: self.state.source === 'video',
          screen: self.state.source === 'screen',
        });
        self.rtc.localStream.init(() => {
          self.setState({ started: true, connecting: false, startingStream: false });
          self.rtc.client.publish(self.rtc.localStream, (p) => {
          }, async (err) => {
            let errorMessage = 'Unable to start stream. Please logout, close the tab and try again.';
            if (err === 'STREAM_ALREADY_PUBLISHED') {
              errorMessage = 'This stream has already been started. Please logout, close all tabs and try again';
            } else if (err === 'INVALID_LOCAL_STREAM') {
              errorMessage = 'Something went wrong. Please logout, close the tab and try again.';
            } else if (err === 'INVALID_OPERATION') {
              errorMessage = 'The connection was disturbed when trying to start the stream. Please make sure you are on a stable internet connection and try again.';
            } else if (err === 'PUBLISH_STREAM_FAILED') {
              errorMessage = 'The connection was disturbed when trying to start the stream. Please make sure you are on a stable internet connection and try again.';
            } else if (err === 'PEERCONNECTION_FAILED') {
              errorMessage = "Couldn't connect to the streaming server. Please make sure you are on a stable wifi connection and try again.";
            }
            try {
              await self.stopStream();
            } catch (e) {

            }
            self.setState({ startingStream: false, errorMessage });
            console.error(err);
          });
          self.rtc.localStream.play('videoholder');
          if (self.props.onStart) self.props.onStart();
        }, (err2) => {
          const err = err2.msg;
          let errorMessage = `[${err}] Not able to start livestream. Please logout, close this tab and try again`;
          if (err === 'NotAllowedError') {
            errorMessage = 'You have not granted permission to your Microphone and Video, we cannot start the stream without these';
          } else if (err === 'MEDIA_OPTION_INVALID') {
            errorMessage = 'This browser or recording device is not supported. Please try on a different device';
          } else if (err === 'DEVICES_NOT_FOUND') {
            errorMessage = 'Unable to find Camera or Mic. Please make sure your mic and camera are connected and working. Then, try again.';
          } else if (err === 'NOT_SUPPORTED') {
            errorMessage = 'Your browser is not supported. Please use the latest Chrome, Firefox or Safari';
          } else if (err === 'PERMISSION_DENIED') {
            errorMessage = 'You need to give permission to access camera and mic. Please grant permission and try again';
          } else if (err === 'CONSTRAINT_NOT_SATISFIED') {
            errorMessage = 'Your browser is not supported. Please use the latest Chrome, Firefox or Safari';
          } else if (err === 'PluginNotInstalledProperly') {
            errorMessage = 'Your browser is not supported for ScreenShare. Please use the latest Chrome, Firefox or Safari';
          }
          self.setState({ startingStream: false, errorMessage });
        });
      }, async (err) => {
        let errorMessage = 'Unable to connect to start stream';
        if (err === 'INVALID_OPERATION') {
          errorMessage = 'Stream initialization already in progress, please try again after 2 minutes';
        } else if (err === 'UID_CONFLICT') {
          errorMessage = 'You seem to have joined this livestreaming with the same user from another device or tab already. Please close all tabs and logout on all other devices, and try again.';
        } else if (err === 'ERR_REPEAT_JOIN') {
          errorMessage = 'Too many start-stream requests. Please try again after 2 min. If the error persists, please logout, close this tab and try again.';
        } else if (err === 'SOCKET_ERROR') {
          errorMessage = 'Unable to connect to internet. Please connect to a stable internet connection, preferably WiFi';
        }
        try {
          await self.stopStream();
        } catch (e) {

        }
        self.setState({ startingStream: false, errorMessage });

        console.error('client join failed', err);
      });
    }, (err) => {
      self.setState({ startingStream: false, errorMessage: 'This browser is not supported for streaming. Please use the latest Chrome, Firefox or Safari' });
      console.error(err);
    });
  }

  stopStream(broadcastEnd) {
    if (broadcastEnd) this.broadcastEnd();
    const self = this;
    if (self.refresher) clearInterval(self.refresher);
    self.refresher = null;
    return new Promise((r, x) => {
      try {
        if (!self.rtc.client) return;
        self.rtc.client.unpublish(self.rtc.localStream);
        self.rtc.client.leave(function () {
          // Stop playing the local stream
          self.rtc.localStream.stop();
          r();
          // Close the local stream
          self.rtc.localStream.close();
          self.setState({ started: false });
          // Stop playing the remote streams and remove the views
          while (self.rtc.remoteStreams.length > 0) {
            const stream = self.rtc.remoteStreams.shift();
            const id = stream.getId();
            stream.stop();
          }
          if (this.rtc.rtmChannel) this.rtc.rtmChannel.leave();
          if (self.rtmClient) self.rtmClient.logout();
        }, (err) => {
          console.error(err);
          x();
        });
      } catch (e) {
        x();
      }
    });
  }

  componentDidMount() {
    const self = this;
    Servercalls.getApp().then((response) => {
      self.setState({ app: response.data });
    });
  }

  async componentWillUnmount() {
    if (this.refresher) clearInterval(this.refresher);
    this.refresher = null;
    try {
      await this.stopCohost();
      this.stopStream();
    } catch (e) {
    }
  }

  refreshCounter() {
    const self = this;
    Servercalls.pluginchat_getViewers(this.props.channel).then((response) => {
      self.setState({ counter: response.data, maxViewers: Math.max(parseInt(response.data), this.state.maxViewers) });
    });
  }

  getViewers() {
    const self = this;
    self.setState({ loadingViewers: true, viewers: [] });

    if (!this.state.started || this.state.source !== 'video') return;
    /* Servercalls.pluginchat_getViewersList(this.props.channel).then(response =>{
            console.log(response.data);
            self.setState({ loadingViewers: false, viewers: response.data || []});
        }).catch(e => self.setState({ loadingViewers: false })); */
    this.rtc.rtmChannel.getMembers().then((members) => self.setState({ loadingViewers: false, viewers: members })).catch((e) => self.setState({ loadingViewers: false }));
  }

  startCohostStream(stream) {
    document.getElementById('videoholder-cohost').innerHTML = '';
    stream.play('videoholder-cohost');
  }

  stopCohostStream() {

  }

  stopCohost() {
    const self = this;
    return new Promise((r, x) => {
      this.rtc.rtmChannel.sendMessage({ text: 'stopcohost' }).then(() => {
        self.setState({ makingCohost: false, cohost: null });
        self.stopCohostStream();
        r();
      }).catch((e) => x(e));
      this.setState({ cohost: null });
    });
  }

  async broadcastEnd() {
    this.rtc.rtmChannel.sendMessage({ text: 'endsession' });
  }

  startCohost(id) {
    const self = this;
    self.setState({ makingCohost: true });
    this.rtc.rtmChannel.sendMessage({ text: 'stopcohost' }).then(() => {
      setTimeout(() => {
        self.rtc.rtmChannel.sendMessage({ text: `cohost:${id}` }).then(() => {
          self.setState({ cohost: id, viewersOpen: false, makingCohost: false });
        }).catch((e2) => self.setState({ makingCohost: false, cohost: null }));
      }, 1000);
    }).catch((e) => {
      self.setState({ makingCohost: false });
    });
  }

  getKudos() {
    const long = ["Wow, that's a long one!", 'Great stamina there!', 'How do you keep your energy so high for so long?', "That's one of your better ones!", 'How long can you keep going?', "We didn't think you'd stop today!"];
    const viewers = ['So many people had fun!', 'You are touching so many lives!', 'You have an amazing fan base', 'Your popularity keeps sky-rocketing!', "You're unstoppable!", "You've made so many people smile today!"];
    const normal = ["That's a wrap! Well done!", 'Good job! See you around soon!', 'Hope you had fun today!', 'Keep it going! See you soon!', "We're so excited you did this live today!"];
    const short = ['Uh Oh! Is everything OK?', 'Something wrong? Do reach out to us!', 'Was this a test run, or did something go wrong?'];
    if (this.state.startTime - this.state.endTime > 60 * 60 * 1000) {
      return long[Math.floor(Math.random() * long.length)];
    }
    if (this.state.maxViewers > 20) {
      return viewers[Math.floor(Math.random() * viewers.length)];
    }
    if (this.state.startTime - this.state.endTime < 5 * 60 * 1000) return short[Math.floor(Math.random() * short.length)];
    return normal[Math.floor(Math.random() * normal.length)];
  }

  end(broadcast) {
    this.setState({ stopped: false }, async () => {
      await this.stopCohost();
      await this.stopStream(broadcast);
      this.setState({ stopped: false });

      if (this.props.onEnd) this.props.onEnd();
    });
    this.props.handleClose();
  }

  async stopVimeo() {
    this.state.systemMessageFunction(`STOP ${this.state.vimeoSource}`, this.state.chat);

    await this.props.setLivestreamStatus({
      message: `STOP ${this.state.vimeoSource}`,
    });
    this.setState({vimeoIframe: false, started: false, stopped: false});
  }

  render() {
    return (
      <div style={{ ...this.props.style, background: '#000' }}>

        {this.state.loading ? (
          <div style={{height: '100%', width: '100%', background: '#000', position: 'absolute', zIndex: 35000, display: 'flex', justifyContent: 'center', alignItems: 'center'}}><CircularProgress style={{color: '#fff'}} /></div>
        ) : null}

        <div
          id="videoholder"
          style={{
            width: '100%', height: this.state.cohost ? '50%' : '100%', position: 'absolute', top: 0,
          }}
        />

        {this.state.vimeoIframe ? (
          <iframe src={this.state.vimeoSource} frameborder="0" allow="autoplay; fullscreen; picture-in-picture" style={{height: '100%', width: '100%', position: 'absolute'}} />
        ) : null }

        {this.state.cohost ? (
          <div
            id="videoholder-cohost"
            style={{
              width: '100%', height: '50%', position: 'absolute', top: '50%',
            }}
          />
        ) : null}

        {this.state.app ? (
          <div style={{
            height: 'calc(100% - 40px)', width: '100%', position: 'absolute', top: 40, display: this.state.showChat ? 'block' : 'none'
          }}
          >
            <Chat
              style={{ height: '100%', width: '100%' }}
              chatroomId={this.props.channel}
              userName={this.state.app.name}
              userPicture={this.state.app.logo}
              userId={this.state.app.appId}
              isAdmin
              onNewMessage={() => this.setState({ chatMessageCount: this.state.chatMessageCount + 1 })}
              onFinishLoading={(systemMessageFunction, chat) => this.setState({loading: false, systemMessageFunction, chat})}
              onBack={() => this.props.handleClose()}
            />
          </div>
        )
          : null}
        <table style={{
          position: 'absolute', top: 0, left: 0, height: 48, background: 'rgba(0,0,0,0.5)', width: 'calc(100%)', paddingLeft: 32,
        }}
        >
          <tr>
            <td>
              {!this.state.started ?  <Button onClick={() => this.props.handleClose()} color="primary" variant="contained"><Icon>arrow_back</Icon></Button> : null}

              {!this.state.started ? <Button style={{ marginLeft: 4 }} disabled={this.state.startingStream} className="content_stream" onClick={() => this.startStream()} color="primary" variant="contained"><Icon>play_arrow</Icon></Button>
                : <Button style={{ marginLeft: 4 }} onClick={() => this.state.vimeoIframe ? this.stopVimeo() : this.setState({ stopped: true, endTime: Date.now() })} color="primary" variant="contained"><Icon>stop</Icon></Button>}
              {this.state.showChat ? <Button style={{ marginLeft: 4 }} color="secondary" variant="contained" onClick={() => this.setState({ showChat: false })}><Icon>cancel_presentation</Icon></Button>
                : <Button style={{ marginLeft: 4 }} color="secondary" variant="contained" onClick={() => this.setState({ showChat: true })}><Icon>chat</Icon></Button>}
            </td>
            <td>
              <div onClick={() => this.setState({ viewersOpen: true, loadingViewers: true }, () => this.getViewers())} style={{ display: 'flex', alignItems: 'center', marginTop: 8 }}>
                <Icon style={{ color: '#fff' }}>visibility</Icon>
                <Typography variant="body1" style={{ color: '#fff', marginLeft: 8 }}>{this.state.counter}</Typography>
              </div>
            </td>
            <td>
              <div style={{ display: 'flex', alignItems: 'center', marginTop: 8 }}>
                <Icon style={{ color: this.state.wifiColor }}>wifi</Icon>
                <Typography variant="body1" style={{ color: this.state.wifiColor, marginLeft: 8 }}>{this.state.wifiStatus}</Typography>
              </div>
            </td>

          </tr>
        </table>
        <Dialog
          open={this.state.playbackError}
        >
          <DialogTitle><Typography variant="h4">Disconnected</Typography></DialogTitle>
          <DialogContent><Typography variant="body1">This might happen due to poor internet connection. You can try to quit and rejoin.</Typography></DialogContent>
          <DialogActions>
            <RoundedButton color="primary" variant="outlined" disabled={this.state.retrying || this.state.startingStream} color="primary" variant="outlined" onClick={() => this.setState({ playbackError: null })}>Dismiss</RoundedButton>
            <RoundedButton
              color="primary"
              variant="outlined"
              style={{ marginLeft: 12 }}
              onClick={() => {
                this.end(false);
                this.setState({ retrying: false, playbackError: null, startingStream: false });
              }}
            >
              Quit without ending session
            </RoundedButton>

            <RoundedButton
              color="primary"
              variant="contained"
              style={{ marginLeft: 12 }}
              onClick={() => {
                this.end(true);
                this.setState({ retrying: false, playbackError: null, startingStream: false });
              }}
            >
              End Session
            </RoundedButton>
          </DialogActions>
        </Dialog>
        <Dialog
          open={this.state.startStreamMessage}
        >
          <DialogTitle>Cannot start streaming</DialogTitle>
          <DialogContent><Typography variant="body1">{this.state.startStreamMessage}</Typography></DialogContent>
          <DialogActions>
            <Button color="primary" onClick={() => this.setState({ startStreamMessage: null })}>OK</Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={this.state.sourceSelectorOpen}
        >
          <DialogTitle>What do you want to stream?</DialogTitle>
          <DialogContent>
            <FormControl style={{width: '100%'}} component="fieldset">
              <FormLabel component="legend">Pick a source</FormLabel>
              <RadioGroup value={this.state.source} onChange={(event) => this.setState({ source: event.target.value })}>
                <FormControlLabel value="video" control={<Radio />} label="Video" />
                <FormControlLabel disabled={(browser.os === 'Android OS' || browser.os === 'android' || browser.os === 'iOS')} value="screen" control={<Radio />} label="Screenshare" />
                <FormControlLabel value="vimeo" control={<Radio />} label="Vimeo" />
                <FormControlLabel value="zoom" control={<Radio />} label="Zoom" />
              </RadioGroup>
              {this.state.source === 'vimeo' ? <TextField placeholder='Vimeo Source Link' fullWidth value={this.state.vimeoSource} onChange={(event) => this.setState({ vimeoSource: event.target.value })} /> : null}
              {this.state.source === 'zoom' ? <TextField placeholder='Zoom Source Link' fullWidth value={this.state.zoomSource} onChange={(event) => this.setState({ zoomSource: event.target.value })} /> : null}
            </FormControl>

          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={() => this.setState({ sourceSelectorOpen: false })}>Don't start stream</Button>
            <Button disabled={this.state.retrying || this.state.startingStream} color="primary" variant="contained" onClick={() => this._startStream()}>Start Stream</Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.errorMessage}
        >
          <DialogTitle>Error in starting the stream</DialogTitle>
          <DialogContent>
            <Typography variant="body1">{this.state.errorMessage}</Typography>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={() => this.setState({ errorMessage: null, startingStream: false })}>Dismiss</Button>
            <Button disabled={this.state.retrying || this.state.startingStream} color="primary" variant="contained" onClick={() => this._startStream()}>Retry</Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={this.state.viewersOpen}
        >
          <DialogTitle>Viewers</DialogTitle>

          <DialogContent>
            {this.state.loadingViewers ? <center><CircularProgress color="primary" /></center> : null}
            <span>{this.state.cohost}</span>
            {!this.state.cohost ? (
              <Typography variant="body1">
                You can make a viewer a cohost by tapping the
                <Icon>video_call</Icon>
                {' '}
                button
                {' '}
              </Typography>
            ) : (
              <div>
                <Typography>
                  {this.state.cohost.split('>>')[0]}
                  {' '}
                  is currently a cohost
                  {' '}
                </Typography>
                <RoundedButton variant="outlined" onClick={() => this.stopCohost()} color="primary">
                  <Icon>stop</Icon>
&nbsp;Stop Co-hosting
                </RoundedButton>
              </div>
            )}
            <List>
              {this.state.viewers.filter((v) => v.split('>>')[0] !== localStorage.getItem('appId')).sort((a, b) => {
                if (a.indexOf('>>') < 0) return -1;
                if (b.indexOf('>>') < 0) return 1;
                return parseInt(a.split('>>')[1]) - parseInt(b.split('>>')[1]);
              }).map((v) => (
                <ListItem>
                  <ListItemAvatar><Avatar>{v[0]}</Avatar></ListItemAvatar>
                  <ListItemText>{v.split('>>')[0]}</ListItemText>

                  <ListItemSecondaryAction edge="end">
                    {this.state.cohost !== v ? <IconButton disabled={this.state.makingCohost} onClick={() => this.startCohost(v)}><Icon color="secondary">video_call</Icon></IconButton> : <IconButton onClick={() => this.stopCohost()} disabled={this.state.makingCohost}><Icon color="primary">stop</Icon></IconButton>}
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={() => this.setState({ viewersOpen: false })}>Close</Button>
          </DialogActions>
        </Dialog>

        <Dialog open={this.state.connecting && !this.state.started && !this.state.forceCloseConnecting}>
          <DialogTitle>You're going to be on air shortly!</DialogTitle>
          <DialogContent>
            <Illustration image="award" style={{ width: '100%' }} />
          </DialogContent>
          <DialogActions>
            <RoundedButton onClick={() => this.setState({ forceCloseConnecting: true })} variant="outlined" color="secondary">Dismiss</RoundedButton>
          </DialogActions>
        </Dialog>
        <Dialog open={this.state.stopped}>
          <DialogTitle>{this.getKudos()}</DialogTitle>
          <DialogContent>
            <Card style={{ width: '100%', backgroundColor: this.props.theme.palette.primary.main }}>
              <CardContent>
                <center>
                  <Typography style={{ color: this.props.theme.palette.primary.contrastText }} variant="body1">Duration</Typography>
                  <Typography style={{ color: this.props.theme.palette.primary.contrastText }} variant="h2">
                    {(() => {
                      const { endTime } = this.state;
                      const { startTime } = this.state;
                      let timeLeft = Math.floor((endTime - startTime) / 1000);
                      let hours = Math.floor((timeLeft) / (60 * 60));
                      timeLeft = timeLeft % (60 * 60);
                      let minutes = Math.floor(timeLeft / 60);
                      timeLeft = timeLeft % 60;
                      let seconds = timeLeft;
                      if (hours > 0) return `${hours} hr ${minutes} min`;
                      return `${minutes} min ${seconds} sec`;
                    })()}
                  </Typography>
                </center>
              </CardContent>
            </Card>
            <Card style={{ width: 'calc(50% - 6px)', display: 'inline-block', marginTop: 12 }}>
              <CardContent>
                <center>
                  <Typography variant="body2">Attendees</Typography>
                  <Typography variant="h3">{this.state.maxViewers}</Typography>
                </center>
              </CardContent>
            </Card>
            <Card style={{
              width: 'calc(50% - 6px)', display: 'inline-block', marginTop: 12, marginLeft: 12,
            }}
            >
              <CardContent>
                <center>
                  <Typography variant="body2">Messages</Typography>
                  <Typography variant="h3">{this.state.chatMessageCount}</Typography>
                </center>
              </CardContent>
            </Card>
            <Illustration video="livehearts" style={{ width: '100%' }} />

          </DialogContent>
          <DialogActions>
            <RoundedButton variant="outlined" color="primary" onClick={() => this.end(false)}>Leave without ending session</RoundedButton>
            <RoundedButton onClick={() => this.end(true)} variant="contained" color="secondary">End Session</RoundedButton>
          </DialogActions>
        </Dialog>

      </div>
    );
  }
}

export default withTheme(Livestream);
