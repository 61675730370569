import React from 'react';
import Theme from '../../Components/theme';
import WsHeader from '../../Components/wsheader';
import WsFooter from '../../Components/wsfooter';
import FileUploadButton from '../../Components/fileuploadbutton';
import { LinearProgress, Button, Typography, TextField, Card, CardContent, Icon } from '@material-ui/core';
import RoundedButton from '../../Components/roundedbutton';
import { Editor as TinyEditor } from '@tinymce/tinymce-react';

const Servercalls = require('../../Utils/Servercalls');
const utils = require('../../Utils/utils');

export default class CommonAddFileContent extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            title: "",
            description: "",
            uploadDone: 0,
            uploadTotal: -1,
            uploading: false,
            url: null
        }
    }
    save(type){
        const bundle = this.props.context;
        const self = this;
        self.setState({ saving : true });
        let link = this.state.url;
        if(link.indexOf('youtube.com') > -1 && link.indexOf('/embed') === -1){
            const id = utils.getParametersByName("v", link);
            if(id)
              link = `https://www.youtube.com/embed/${id}`;
          }
          else if(link.indexOf('youtu.be') > -1 ) {
            const path = link.replace('https://','').split('/');
            if(path.length > 1)
              link = `https://www.youtube.com/embed/${path[1]}`;
          }
      
        Servercalls.pluginpremier_addContentToBundle(bundle.pluginId, bundle.itemId, null, this.state.title, this.state.description, link, true, type==='free', 'embed', null, "<p></p>", [], 'normal').then(response => {
            self.setState({ saving: false, saved: true });
            setTimeout(() => self.props.back(), 3000);
        }).catch(e => {alert(e); self.setState({ saving: false })});
    }

    render() {
        if(this.state.saved)
            return (
                <center style={{ margin: 64}}>
                    <Icon color="secondary" style={{fontSize: 120}}>check</Icon>
                    <br /><br />
                    <Typography variant="body1">Added content to your bundle. Redirecting you shortly ...</Typography>
                </center>
            )
        return (
            <Theme>
                <WsHeader 
                    back={this.props.back}
                    title="Add an embed"
                    helpText="What is an embed"
                    helpVideo="a"
                    zIndex={0}
                />
                <Card style={{ margin: 32 }}>
                    <CardContent>
                    <TextField fullWidth label={`Title for this embed`} value={this.state.title} onChange={e => this.setState({ title: e.target.value })} />
                    <br /><br />
                    <TextField fullWidth label={`Description for this embed`} value={this.state.description} onChange={e => this.setState({ description: e.target.value })} />
                    <br /><br />
                    <TextField fullWidth label={`URL of page to embed`} value={this.state.url} onChange={e => this.setState({ url: e.target.value })} />
                    <br /><br />
                    
                    <Typography variant="h6" style={{ marginTop: 24}}>Add {this.props.filetype} to collection : </Typography>
                    <div className="bundle_addcontent_publish">
                    <Typography variant="body1" style={{ marginTop: 23}}>When can people view this {this.props.filetype}?</Typography>
                    <RoundedButton variant="outlined" onClick={() => this.save('free')} fullWidth style={{ marginTop: 32}} color="secondary" disabled={this.state.saving || !(this.state.url && this.state.title.length > 0 && this.state.description.length > 0 )}>Before paying</RoundedButton>
                    <RoundedButton onClick={() => this.save('paid')} fullWidth style={{ marginTop: 32, marginBottom: 64}} variant="contained" color="secondary" disabled={this.state.saving || !(this.state.url && this.state.title.length > 0 && this.state.description.length > 0 )}>Only after paying</RoundedButton>
                    </div>
                    </CardContent>
                </Card>
                <WsFooter text="Copy paste the link to the page you want to show here" />
            </Theme>
        )
    }
}