exports.allowedIcons = ['accessible_forward', 'account_balance', 'account_box','add_shopping_cart', 'alarm', 'build', 'today', 'check_circle', 'commute', 'code', 'delete', 'done', 'donut_small', 'explore', 'extension', 'face', 'favorite', 'fingerprint', 'grade', 'home', 'hourglass_empty', 'lock', 'offline_bolt', 'question_answer', 'room', 'theaters', 'timeline', 'thumb_up', 'thumb_down', 'trending_up', 'verified_user', 'view_carousel','visibility', 'work', 'watch_later', 'equalizer', 'library_books', 'library_music', 'video_library','mic', 'movie', 'music_video', 'new_releases', 'play_arrow', 'radio', 'subscriptions', 'video_call','call', 'chat', 'create', 'flag','inbox','waves', 'weekend','save', 'send','monetization_on', 'cloud', 'folder', 'headset', 'computer','smartphone','tablet', 'watch', 'speaker', 'camera','color_lens','palette','photo','wb_sunny','directions_run','hotel','local_bar','local_cafe','local_dining', 'local_florist', 'local_pizza','local_play','ac_unit','casino','spa','cake', 'public', 'school','whatshot'];
exports.currencies = [
    {
      value: '',
      label: ''
    },
    {
      value: 'USD',
      label: 'USD',
    },
    {
      value: 'EUR',
      label: 'EUR',
    },
    {
      value: 'JPY',
      label: 'JPY',
    },
    {
        value: 'INR',
        label: 'INR',
    }
  ];


exports.testtheme = {
  palette: {
      primary: { main: "#211c39", contrastText: "#fff" },
      secondary: { main: "#562eff", contrastText: "#fff" },
      type: 'light',
      background: { paper: "#fff", default: "#d8d8d8"},
  },
  typography: {
      h1: { fontFamily: 'ProximaNova-Regular' },
      h2: { fontFamily: 'ProximaNova-Regular' },
      h3: { fontFamily: 'ProximaNova-Regular' },
      h4: { fontFamily: 'ProximaNova-Regular' },
      h5: { fontFamily: 'ProximaNova-Regular' },
      h6: { fontFamily: 'ProximaNova-Regular'},
      body1: {fontFamily: 'ProximaNova-Regular'},
      body2: {fontFamily: 'ProximaNova-Regular'},
      button: {fontFamily: 'ProximaNova-Regular'},
  },
  overrides: {
    MuiIcon: {
      root: {
        '& > img': {
          height: '100%',
        }
      }
    },
    MuiCard: {
      root: {
        boxShadow: '0 0 16px 0 rgba(0, 0, 0, 0.16)'
      }
    },
    MuiSelect: {
      root: {
        padding: '0',
      }
    },
    MuiExpansionPanel: {
      root: {
        '&::before': {
          display: 'none',
          backgroundColor: 'transparent',
        }
      },
      
    },
    MuiExpansionPanelSummary: {
      root: {
        '&$expanded' : {
          margin: '0',
          minHeight: '0',
          borderBottom: 'none', //'1px solid #f2f2f2',
        },
        margin: '0',
        minHeight: '0',
      },
      content: {
        margin: '0',
        '&$expanded': {
          margin: '0',
        }
      }
    },
    MuiStepLabel: {
      root: {
        '&$active': {
          color: '#ffffff',
        }
      }
    },
    MuiStepIcon: {
      root: {
        // to change inactive stepper color to light gray
        // color: "rgba(67, 75, 93, 0.07)",
        height: '20px',
        width: '20px',
        '&$active': {
          fill: '#562eff',
        },
        '&$completed': {
          color: '#562eff',
        },
      },
      active: {
        color: '#562eff',
      },
      text: {
        // change text color of all stepper icons, but cannot change for active color
        // fill: '#434b5d',
      }
    },
  },
};  


exports.theme = {
  palette: {
      primary: { main: "#252040", contrastText: "#fff" },
      secondary: { main: "#562eff", contrastText: "#fff" },
      type: 'light',
      background: { paper: "#fff", default: "#fbfbfb"},
  },
  typography: {
      h1: { fontFamily: 'ProximaNova-Regular' },
      h2: { fontFamily: 'ProximaNova-Regular' },
      h3: { fontFamily: 'ProximaNova-Regular' },
      h4: { fontFamily: 'ProximaNova-Regular' },
      h5: { fontFamily: 'ProximaNova-Regular' },
      h6: { fontFamily: 'ProximaNova-Regular'},
      body1: {fontFamily: 'ProximaNova-Regular'},
      body2: {fontFamily: 'ProximaNova-Regular'},
      button: {fontFamily: 'ProximaNova-Regular'},
  },
  overrides: {
    MuiIcon: {
      root: {
        '& > img': {
          height: '100%',
        }
      }
    },
    MuiCard: {
      root: {
        boxShadow: '0 0 16px 0 rgba(0, 0, 0, 0.16)'
      }
    },
    MuiSelect: {
      root: {
        padding: '0',
      }
    },
    MuiExpansionPanel: {
      root: {
        '&::before': {
          display: 'none',
          backgroundColor: 'transparent',
        }
      },
      
    },
    MuiExpansionPanelSummary: {
      root: {
        '&$expanded' : {
          margin: '0',
          minHeight: '0',
          borderBottom: 'none', //'1px solid #f2f2f2',
        },
        margin: '0',
        minHeight: '0',
      },
      content: {
        margin: '0',
        '&$expanded': {
          margin: '0',
        }
      }
    },
    MuiStepLabel: {
      root: {
        '&$active': {
          color: '#ffffff',
        }
      }
    },
    MuiStepIcon: {
      root: {
        // to change inactive stepper color to light gray
        // color: "rgba(67, 75, 93, 0.07)",
        height: '20px',
        width: '20px',
        '&$active': {
          fill: '#562eff',
        },
        '&$completed': {
          color: '#562eff',
        },
      },
      active: {
        color: '#562eff',
      },
      text: {
        // change text color of all stepper icons, but cannot change for active color
        // fill: '#434b5d',
      }
    },
  },
};

exports.WebAuthClientId = '2hftn88glut75itse57tpn50u6';
exports.GoogleFontsApiKey = 'AIzaSyDIJV9pNkOHZEi9VOkiGggS9Np9qLhFx58'; 
exports.OpenExchangeRatesApiKey = "fdd35b9c65c74d969748db394f7256ba"; //https://openexchangerates.org/account/app-ids
exports.IpDataApiKey = "160890fe153909387d2c4888b7c6abe65273981f9e504f0dcc6250f3";//ipdata.co

//TEST 
/*
window.STAGE = "TEST";
exports.RazorpayKeyId = "rzp_test_uz9cnhBRAi5TZE";
exports.AppUrl = 'http://localhost:3001';
exports.ApiUrl = 'http://localhost:3000';
exports.ZoomClientId = '8qTCFPOrRYKCbdWGdgq2Tg';
exports.WebAuthUrl = 'https://auth.global-webauth.com/login'; //globalwebauth
*/


//ALPHA
/* 
window.STAGE = "ALPHA";
exports.RazorpayKeyId = "rzp_test_uz9cnhBRAi5TZE";
exports.AppUrl = 'https://build-alpha.socionity.com';
exports.ApiUrl = 'https://p1xeq7loa9.execute-api.ap-south-1.amazonaws.com/alpha-regional';
exports.ZoomClientId = 'p0WGK22fTUWiGRmnpzahzw';
exports.WebAuthUrl = 'https://auth.global-webauth.com/login'; //globalwebauth
*/

//BETA
/*
window.STAGE = "BETA";
exports.RazorpayKeyId = "rzp_live_FSsW0GvSkkLvWP";
exports.AppUrl = 'https://build-beta.socionity.com';
exports.ApiUrl = 'https://api-v1.socionity.com';
exports.ZoomClientId = 'p0WGK22fTUWiGRmnpzahzw';
//exports.WebAuthUrl = 'https://CreatorOS.co/builder_login/beta_index.html';//'https://auth.global-webauth.com'; //globalwebauth
exports.WebAuthUrl = 'https://auth.global-webauth.com/login'; //globalwebauth
*/



//PROD


window.STAGE = "PROD";
exports.RazorpayKeyId = "rzp_live_FSsW0GvSkkLvWP";
exports.AppUrl = 'https://build.socionity.com';
exports.ApiUrl = 'https://api-v1.socionity.com';
exports.ZoomClientId = '8qTCFPOrRYKCbdWGdgq2Tg';
exports.WebAuthUrl = 'https://auth.global-webauth.com/login'; //globalwebauth

