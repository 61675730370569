import React from 'react';
import { Button, Card, CardContent, LinearProgress, List, ListItem, ListItemAvatar, Avatar, ListItemText, ListItemSecondaryAction, IconButton, Icon, TextField, Typography, CircularProgress } from '@material-ui/core';

import WsHeader from '../../Components/wsheader';
import RoundedButton from '../../Components/roundedbutton';
import StyledInput from '../../Components/StyledInput';
import MainCTA from '../../Components/MainCTA';
import WsFooter from '../../Components/wsfooter';
import SuccessDialog from '../../Components/SuccessDialog';
import FileUploadButton from '../../Components/fileuploadbutton';
import md5 from 'md5';
import ConfirmUnsavedDialog from '../../Components/ConfirmUnsavedDialog';
import { Editor as TinyEditor } from '@tinymce/tinymce-react';

const Servercalls = require('../../Utils/Servercalls');
const Constants = require('../../Utils/Constants');

export default class WsAddVimeo extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            loading: false,
            uri: this.props.context.fileUrl,
            name: this.props.context.name || "",
            description: this.props.context.description || "",
            searchTerm: null,
            thumbnail: this.props.context.thumbnail || `https://www.gravatar.com/avatar/${md5((Date.now() + Math.random()) + " ")}?d=identicon`,

            itemId: this.props.context.itemId,

            contentId: this.props.context.contentId || null,
            published: this.props.context.published || true,
            free: this.props.context.free || false,
            type: this.props.context.type || 'blog',
            longDescription: this.props.context.longDescription || "",
            tags: this.props.context.tags || [],
            list: this.props.context.list || 'normal',

        }
    }

    async save(){
        const self = this;
        self.setState({ saving : true });

        let itemId = this.state.itemId;
        if(!itemId) {
            itemId = await this.props.context.getItemId();
        }

        const {contentId, name, description, published, free, type, thumbnail, longDescription, tags, list, uri} = this.state;
        Servercalls.pluginpremier_addContentToBundle(this.props.context.pluginId, itemId, contentId, name, description, uri, published, free, type, thumbnail, longDescription, tags, list).then(response => {
            
            window.analytics.track('Content Confirm Clicked', {'content_type': type});
            self.setState({ saving: false, saved: true, itemId});
            setTimeout(() => {
                this.setContextAndBack();
            }, 3000);
        }).catch(e => { alert(e); self.setState({ saving: false, itemId})});
    }

    setContextAndBack() {
        this.props.changeParentContext('pluginId', this.props.context.pluginId);
        this.props.changeParentContext('itemId', this.state.itemId);
        
        if(this.props.context.bundle_name) {
            this.props.changeParentContext('name', this.props.context.bundle_name);
        }
        if(this.props.context.bundle_description) {
            this.props.changeParentContext('description', this.props.context.bundle_description);
        }
        
        if(this.props.context.bundle_priceList) {
            this.props.changeParentContext('priceList', this.props.context.bundle_priceList);
        }
        if(this.props.context.bundle_imageUrl) {
            this.props.changeParentContext('imageUrl', this.props.context.bundle_imageUrl);
        }
        if(this.props.context.bundle_availableForPurchase) {
            this.props.changeParentContext('availableForPurchase', this.props.context.bundle_availableForPurchase);
        }
        if(this.props.context.bundle_published) {
            this.props.changeParentContext('published', this.props.context.bundle_published);
        }
        this.props.changeParentContext('newCollection', false);
        this.props.back();
    }


    renderContentNameInput() {
        return (
            <StyledInput
                alwaysOpen={this.props.context.alwaysOpen}
                placeholder="Enter title"
                fullWidth
                value={this.state.name}
                onChange={e => this.setState({ name: e.target.value })}
            />
        )
    }

    renderContentDescriptionInput() {
        if(this.props.context.alwaysOpen) {
            return (
                <StyledInput
                    alwaysOpen
                    variant='outlined'
                    style={{marginTop: '24px', flex: '0'}}
                    inputStyle={{height: '2em', overflow: 'scroll'}}
                    multiline
                    placeholder="Enter description"
                    value={this.state.description}
                    onChange={e => this.setState({ description: e.target.value})} 
                />
            )
        }
        return (
            <StyledInput
                placeholder="Enter description"
                fullWidth
                style={{marginTop: '16px'}}
                value={this.state.description}
                onChange={e => this.setState({ description: e.target.value })}
            />
        ) 
    }

    
    renderThumbnailInput() {
        return (
            <div style={this.props.context.alwaysOpen ? {position: 'relative'} : {marginLeft: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <img style={{
                    borderRadius: '12px',
                    zIndex: '10',
                    boxShadow: '0 0 16px 0 rgba(0, 0, 0, 0.16)',
                    backgroundColor: 'rgba(0,0,0,0.15)',
                    height: this.props.context.alwaysOpen ? '23.611vw' : '23.611vw',
                    width: this.props.context.alwaysOpen ? '41.975vw' : '23.611vw',
                    maxHeight: this.props.context.alwaysOpen ? '188.88px' : '188.88px',
                    maxWidth: this.props.context.alwaysOpen ? '335.785px' : '188.88px',
                    }} src={this.state.thumbnail}/>
                <FileUploadButton
                    style={{ 
                        position: 'relative',
                        height: '23.611vw',
                        width: this.props.context.alwaysOpen ? '41.975vw' : '23.611vw',
                        maxHeight: '188.88px',
                        maxWidth: this.props.context.alwaysOpen ? '335.785px' : '188.88px',
                        backgroundColor: 'transparent',
                        borderRadius: '12px',
                        boxShadow: 'none',
                    }}
                    extensions={['png','jpg','jpeg']}
                    maxSizeMBs={2}
                    onFail={e => alert(e)}
                    onUpload={(url) => this.setState({ thumbnail: url })}
                    icon="edit"
                    color="primary"
                    variant="contained"
                    containerStyles={this.props.context.alwaysOpen ? {top: '0'} : {}}
                />
            </div>
        )
    } 

    renderEditor() {
        return (
            <TinyEditor
                style={{ height: '100%', width: '100%', marginTop: 12}}
                initialValue={this.state.uri || "<p>Start typing here ...</p>"}
                init={{
                images_upload_handler : async (blobInfo, resolve, reject) => {
                    let blob = blobInfo.blob();
                    let f = new File([blob],blobInfo.filename());
                    const response = await Servercalls.uploadToS3(f);
                    resolve(response.url);
                },
                plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help wordcount'
                    ],
                imagetools_cors_hosts: ['build.socionity.com', 'cdn.socionity.com'],
                height: window.innerHeight - 100,
                toolbar: "undo redo | styleselect | bold italic forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image",
                imagetools_toolbar: "rotateleft rotateright | flipv fliph | editimage imageoptions"

                }}
                onChange={(e) => this.setState({uri: e.target.getContent()})}
            />
        )
    }

    render () {
        if(this.state.loading)
            return <LinearProgress />
        return (

            <>
                <div style={{display: 'flex', flex: '1', marginBottom: '42px', flexDirection: 'column'}}>
                    <WsHeader title={'Blog'} back={async() => {
                        const self = this;
                        let confirmUnsaved = () => new Promise(resolve => self.setState({confirmUnsavedDialogOpen: true, confirmUnsavedResolver: resolve}))
                        const confirmation = await confirmUnsaved();
                        self.setState({confirmUnsavedDialogOpen: false, confirmUnsavedResolver: null});

                        if(!confirmation) {
                            // cannot save here as it will break design incase of incomplete input
                            // this.save()
                            return;
                        } else {
                            this.setContextAndBack()
                        }
                    }} />
                    {this.props.context.alwaysOpen ? (
                        <Card style={{margin: '68px 40px 20px', borderBottom: 'solid 1px #dbdbdb', borderRadius: '6px'}}>
                            <CardContent style={{padding: '0'}}>
                                <div style={{display: 'flex', marginBottom: '24px', padding: '40px 40px 0'}}>
        
                                    <div style={{marginRight: 'auto', flex: '1', flexDirection: 'column', display: 'flex'}}>
                                        
                                        {this.renderContentNameInput()}
                                        {this.renderContentDescriptionInput()}
                                        
                                    </div>
                                    <div style={{marginLeft: '60px', display: 'flex', justifyContent: 'center'}}>
                                        {this.renderThumbnailInput()}
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                    ) : (
                        <Card style={{marginTop: '52px', boxShadow: 'none'}}>
                            <CardContent style={{padding: '0'}}>
                                <div style={{padding: '20px'}}>
                                    <div style={{display: 'flex'}}>

                                        <div style={{marginRight: 'auto', flex: '1', flexDirection: 'column', justifyContent: 'space-evenly', display: 'flex'}}>
                                            
                                            {this.renderContentNameInput()}
                                            {this.renderContentDescriptionInput()}
                                            
                                        </div>
                                        {this.renderThumbnailInput()}
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                    )}


                    {this.props.context.alwaysOpen ? (
                        <Card style={{zIndex: '100', margin: '12px 40px', padding: '12px 12px'}}>
                            <CardContent style={{display: 'flex', flexDirection: 'column', padding: '0'}}>
                                {this.renderEditor()}
                            </CardContent>
                        </Card> 
                    ) : (
                        <Card style={{zIndex: '100', marginTop: '12px', boxShadow: 'none', padding: '12px 12px'}}>
                            <CardContent style={{display: 'flex', flexDirection: 'column', padding: '0'}}>
                                {this.renderEditor()}
                            </CardContent>
                        </Card>
                    )}

                
                <SuccessDialog open={this.state.saved} text="Blog updated successfully"/>
                <ConfirmUnsavedDialog open={this.state.confirmUnsavedDialogOpen} resolve={this.state.confirmUnsavedResolver} />

                <WsFooter text="" />
                <MainCTA
                    toast='Please add the title and description to proceed'
                    topRight
                    alwaysOpen={this.props.context.alwaysOpen}
                    settings={[]}
                    help={[]}
                    text="Confirm"
                    onCTAClick={() => this.save()}
                    square={!this.props.context.alwaysOpen}
                    disabled={!this.state.name || !this.state.description}
                />
            
            </div>
            </>
        )
    }
}