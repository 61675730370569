import { ButtonBase, Icon, Typography } from '@material-ui/core';
import { makeStyles, withTheme } from '@material-ui/styles';
import React from 'react';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(1.5, 2.5),
        // height: theme.spacing(7),
        textAlign: 'left',
        width: '100%',
    },
    title: {
        marginLeft: theme.spacing(1.5),
        marginBottom: theme.spacing(0.75),
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    subtitle: {
        marginLeft: theme.spacing(1.5),
        color: '#999990',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    icon: {
        height: theme.spacing(6),
        width: theme.spacing(6),
        borderRadius: theme.spacing(1.3875),
        lineHeight: 0,
        '& > *': {
            width: '100%',
            objectFit: 'cover',
        }
    },
    divider: {
        height: '1px',
        backgroundColor: '#eeeeee',
        margin: theme.spacing(0, 2.5),
    }
}))

const ChatGroupsList = (props) => {
    const classes = useStyles();

    return(
        <>
            {props.chatrooms.map(chatroom => (
                <>
                    <div className={classes.divider} />
                    <ButtonBase onClick={() => chatroom.onClick()} className={classes.root}>
                        <Icon className={classes.icon}>{chatroom.icon}</Icon>
                        <div style={{flex: '1', overflow: 'hidden'}}>
                            <Typography className={classes.title} variant='body1'>{chatroom.chatroomName}</Typography>
                            <Typography className={classes.subtitle} variant='caption'>{chatroom.chatManifesto}</Typography>
                        </div>
                    </ButtonBase>
                </>
            ))}
            <div className={classes.divider} />
        </>
    )
}

export default withTheme(ChatGroupsList);
