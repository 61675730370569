/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import {
  Card,
  fade, Icon, IconButton, makeStyles, Tab, Tabs, Typography, withTheme,
} from '@material-ui/core';
import { TabPanel } from '@material-ui/lab';
import React from 'react';
import ShowMoreText from 'react-show-more-text';
import moment from 'moment';
import ScheduleCard from './ScheduleCard';


const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    position: 'relative',
  },
  backgroundContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    zIndex: 1,
    position: 'absolute',
  },
  banner: {
    maxWidth: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
  },
  overlay: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    backgroundColor: fade(theme.palette.common.black, 0.8),
    backdropFilter: 'blur(4px)',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2.3125, 3, 3),
    justifyContent: 'flex-end',
    flex: 1,
    zIndex: 2,
  },
  creatorPicture: {
    height: theme.spacing(13.75),
    width: theme.spacing(13.75),
    border: `2px solid ${theme.palette.primary.contrastText}`,
    borderRadius: '50%',
    marginBottom: theme.spacing(2.5),
    boxSizing: 'border-box',
    zIndex: 10,
  },
  title: {
    fontWeight: 'bold',
    lineHeight: 1.23,
    marginBottom: theme.spacing(2),
    color: theme.palette.common.white,
  },
  ratingsContainer: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(0, 0, 2),
    color: theme.palette.primary.contrastText,
  },
  badge: {
    borderRadius: theme.spacing(0.75),
    backgroundColor: 'rgba(255, 255, 255, 0.18)',
    padding: theme.spacing(0.75, 1.5),
    marginRight: 'auto',
  },
  badgeContent: {
    fontWeight: 'bold',
    color: theme.palette.common.white,
    lineHeight: 1.21,
    whiteSpace: 'nowrap',
    flex: 1,
  },
  shareButton: {
    padding: theme.spacing(1),
    marginLeft: theme.spacing(1),
    // backgroundColor: fade(theme.palette.common.black, 0.6),
  },
  shareButtonRipple: {
    backgroundColor: fade(theme.palette.common.black, 0.4),
  },
  backButton: {
    position: 'absolute',
    top: theme.spacing(1),
    left: theme.spacing(1),

    backgroundColor: fade(theme.palette.common.black, 0.6),
  },
  ratingsContainer2: {
    display: 'flex',
    alignItems: 'flex-start',
    margin: theme.spacing(1, 1, 0),
    padding: theme.spacing(2),
    boxShadow: 'none',
    flexDirection: 'column',
    borderRadius: theme.spacing(0.75),
    '& > div': {
      display: 'flex',
      alignItems: 'flex-start',
    },
  },

  mainContentCard: {
    display: 'flex',
    alignItems: 'flex-start',
    margin: theme.spacing(1, 1, 0),
    padding: theme.spacing(1.625),
    boxShadow: 'none',
    flexDirection: 'column',
    borderRadius: theme.spacing(0.75),
  },

  styledTabs: {
    width: '100%',
    height: theme.spacing(4.1875),
    minHeight: 0,
    borderBottom: `1px solid ${fade('#707070', 0.1)}`,
    marginBottom: theme.spacing(2.5),
  },

  styledTab: {
    textTransform: 'none',
    fontWeight: 'bold',
    fontSize: theme.spacing(2),
    marginTop: 0,
    marginRight: theme.spacing(2.6875),
    padding: 0,
    height: theme.spacing(4.1875),
    minHeight: 0,
    paddingBottom: theme.spacing(1.4375),
    minWidth: 0,
    opacity: 1,
    color: '#707070',
  },

  styledTabActive: {
    color: theme.palette.common.black,
  },

  collapsedText: {
    lineHeight: '21px',
    fontSize: '14px',
    color: '#1d1d1d',

    marginBottom: theme.spacing(1.5),
  },

  expanded: {
    height: 'auto',
  },

  bannerTop: {
    paddingTop: '56.25%',
    background: theme.palette.grey[400],
    position: 'relative',
    zIndex: 1,
  },
  bannerImage: {
    position: 'absolute',
    top: 0,
    height: '100%',
    width: '100%',
    objectFit: 'initial',
  },
}));

function abbreviateNumber(value) {
  let newValue = value;
  if (value >= 1000) {
    const suffixes = ['', 'k', 'm', 'b', 't'];
    const suffixNum = Math.floor((`${value}`).length / 3);
    let shortValue = '';
    for (let precision = 2; precision >= 1; precision--) {
      shortValue = parseFloat((suffixNum !== 0 ? (value / Math.pow(1000, suffixNum)) : value).toPrecision(precision));
      const dotLessShortValue = (`${shortValue}`).replace(/[^a-zA-Z 0-9]+/g, '');
      if (dotLessShortValue.length <= 2) { break; }
    }
    if (shortValue % 1 != 0) shortValue = shortValue.toFixed(1);
    newValue = shortValue + suffixes[suffixNum];
  }
  return newValue;
}

function IconContainer(props) {
  const { ...other } = props;
  return <span {...other}><Icon size="small" style={{ fontSize: '0.8rem' }}>stars</Icon></span>;
}

function LargeIconContainer(props) {
  const { ...other } = props;
  return <span {...other}><Icon size="large" style={{ fontSize: '3rem' }}>stars</Icon></span>;
}

const FolderDetails = (props) => {
  const classes = useStyles();
  const {
    theme, bundle,
  } = props;

  const [value, setValue] = React.useState(0);
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const d = new Date();
  const courseStartTime = d.getTime();

  const badge = `Live class Starting on ${moment(props.startDate).format('DD MMMM')}`;
  const rating = 5;
  const ratingCount = 1;
  return (
    <div style={{ background: '#eff4ff' }}>
      <div className={classes.bannerTop}>
        <img src={bundle.imageUrl} alt="course banner" className={classes.bannerImage} />
      </div>

      <div className={classes.root}>
        <div className={classes.contentContainer}>
          {/* <img src="https://images.inuth.com/2017/05/2Paresh-Rawals-Babu-Bhaiya-from-Hera-Pheri.jpg" className={classes.creatorPicture} alt="Creator" /> */}
          <Typography variant="h6" className={classes.title}>{bundle.name}</Typography>


          <div className={classes.ratingsContainer}>
            <Icon style={{ marginRight: theme.spacing(0.8125), lineHeight: 0.8, fontSize: '0.75rem' }} className={classes.ratingsContent}>star</Icon>
            <Typography className={classes.ratingsContent} variant="caption">{rating}</Typography>
            <Typography style={{ margin: theme.spacing(0, 1.5) }} className={classes.ratingsContent} variant="caption">●</Typography>
            <Icon style={{ marginRight: theme.spacing(0.8125), lineHeight: 0.8, fontSize: '0.75rem' }} className={classes.ratingsContent}>person</Icon>
            <Typography className={classes.ratingsContent} variant="caption">{ ratingCount.toLocaleString()}</Typography>
          </div>


          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div className={classes.badge}>
              <Typography className={classes.badgeContent} variant="body2">{badge}</Typography>
            </div>
            <IconButton classes={{ root: classes.shareButton }} TouchRippleProps={{ classes: { child: classes.shareButtonRipple } }}>
              <Icon style={{ color: theme.palette.common.white }}>share</Icon>
            </IconButton>
          </div>
        </div>

        <div className={classes.backgroundContainer}>
          <img src={bundle.imageUrl} alt="banner" className={classes.banner} />
          <div className={classes.overlay} />
        </div>
      </div>

      <Card className={classes.ratingsContainer2}>
        <div>
          <Icon
            style={{
              marginRight: theme.spacing(0.8125), marginTop: theme.spacing(0.375), fontSize: '0.75rem', color: '#10bc80',
            }}
            className={classes.ratingsContent}
          >
            check_circle_outline
          </Icon>
          <Typography className={classes.ratingsContent} variant="caption">
            100% Refund in the first 3 days.
            <br />
            {' '}
            NO QUESTIONS ASKED
          </Typography>
        </div>
        <div style={{ marginTop: theme.spacing(1.5) }}>
          <Icon
            style={{
              marginRight: theme.spacing(0.8125), marginTop: theme.spacing(0.375), fontSize: '0.75rem', color: '#10bc80',
            }}
            className={classes.ratingsContent}
          >
            check_circle_outline
          </Icon>
          <Typography className={classes.ratingsContent} variant="caption">
            Rated
            {' '}
            {rating}
            {' '}
            Stars by
            {' '}
            {abbreviateNumber(ratingCount)}
            {'+ '}
            People
          </Typography>
        </div>
      </Card>

      <Card className={classes.mainContentCard}>
        <Tabs className={classes.styledTabs} value={value} onChange={handleChange}>
          <Tab classes={{ root: classes.styledTab, selected: classes.styledTabActive }} className={classes.styledTab} label="Course Details" tabIndex={0} />
          <Tab classes={{ root: classes.styledTab, selected: classes.styledTabActive }} label="Reviews" tabIndex={1} />
          <Tab classes={{ root: classes.styledTab, selected: classes.styledTabActive }} label="Resources" tabIndex={2} />
        </Tabs>

        {/* <div className={collapsed ? classes.collapsedText : classes.expanded} onClick={toggleCollapsed}>
            <Typography variant="caption" style={{ lineHeight: '21px', fontSize: '14px' }} />
          </div> */}

        {value === 0 ? (
          <>
            <ShowMoreText
              lines={3}
              more="Show more"
              less="Show less"
              className={classes.collapsedText}
              onClick={toggleExpanded}
              expanded={expanded}
            >
              <div dangerouslySetInnerHTML={{ __html: bundle.description || '' }} />

            </ShowMoreText>

            {props.liveSchedule.filter(schedule => schedule.startTime >= courseStartTime).filter((schedule, index) => index < props.validFor).map((schedule, index) => <ScheduleCard index={index} schedule={schedule} />)}

          </>
        ) : value === 1 ? (
          <Typography variant="caption">Reviews will come here</Typography>
        ) : (
          <Typography variant="caption">Course Content will come here</Typography>
        )}
      </Card>
    </div>
  );
};

export default withTheme(FolderDetails);
