import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card, Icon, makeStyles, Typography, withTheme,
} from '@material-ui/core';
import React from 'react';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#f4f4f4',
    borderRadius: theme.spacing(0.75),
    marginTop: theme.spacing(1),
    padding: theme.spacing(1, 0),
    boxShadow: 'none',
    width: '100%',
  },
  toprow: {
    display: 'flex',
    marginTop: theme.spacing(0, 1.25),
  },
  toprowText: {
    color: 'rgba(77, 79, 118, 0.59)',
    lineHeight: 1.25,
    fontWeight: 'bold',
    textTransform: 'captialize',
    padding: theme.spacing(0, 1.5),
  },
  divider: {
    backgroundColor: 'rgba(77, 79, 118, 0.59)',
    opacity: 0.1,
    height: '1px',
    margin: theme.spacing(1.0625, 0, 1.4375),
  },
  summary: {
    fontWeight: 'bold',
  },

  accordianRoot: {
    boxShadow: 'none',
    background: 'none',
    '&:before': {
      height: 0,
    },
  },
  accordianSummary: {
    marginTop: '0 !important',
    marginBottom: '0 !important',
  },
  accordianSummaryExpanded: {
    marginTop: '0 !important',
    marginBottom: '0 !important',
  },
  accordianSummaryFocused: {
    backgroundColor: 'transparent',
  },
}));

const ScheduleCard = (props) => {
  const classes = useStyles();
  const { schedule, index } = props;
  return (
    <Card className={classes.root}>
      <div className={classes.toprow}>
        <Typography style={{ marginRight: 'auto' }} className={classes.toprowText} variant="caption">
          Day
          {index + 1}
        </Typography>
        <Typography className={classes.toprowText} variant="caption">{moment(schedule.startTime).format('hh:mm a[, ]DD MMM')}</Typography>
      </div>
      <div className={classes.divider} />

      <Accordion className={classes.accordianRoot}>
        <AccordionSummary
          classes={{
            content: classes.accordianSummary,
            expanded: classes.accordianSummaryExpanded,
            focused: classes.accordianSummaryFocused,
          }}
          expandIcon={<Icon>expand_more</Icon>}
        >
          <Typography variant="body1" className={classes.summary}>Session {index}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1" className={classes.details}>{schedule.description}</Typography>
        </AccordionDetails>
      </Accordion>

    </Card>
  );
};

export default withTheme(ScheduleCard);
