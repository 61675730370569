import React from 'react';
import { Typography, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Card, CardContent, CardHeader, Avatar, CardActions, Icon, Button, IconButton,  } from '@material-ui/core';

import WsHeader from '../../Components/wsheader';
import RoundedButton from '../../Components/roundedbutton';
import FileUploadButton from '../../Components/fileuploadbutton';
import Illustration from '../../Components/illustration';
import { withTheme } from '@material-ui/styles';

const Servercalls = require('../../Utils/Servercalls');
class WsAppEngagement extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            stories: []
        }
    }

    componentDidMount() {
        this.reload();
    }

    async reload() {
        const app =(await Servercalls.getApp()).data;
        this.setState({ stories : app.stories || []});
    }

    async saveStories() {
        const stories = this.state.stories.slice(0,15);
        this.setState({ savingStories: true });
        await  Servercalls.updateAppDetails("stories", stories);
        this.setState({ savingStories: false });
    }

    render() {
        return (
            <div style={{ margin: 12}}>
                <WsHeader back={this.props.back} title="Engagement Tools" description="This is the identity of your app. When a user installs your app on their device, this is the name and logo they'll see on their home screen" helpText="Why should I customize this?" helpVideo="a" />
                <Typography style={{ marginTop: 12}} variant="h6">Personal Growth Advisor</Typography>                        
                <Card style={{marginTop: 12}}>
                    <CardHeader 
                        avatar={<Avatar src="https://socionity.com/assets/uploads/c.jpeg?p=By08uSkp"/>}
                        title="Chitrak Gangrade"
                        subheader="Growth Advisor"
                    />
                    <CardContent >
                        <Typography variant="body2">Contact your personal growth advisor and get detailed strategies that will work for you.</Typography>
                        <Typography style={{marginTop: 12}} variant="subtitle2" color="textSecondary">
                            chitrak@socionity.com
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <RoundedButton variant="outlined" color="primary" onClick={() => window.open("mailto:chitrak@socionity.com")}><Icon>mail</Icon>&nbsp;Email</RoundedButton>
                    </CardActions>
                </Card>

                <Typography style={{ marginTop: 12}} variant="h6">Fresh Content</Typography>     
                <ExpansionPanel style={{marginTop: 12}}>
                    <ExpansionPanelSummary
                    expandIcon={<Icon>expand_more</Icon>}
                    >
                    <Typography style={{fontSize: this.props.theme.typography.pxToRem(15),fontWeight: this.props.theme.typography.fontWeightRegular,}}>Stories</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <div>
                            <FileUploadButton
                                style={{ marginTop: 32 }}
                                extensions={['png','jpg','jpeg']}
                                maxSizeMBs={5}
                                onFail={e => {
                                    this.setState({ savingStories: false });
                                    alert(e);
                                }}
                                onUpload={(url) => {
                                    this.setState({ url: this.setState({ stories: [{imageUrl: url, timestamp: Date.now()}, ...this.state.stories || []]}) }, () => this.saveStories())
                                }}
                                icon="add_a_photo"
                                text="Upload/Design Story Image"
                                color="primary"
                                variant="contained"
                                disabled={this.state.savingStories}
                            />
                            <div style={{ marginTop: 12,  height: 190, width: '100%', overflowX: 'scroll', overflowY: 'hidden', whiteSpace: 'noWrap'}}>
                                {this.state.stories.map(story => (
                                        <div style={{ position: 'relative', display: 'inline-block'}}>
                                            <img src={story.imageUrl} style={{ height: 172, margin: 4, display: 'inline=block'}} />
                                            <Button onClick={() =>{
                                                let stories = this.state.stories.filter(s2 => story.imageUrl !== s2.imageUrl);
                                                this.setState({ stories });
                                                this.saveStories();
                                            }}style={{position: 'absolute', top: 12, left: 12,}} variant="contained"><Icon>close</Icon></Button>
                                        </div>
                                ))}
                            </div>
                        </div>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
  
                <Typography variant="h6" style={{ marginTop: 12, marginBottom: 12}}>Communication</Typography>
                <ExpansionPanel disabled>
                    <ExpansionPanelSummary
                    expandIcon={<Icon>expand_more</Icon>}
                    >
                    <Typography style={{fontSize: this.props.theme.typography.pxToRem(15),fontWeight: this.props.theme.typography.fontWeightRegular,}}>Welcome Emails (Coming soon)</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <div>
                        </div>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel disabled>
                    <ExpansionPanelSummary
                    expandIcon={<Icon>expand_more</Icon>}
                    >
                    <Typography style={{fontSize: this.props.theme.typography.pxToRem(15),fontWeight: this.props.theme.typography.fontWeightRegular,}}>Email Campaign (Coming soon)</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <div>
                        </div>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel disabled>
                    <ExpansionPanelSummary
                    expandIcon={<Icon>expand_more</Icon>}
                    >
                    <Typography style={{fontSize: this.props.theme.typography.pxToRem(15),fontWeight: this.props.theme.typography.fontWeightRegular,}}>WhatsApp or SMS campaign (Coming soon)</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <div>
                        </div>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel disabled>
                    <ExpansionPanelSummary
                    expandIcon={<Icon>expand_more</Icon>}
                    >
                    <Typography style={{fontSize: this.props.theme.typography.pxToRem(15),fontWeight: this.props.theme.typography.fontWeightRegular,}}>Send Notification (Coming soon)</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <div>
                        </div>
                    </ExpansionPanelDetails>
                </ExpansionPanel>

            <Card style={{ marginTop: 12 }}>
                <CardContent>
                    <Typography variant="h6">More engagement tools coming soon... </Typography>
                    <Illustration image="underconstruction" style={{ width: '100%'}} />
                </CardContent>
            </Card>
            </div>

        )
    }
}

export default withTheme(WsAppEngagement);