import { Card, InputBase, Typography } from '@material-ui/core';
import { makeStyles, withTheme } from '@material-ui/styles';
import React from 'react';
import { Editor as TinyEditor } from '@tinymce/tinymce-react';

const useStyles = makeStyles(theme => ({
    root: {
        margin: theme.spacing(1),
        padding: theme.spacing(2, 2, 1.25),
        borderRadius: theme.spacing(1.25),
        boxShadow: 'none',
    },
    title: {
        fontWeight: 'bold',
        fontSize: theme.spacing(2.25),
        marginBottom: theme.spacing(0.5),
    },
    subtitle: {
        marginBottom: theme.spacing(2),
    },
    inputBase: {
        border: '1px solid #eaeaea',
        background: '#fafafa',
        borderRadius: theme.spacing(0.75),
        width: '100%',
        padding: theme.spacing(1.625),
        marginBottom: theme.spacing(1),
    },
    input: {
        padding: 0,
        overflow: 'scroll !important',
    },
    wordCount: {
        textAlign: 'end',
        color: 'rgba(77, 79, 118, 0.59)',
    },
}))

const DescriptionInputCard = (props) => {
    const classes = useStyles();
    return (
        <Card className={classes.root}>
            <Typography className={classes.title} variant='h6'>{props.title}</Typography>
            <Typography className={classes.subtitle} variant='body2'>{props.subtitle}</Typography>
            <TinyEditor
                style={{ height: '100%', width: '100%', border: props.error ? '1px solid #f02e2e' : ''}}
                initialValue={props.value || `
                <b>Replace this with a course description to define your overall course </b>
                <p><b>[Day 1] : [Day 1 Date]</b>
                You can replace this text with the
                Description of the content you will
                Be teaching on day 1</p>`}
                init={{
                // images_upload_handler : async (blobInfo, resolve, reject) => {
                //     let blob = blobInfo.blob();
                //     let f = new File([blob],blobInfo.filename());
                //     const response = await Servercalls.uploadToS3(f);
                //     resolve(response.url);
                // },
                // plugins: [
                //     'advlist autolink lists link image charmap print preview anchor',
                //     'searchreplace visualblocks code fullscreen',
                //     'insertdatetime media table paste code help wordcount'
                //     ],
                // imagetools_cors_hosts: ['build.socionity.com', 'cdn.socionity.com'],
                // height: window.innerHeight - 100,
                toolbar: "undo redo | styleselect | bold italic forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image",
                // imagetools_toolbar: "rotateleft rotateright | flipv fliph | editimage imageoptions"

                }}
                value={props.value}
                onChange={e => props.onChange(e.target.getContent())}
            />
            <div style={{display: 'flex'}}>
                <Typography style={{color: '#f02e2e', marginRight: 'auto', marginTop: props.theme.spacing(1)}} variant='body2'>{props.error}</Typography>
            </div>
        </Card>
    )
}

export default withTheme(DescriptionInputCard);