import React from 'react';
import Theme from '../Components/theme';
import WsHeader from '../Components/wsheader';
import WsFooter from '../Components/wsfooter';
import Illustration from '../Components/illustration';
import { Typography, Icon, Button, TextField, LinearProgress, Card, CardContent, Dialog } from '@material-ui/core';
import QRCode from 'qrcode.react';
import amplitude from 'react-amplitude-js';
import copy from 'copy-to-clipboard';


import ReactPixel from 'react-facebook-pixel';
import RoundedButton from '../Components/roundedbutton';
ReactPixel.init('2108565599460395', {}, {
    autoConfig: true, 	// set pixel's autoConfig
    debug: false, 		// enable logs
});


const Servercalls = require('../Utils/Servercalls');

export default class WsAppTnc extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            phone: '',
            loading: true,
        };
    }

    componentDidMount(){
        const self = this;
        self.setState({ loading: true });
    
        Servercalls.getApp().then((response) => {
            self.setState({ loading: false, app: response.data});
            ReactPixel.track('CompleteRegistration');
            amplitude.getInstance().logEvent("app-published", { url: `https://${response.data.domain}` } );            
        }).catch(console.error);
    }
    share(){

        window.analytics.track('Share CTA Clicked', {'content_shared' : 'app'});

        if(navigator.share){
            navigator.share({
                url: `https://${this.state.app.domain}`,
                title: `${this.state.app.name}`,
                text: "Check out my app!",
            });
        }
        else {
            copy(`https://${this.state.app.domain}`);
            this.setState({ copied : true });

            setTimeout(() => this.setState({ copied: false }), 3000);
        }
    }

    save(){
        const self = this;
        self.setState({ saving: true  });
        Servercalls.updateBuilderPhone(this.state.phone).then(() => {
            ReactPixel.track('Contact');

            self.setState({ saving: false, saved: true });
            setTimeout(() => self.setState({ saved: false }), 3000);
        }).catch(() => {
            self.setState({ saving: false });
        })
    }

    render() {
        if(this.state.loading) return <LinearProgress />
        if(this.state.saved) return (
            <center style={{ margin: 64}}>
                <Icon style={{ fontSize: 120}} color="secondary">check</Icon>
                <br /><br />
                <Typography variant="body2">Call back requested. You'll be redirected shortly</Typography>
            </center>
        );

        if(this.props.renderAsDialog) {
            return (
                <Dialog PaperProps={{ style: {borderRadius: '12px', maxWidth: '234px'} }} open={this.props.open} onClose={this.props.onClose}>
                    <div style={{padding: '32px 30px', display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center'}}>
                        <Typography style={{fontWeight: 'bold', marginTop: '4px'}} variant='body1'>Your App has been Published</Typography>
                        
                        <Icon style={{cursor: 'pointer', position: 'absolute', right: '0', top: '0', margin: '14px'}} onClick={this.props.onClose}>close</Icon>
                        
                        <div style={{padding: '16px 30px', display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center'}}>
                            <Icon color='secondary' style={{fontSize: '4em'}}>check_circle</Icon>
                        </div>
                        
                        <Typography style={{color: '#999999'}} variant='body2'><a href={`https://${this.state.app.domain}`} target="_blank">{this.state.app.domain}</a></Typography>
                        <div style={{display: 'flex', marginTop: '26px', width: '100%', justifyContent: 'center'}}>
                            {this.state.copied ? 'Link Copied!' :
                            <RoundedButton style={{whiteSpace: 'nowrap'}} color="secondary" variant="outlined" onClick={() => this.share()}>
                                Share
                            </RoundedButton>
                            }
                            <RoundedButton style={{whiteSpace: 'nowrap', marginLeft: '12px'}} color="secondary" variant="contained" onClick={() => window.open(`https://${this.state.app.domain}`, "_blank")}>
                                Open App
                            </RoundedButton>
                        </div>
                    </div>
                </Dialog>
            )
        }

        

        return(
            <Theme>
                <WsHeader back={this.props.back}  title="Publish App" description="Publishing your app will make it available to users around the world" />
                <div>
                    {
                        this.state.app.domain?
                            <Card style={{ margin: 12, marginTop: 64}}>
                                <CardContent>
                                    <Typography variant={window.innerWidth>800?"h1":"h3"}>Published!</Typography>
                                        <Typography variant="body1"><a href={`https://${this.state.app.domain}`} target="_blank">{this.state.app.domain}</a></Typography>
                                        <RoundedButton variant="outlined" color="secondary" style={{marginTop: 12}} onClick={() => window.open(`https://${this.state.app.domain}`, "_blank")}> <Icon>exit_to_app</Icon> &nbsp;Open App </RoundedButton>
                                        <br />
                                        <RoundedButton variant="contained" color="primary" style={{marginTop:12}} onClick={() => this.share()}> <Icon>share</Icon> &nbsp; {this.state.copied?"Link Copied!":"Share App Link"} </RoundedButton>
                                        <Illustration video="apppublished" style={{ width: '100%' , marginTop: 24 }} />
                                </CardContent>
                            </Card>:<Typography variant="body1">Your app is not published. You need to provide an <Icon>apps</Icon> app name & icon to publish from the <br /><br /><Button onClick={() => this.props.openOptions()} variant="contained" color="primary"><Icon>chevron_right</Icon> &nbsp; editing tools menu </Button></Typography>
                    }
                </div>
                <WsFooter text="Once the app is published, anyone around the world can install the app" />
            </Theme>
        )
    }
}