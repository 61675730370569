import React from 'react';
import FileUploadButton from '../../Components/fileuploadbutton';
import MainCTA from '../../Components/MainCTA';
import moment from 'moment';

import Theme from '../../Components/theme';
import WsHeader from '../../Components/wsheader';
import WsFooter from '../../Components/wsfooter';
import { TextField, Button, RadioGroup, FormControlLabel, Radio, Typography, Icon, Card, CardContent, FormControl, InputLabel, Select, MenuItem, Checkbox, FormLabel, FormGroup } from '@material-ui/core';
import { Server } from 'tls';
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

const FileDownload = require('js-file-download');
const Servercalls = require('../../Utils/Servercalls');

export default class AppUsersScheduleNotifications extends React.Component {
    constructor(props){
        super(props);
        const d = new Date();
        this.state = {
            title : '',
            message: '',
            attachment: '',
            saving: false,
            datetime: moment().format("YYYY-MM-DDTHH:mm"),
            bundles: [],
        }
    }
    componentDidMount() {
        this.reload();
    }

    async reload() {
        this.setState({ loading: true });
        const plugins = await Servercalls.getPlugins();
        let bundles = [];
        for(let i = 0 ; i < plugins.data.length; i+= 1){
            const plugin = plugins.data[i];
            if(plugin.type === 'premium') {
                const bs = await Servercalls.pluginpremier_getItems(plugin.pluginId);
                bundles = bundles.concat(bs.data || []);
            }
        }
        if(bundles.lenth > 0){
            this.setState({ toBundle: bundles[0].itemId});
        }
        this.setState({ bundles, loading: false });
    }

    schedule() {
        const d = moment(this.state.datetime, 'YYYY-MM-DDTHH:mm').toDate();
        const utckey = `${d.getUTCFullYear()}-${d.getUTCMonth()}-${d.getUTCDate()}T${d.getUTCHours()}:${d.getUTCMinutes() < 30? "00" : "30"}`;
        const self = this;
        self.setState({ saving: true });
        let to = this.state.to;
        if(to.endsWith("_")){
            to+=this.state.toBundle;
        }
        Servercalls.scheduleMessage(utckey,to,self.state.title, self.state.message, self.state.attachment ).then(r => {
            self.setState({ saving: false, saved: true});
            setTimeout(() => {
                self.setState({ saved: false})
                self.props.back();
            }, 3000);
        }).catch(e => { alert(e); self.setState({ saving: false})});
    }

    async repeatSchedule() {
        try{
            this.setState({ saving: true });
            let dt = moment(this.state.datetime, 'YYYY-MM-DDTHH:mm');
            const uptil = moment(this.state.repeatUntil, 'YYYY-MM-DDTHH:mm');
            let to = this.state.to;
            if(to.endsWith("_")){
                to+=this.state.toBundle;
            }
            while(dt < uptil){
                const d = dt.toDate();
                const utckey = `${d.getUTCFullYear()}-${d.getUTCMonth()}-${d.getUTCDate()}T${d.getUTCHours()}:${d.getUTCMinutes() < 30? "00" : "30"}`;
                const day = dt.format("dddd");
                console.log("SCHEDULING", dt.format(), day);
                if(this.state[`repeat${day}`]){

                    await Servercalls.scheduleMessage(utckey,to,this.state.title, this.state.message, this.state.attachment );
                    console.log("Scheduled");
                }

                dt = dt.add(1, 'day');
            }
            this.setState({ saving: false, saved: true });
            setTimeout(() => {
                this.setState({ saved: false})
                this.props.changeWorkspace("appusersnotificationslist", "Upcoming notifications", {});
            }, 3000);
        }
        catch(e){
            console.log(e);
        }


    }

    render() {
        if(this.state.saved) return (
            <center style={{ margin: 64}}>
                <Icon style={{ fontSize: 120}} color="secondary">check</Icon>
                <br /><br />
                <Typography variant="body2">Notifications scheduled. You'll be redirected shortly</Typography>
            </center>
        )

        return (
            <Theme>
                <WsHeader back={this.props.back} title="Schedule notification for all users" description="You can schedule a notification for a bulk of users" helpText="How to send good notifications?" helpVideo="a"/>
                <Card style={{margin: 12 }}>
                    <CardContent>
                        <Typography variant="h6">Send a Notification to your users</Typography>
                        <Typography variant="body1" style={{ marginTop: 12 }}>For guaranteed delivery, schedule the notification atleast 60 minutes before you want it sent</Typography>
                    <TextField
                        style={{ marginTop: 12 }}
                        autoFocus
                        margin="dense"
                        label="Subject"
                        fullWidth
                        value={this.state.title}
                        onChange={ event => this.setState({ title: event.target.value })}
                    />
                    <TextField
                        autoFocus
                        style={{ marginTop: 12}}
                        margin="dense"
                        label="Message"
                        fullWidth
                        multiline
                        value={this.state.message}
                        onChange={ event => this.setState({ message: event.target.value })}
                    />
                    {this.state.attachment? <a style={{marginTop: 24}} href={this.state.attachment} target='_blank'>Open attachment</a>:null}
                    <FileUploadButton
                        icon="cloud_upload"
                        text="Attach File"
                        color="secondary"
                        style={{ marginTop: 12 }}
                        onFail={ e => alert(e)}
                        onUpload={url => this.setState({ attachment : url })}
                        maxSizeMBs={5}
                    />
                    <Typography style={{marginTop: 24 }} variant="h6">When should this notification be sent?</Typography>                    
                    <MuiPickersUtilsProvider style={{marginTop: 12}} utils={MomentUtils}><DateTimePicker helperText={`The time is in the ${Intl.DateTimeFormat().resolvedOptions().timeZone} timezone`} value={this.state.datetime} onChange={m => this.setState({ datetime: m.format("YYYY-MM-DDTHH:mm")})} label="Send time" fullWidth/></MuiPickersUtilsProvider>

                    <Typography style={{marginTop: 24 }} variant="h6">Should this notification repeat?</Typography>                    
                    <RadioGroup aria-label="visibility" name="visibility" value={this.state.repeat} onChange={e => this.setState({ repeat: e.target.value })}>
                        <FormControlLabel value="norepeat" control={<Radio />} label="Don't repeat" />
                        <FormControlLabel value="repeat" control={<Radio />} label="Repeat" />
                    </RadioGroup>
                    {this.state.repeat === "repeat"? (
                        <FormControl style={{ marginTop: 12 }} component="fieldset">
                            <FormLabel component="legend">When to repeat?</FormLabel>
                            <FormGroup>
                                <FormControlLabel
                                    control={<Checkbox checked={this.state.repeatMonday} onChange={(event) => this.setState({ repeatMonday: event.target.checked })} name="repeatMonday" />}
                                    label="Monday"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={this.state.repeatTuesday} onChange={(event) => this.setState({ repeatTuesday: event.target.checked })} name="repeatTuesday" />}
                                    label="Tuesday"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={this.state.repeatWednesday} onChange={(event) => this.setState({ repeatWednesday: event.target.checked })} name="repeatWednesday" />}
                                    label="Wednesday"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={this.state.repeatThursday} onChange={(event) => this.setState({ repeatThursday: event.target.checked })} name="repeatThursday" />}
                                    label="Thursday"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={this.state.repeatFriday} onChange={(event) => this.setState({ repeatFriday: event.target.checked })} name="repeatFriday" />}
                                    label="Friday"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={this.state.repeatSaturday} onChange={(event) => this.setState({ repeatSaturday: event.target.checked })} name="repeatSaturday" />}
                                    label="Saturday"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={this.state.repeatSunday} onChange={(event) => this.setState({ repeatSunday: event.target.checked })} name="repeatSunday" />}
                                    label="Sunday"
                                />
                                
                            </FormGroup>
                            <MuiPickersUtilsProvider style={{marginTop: 12}} utils={MomentUtils}><DateTimePicker helperText={`The time is in the ${Intl.DateTimeFormat().resolvedOptions().timeZone} timezone`} value={this.state.repeatUntil} onChange={m => this.setState({ repeatUntil: m.format("YYYY-MM-DDTHH:mm")})} label="Repeat Until" fullWidth/></MuiPickersUtilsProvider>

                        </FormControl>
                    ): null}


                    <Typography style={{marginTop: 24 }} variant="h6">Who should receive this notification?</Typography>
                    <RadioGroup aria-label="visibility" name="visibility" value={this.state.to} onChange={e => this.setState({ to: e.target.value })}>
                        <FormControlLabel value="SOC:REGISTERED" control={<Radio />} label="All Users who have logged in" />
                        <FormControlLabel value="SOC:SUBSCRIBED" control={<Radio />} label="Users who have a valid subscription" />
                        <FormControlLabel value="SOC:EXSUBSCRIBED" control={<Radio />} label="Users who had a subscription, but it has expired" />
                        <FormControlLabel value="SOC:NOTSUBSCRIBED" control={<Radio />} label="Users who have logged in but not made a purchase" />
                        <FormControlLabel value="SOC:BUNDLESUBSCRIBED_" control={<Radio />} label="Users who have a subscription for ..." />
                        <FormControl disabled={this.state.to !== 'SOC:BUNDLESUBSCRIBED_' || this.state.loading || this.state.bundles.length === 0}>
                            <InputLabel id="demo-simple-select-label">Collection</InputLabel>
                            <Select
                                value={this.state.toBundle}
                                onChange={(event) => {this.setState({ toBundle: event.target.value })}}
                            >
                                {
                                    this.state.bundles.map(b => (
                                        <MenuItem value={b.itemId}>{b.name}</MenuItem>         
                                    ))
                                }
                                
                            </Select>
                        </FormControl>

                        <FormControlLabel value="SOC:BUNDLEEXSUBSCRIBED_" control={<Radio />} label="Users who had a subscription but has expired for ..." />
                        <FormControl disabled={this.state.to !== 'SOC:BUNDLEEXSUBSCRIBED_' || this.state.loading || this.state.bundles.length === 0}>
                            <InputLabel id="demo-simple-select-label">Collection</InputLabel>
                            <Select
                                value={this.state.toBundle}
                                onChange={(event) => {this.setState({ toBundle: event.target.value })}}
                            >
                                {
                                    this.state.bundles.map(b => (
                                        <MenuItem value={b.itemId}>{b.name}</MenuItem>         
                                    ))
                                }
                                
                            </Select>
                        </FormControl>

                        <FormControlLabel value="SOC:BUNDLENOTSUBSCRIBED_" control={<Radio />} label="Users who have not subscribed for ..." />
                        <FormControl disabled={this.state.to !== 'SOC:BUNDLENOTSUBSCRIBED_' || this.state.loading || this.state.bundles.length === 0}>
                            <InputLabel id="demo-simple-select-label">Collection</InputLabel>
                            <Select
                                value={this.state.toBundle}
                                onChange={(event) => {this.setState({ toBundle: event.target.value })}}
                            >
                                {
                                    this.state.bundles.map(b => (
                                        <MenuItem value={b.itemId}>{b.name}</MenuItem>         
                                    ))
                                }
                            </Select>
                        </FormControl>

                    </RadioGroup>
                    </CardContent>
                </Card>
                <MainCTA 
                    disabled={this.state.saving || moment(this.state.datetime, 'YYYY-MM-DDThh:mm').toDate().toString() === 'Invalid Date' || this.state.title.trim().length ===0 || this.state.message.trim().length === 0 || !this.state.to} 
                    onCTAClick={() => {
                        if(this.state.repeat !== 'repeat')
                            this.schedule()
                        else   
                            this.repeatSchedule();
                    }
                    } 
                    icon="check" 
                    text="Schedule Sending" 
                    settings={[]}
                    help={[
                        {title: "Why can't I send NOW?", text: "We add notifications to a queue and try to send the notification at the designated time. Many times the notifications fail to reach the user because of various reasons including a poor internet on the user's phone. We keep retrying for an acceptable number of times. We need time to prepare for that. Scheduling makes sure we are able to allocate resources to ensure deliverablility. The notification will actually get sent 15 minutes before or after the designated time."}
                    ]}
                />
                <WsFooter />

            </Theme>
        )
    }
}