import React from 'react';
import Joyride from 'react-joyride';
import { DialogTitle, DialogContent, DialogActions, Dialog, Button } from '@material-ui/core';


export default class OnboardingHelper extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            steps: [],
            index: isNaN(localStorage.getItem('onboarding_index'))? 0: parseInt(localStorage.getItem('onboarding_index'))
        }
    }

    componentDidMount() {
        this.reloadSteps();
        const onboardingIndex = isNaN(localStorage.getItem('onboarding_index'))? -1 : parseInt(localStorage.getItem('onboarding_index'));
        if(onboardingIndex > -1) {
            this.checker = setInterval(() => {
                this.next();
            },2000);
        }
        this.setState({ show: true });
    }
    componentWillUnmount() {
        if(this.checker)
            clearInterval(this.checker);
    }

    getContent(step){
        switch(step){
            case "tabs_premium": return "This is your contents tab. This is where all your content resides"; break;
            case "tabs_survey": return "This is your interactive chatbot tab. Google form, but in the form of a chatbot! Ask questions to create personalized content for your customers. "; break;
            case "premium_addcollection": return "A collection is what you put your videos and other content into. You set a price for a collection. When a user buys a collection, everything inside it gets unlocked for them."; break;
            case "bundle_addcontent": return "Add videos, pdf, audio. This what your users will come to your app for."; break;
            case "bundle_addcontent_livestream": return "Add a live stream. People can see you when you go live."; break;
            case "bundle_price": return "Set what users need to pay to unlock the contents in this collection. Contents remain locked till they pay."; break;
            case "bundle_banner": return "Set a cover photo. Make it attractive and informative - this is the first thing your customers see."; break;
            case "bundle_banner_canva": return "Create beautiful cover photos using canva. Use ready made templates."; break;
            case "openapp": return "Check out how your app looks live"; break;
            case "contact": return "Request a call back from our experts to give finishing touches to the app!"; break;
            case "bundle_addcontent_video": return "Add a video. People can watch this video after paying for the collection"; break;
            case "survey_addquestions": return "Add questions that you ask your audience. Set a price that they must pay before submitting their answers."; break;
            case "premium_openbundle": return "Setup your collection here. Add content, set a price and set how it looks"; break;
            case "bundle_price_add": return "Set pricing for your collection. You can have multiple pricing for different validity periods."; break;
            case "bundle_addcontent_publish": return "Select if you want people to be able to see before paying or after. Choose 'before paying' if you want to provide this as a preview of the collection."; break;
            case "tabs_booking": return "This is where you can initiate group calls."; break;
            case "booking_addevent": return "Your users will be able to book time slots you mention here."; break;
            case "bookingevent_save": return "Save!"; break;
            case "booking_manage" : return "Manage all your bookings here. Tap on the session to start the call."; break;
            case "booking_price" : return "Add a subscription, so that your users don't have to pay to book each session"; break;            
            case "survey_setprice" : return "Set a price for submitting the responses"; break;
            case "bundle_banner_save" : return "Save!"; break;
            case "appsettings" : return "Add more tabs and customize your app further here"; break;
            case "booking_price_save" : return "Save!"; break;
            case "booking_date_0": return"Pick a date to list the events on that date"; break;
            case "booking_open_event" : return "Tap here to join the call when you are ready"; break;
            case "content_open_0" : return "To start your live stream open your live content item"; break;
            case "content_stream": return "Tap here to start streaming when you are ready"; break;
            case "tabs_onlylivestream": return "This is your livestream tab. This is where you can start your stream"; break;
            case "onlylivestream_nextsession": return "Schedule when you will go live";
            case "onlylivestream_startstream" : return "One tap to start streaming!";

        }
    }

    getTarget(step){
        return '.'+step;
    }

    setupTarget(step) {
        if(document.getElementsByClassName(step).length > 0){
            document.getElementsByClassName(step)[0].addEventListener('click',() => {
                this.next();
            });
        }
    }

    next() {
        if(!this.getSteps()[this.state.index]) return;
        if(document.getElementsByClassName(this.getSteps()[this.state.index].target.substr(1))){
            this.setState({ show: true })
        }
    }

    quit(){
        localStorage.setItem('onboarding_index', 100);
        this.setState({index: 100, show: false, confirmQuit: false});
    }

    callbackHandler(e) {
        if(e.action === 'close' && e.lifecycle==='complete'){
            this.setState({ confirmQuit: true });
        }
        if( e.action==='next' && e.lifecycle==='complete'){
            localStorage.setItem('onboarding_index', this.state.index + 1);
            this.setState({index: this.state.index + 1, show: false});
        }
    }

    reloadSteps() {
        let steps = [];
        const onboardingIndex = isNaN(localStorage.getItem('onboarding_index'))? -1 : parseInt(localStorage.getItem('onboarding_index'));
        if(onboardingIndex > -1) {
            if(localStorage.getItem('onboarding_stages')) {
                const steps = localStorage.getItem('onboarding_stages').split(',');
                if(document.getElementsByClassName(steps[onboardingIndex]) ){
                    this.setState({ steps: [{ target: this.getTarget(steps[onboardingIndex]), content: this.getContent(steps[onboardingIndex]), placement: 'top', disableBeacon: true, open: true }] } );
                    //this.setupTarget(steps[onboardingIndex]);
                
                    
                }
            }
        }

    }

    getSteps(){
        let steps = [];
        const onboardingIndex = isNaN(localStorage.getItem('onboarding_index'))? -1 : parseInt(localStorage.getItem('onboarding_index'));

        if(onboardingIndex > -1) {
            if(localStorage.getItem('onboarding_stages')) {
                const stepsLocal = localStorage.getItem('onboarding_stages').split(',');
                for( let i = 0 ; i < stepsLocal.length; i += 1){
                    const step = stepsLocal[i];
                    steps.push({ target: this.getTarget(step), content: this.getContent(step), disableBeacon: true, placement: 'auto', open: true  })
                }
            }
        }
        return steps;
    }

    getIndex() {
        return isNaN(localStorage.getItem('onboarding_index'))? -1 : parseInt(localStorage.getItem('onboarding_index'));
    }

    render() {
        return(<div style={{position: 'fixed'}}>
            {this.state.show && !this.state.confirmQuit?<Joyride
                steps={this.getSteps()}
                disableOverlayClose={true}
                hideBackButton={true}
                stepIndex={this.state.index}
                locale={{ back: 'Cancel', close: 'OK', last: 'OK', next: 'OK', skip: 'OK' }}
                callback={e => this.callbackHandler(e)}
                styles={{
                    options: {
                    zIndex: 10000,
                    },
                }}
                run={true}
                continuous={true}
                disableScrollParentFix={true}
            />:false}
            <Dialog
                open={this.state.confirmQuit}
                style={{ zIndex: 20000}}
            >
                <DialogTitle>Terminate Tour?</DialogTitle>
                <DialogContent>
                    This tour will guide you to set up the app. There are {(localStorage.getItem('onboarding_stages')? localStorage.getItem('onboarding_stages').split(",").length : 0) - parseInt(localStorage.getItem('onboarding_index'))} steps left in the tour 
                </DialogContent>
                <DialogActions>
                    <center>
                        <Button fullWidth style={{width: '100%'}} color="secondary" onClick={() => this.quit()}>I know what to do on this builder</Button><br />
                        <Button fullWidth style={{width: '100%'}} color="primary" variant="contained" onClick={() => this.setState({ confirmQuit: false })}>Continue with the tour</Button><br />
                    </center>
                </DialogActions>
            </Dialog>
        </div>)
    }
}