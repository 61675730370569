import React from 'react';
import { Button, Icon, Badge, Menu, MenuItem, Dialog, DialogTitle, DialogContent, DialogActions, Typography, CardMedia, CardContent, Card, CardHeader, Avatar, CardActions, Snackbar } from '@material-ui/core';
import { getWindowDimensions } from '../Utils/utils';
import { withTheme } from '@material-ui/styles';
import RoundedButton from './roundedbutton';
import CircularButton from './circularbutton';
const Constants = require('../Utils/Constants');

class MainCTA extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            snackbarOpen: false,
        }
    }

    getSettingsBadgeCount(){
        let count = 0;
        for(let i = 0 ; i < this.props.settings.length; i+= 1){
            if(this.props.settings[i].dot)
                count+= 1
        }
        return count;
    }
    

    render() {

        // using props such as topRight, square etc. These wont be required when all designs are normalized
        if (this.props.square) {
            return (
                <>
                    <Button variant='contained' color='secondary' onClick={() => this.props.disabled ? this.setState({snackbarOpen: true}) : this.props.onCTAClick()} style={{width: this.props.alwaysOpen ? 'calc(100% - max(19.06vw, 244px))' : 'calc(100% - 16px)', zIndex: '1000', display: 'flex', position: 'fixed', bottom: '0', borderRadius: '0', textTransform: 'none', padding: '9px 0', backgroundColor: this.props.disabled ? '#999999' : '#562eff', ...this.props.style }}>
                        {this.props.text === 'Publish' || this.props.text === 'publish' ? 'Confirm' : this.props.text}
                    </Button>
                    {this.props.toast ? <Snackbar
                        anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                        }}
                        open={this.state.snackbarOpen}
                        autoHideDuration={3000}
                        onClose={() => this.setState({snackbarOpen: false})}
                        message={this.props.toast}
                    /> : null}
                </>
            )
        }

        return (
            <div style={{zIndex: 2500, width: this.props.topRight ? 'fit-content' : this.props.alwaysOpen || window.innerWidth > 800 ? 'calc(100% - max(19.06vw, 244px))' : 'calc(100% - 16px)', height: this.props.topRight ? 'fit-content' : '', position: 'fixed', top: this.props.topRight ? '7.5px' : '', bottom: !this.props.topRight ? this.props.aboveTabs ? 48 : 6 : 0, margin: this.props.topRight ? '0' : 'auto', right: this.props.topRight ? '0' : ''}}>
            <div style={{ margin: 'auto',  zIndex: this.props.zIndex === 0 ? 0 : 2, left:0,  bottom:0, zIndex: 1000, marginBottom: '16px', display: 'flex', justifyContent: this.props.topRight ? 'flex-end' : 'center', padding: this.props.alwaysOpen ? '0 32px' : '0'}}>
            {/*<span style={{ width: '20%', height: '100%', display: 'inline-block'}}>
                <Badge color="primary" badgeContent={this.getSettingsBadgeCount()} invisible={this.getSettingsBadgeCount() === 0}> <CircularButton icon="settings" onClick={event => this.setState({ settingsAnchorEl: event.currentTarget })} disabled={!this.props.settings || this.props.settings.length===0 }/></Badge>
            </span>*/}
            {this.props.settings.length === 0?<span style={{ width: '100%%', height: '100%', display: 'inline-block'}}>
                
                    <RoundedButton square style={{backgroundColor: this.props.disabled ? '#999999' : '#562eff'}} variant="contained" color="secondary" onClick={() => this.props.disabled ? this.setState({snackbarOpen: true}) : this.props.onCTAClick()}>
                        {this.props.icon ? <><Icon>{this.props.icon}</Icon>{'\u00A0'}</> : null } {this.props.text === 'Publish' || this.props.text === 'publish' ? 'Confirm' : this.props.text}
                    </RoundedButton>
                
            </span>:<span style={{ width: '100%%', height: '100%', display: 'inline-block'}}>
                <center>
                    <Button variant="contained" style={{ borderTopLeftRadius: 32, borderBottomLeftRadius: 32 }} icon="settings" onClick={event => this.setState({ settingsAnchorEl: event.currentTarget })} disabled={!this.props.settings || this.props.settings.length===0 }><Icon>settings</Icon></Button>
                    <Button square style={{ borderTopRightRadius: 32, borderBottomRightRadius: 32, backgroundColor: this.props.disabled ? '#999999' : '#562eff'}} variant="contained" color="primary" onClick={() => this.props.disabled ? this.setState({snackbarOpen: true}) : this.props.onCTAClick()}>
                        {this.props.icon ? <><Icon>{this.props.icon}</Icon>{'\u00A0'}</> : null } {this.props.text === 'Publish' || this.props.text === 'publish' ? 'Confirm' : this.props.text}
                    </Button>
                </center>
            </span>} 
            {/*<span style={{ width: '20%', height: '100%', display: 'inline-block'}}>
                <CircularButton onClick={event => this.setState({ helpAnchorEl: event.currentTarget})} icon="help" disabled={!this.props.help || this.props.help.length===0}/>
            </span>*/}
            
            </div>
            <Menu
                id="settings-menu"
                anchorEl={this.state.settingsAnchorEl}
                keepMounted
                open={Boolean(this.state.settingsAnchorEl)}
                onClose={() => this.setState({ settingsAnchorEl: null})}
            >
                {
                    this.props.settings.map(s => (
                        <MenuItem onClick={() => s.onClick()}><Icon>{s.icon}</Icon>&nbsp;{s.text}&nbsp;{s.dot?<Icon color="primary">error</Icon>: null}</MenuItem>
                    ))
                }
            </Menu>
            <Menu
                id="help-menu"
                anchorEl={this.state.helpAnchorEl}
                keepMounted
                open={Boolean(this.state.helpAnchorEl)}
                onClose={() => this.setState({ helpAnchorEl: null})}
            >
                {
                    this.props.help.map(s => (
                        <MenuItem onClick={() => this.setState({ helpOpen: s})}>{s.title}</MenuItem>
                    ))
                }
            </Menu>
            {this.state.helpOpen?
                <Dialog open={this.state.helpOpen} onClose={() => this.setState({ helpOpen: null })} fullScreen={window.innerWidth<800}>
                    <DialogTitle><Typography variant="h6">{this.state.helpOpen.title}</Typography></DialogTitle>
                    <DialogContent>
                        <Typography variant="body1">{this.state.helpOpen.text}</Typography>
                        {this.state.helpOpen.video?<video style={{ width: '100%'}} src={this.state.helpOpen.video} /> : null}
                        <Typography variant="h5" style={{marginTop: 12, marginBottom: 12}}>Contact the founders</Typography>
                        <Card>
                            <CardHeader 
                                avatar={<Avatar src="https://socionity.com/images/m.jpg"/>}
                                title="Madhavan Malolan"
                                subheader="Founder"
                            />
                            <CardContent >
                                <Typography variant="subtitle1" color="textSecondary">
                                    +91 889 704 1840
                                </Typography>
                                <Typography variant="subtitle2" color="textSecondary">
                                    madhavan@socionity.com
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <RoundedButton variant="outlined" color="primary" onClick={() => window.open("https://wa.me/+918897041840", "_blank")}><Icon>call</Icon>&nbsp;WhatsApp</RoundedButton>
                                <RoundedButton variant="outlined" color="primary" onClick={() => window.open("mailto:madhavan@socionity.com")}><Icon>mail</Icon>&nbsp;Email</RoundedButton>
                            </CardActions>
                            
                        </Card>
                        <Card style={{marginTop: 12}}>
                            <CardHeader 
                                avatar={<Avatar src="https://socionity.com/images/h.jpeg"/>}
                                title="Harsha Karamchati"
                                subheader="Founder"
                            />
                            <CardContent >
                                <Typography variant="subtitle1" color="textSecondary">
                                    +91 901 041 9999
                                </Typography>
                                <Typography variant="subtitle2" color="textSecondary">
                                    harsha@socionity.com
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <RoundedButton variant="outlined" color="primary" onClick={() => window.open("https://wa.me/+919010419999", "_blank")}><Icon>call</Icon>&nbsp;WhatsApp</RoundedButton>
                                <RoundedButton variant="outlined" color="primary" onClick={() => window.open("mailto:harsha@socionity.com")}><Icon>mail</Icon>&nbsp;Email</RoundedButton>
                            </CardActions>

                            
                        </Card>


                    </DialogContent>
                    <DialogActions>
                        <Button color="primary" onClick={() => this.setState({ helpOpen: null})}>Close</Button>
                    </DialogActions>
                </Dialog>:null}

                {this.props.toast ? <Snackbar
                    anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                    }}
                    open={this.state.snackbarOpen}
                    autoHideDuration={3000}
                    onClose={() => this.setState({snackbarOpen: false})}
                    message={this.props.toast}
                /> : null}

            </div>
        );
    }
}

export default withTheme(MainCTA);