import React from 'react';
import { withTheme } from '@material-ui/styles';
import { Card, CardActionArea, Icon, Typography, CardActions, MenuItem, Badge, Menu, IconButton, ListItem, List } from '@material-ui/core';
import RoundedButton from '../Components/roundedbutton';
import md5 from 'md5';
import StyledDrawer from '../Components/StyledDrawer';

import DeleteIcon from '../Assets/Icons/Delete.svg';
import UnpublishIcon from '../Assets/Icons/Unhide.svg';
import StyledListItems from '../Components/StyledListItems';
import WsContentTitle from './Content/ws-contentpublish';
import WsContentMakeFree from './Content/ws-contentaccess';

class ContentItem extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            menuOpen: false,
            publishDialogOpen: false,
            makeFreeDialogOpen: false,
        }
        this.openDrawerIconRef = React.createRef();
    }

    getSettingErrorCount() {
        const { content } = this.props;
        if(!content.thumbnail ||content.thumbnail.toLowerCase().indexOf("gravatar") > 0)
            return 1;
        return 0;
    }

    getOpenButton(){
        const {content} = this.props;
        if(content.type==='livestream'){
            return <RoundedButton onClick={() => this.props.openContent("contentlivestream")} style={{margin: 12}} variant="contained" color="primary"><Icon>videocam</Icon>&nbsp;Start Livestream</RoundedButton>
        }
        let text = "Edit Content"
        let workspace = "contentdownloadable";
        let count = 0 ;
        if(content.type === 'blog'){
            text = "Edit blog";
            workspace = "contentblog";
            if(content.fileUrl === "<p></p>"){
                count = 1;
            }
        }
        else if(content.type==='videolist'){
            text = "Update videos";
            workspace = "contentvideolist";
            if(!content.fileUrl && content.fileUrl.length === 0){
                count = 1
            }
        }
        else if(content.type === "quiz"){
            text = "edit questions & see responses";
            workspace = "contentquiz";
            if(!content.fileUrl || !content.fileUrl.questions || content.fileUrl.questions.length === 0)
                count = 1
        }    
        else if(content.type === "vimeo"){
            text = "edit vimeo video";
            workspace = "contentvimeo";
        }
        else if(content.type === "zoom"){
            text = "edit zoom webinar";
            workspace = "contentzoom";
        }
        else if(content.type === "image"){
            text = "replace image";
            workspace = "contentimage";
        }

        else if(content.type === "videostream"){
            text = "Edit Stream";
            workspace = "contentvideostream"
        }
        else if(content.type === "video" || content.fileUrl.toLowerCase().trim().endsWith(".mov") || content.fileUrl.toLowerCase().trim().endsWith(".mp4") || content.fileUrl.toLowerCase().trim().endsWith(".webm")){
            text = "Change video";
            workspace = "contentvideo";
        }
        else if(content.fileUrl.toLowerCase().endsWith(".mp3") || content.fileUrl.toLowerCase().endsWith(".ogg")){
            text = "Change audio";
            workspace = "contentaudio";
        }
        else if(content.fileUrl.toLowerCase().endsWith(".pdf")){
            text = "Change PDF"
            workspace = "contentpdf";
        }
        return <Badge color="primary" style={{margin: 12}} badgeContent={count} invisible={count===0}><RoundedButton onClick={() => this.props.openContent(workspace)} variant="outlined" color="primary">{text}</RoundedButton></Badge>
    }
    render() {
        const {content} = this.props;
        return(<>
            {this.props.alwaysOpen ? (
                <Card style={{
                    display: 'flex',
                    padding: '12px',
                    borderRadius: '12px',
                    boxShadow: '0 0 8px 0 rgba(0, 0, 0, 0.08)',
                    border: 'solid 1px #dbdbdb',
                    marginRight: '12px',
                    width: 'calc(min(21.56vw, 276px))',
                    minWidth: '276px',
                    marginBottom: '12px',
                    overflow: 'hidden',
                    ...this.props.style,
                    }}
                >
                    <img
                    onContextMenu={(e) => e.preventDefault()}
                    onClick={() => {
                        this.props.openContent("contentedit")}
                    } 
                    style={{
                        borderRadius: '6px',
                        zIndex: '10',
                        height: '6vw',
                        width: '6vw',
                        maxHeight: '72px',
                        maxWidth: '72px',
                        cursor: 'pointer',
                    }} src={content.thumbnail || `https://gravatar.com/avatar/${md5(content.contentId)}?d=identicon`} />
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flex: '1', marginLeft: '12px', overflow: 'hidden'}}>
                        <Typography 
                            onClick={() => {
                                this.props.openContent("contentedit")}
                            } 
                            variant='body1' style={{cursor: 'pointer', fontWeight: 'bold', maxHeight: '3em', overflow: 'hidden', textOverflow: 'ellipsis', wordBreak: 'break-all'}}>{content.free ? '(Free)\u00A0' : null}{content.name}
                        </Typography>
                        <Typography variant='body2' style={{marginTop: '6px', maxHeight: '2.86em', overflow: 'hidden', wordBreak: 'break-all'}}>{content.description}</Typography>
                    </div>
                    <IconButton ref={this.openDrawerIconRef} style={{alignSelf: 'flex-start', padding: '4px', margin: '-4px', marginLeft: '12px'}} onClick={event => this.setState({ menuOpen: true})}><Icon>more_vert</Icon></IconButton>

                </Card>

            ) : (
                <Card style={{display: 'flex', padding: '12px', borderRadius: '12px', boxShadow: '0 0 8px 0 rgba(0, 0, 0, 0.08)', border: 'solid 1px #dbdbdb', ...this.props.style,}}>
                    <img
                    onContextMenu={(e) => e.preventDefault()} 
                    onClick={() => {
                        this.props.openContent("contentedit")}
                    } 
                    style={{
                        borderRadius: '6px',
                        zIndex: '10',
                        height: '22.78vw',
                        width: '22.78vw',
                        maxHeight: '182.24px',
                        maxWidth: '182.24px',
                        cursor: 'pointer',
                    }} src={content.thumbnail || `https://gravatar.com/avatar/${md5(content.contentId)}?d=identicon`} />
                    <div onClick={() => this.props.openContent("contentedit")} style={{cursor: 'pointer', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flex: '1', marginLeft: '12px'}}>
                        <Typography variant='body1' style={{fontWeight: 'bold', maxHeight: '3em', overflow: 'hidden', wordBreak: 'break-all'}}>{content.free ? '(Free)\u00A0' : null}{content.name}</Typography>
                        <Typography variant='body2' style={{marginTop: '6px', maxHeight: '2.86em', overflow: 'hidden', wordBreak: 'break-all'}}>{content.description}</Typography>
                    </div>
                    <IconButton ref={this.openDrawerIconRef} style={{alignSelf: 'flex-start', padding: '4px', margin: '-4px', marginLeft: '12px'}} onClick={event => this.setState({ menuOpen: true})}><Icon>more_vert</Icon></IconButton>

                </Card>
            )}

            

            <StyledDrawer alwaysOpen={this.props.alwaysOpen} anchorEl={this.openDrawerIconRef.current} title="Select and add content to the folder" onClose={() => {
                this.setState({menuOpen: false, cursor: 'pointer'})}
             } open={this.state.menuOpen} anchor='bottom'>
                <StyledListItems
                    listItems={[{
                        onClick: () => this.props.openContent("contentedit"),
                        icon: 'edit',
                        text: 'Edit Content',
                    }, {
                        onClick: () => this.setState({menuOpen: false, makeFreeDialogOpen: true}),
                        icon: 'money_off',
                        text: content.free?"Make this paid" : "Make this free",
                    }, , {
                        onClick: () => this.setState({menuOpen: false, publishDialogOpen: true}),
                        icon: content.published? <img src={UnpublishIcon} style={{height: '100%'}} /> :"visibility",
                        text: content.published?"Unpublish":"Publish",
                    }, {
                        onClick: () => {this.props.move("up"); this.setState({ menuOpen: false})},
                        icon: 'vertical_align_top',
                        text: this.props.alwaysOpen ? 'Move left' : 'Move up',
                        iconStyle: {transform: this.props.alwaysOpen ? 'rotate(-90deg)' : 'rotate(0deg)'},
                    },  {
                        onClick: () => {this.props.move("down"); this.setState({ menuOpen: false})},
                        icon: 'vertical_align_bottom',
                        text: this.props.alwaysOpen ? 'Move right' : 'Move down',
                        iconStyle: {transform: this.props.alwaysOpen ? 'rotate(-90deg)' : 'rotate(0deg)'},
                    }]}
                />
            </StyledDrawer>

            <WsContentTitle renderAsDialog context={this.props.content} open={this.state.publishDialogOpen} onClose={() => this.setState({publishDialogOpen: false}) || this.props.reload()}/>
            <WsContentMakeFree renderAsDialog context={this.props.content} open={this.state.makeFreeDialogOpen} onClose={() => this.setState({makeFreeDialogOpen: false}) || this.props.reload()}/>
        </>);
    }
}

export default withTheme(ContentItem)