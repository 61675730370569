import { FormControl, Icon, Input, MenuItem, Select } from '@material-ui/core';
import React, {Component} from 'react';

class StyledSelect extends Component {

    selectRef = React.createRef();
    state = {menuProp: null, open: false};

    calculateMenuProps() {
        let menuProp = {
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left"
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left"
            },
            getContentAnchorEl: null,
            PaperProps: {
                style: {
                    borderRadius: '0',
                    marginTop: '4px',
                    borderRadius: '5px',
                    border: 'solid 1px rgba(112, 112, 112, 0.12)',
                    boxShadow: 'none',
                }
            },
        };

        let menuListProp = {
            style: {
                padding: '0',
            }
        }

        const itemHeight = '37.63';
        const minElements = 3, totalElements = 4;

        if(!this.selectRef.current) {
            this.setState({menuProp});
            return;
        }

        const selectElementPosition = this.selectRef.current.getBoundingClientRect();
        const windowHeight = window.innerHeight;
        const spaceBelow = windowHeight - selectElementPosition.bottom - 8;

        // console.log(spaceBelow, itemHeight*totalElements, itemHeight*minElements);

        if( spaceBelow < itemHeight*totalElements && spaceBelow > itemHeight*minElements) {
            menuListProp.style.maxHeight = itemHeight*minElements;
        }

        if( spaceBelow < itemHeight*totalElements) {
            if (spaceBelow > itemHeight*minElements) {
                menuListProp.style.maxHeight = itemHeight*minElements;
            } else {
                menuProp.anchorOrigin.vertical = 'top';
                menuProp.transformOrigin.vertical = 'bottom';
                menuProp.PaperProps.style.marginTop = '-4px';
            }
        }

        menuProp.MenuListProps = menuListProp;
        this.setState({menuProp});

    }

    render() {
        return (
            <FormControl style={{
                border: this.props.variant && this.props.variant == 'underlined' ? 'none' : '1px solid #dbdbdb',
                borderTopLeftRadius: '4px',
                borderBottomLeftRadius: '4px',
                display: 'flex',
                justifyContent: 'center',
                height: 'min-content',
                ...this.props.formControlStyle,
            }}>
                <Select
                    autoWidth
                    value={this.state.currency}
                    onChange={event => this.props.onChange(event)}
                    // open={this.state.open}
                    ref={this.selectRef}
                    onOpen={() => this.calculateMenuProps() || this.setState({open: true})}
                    onClose={() => this.setState({open: false})}
                    style={{fontSize: '0.875em', padding: '0', fontWeight: 'bold'}}
                    IconComponent={() => {
                        return (
                            <Icon style={{
                                pointerEvents: 'none',
                                padding: '0',
                                margin: '-8px',
                                position: 'absolute',
                                right: '8px',
                                transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                                transform: this.state.open ? 'rotate(180deg)' : 'rotate(0deg)',
                                ...this.props.iconStyle,
                            }}>expand_more</Icon>
                        )}
                    }
                    SelectDisplayProps={{
                        style: this.props.style || {
                            overflow: 'visible',
                            padding: '10.5px 0px 10.5px 31px',
                            width: '7ch',
                        }
                    }}
                    disableUnderline={!this.props.variant || this.props.variant != 'underlined'}
                    MenuProps={this.state.menuProp ? this.state.menuProp : {}}
                    displayEmpty
                    value={this.props.value}
                >
                    {this.props.placeholder ? <MenuItem style={{
                            padding: '10.5px 0 10.5px 16px',
                            width: this.selectRef.current && this.selectRef.current.getBoundingClientRect() ? this.selectRef.current.getBoundingClientRect().width : '0',
                            minHeight: '0',
                            height: '2.688em',
                            fontSize: '0.875em',
                            fontWeight: 'bold',
                        }} value="">
                        {this.props.placeholder}
                    </MenuItem> : null}
                    {this.props.itemList.map(option => (
                        <MenuItem style={{
                            padding: '10.5px 0 10.5px 16px',
                            width: this.selectRef.current && this.selectRef.current.getBoundingClientRect() ? this.selectRef.current.getBoundingClientRect().width : '0',
                            minHeight: '0',
                            height: '2.688em',
                            fontSize: '0.875em',
                            fontWeight: 'bold',
                        }} key={option.value} value={option.value}>
                        {option.label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        )
    }
}

export default StyledSelect;