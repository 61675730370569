import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Button, Snackbar, TextField, CircularProgress, AppBar, Toolbar, IconButton, Icon, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, CardContent, CardActions, Card, FormControl, Select, MenuItem, InputLabel } from '@material-ui/core';
import amplitude from 'react-amplitude-js';
import FileUploadButton from '../../Components/fileuploadbutton';
import Illustration from '../../Components/illustration';
import Theme from '../../Components/theme';
import WsHeader from '../../Components/wsheader';
import WsFooter from '../../Components/wsfooter';
import MainCTA from '../../Components/MainCTA';

const FileDownload = require('js-file-download');
const Servercalls = require('../../Utils/Servercalls');

export default class Users extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            users : [],
            loading: true,
            cursor: null,
            sendMessageUser: null,
            title: null, 
            message: null,
            sending: false,
            downloading: false,
            filter: 'all', 
            searchTerm: "",
        }
    }

    componentDidMount() {
        this.reload();
    }

    async reload() {
        amplitude.getInstance().logEvent('users-open');
        const self = this;
        self.setState({ cursor: null })
        let cursor = null;
        while(true){
            const response = await Servercalls.getAppUsers(cursor);
            cursor = response.data.cursor;
            this.setState({ users: this.state.users.concat( response.data.users )});
            if(!cursor)
                break;
        }
        this.setState({ loading: false });
        
    }

    loadMore(){
        const self = this;
        Servercalls.getAppUsers(this.state.cursor).then(response => {
            const { users, cursor } = response.data;
            self.setState({ users: [...this.state.users, ...users], cursor, loading: false });
        });
    }

    sendMessage() {
        const self = this;
        this.setState({ sending: true });
        let receiver = this.state.sendMessageUser.userId;
        Servercalls.sendMessage(receiver, this.state.title, this.state.message, this.state.attachment).then(() => {
            self.setState({ sendMessageUser: null, alert: 'Message sent', sending: false});
        }).catch(e => {
            self.setState({ alert: 'Message not sent. Something went wrong : '+ e.message, sending: false});            
        });
    }

    download() {
        const self = this;
        self.setState({ downloading: true });
        const users = this.state.users.filter(u => this.isPartOfFilter(u));
        let data = 'Email,Phone,Transaction Count\n';
        for(let i = 0 ; i < users.length; i+= 1){
            data += `${users[i].email},${users[i].phone},${users[i].transactions?users[i].transactions.length: 0}\n`
        }
        FileDownload(data, 'users.csv');
        self.setState({ downloading: false });
    }

    getUserScore(user) {
        try{
            let score = 0;
            if(user.email)
                score += 10;
            if(user.phone)
                score += 10;
            if(user.transactions) {
                for(let i = 0; i < user.transactions.length; i+=1)
                    score += user.transactions[i].price;
            }
            return score;
        }
        catch(e){
            return 0;
        }
    }

    block(userId) {
        const self = this;
        self.setState({ blocking: true });
        Servercalls.updateUser(userId, "blocked", true).then(() => {
            self.setState({ blocking: false, blockUser: null });
            self.reload();
        }).catch(e => {
            alert("Couldn't block");
            self.setState({ blocking: false });
        });
    }
    promoteUser(userId, type) {
        const self = this;
        self.setState({ promoting: true });
        Servercalls.updateUser(userId, "type", type).then(() => {
            self.setState({ promoting: false, promoteUser: null });
            self.reload();
        }).catch(e => {
            alert("Couldn't promote");
            self.setState({ promoting: false });
        });
    }

    isPartOfFilter(user) {
        let isPart = false;
        if(!user.email) return false;
        if(this.state.filter === "all")
            isPart = true;
        if(this.state.filter === "paid" && user.transactions && user.transactions.length > 0)
            isPart = true;
        if(this.state.filter === "unpaid" && (!user.transactions || user.transactions.length === 0))
            isPart = true;
        if(isPart && (user.email.toLowerCase().indexOf(this.state.searchTerm) > -1 || (user.phone && user.phone.indexOf(this.state.searchTerm) > -1) || ( user.info && user.info.name && user.info.name.toLowerCase().indexOf(this.state.searchTerm) > -1)))
            isPart = true;
        else 
            isPart = false;
        return isPart;
    }


    render() {
        if(!this.state.loading && this.state.users.length === 0){
            return <div>
                <WsHeader back={this.props.back} title="Users of Your App" description="Here you can find the email address of people who have logged in and all the transactions they've made on your app. You can send them messages" helpText="How to engage with audience?" helpVideo="a"/>

                <Card style={{ width: 'calc(100% - 24px)', margin: 12 }}>
                    <CardContent>
                        <Typography variant="h6">No users yet</Typography>
                        <Typography variant="body1" style={{marginTop: 12}}>Start sharing your app with your friends and family. That's always the right place to start!</Typography>
                        <Illustration image="lonely" style={{ width: '100%'}} />
                    </CardContent>
                </Card>
            </div>
        }
        return (
            <Theme>
                <WsHeader back={this.props.back} title="Users of Your App" description="Here you can find the email address of people who have logged in and all the transactions they've made on your app. You can send them messages" helpText="How to engage with audience?" helpVideo="a"/>
            <div style={{ margin: 12 }}>
                <FormControl >
                    <InputLabel id="demo-simple-select-helper-label">Filter Users</InputLabel>
                    <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.state.filter}
                    onChange={event => this.setState({ filter: event.target.value})}
                    >
                    <MenuItem value="all">All Users</MenuItem>
                    <MenuItem value="paid">Users who have paid</MenuItem>
                    <MenuItem value="unpaid">Users who have not paid</MenuItem>
                    </Select>
                </FormControl>
                <TextField style={{ marginTop: 12 }} onChange={(event) => this.setState({ searchTerm: event.target.value })} fullWidth label="Search" />
                    {this.state.users.filter(user => this.isPartOfFilter(user)).length === 0? (
                        <Card style={{ width: 'calc(100% - 24px)', margin: 12 }}>
                            <CardContent>
                                <Typography variant="h6">No users that match the filter</Typography>
                                <Typography variant="body1" style={{marginTop: 12}}>Please try a different option from the filter menu</Typography>
                                <Illustration image="lonely" style={{ width: '100%'}} />
                            </CardContent>
                        </Card>
                    
                    ) : null
                    }
                    {this.state.users.filter(user => this.isPartOfFilter(user)).sort((a,b) => this.getUserScore(b) - this.getUserScore(a)).map((user,index) => (
                        <Card style={{width: '100%', marginTop: 20}}>
                            <CardContent>
                                <Typography variant="body1"><b>{user.email}</b></Typography>
                                <Typography variant="body1">{user.phone}</Typography>
                                <Typography variant="body2">Transactions : {user.transactions ? user.transactions.length : 0}</Typography>
                                <Typography variant="body2">User Type : {user.type || "Normal"}</Typography>
                            </CardContent>
                            <CardActions>
                                <Button variant="contained" color="secondary" onClick={() => this.setState({ sendMessageUser: user })}><Icon>mail</Icon></Button>
                                <Button disabled={user.blocked} variant="contained" color="primary" onClick={() => this.setState({ blockUser: user })}>Block</Button>
                                <Button variant="contained" color="primary" onClick={() => this.setState({ promoteUser: user })}>Promote</Button>
                            </CardActions>
                        </Card>
                    ))}
                <center>{this.state.loading? <CircularProgress style={{ margin: 16 }} />: (this.state.cursor? <Button onClick={() => this.loadMore() } color="secondary">Load more</Button> : null) }</center>
            </div>
        
            {this.state.sendMessageUser? <Dialog open={this.state.sendMessageUser} onClose={() => this.setState({ sendMessageUser: null })} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Send message to {this.state.sendMessageUser.email} </DialogTitle>
                <DialogContent>
                <DialogContentText>
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    label="Subject"
                    fullWidth
                    value={this.state.title}
                    onChange={ event => this.setState({ title: event.target.value })}
                />
                <br />
                <TextField
                    autoFocus
                    margin="dense"
                    label="Message"
                    fullWidth
                    multiline
                    value={this.state.message}
                    onChange={ event => this.setState({ message: event.target.value })}
                />
                {this.state.attachment? <a href={this.state.attachment} target='_blank'>Open attachment</a>:null}
                <FileUploadButton
                    icon="cloud_upload"
                    text="Attach File"
                    color="secondary"
                    style={{ marginTop: 24 }}
                    onFail={ e => alert(e)}
                    onUpload={url => this.setState({ attachment : url })}
                    maxSizeMBs={5}
                />

                </DialogContent>
                <DialogActions>
                <Button onClick={() => this.setState({ sendMessageUser: null })}>
                    Cancel
                </Button>
                <Button disabled={this.state.sending} onClick={() => this.sendMessage(false)} color="secondary">
                    Send
                </Button>
                </DialogActions>
            </Dialog> : null}
            {this.state.blockUser? <Dialog open={true}>
                    <DialogTitle>Are you sure you want to block this user</DialogTitle>
                    <DialogContent>
                        This user ({this.state.blockUser.email}) will not be able to access your app at all. This is irreversible.
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.setState({ blockUser: null })} color="secondary">Cancel</Button>
                        <Button disabled={this.state.blocking} onClick={() => this.block(this.state.blockUser.userId)} color="primary" variant="contained">Block Permanently</Button>
                    </DialogActions>                    
            </Dialog>: null}
            {this.state.promoteUser? <Dialog open={true}>
                    <DialogTitle>Promote/Demote this user</DialogTitle>
                    <DialogContent>
                        This user ({this.state.promoteUser.email}) will be granted access to various parts of your app depending on the rules you've set. Choose the status for this user.
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={this.state.promoting} variant="outlined" color="secondary" onClick={() => this.promoteUser(this.state.promoteUser.userId, "Normal")}  color="secondary">Normal User</Button>
                        <Button disabled={this.state.promoting} variant="outlined" color="secondary" onClick={() => this.promoteUser(this.state.promoteUser.userId, "Moderator")}  color="secondary">Moderator</Button>
                        <Button disabled={this.state.promoting} variant="outlined" color="secondary" onClick={() => this.promoteUser(this.state.promoteUser.userId, "Hero")}  color="secondary">Hero</Button>
                    </DialogActions>                    
            </Dialog>: null}


            <Snackbar
                anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
                }}
                open={this.state.alert}
                autoHideDuration={3000}
                onClose={() => this.setState({ alert: null })}
                ContentProps={{
                'aria-describedby': 'message-id',
                }}
                message={<span id="message-id">{this.state.alert}</span>}
            />
                <WsFooter text="You can see all the people who have loggedin into your app and the number of transactions they've made on the app. You can send them messages and notifications from here." />
                <MainCTA 
                    text="Send Notification" 
                    icon="mail"
                    disabled={this.state.downloading} 
                    settings={[
                     {text:"Download User List", icon: "download", onClick: () => this.download()},
                     {text: "See scheduled notifications", icon: "queue", onClick: () => this.props.changeWorkspace("appusersnotificationslist", "Scheduled Notifications", {})}
                    ]}
                    help={[]}
                    onCTAClick={() => this.props.changeWorkspace("appusersschedulenotifications", "Schedule Notification", this.props.context)}
                />
            </Theme>
        )
    }
}