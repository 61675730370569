import React from 'react';
import CommonAddContent from './ws-commonaddcontent';

export default class WsAddVideoContent extends React.Component {
    render() {
        return (
            <CommonAddContent
                title="Upload an Audio file"
                description="Video files will play in the Secure Socionity media player. This includes protection from downloading and sharing. You can upload music or podcasts." 
                helpText="Know more about Audio content"
                helpVideo="a"
                filetype="audio"
                {...this.props}
            />
        )
    }
}