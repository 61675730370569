import { ButtonBase, Card, IconButton, InputBase, Menu, MenuItem, Popover, Popper, Typography } from '@material-ui/core';
import { makeStyles, withTheme } from '@material-ui/styles';
import React, { createRef } from 'react';

const useStyles = makeStyles(theme => ({
    root: {
        margin: theme.spacing(1),
        padding: theme.spacing(2, 2, 5.375),
        borderRadius: theme.spacing(1.25),
        boxShadow: 'none',
    },
    title: {
        fontWeight: 'bold',
        fontSize: theme.spacing(2.25),
        marginBottom: theme.spacing(1.625),
    },
    priceInput: {
        position: 'relative',
    },
    inputBase: {
        border: '1px solid #eaeaea',
        background: '#fafafa',
        borderRadius: theme.spacing(0.75),
        width: '100%',
        padding: theme.spacing(1.625),
        marginBottom: theme.spacing(1.5),
    },
    currency: {
        position: 'absolute',
        fontSize: theme.spacing(2.125),
        width: theme.spacing(6),
        height: theme.spacing(6),
        top: 0,
        left: 0,
        lineHeight: 1.4,
        fontWeight: 'bold',
    },
    currencyDivider: {
        height: '28px',
        width: '1px',
        background: '#707070',
        position: 'absolute',
        top: '10px',
        left: '48px',
        opacity: 0.1,
    },
    input: {
        padding: 0,
        overflow: 'scroll !important',
        paddingLeft: theme.spacing(7.5),
    },
    subtitle: {
        textAlign: 'left',
        color: theme.palette.secondary.main,
        marginBottom: theme.spacing(1.5),
    },
    subtitle2: {
        color: '#707070',
        marginBottom: theme.spacing(1.5),
    },
    menuItem: {
        fontSize: theme.spacing(2.125),
        width: theme.spacing(6),
        fontWeight: 'bold',
        display: 'flex',
        justifyContent: 'center',
    }
}))

function getCurrencySymbol(currency) {
    if (currency === 'INR') return '₹';
    if (currency === 'USD') return '$';
    if (currency === 'EUR') return '€';
    if (currency === 'JPY') return '¥';
}

const AddPriceCard = (props) => {
    const classes = useStyles();
    const inputRef = createRef();

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <Card className={classes.root}>
            <Typography className={classes.title} variant='h6'>{props.title}</Typography>
            
            <div className={classes.priceInput}>
                <InputBase
                    ref={inputRef}
                    className={classes.inputBase}
                    placeholder={'Enter Price'}
                    multiline={props.rows ? true : false}
                    inputProps={{
                        className: classes.input,
                        rows: 1,
                        style: {
                            maxHeight: props.theme.spacing(2.375* (1))
                        }                    
                    }}
                    style={props.error ? {borderColor: '#f02e2e'} : {}}
                    value={props.value}
                    onChange={e => props.onChange(e)}
                />
                <ButtonBase onClick={handleClick} className={classes.currency}>{getCurrencySymbol(props.currency)}</ButtonBase>
                
                <div className={classes.currencyDivider} />
                
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        style: {
                            padding: 0,
                        }
                    }}
                    PaperProps={{
                        style: {
                            marginTop: '48px',
                        }
                    }}
                >
                    <MenuItem className={classes.menuItem} onClick={() => handleClose() || props.setCurrency('INR')}>₹</MenuItem>
                    <MenuItem className={classes.menuItem} onClick={() => handleClose() || props.setCurrency('USD')}>$</MenuItem>
                    <MenuItem className={classes.menuItem} onClick={() => handleClose() || props.setCurrency('EUR')}>€</MenuItem>
                    <MenuItem className={classes.menuItem} onClick={() => handleClose() || props.setCurrency('JPY')}>¥</MenuItem>
                </Menu>
            </div>

            <div style={{display: 'flex'}}>
                <Typography style={{color: '#f02e2e', marginRight: 'auto', marginTop: props.theme.spacing(-0.875)}} variant='body2'>{props.error}</Typography>
            </div>

            <Typography className={classes.subtitle} variant='body2'>{'Average pay for a 7 days course is 400'}</Typography>
            <Typography className={classes.subtitle2} variant='body2'>{'Daily settlement will be done directly to your bank account'}</Typography>
        </Card>
    )
}

export default withTheme(AddPriceCard);