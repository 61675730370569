import React from 'react';
import {SketchPicker} from 'react-color';
import { withStyles, MuiThemeProvider } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Fab from '@material-ui/core/Fab';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
//import ColorContrastChecker from 'color-contrast-checker';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import LinearProgress from '@material-ui/core/LinearProgress';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Snackbar from '@material-ui/core/Snackbar';
import FontPicker from "font-picker-react";
import RoundedButton from '../../Components/roundedbutton';

import amplitude from 'react-amplitude-js';

import WsHeader from '../../Components/wsheader';
import WsFooter from '../../Components/wsfooter';
import MainCTA from '../../Components/MainCTA';




const contrast = require('get-contrast');
const Servercalls = require('../../Utils/Servercalls');
const Constants = require('../../Utils/Constants');

const styles = theme => ({
    flex: {
      flex: 1,
    },
    container: {
      paddingTop: theme.spacing.unit * 2,
      paddingBottom: theme.spacing.unit * 2,
      maxWidth: 800,
      margin: 'auto',
      overflowY: 'auto',
      menu: {
        width: 200,
      },
    },
    innerContainer: {
    },
    topBar: {
      position: 'fixed',
      width: '100%',
      zIndex: 100,
      top: 0,
    },
    card: {
      paddingTop: '56%',
      position: 'relative',
    },
    smallCard: {
      paddingTop: '25%',
      position: 'relative',
      marginTop: 8
    },
  
    cardContent: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },
    changeImageButton: {
      margin: theme.spacing.unit * 4,
    },
    fab: {
      position: 'fixed',
      bottom: theme.spacing.unit * 2,
      right: theme.spacing.unit * 2,
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
      },
      secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
      },    
  });
  
  

class ThemePicker extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            bg: '#fafafa',
            fg: '#fff',
            primary: '#3f51b5',
            accent: '#f50057',
            headingfont: 'Roboto',
            mainfont: 'Roboto',
            expanded: null,
            saving: false,
            message: null,
            loading: false            
        };
        
    }

    componentDidMount() {
         amplitude.getInstance().logEvent('theme-open');
        this.reload();
    }  

    reload(){
        const self = this;
        self.setState({ loading: true });
        Servercalls.getApp().then(response => {
            const { bg, fg, primary, accent, mainfont, headingfont } = response.data.theme;
            self.setState({ bg, fg, primary, accent, loading: false, oldTheme: { bg, fg, primary, accent, mainfont, headingfont } });
        }).catch(e => self.setState({loading: false}));

    }

    handleColorChange(type, hex) {
        amplitude.getInstance().logEvent('theme-color-change');
        this.setState({[type]: hex});
        if(type === 'primary') {
            if(contrast.isAccessible(hex, '#fff')){
                this.setState({ primaryText: '#fff'});
            }
            else {
                this.setState({ primaryText: "#000"});
            }
        }
        if(type === 'accent') {
            if(contrast.isAccessible(hex, '#fff')){
                this.setState({ accentText: '#fff'});
            }
            else {
                this.setState({ accentText: "#000"});
            }
        }

    }

    getTheme() {
        return createMuiTheme({
            palette: {
                primary: { main: this.state.primary },
                secondary: { main: this.state.accent },
                type: contrast.isAccessible(this.state.bg, "#fff") ? 'dark' : 'light',
                background: { paper: this.state.fg, default: this.state.bg},
                text: contrast.isAccessible(this.state.bg, "#fff") ? { primary: 'rgba(255,255,255,0.87)', secondary: 'rgba(255,255,255,0.54)', disabled: 'rgba(255,255,255,0.38)', hint: 'rgba(255,255,255,0.34)'}: { primary: 'rgba(0,0,0,0.87)', secondary: 'rgba(0,0,0,0.54)', disabled: 'rgba(0,0,0,0.38)', hint: 'rgba(0,0,0,0.34)'}
            },
            typography: {
                h1: { fontFamily: this.state.headingfont },
                h2: { fontFamily: this.state.headingfont },
                h3: { fontFamily: this.state.headingfont },
                h4: { fontFamily: this.state.headingfont },
                h5: { fontFamily: this.state.headingfont },
                h6: { fontFamily: this.state.headingfont },
                body1: {fontFamily: this.state.mainfont},
                button: {fontFamily: this.state.mainfont},
            }
        })
    }

    save() {
        const self = this;
        self.setState({ saving: true });
        Servercalls.updateAppTheme({ bg: this.state.bg, fg: this.state.fg, primary: this.state.primary, accent: this.state.accent, mainfont: this.state.mainfont, headingfont: this.state.headingfont }).then(() => {
            self.setState({ saving: false, saved: true });
            self.reload();
            setTimeout(() => self.setState({ saved: false }), 3000);
        }).catch(() => {
            self.setState({ saving: false, message: 'Something went wrong. Please try saving again.' });
        })
    }

    getThemeCard(theme){
        return <Card style={{ marginTop: 12, marginLeft: 12, marginRight: 12, backgroundColor: theme.fg}}>
            <CardContent>
            <Typography variant="h6" style={{ fontFamily: theme.headingFont, color: theme.text}}>{theme.title}</Typography>
            <Typography variant="body1" style={{ marginTop: 12, marginBottom: 12, fontFamily: theme.mainFont, color: theme.text}}>{theme.description}</Typography>
            <div style={{ display:'inline-block',height: 50, width: 50, backgroundColor: theme.bg}}></div>
            <div style={{ display:'inline-block',height: 50, width: 50, backgroundColor: theme.fg}}></div>
            <div style={{ display:'inline-block',height: 50, width: 50, backgroundColor: theme.primary}}></div>
            <div style={{ display:'inline-block',height: 50, width: 50, backgroundColor: theme.accent}}></div>
            <br />
            <RoundedButton disabled={this.state.saving} onClick={() => this.setState({ ...theme }, () => this.save())}variant="contained" style={{marginTop: 12, backgroundColor: theme.primary, color: theme.contrastText}}>Set This Theme</RoundedButton>
            </CardContent>
        </Card>
    }

    render() {
        const { classes, onClose } = this.props;
        const { expanded } = this.state;
        if(this.state.saved) return (
            <center style={{ margin: 64}}>
                <Icon style={{ fontSize: 120}} color="secondary">check</Icon>
                <br /><br />
                <Typography variant="body2">App theme has been saved. You'll be redirected shortly</Typography>
            </center>
        )

        return (
            <div>
                <WsHeader back={this.props.back} title="Set Theme" description="Set a theme according to your liking. Play around with the colors and fonts" helpText="How to make a good theme?" helpVideo="" />
                {this.state.loading? <LinearProgress /> : null}    
                {[
                    ...this.state.oldTheme?[{...this.state.oldTheme, text: contrast.isAccessible(this.state.oldTheme.bg, '#fff')?"#fff":"#000", contrastText: contrast.isAccessible(this.state.oldTheme.primary, '#fff')?"#fff":"#000",  title:"Current Theme", description: "This is the theme that is currently on your app"}]:[],
                    { bg: "#000000", fg: "#272727", primary: "#49A600", accent:"#A5F268", title:"Black Money", description: "A professional dark theme for those who care about money", text:"#fff", contrastText:"#fff", headingFont: "Montserrat", mainFont: "Raleway" },
                    { bg: "#000000", fg: "#272727", primary: "#D89B00", accent:"#FFD76F", title:"Gold Fitness", description: "A dark theme with strokes of premium. Great for fitness instructors.", text:"#fff", contrastText:"#fff", headingFont: "Montserrat", mainFont: "Raleway" },
                    { bg: "#000000", fg: "#272727", primary: "#E51E00", accent:"#FD7C68", title:"The Movies", description: "A movies theme you might recognize", text:"#fff", contrastText:"#fff", headingFont: "Playfair Display", mainFont: "Raleway" },
                    { bg: "#000000", fg: "#272727", primary: "#2866C1", accent:"#649CEF", title:"Deep Ocean", description: "A dark theme for hackers", text:"#fff", contrastText:"#fff", headingFont: "Ubuntu", mainFont: "Ubuntu" },
                    { bg: "#FBFBFB", fg: "#F3F3F3", primary: "#49A600", accent:"#388000", title:"Spring Semester", description: "A simple light theme, great for teachers", text:"#010", contrastText:"#fff", headingFont: "Montserrat", mainFont: "Raleway" },
                    { bg: "#FBFBFB", fg: "#F3F3F3", primary: "#D89B00", accent:"#A97A00", title:"Joyful Canvas", description: "A great light theme for kids", text:"#010", contrastText:"#fff", headingFont: "Montserrat", mainFont: "Raleway" },
                    { bg: "#FBFBFB", fg: "#F3F3F3", primary: "#E51E00", accent:"#BA1800", title:"Red AF", description: "A glaring red theme. Not sure why you'd use this.", text:"#010", contrastText:"#fff", headingFont: "Montserrat", mainFont: "Raleway" },
                    { bg: "#FBFBFB", fg: "#F3F3F3", primary: "#2866C1", accent:"#003686", title:"Social Media", description: "A familiar theme for communities", text:"#010", contrastText:"#fff", headingFont: "Montserrat", mainFont: "Raleway" },
                    { bg: "#f1faee", fg: "#fff", primary: "#1d3557", accent:"#457b9d", title:"Cool Ocean", description: "A simple monochrome light theme based on blue", text:"#010", contrastText:"#fff", headingFont: "Montserrat", mainFont: "Raleway" },
                    { bg: "#ffeedd", fg: "#f8f7ff", primary: "#9381ff", accent:"#ffd8be", title:"Lilac", description: "A soft no distractions theme", text:"#010", contrastText:"#000", headingFont: "Oxygen", mainFont: "Roboto" },
                    { bg: "#f7ede2", fg: "#fff", primary: "#b5838d", accent:"#e5989b", title:"Classic Socionity", description: "A modification of Floral used on this app builder", text:"#000", contrastText:"#fff", headingFont: "Montserrat", mainFont: "Raleway" },
                    { bg: "#020f13", fg: "#042229", primary: "#f3e100", accent:"#fff", title:"Studious", description: "A theme based on research for improving concentration", text:"#fff", contrastText:"#000", headingFont: "Montserrat", mainFont: "Raleway" },
                    
                ].map(theme => this.getThemeCard(theme))}

                <Snackbar
                    anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                    }}
                    open={this.state.message}
                    autoHideDuration={6000}
                    onClose={() => this.setState({ message: null })}
                    ContentProps={{
                    'aria-describedby': 'message-id',
                    }}
                    message={<span id="message-id">{this.state.message}</span>}
                    action={[
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        className={classes.close}
                        onClick={() => this.setState({ message: null })}
                    >
                        <CloseIcon />
                    </IconButton>,
                    ]}
                />

            <WsFooter text="Represent yourself with the looks of the app" />
            </div>
        )
    }
}

export default withStyles(styles)(ThemePicker);