import React from 'react';
import Theme from '../Components/theme';
import WsHeader from '../Components/wsheader';
import WsFooter from '../Components/wsfooter';
import { LinearProgress, Button, Icon, Typography, CircularProgress, TextField, Card, CardContent } from '@material-ui/core';
import { Editor as TinyEditor } from '@tinymce/tinymce-react';
import MainCTA from '../Components/MainCTA';
import FileUploadButton from '../Components/fileuploadbutton';

const Servercalls = require('../Utils/Servercalls');


export default class WsLanding extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            content : null,
            loading: true
        }
    }

    componentDidMount(){
        const self = this;
        self.setState({ loading: true });
        Servercalls.pluginlanding_getContent(this.props.tab.pluginId).then(response => {
            self.setState({ ...response.data.content, loading: false });
        }).catch(e => alert(e));
    }

    save(){
        const tab = this.props.context;
        const self = this;
        self.setState({ saving: true });
        Servercalls.pluginlanding_updateContent(this.props.tab.pluginId, { content: this.state.content, valueProposition: this.state.valueProposition, delivery: this.state.delivery, video: this.state.video, cost: this.state.cost}).then(response => {
            self.setState({ saving: false, saved: true});
            setTimeout(() => self.setState({ saved: false}), 3000);
        }).catch(e => { alert(e); self.setState({ saving: false })});
    }


    render() {
        if(this.state.saved) return (
            <center style={{ margin: 64}}>
                <Icon style={{ fontSize: 120}} color="secondary">check</Icon>
                <br /><br />
                <Typography variant="body2">Saved changes to the landing. You'll be redirected shortly</Typography>
            </center>
        )
        if(this.state.loading) return <LinearProgress />

        return(
            <Theme>
                <Card style={{ margin: 12 }}>
                    <CardContent>
                        <Typography variant="h5">Your Sales Page</Typography>
                        <Typography variant="body1" style={{marginTop: 12}}>This is a <a href="https://www.black.design/messaging/">research driven</a> effective approach to drive sales. Add information here and we'll do the rest.</Typography>
                        <Typography variant="h6" style={{marginTop: 24}}>Value Proposition</Typography>
                        <Typography style={{marginTop: 12}} variant="body2">Describe in less than 80 characters what value your business enables, and for whom. This is usually an intangible quality that is closely linked to the user experience of your product.</Typography>                                                
                        <TextField style={{ marginTop: 12}} defaultValue={this.state.valueProposition} label="Value Proposition (What)" onChange={event => this.setState({ valueProposition: event.target.value})} fullWidth />
                        <Typography style={{marginTop: 12}} variant="body2">“How” is what most creators mistake for “what”. Being able to access interviews of global celebrities is value of Joe Rogan Experience. The candid nature and free flow style that lasts over 2 hours unlike other 20 minute podcasts is how it delivers its value.</Typography>                                                                        
                        <TextField style={{marginTop:12}} defaultValue={this.state.delivery} label="Value Add (How)" onChange={event => this.setState({ delivery: event.target.value})} fullWidth />

                        <Typography style={{marginTop: 24}} variant="h6">Welcome Video</Typography>
                        <Typography style={{marginTop: 12}} variant="body2">Welcome the users with cheer and enthusiasm. Help them build an immediate personal connection with you. People want to establish trust before making a purchase.</Typography>                                                                        
                        
                        {this.state.video? <video controls preload playsInline src={this.state.video} style={{ height: 200, marginTop: 12 }}/> : null}
                        <FileUploadButton
                            style={{ marginTop: 12 }}
                            color="secondary"
                            icon="videocam"
                            text="Upload new video"
                            extensions={['mp4','webm', 'mov', 'avi']}
                            maxSizeMBs={2000}
                            onFail = { e => alert(e)}
                            onUpload = { url => this.setState({ video: url })}
                        />

                        <Typography style={{marginTop: 24}} variant="h6">Cost Justification</Typography>                        
                        <Typography style={{marginTop: 12}} variant="body2">Explanation of pricing goes here. Why does it cost this much? Why is it more expensive or cheaper than other competing products?</Typography>                        
                        <TextField  style={{marginTop: 12}} defaultValue={this.state.cost} onChange={event => this.setState({ cost: event.target.value})} fullWidth />



                        <Typography style={{marginTop: 24}} variant="h6">About you</Typography>                        
                        <Typography style={{marginTop: 12}} variant="body2">Tell why they should buy from you and no one else. Your experience, your uniqueness should be highlighted here.</Typography>                        

                    <TinyEditor
                        inline={true}
                        style={{ height: '100%', width: '100%'}}
                        initialValue={this.state.content || "<p>Start typing here ...</p>"}
                        
                        init={{
                        images_upload_handler : async (blobInfo, resolve, reject) => {
                            let blob = blobInfo.blob();
                            let f = new File([blob],blobInfo.filename());
                            const response = await Servercalls.uploadToS3(f);
                            resolve(response.url);
                        },
                        plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount'
                          ],

                        menubar: "view",
                        imagetools_cors_hosts: ['build.socionity.com', 'cdn.socionity.com'],
                        height: '100%',
                        toolbar: "undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image media",
                        imagetools_toolbar: "rotateleft rotateright | flipv fliph | editimage imageoptions"

                        }}
                        onChange={(e) => this.setState({content: e.target.getContent()})}
                />



                    </CardContent>
                </Card>
                <WsFooter text="Use this tab to tell users about yourself and your project" />
                <MainCTA aboveTabs icon="save" text="Save" onCTAClick={() => this.save()} settings={[]} help={[]}/>
            </Theme>
        )
    }
}