import { Typography } from '@material-ui/core';
import React from 'react';

const Constants = require('../Utils/Constants');

class LegacyBuilder extends React.Component {
    componentDidMount() {
        window.location.href = "https://build.socionity.com";
    }
    render() {
        return (
            <Typography variant="body2" style={{ margin: 20 }}>
                Redirecting you to the old builder ...
            </Typography>
        )
    }
} 

export default LegacyBuilder;