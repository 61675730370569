import { Icon, ListItem, Typography } from '@material-ui/core';
import React from 'react';

const StyledListItems = ({listItems}) => {
    return (
        <>
            {listItems.map(item => (
                <ListItem style={{padding: '12px 20px'}} button disabled={!item.onClick} onClick={item.onClick}>
                    <Icon style={{color: '#666666', fontSize: '1.25em', marginRight: '14px', ...item.iconStyle}}>{item.icon}</Icon>
                    <Typography variant='body2' style={{color: '#666666'}}>{item.text}</Typography>
                </ListItem>
            ))}
        </>
    )
}

export default StyledListItems;