import { Card, Icon, IconButton, Typography } from '@material-ui/core';
import React, {Component} from 'react';
import ConfirmUnsavedDialog from '../Components/ConfirmUnsavedDialog';
import FileUploadButton from '../Components/fileuploadbutton';
import MainCTA from '../Components/MainCTA';
import StyledInput from '../Components/StyledInput';
import WsHeader from '../Components/wsheader';
import RoundedButton from '../Components/roundedbutton';
import md5 from 'md5';
import SuccessDialog from '../Components/SuccessDialog';
import ContainerHeader from '../Testing/UI/ContainerHeader';
import MembersList from '../Testing/UI/MembersList';
import MemberSearch from '../Testing/UI/MemberSearch';
const Servercalls = require('../Utils/Servercalls');

class ChatCreate extends Component {
    state = {
        step: 0,

        successDialogOpen: false,
        confirmUnsavedDialogOpen: false,
        confirmUnsavedResolver: null,

        groupIcon: 'https://cdn.socionity.com/assets/build.socionity.com/illustrations/nobannerimage.png',
        groupName: '',
        groupManifesto: '',
        groupRules: '',

        userList: [],
        selectedUserIndices: {},

        userFilter: null,
    }

    async componentDidMount() {
        const self = this;
        this.setState({loading: true});

        self.setState({ cursor: null })
        let cursor = null;
        while(true){
            const response = await Servercalls.getAppUsers(cursor);
            cursor = response.data.cursor;
            this.setState({ userList: this.state.userList.concat( response.data.users )});
            if(!cursor)
                break;
        }
        const {selectedUserIndices} = this.state;
        this.state.userList.map((user, index) => selectedUserIndices[index] = false)
        const userList = this.state.userList.map((user, index) => ({...user, index}))
        this.setState({ loading: false, selectedUserIndices, userList });
    }

    async save() {
        const {groupIcon, groupName, groupManifesto, groupRules} = this.state;
        let chatroom = {}
        chatroom.chatroomLogoUrl = groupIcon;
        chatroom.chatroomName = groupName;
        chatroom.chatManifesto = groupManifesto;
        chatroom.chatRules = groupRules;
        chatroom.rules = [
            {groupType: 'private', allowUsers: this.state.userList.filter((user) => this.state.selectedUserIndices[user.index] === true).map(user => user.userId)}    
        ];

        const response = await Servercalls.addChatroom(this.props.context.appId, this.props.context.pluginId, chatroom);
        console.log(response);
        if (response.data === 'ok')
            this.setState({successDialogOpen: true}, () => setTimeout(() => this.props.back(), 3000))
        else
            this.setState({failureDialogOpen: true}, () => setTimeout(() => this.props.back(), 3000))
    }

    renderMembers() {
        return (
            <MembersList users={this.state.userList.filter((user, index) =>  (
                !this.state.userFilter || (user.info && user.name && user.info.name.startsWith(this.state.userFilter)) || user.email.startsWith(this.state.userFilter))).map((user)=>(
                    {
                        ...user,
                        icon: <img src={ user.info && user.info.picture ? user.info.picture : `https://gravatar.com/avatar/${md5(user.userId)}?d=identicon`} />,
                        name: user.info && user.info.name ? user.info.name : user.email,
                        email: user.email,
                        action: this.state.selectedUserIndices[user.index] ? 'Remove' : 'Add',
                        onClick: this.state.selectedUserIndices[user.index] ? () => {
                            const {selectedUserIndices} = this.state;
                            selectedUserIndices[user.index] = false;
                            this.setState({selectedUserIndices})
                            if (this.state.userFilter)
                                this.setState({userFilter: null})
                        } : () => {
                            const {selectedUserIndices} = this.state;
                            selectedUserIndices[user.index] = true;
                            this.setState({selectedUserIndices})
                            if (this.state.userFilter)
                                this.setState({userFilter: null})
                        },
                    }
                ))} 
            />
        )
        return (
            <div style={{display: 'flex', flexDirection: 'column', padding: '0'}}>
                
                <div style={this.props.context.alwaysOpen ? {display: 'flex', flexWrap: 'wrap'} : {}}>
                    {this.state.userList.filter((user, index) =>  (user.info && user.name && user.info.name.startsWith(this.state.userFilter)) || user.email.startsWith(this.state.userFilter)).map((user, index)=>(
                        <>
                            {this.props.context.alwaysOpen ? (
                                <Card style={{
                                    display: 'flex',
                                    paddingBottom: '16px',
                                    boxShadow: 'none',
                                    borderRadius: '0px',
                                    border: 'solid 1px #dbdbdb',
                                    marginRight: '16px',
                                    width: 'calc(min(21.56vw, 276px))',
                                    minWidth: '276px',
                                    marginBottom: '16px',
                                    overflow: 'hidden',
                                    }}
                                >
                                    <img
                                        onContextMenu={(e) => e.preventDefault()}
                                        onClick={() => {
                                            // this.props.openContent("contentedit")
                                        }} 
                                        style={{
                                            borderRadius: '50%',
                                            zIndex: '10',
                                            height: '6vw',
                                            width: '6vw',
                                            maxHeight: '72px',
                                            maxWidth: '72px',
                                            cursor: 'pointer',
                                        }} src={ user.info && user.info.picture ? user.info.picture : `https://gravatar.com/avatar/${md5(user.userId)}?d=identicon`} />
                                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flex: '1', marginLeft: '12px', overflow: 'hidden'}}>
                                        <Typography 
                                            onClick={() => {
                                                // this.props.openContent("contentedit")}
                                            }}
                                            variant='body1' style={{cursor: 'pointer', fontWeight: 'bold', maxHeight: '3em', overflow: 'hidden', textOverflow: 'ellipsis', wordBreak: 'break-all'}}>
                                                {user.info && user.info.name ? user.info.name : user.email}
                                        </Typography>
                                        <Typography variant='body2' style={{maxHeight: '2.86em', overflow: 'hidden', wordBreak: 'break-all', color: '#999990'}}>{user.email}</Typography>
                                    </div>
                                    <RoundedButton style={{alignSelf: 'center'}} color='secondary' variant='contained'>Remove</RoundedButton>
                                </Card>

                            ) : (
                                <Card style={{
                                        display: 'flex',
                                        paddingBottom: '16px',
                                        borderRadius: '0px',
                                        boxShadow: 'none',
                                        borderBottom: index === this.state.userList.length - 1 ? 'none' : 'solid 1px #dbdbdb',
                                        marginBottom: index === this.state.userList.length - 1 ? '0' : '16px',
                                    }}>
                                    <img
                                    onContextMenu={(e) => e.preventDefault()} 
                                    onClick={() => {
                                        // this.props.openContent("contentedit")
                                    }} 
                                    style={{
                                        borderRadius: '50%',
                                        zIndex: '10',
                                        height: '22.78vw',
                                        width: '22.78vw',
                                        maxHeight: '46px',
                                        maxWidth: '46px',
                                        cursor: 'pointer',
                                    }} src={ user.info && user.info.picture ? user.info.picture : `https://gravatar.com/avatar/${md5(user.userId)}?d=identicon`} />
                                    <div 
                                        // onClick={() => this.props.openContent("contentedit")}
                                        style={{cursor: 'pointer', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flex: '1', marginLeft: '12px'}}
                                    >
                                        <Typography variant='body1' style={{fontWeight: 'bold', maxHeight: '3em', overflow: 'hidden', wordBreak: 'break-all'}}>{user.info && user.info.name ? user.info.name : user.email}</Typography>
                                        <Typography variant='body2' style={{maxHeight: '2.86em', overflow: 'hidden', wordBreak: 'break-all', color: '#999990'}}>{user.email}</Typography>
                                    </div>
                                    {this.state.selectedUserIndices[index] ? (
                                        <RoundedButton onClick={() => {
                                            const {selectedUserIndices} = this.state;
                                            selectedUserIndices[index] = false;
                                            this.setState({selectedUserIndices})
                                        }} style={{alignSelf: 'center'}} color='secondary' variant='outlined'>Remove</RoundedButton>
                                    ) : (
                                        <RoundedButton onClick={() => {
                                            const {selectedUserIndices} = this.state;
                                            selectedUserIndices[index] = true;
                                            this.setState({selectedUserIndices})
                                        }} style={{alignSelf: 'center'}} color='secondary' variant='contained'>Add</RoundedButton>
                                    )}
                                </Card>
                            )}
                        </>
                    ))}
                </div>
            </div>
        )
    }

    renderContent() {
        if (this.state.step === 0) 
        return (
            <>
                <Card style={{minHeight: 'calc(100% - 56px)', display: 'flex', flexDirection: 'column', marginTop: '56px'}}>
                    <div style={{display: 'flex', flexDirection: 'column', 'alignItems': 'center'}}>
                        <div style={{margin: '32px'}}>
                            <img style={{borderRadius: '20px', zIndex: '10', boxShadow: 'rgba(0, 0, 0, 0.32) 0px 0px 16px 0px', width: 'calc(min(14.11vh, 92px)', height: 'calc(min(14.11vh, 92px)', objectFit: 'cover'}} src={this.state.groupIcon} />
                            <FileUploadButton
                                containerStyles={{position: 'relative', zIndex: '20', marginTop: '-105%'}}
                                icon="photo_camera"
                                color="secondary"
                                variant="text"
                                style={{border: '1px solid', borderRadius: '20px', background: 'rgba(255, 255, 255, 0.3)',padding: '0', minWidth: '0', height: 'calc(min(14.11vh, 92px)', width: 'calc(min(14.11vh, 92px)'}}
                                onFail={ e => alert(e)}
                                onUpload={url => this.setState({ groupIcon : url})}
                                onEnter={() => window.analytics.track('Logo Image Change Clicked', {})}
                                extensions={['png', 'jpg','jpeg']}
                                maxSizeMBs={1}
                            />
                        </div>
                        <div style={{display: 'flex', margin: '0 20px 20px', flexDirection: 'column', alignSelf: 'stretch'}}>
                            <StyledInput alwaysOpen={this.props.context.alwaysOpen} value={this.state.groupName} onChange={e => this.setState({groupName: e.target.value})} placeholder='Enter Group Name' />
                            <StyledInput alwaysOpen={this.props.context.alwaysOpen} style={{marginTop: '24px'}} value={this.state.groupManifesto} onChange={e => this.setState({groupManifesto: e.target.value})} placeholder='Enter manifesto' />
                            <Typography variant="body1" style={{marginRight: 'auto', fontWeight: 'bold', marginTop: '16px'}}>
                                Community Rules
                            </Typography>
                            <StyledInput
                                alwaysOpen={this.props.context.alwaysOpen}
                                style={{marginTop: '8px'}}
                                inputStyle={{padding: '20px', minHeight: '145px'}}
                                value={this.state.groupRules}
                                onChange={e => this.setState({groupRules: e.target.value})}
                                placeholder='Enter community rules'
                                variant='outlined'
                                multiline
                            />
                        </div>
                    </div>
                </Card>

                <MainCTA
                    alwaysOpen={this.props.context.alwaysOpen}
                    square={!this.props.context.alwaysOpen}
                    text="Continue"
                    onCTAClick={() => this.setState({step: 1})}
                    disabled={this.state.groupName === '' || this.state.groupManifesto === ''}
                    help={[{}]}
                    settings={[]}
                    toast='Please add the group name and manifesto to proceed'
                />
            </>
        )

        if (this.state.step === 1) 
        return (
            <div style={{minHeight: 'calc(100% - 98px)', display: 'flex', flexDirection: 'column', marginTop: '56px'}}>
                {/* <Card style={{zIndex: '250'}}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <div style={{margin: '24px'}}>
                            <img style={{borderRadius: '16px', zIndex: '10', boxShadow: 'rgba(0, 0, 0, 0.32) 0px 0px 16px 0px', width: 'calc(min(14.11vh, 72px)', height: 'calc(min(14.11vh, 72px)', objectFit: 'cover'}} src={this.state.groupIcon} />
                            <FileUploadButton
                                containerStyles={{position: 'relative', zIndex: '20', marginTop: '-105%'}}
                                icon="photo_camera"
                                color="secondary"
                                variant="text"
                                style={{border: '1px solid', borderRadius: '16px', background: 'rgba(255, 255, 255, 0.3)',padding: '0', minWidth: '0', height: 'calc(min(14.11vh, 74px)', width: 'calc(min(14.11vh, 74px)'}}
                                onFail={ e => alert(e)}
                                onUpload={url => this.setState({ newLogo : url})}
                                onEnter={() => window.analytics.track('Logo Image Change Clicked', {})}
                                extensions={['png', 'jpg','jpeg']}
                                maxSizeMBs={1}
                            />
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', flex: '1', margin: '20px', marginLeft: '0'}}>
                            <Typography variant='body1' style={{fontWeight: 'bold', maxHeight: '3em', overflow: 'hidden', wordBreak: 'break-all'}}>{this.state.groupName}</Typography>
                            <Typography variant='body2' style={{maxHeight: '2.86em', overflow: 'hidden', wordBreak: 'break-all', color: '#999990'}}>{this.state.groupManifesto}</Typography>
                            <div style={{display: 'flex', marginTop: '10px'}}>
                                <RoundedButton style={{padding: '6px 16px', borderRadius: '20px'}} color='secondary' variant='outlined' onClick={() => this.props.changeWorkspace('createchat', 'Create Group', this.props.context)}>
                                    <Typography variant="body2" style={{fontWeight: 'bold', color: '#562eff'}}>
                                        Edit
                                    </Typography>
                                </RoundedButton>
                                <RoundedButton style={{padding: '6px 16px', borderRadius: '20px', marginLeft: '12px'}} color='secondary' variant='outlined' onClick={() => this.props.changeWorkspace('createchat', 'Create Group', this.props.context)}>
                                    <Typography variant="body2" style={{fontWeight: 'bold', color: '#562eff'}}>
                                        Share
                                    </Typography>
                                </RoundedButton>
                            </div>
                        </div>
                    </div>
                </Card> */}
                <Card elevation={0} style={{boxShadow: 'none', padding: '0px', paddingBottom: '0', flex: '1'}}>
                    {/* <div style={{display: 'flex', alignItems: 'center', marginBottom: '12px'}}>
                        <Typography variant='body1' style={{fontWeight: 'bold', maxHeight: '3em', overflow: 'hidden', wordBreak: 'break-all', marginRight: 'auto'}}>
                            {this.state.userList.length} Members
                        </Typography>
                        <RoundedButton onClick={() => {
                            const {selectedUserIndices} = this.state;
                            this.state.userList.map((user, index) => selectedUserIndices[index] = true)
                            this.setState({ selectedUserIndices });
                        }} style={{alignSelf: 'center'}} color='secondary' variant='outlined'>Select All</RoundedButton>
                    </div> */}
                    <ContainerHeader
                        title={`${this.state.userList.length} Members`} 
                        onClick={() => {
                            const {selectedUserIndices} = this.state;
                            this.state.userList.map((user, index) => selectedUserIndices[index] = true)
                            this.setState({ selectedUserIndices });
                        }}
                        action='Select all'
                    />

                    {/* <StyledInput placeholder='Search for Members' style={{width: '100%'}} variant='outlined' value={this.state.userFilter} onChange={(e) => this.setState({userFilter: e.target.value})}/> */}
                    <MemberSearch onClick={() => this.setState({userFilter: ""})} open={this.state.userFilter || this.state.userFilter === ""} onBack={() => this.setState({userFilter: null})} value={this.state.userFilter} onChange={(e) => this.setState({userFilter: e.target.value})} />

                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        {this.renderMembers()}
                    </div>  
                </Card>

                <SuccessDialog text='Yay! Group has been created successfully' open={this.state.successDialogOpen}/>
                <SuccessDialog text='We are unable to process your request at this moment, please try again' open={this.state.failureDialogOpen} failed/>
                
                <MainCTA
                    alwaysOpen={this.props.context.alwaysOpen}
                    square={!this.props.context.alwaysOpen}
                    text="Create Group"
                    onCTAClick={() => this.save()}
                    disabled={false}
                    help={[{}]}
                    settings={[]}
                />

            </div>
        )
    }

    render() {
        
        return(
            <>
                <WsHeader back={async() => {
                    if (this.state.step === 1) {
                        this.setState({step: 0});
                        return;
                    }

                    const self = this;
                    let confirmUnsaved = () => new Promise(resolve => self.setState({confirmUnsavedDialogOpen: true, confirmUnsavedResolver: resolve}))
                    const confirmation = await confirmUnsaved();
                    self.setState({confirmUnsavedDialogOpen: false, confirmUnsavedResolver: null});

                    if(!confirmation) {
                        // cannot save here as it will break design incase of incomplete input
                        // this.save()
                        return;
                    } else {
                        this.props.back()
                    }
                }}  title={this.state.step === 0 ? "Create A Group" : "Add Members"}/>

                {this.renderContent()}

                <ConfirmUnsavedDialog open={this.state.confirmUnsavedDialogOpen} resolve={this.state.confirmUnsavedResolver} body='Your group has not been created. Any changes made will not be reflected further. Do you wish to proceed without saving?' />
            </>
        )
    }
}

export default ChatCreate;