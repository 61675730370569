import React from 'react';
import { Button, Icon } from '@material-ui/core';
import {withTheme} from '@material-ui/styles';
const Constants = require('../Utils/Constants');
class CircularButton extends React.Component {
    render() {
        return (
            <Button variant="contained" style={{ width: 36, height: 36, borderRadius: 18, padding: 6, minWidth: 0, ...this.props.color?{}:{backgroundColor:Constants.theme.palette.background.card }}} {...this.props}><Icon>{this.props.icon}</Icon></Button>
        )
    }
}

export default withTheme(CircularButton)