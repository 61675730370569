import React from 'react';
import { FilePicker } from 'react-file-picker';
import { Button, Icon, DialogTitle, DialogContent, DialogActions, Card, CardContent, CardHeader, CircularProgress, Avatar, CardActions, Typography, Dialog, LinearProgress } from '@material-ui/core';

import Theme from './theme';
import RoundedButton from './roundedbutton';

const Servercalls = require('../Utils/Servercalls');

export default class FileUploadButton extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      uploading: false,
      processing: false,
      files: [{name: "Big Bunny", url: "https://www.w3schools.com/html/mov_bbb.mp4" }],
      loading: false,
    }
  }

  componentDidMount(){
    this.reload();
  }

  reload(){
    const self = this;
    self.setState({ loading: true, url: null, filename: null });
    Servercalls.getMediaLibrary().then(response => {
      self.setState({ loading: false, files: response.data, optimizing: false });
    }).catch(e => self.setState({ loading: false }));
  }


  optimizerListener(url, filename, id, uploadId){
    const self = this;
    Servercalls.optimizeVideoStatus(id).then(response => {
      const job = response.data.Job;
      console.log(job);
      if(job.Status === "COMPLETE"){
        if(self.optimizerListenerInterval){
          Servercalls.updateMediaLibrary(uploadId, url);          
          self.setState({ processing: false });
          self.select(url, filename);
          self.reload();
          clearInterval(self.optimizerListenerInterval);
        }
      }
      else {
        self.setState({ processing: true });
          
      }
    })
  }


  async optimizeVideo(objectUrl, filename, uploadId) {
    const self = this;
    self.setState({ processing: true });
    Servercalls.optimizeVideo(objectUrl).then(response => {
      const { data, downloadUrl } = response.data;
      const id = data.Job.Id;
      self.optimizerListenerInterval = setInterval(() => self.optimizerListener(downloadUrl, filename, id, uploadId),10000);
    })
  }

    uploadFile(file, options = null) {
        const self = this;
        self.setState({ uploading: true });
        if(this.props.onUploadStart)
          this.props.onUploadStart();
        if (FileReader) {
          var fr = new FileReader();
          try {
            fr.onload = function () {
              Servercalls.uploadToS3(file, (uploaded, total) => { 
                self.setState({ completed: uploaded / (total + 1)})
              }).then( ({url, uploadId}) => {
                if(!options || !options.optimizeVideo){
                  self.select(url, file.name);
                  self.setState({ uploading: false});
                  self.reload();
                }
                else {
                  self.optimizeVideo(url, file.name, uploadId);
                }
              }).catch(e => {
                self.props.onFail(e);
                self.setState({ uploading: false});
              })
            }
            fr.readAsDataURL(file);
          } catch (e) {
            self.props.onFail(e);
            self.setState({ uploading: false });        
          }
        }
      }
    
      isSelectable(url) {
        if(!this.props.extensions) return true;
        const extensions = this.props.extensions;
        for(let i = 0; i < extensions.length; i+= 1){
          if(url.toLowerCase().endsWith(extensions[i].trim().toLowerCase()))
            return true;
        }
        return false;
      }

      select(url, filename){
        this.props.onUpload(url, filename);        
        this.setState({ fileSelectorOpen: false, url: null, filename: null });
      }
    render() {
        return (
            <div style={{position: 'absolute', zIndex: '10', ...this.props.containerStyles}}>
            <Theme>
              <RoundedButton
                onClick={() => this.setState({ fileSelectorOpen: true, uploading: false })}
                disabled={this.props.disabled}
                color={this.props.color}
                variant={this.props.variant}
                style={this.props.style}>
                  {this.props.icon ? <Icon style={{fontSize: window.innerWidth > 600 ? '4em' : '1.5em', marginRight: this.props.text ? '0.4em' : '0', ...this.props.iconStyle}}>{this.props.icon}</Icon> : null}
                  {this.props.text}
              </RoundedButton>
              
              <Dialog onEnter={this.props.onEnter} open={this.state.fileSelectorOpen}>
                  <DialogTitle>Select a file - Media Library</DialogTitle>
                  <DialogContent>
                    <Card style={{ width: '100%'}}>
                      <CardHeader title="Upload new file" />
                      <CardContent>
                        <FilePicker
                          extensions={this.props.extensions}
                          maxSize={this.props.maxSizeMBs}
                          onError={errMsg => alert(errMsg)}
                          onChange={(FileObject) => {
                            if(FileObject.name.toLowerCase().endsWith(".webm") || FileObject.name.toLowerCase().endsWith(".mp4") || FileObject.name.toLowerCase().endsWith(".mov")){
                              this.setState({ videoOptimizationPrompt: FileObject });
                            }
                            else{
                              this.uploadFile(FileObject, null);
                            }
                          }}
                        >
                          <center>
                            <RoundedButton color="primary" variant="contained" style={{ margin: 12 }} disabled={this.state.uploading}>Upload</RoundedButton>
                            {this.state.uploading?<div>
                              <LinearProgress variant={this.state.processing? "indeterminate": "determinate"} fullWidth value={parseInt(this.state.completed * 100)} />
                              <Typography variant="body2" style={{ marginTop: 12 }}>Please do not close this window till fully uploaded. This might take some time.</Typography>
                              </div>: null}
                          </center>
                        </FilePicker>
                      </CardContent>
                    </Card>
                    {this.state.loading? <center style={{margin: 12 }}><CircularProgress color="primary" /></center>: null }
                    {this.state.files.filter(f => this.isSelectable(f.url)).map(f => (
                      <Card style={{ width: '100%', marginTop: 12}}>
                        <CardHeader avatar={<Avatar>{f.name? f.name[0]: "?"}</Avatar>} title={f.name} />
                        <CardContent>
                          <center>
                          {(() => {
                            if(this.state.showUrl !== f.url)
                              return <RoundedButton variant="outlined" onClick={() => this.setState({ showUrl: f.url})} style={{marginTop: 12, marginBottom: 12}}> Show file </RoundedButton>
                            if(f.url.toLowerCase().endsWith(".mp4") || f.url.toLowerCase().endsWith(".webm")){
                              return <video style={{ height: 150 }} src={`${f.url}#t=0.5`} preload="metadata" controls></video>
                            }
                            if(f.url.toLowerCase().endsWith(".jpg") || f.url.toLowerCase().endsWith(".jpeg") || f.url.toLowerCase().endsWith(".png") || f.url.toLowerCase().endsWith(".gif") || f.url.toLowerCase().endsWith(".webp")){
                              return <img style={{ height: 150 }} src={f.url} />
                            }
                            return <div>
                                <Typography variant="body1">Preview not available</Typography>
                                <RoundedButton onClick={() => window.open(f.url, "_blank")}>Open downloadable file</RoundedButton>
                              </div>
                          })()}
                          </center>
                        </CardContent>
                        <CardActions>
                          <RoundedButton onClick={() => this.select(f.url, f.name)} color="primary" variant="contained">Select</RoundedButton>
                        </CardActions>
                      </Card>
                    ))}
                    

                  </DialogContent>
                  <DialogActions>
                    <Button color="secondary" onClick={() => this.setState({ fileSelectorOpen: false })}>Dismiss</Button>
                  </DialogActions>
              </Dialog>
              {
                this.state.videoOptimizationPrompt?<Dialog open={true}>
                  <DialogTitle>Video is not optimized for streaming</DialogTitle>
                  <DialogContent>
                    <Typography variant="body1">To ensure smooth streaming for all users you must optimize the video for online streaming.</Typography>
                  </DialogContent>
                  <DialogActions>
                    <RoundedButton variant="outlined" color="secondary" onClick={() => { this.uploadFile(this.state.videoOptimizationPrompt, null); this.setState({ videoOptimizationPrompt: null })}}>Don't optimize</RoundedButton>
                    <RoundedButton variant="contained" color="secondary" onClick={() => { this.uploadFile(this.state.videoOptimizationPrompt, {optimizeVideo: true}); this.setState({ videoOptimizationPrompt: null })}}>Optimize</RoundedButton>
                  </DialogActions>
                </Dialog>: null
              }
            </Theme>
            </div>
        )
    }
}