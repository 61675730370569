import React from 'react';
import { Card, Toolbar, Typography, IconButton, Icon, Menu, MenuItem, Dialog, DialogTitle, DialogContent, DialogActions, Button, AppBar, SwipeableDrawer, List, ListItem, ListItemIcon, ListItemText, CardContent } from '@material-ui/core';
import { withTheme } from '@material-ui/styles';
import { getWindowDimensions } from '../Utils/utils';
import RoundedButton from './roundedbutton';
import CircularButton from './circularbutton';
import { AreaSeries, ArgumentAxis, Chart, LineSeries, ValueAxis } from '@devexpress/dx-react-chart';
import moment from 'moment';

import HamburgerIcon from '../Assets/Icons/Hamburger.svg';

const utils = require('../Utils/utils');

const Servercalls = require('../Utils/Servercalls');
class Header extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            helpOpen: false,
            drawerOpen: false,
        }
    }

    getMenuItem(item){
        return (
            <MenuItem disabled={item.disabled} onClick={item.onClick}><Icon>{item.icon}</Icon>&nbsp; {item.text}</MenuItem>
        )
    }

    componentDidMount(){
        this.loadPayments();
    }

    async loadPayments() {
        try{
        let cursor = null;
        let payments = [];
        const now = new Date();
        const nowMonth = now.getMonth();
        this.setState({ loadingPayments: true });
        

        for(let i = 0 ; i < 20; i+= 1){
            try{
                    const data = (await Servercalls.getAppPayments("nouser", cursor)).data;

                    payments = payments.concat(data.payments);
                    cursor = data.hasMore;

                    const paymentTimestamp = new Date(payments[payments.length - 1].timestamp);
                    const paymentMonth = paymentTimestamp.getMonth();
                    if(nowMonth - paymentMonth > 1)
                        break;
                    if(!cursor)
                        break;
                    if(payments.length > 3000){
                        break;
                    }

                }
                catch(e){
                    break;
                }
            }
            let lastLogin = localStorage.getItem("lastlogin");
            let graph = {};
            let since = {};
            let count = 0;
            let thisMonth = {};
            let target = {};
            const reversePayments = payments.sort((p1,p2) => p1.timestamp - p2.timestamp);
            for(let j = 0 ; j < reversePayments.length; j+= 1){
                const p = reversePayments[j];
                const pTime = new Date(p.timestamp);
                const pMonth = pTime.getMonth();
                const pDay = pTime.getDate();
                if(!p.currency || p.currency.length > 4)
                    continue;
                
                const index = pDay - 1;
                if(!graph[p.currency]){
                    graph[p.currency] = { lastMonth: [], thisMonth: []}
                    for(let i = 0 ; i < 31; i += 1){
                        graph[p.currency].lastMonth[i] = 0;
                        graph[p.currency].thisMonth[i] = 0;
                    }
                }
                let graphMonth = null;
                if(p.timestamp > Date.now() - 30 * 24 * 60 * 60 * 1000){
                    graphMonth = "thisMonth";
                }
                else if (p.timestamp > Date.now() - 60 * 24 * 60 * 60 * 1000){
                    graphMonth = "lastMonth";
                }
                if(!graphMonth) continue;

                if(!thisMonth[p.currency])
                    thisMonth[p.currency] = 0;
                if(pMonth === nowMonth){
                    thisMonth[p.currency] += parseInt(p.price);
                }

                
                graph[p.currency][graphMonth][index] += parseInt(p.price);
                count += 1;
            }
            let graphData = {};
            for(let c = 0 ; c < Object.keys(graph).length; c+= 1){
                const currency = Object.keys(graph)[c];
                if(graph.hasOwnProperty(currency)){
                    graphData[currency] = [];
                    for(let i = 0 ; i < 31; i += 1){
                        graphData[currency][i] = { thisMonth: i <= now.getDate() - 1 ? graph[currency].thisMonth[i] + (i > 0 ? graphData[currency][i - 1].thisMonth : 0) : null , lastMonth: graph[currency].lastMonth[i] + (i > 0 ? graphData[currency][i - 1].lastMonth : 0), date: i + 1 }
                    }
                    target[currency] = (graphData[currency][30].lastMonth + 10) * 1.1;
                    for(let i = 0 ; i < 31; i += 1){
                        graphData[currency][i].target = parseInt((graphData[currency][30].lastMonth + 10) * 1.1);
                    }
                }
            }


            this.setState({ graph: graphData, paymentCount: count, loadingPayments: false, thisMonth, target});

        }
        catch(e){
            console.log(e);
        }
    }

    getKudos(currency) {
        const now = new Date();
        const nowDate = now.getDate();
        let kudos = ["Keep at it!"];
        if(nowDate < 8){
            kudos = [
                "Let's get to a great start this month!",
                "Make the first week count!",
                "First week is a good time for sales!",
                `Let's crush it this ${moment().format("MMMM")}!`,
                "A good start is half the battle!"
            ]
        }
        else if(nowDate < 15) {
            kudos = [
                `We're approaching mid-${moment().format("MMMM")}, buckle up!`,
                "Don't lose steam in the second week",
                "Keep it going. Never stop.",
                "You can do it, we'll crush that target this month!",
                "Build up sales momentum",
            ]
        }
        else if(nowDate < 21) {
            kudos = [
                "We're into our third week, how's the energy?",
                "Make this week count. This week is going to decide this month's performance.",            
            ]
            if(this.state.target[currency] * 0.4 > this.state.thisMonth[currency]) {
                kudos = kudos.concat([
                    "Uh oh! We're trailing. But we can still make it!",
                    "Don't give up yet. We have time to cover up ground",
                    "Get back to the sales game now!",
                    "We need to do more to hit our target",
                ])
            }
            else {
                kudos = kudos.concat([
                    "Good start to this month! Keep it going!",
                    "Don't lose the great momentum you've built",
                    "Wonderful start, I must say. Keep going!",
                    "Off to a great start. Keep going!",
                    "Perfect start. Now make it count!"
                ])
            }

        }
        else {
            kudos = [
                "We're into the last week. Don't slack!",
                "Give a great wrap to this month",
                `Make ${moment().format("MMM")} your best revenue month!`,
                "Make this your best month till date!",
                "Finish this month strong",
                "Keep pushing for more sales"
            ]
        }

        return kudos[Math.floor(Math.random() * (kudos.length - 1))];

    }



    getGraph = () => {
        if(this.state.loadingPayments){
            return (
                <CardContent>
                    <Typography style={{color: this.props.theme.palette.primary.contrastText}} variant="h6">Loading...</Typography>                
                </CardContent>
            )
        }
        if(this.state.paymentCount === 0)
            return (
                <CardContent style={{ width: '100%'}}>
                    <Typography style={{color: this.props.theme.palette.primary.contrastText}} variant="h6">No Payments Recorded</Typography>                
                    <Typography style={{ marginTop: 12, color: this.props.theme.palette.primary.contrastText}} variant="body1">Start distributing your app to start earning!</Typography>                
                    {/*<Illustration image="bargraph" style={{width: '100%'}}/>*/}
                </CardContent>
            )
        

        if(!this.state.graph) return null;

        return (
            <CardContent>
                {
                    Object.keys(this.state.graph).map((currency, index) => {
                        if(this.state.graph.hasOwnProperty(currency)){
                            return <div>
                                <Typography style={{marginTop: index===0?0:24, color: this.props.theme.palette.primary.contrastText}} variant="h6">{currency} {this.state.thisMonth[currency]} <small>(approx)</small></Typography>
                                <Typography style={{color: this.props.theme.palette.primary.contrastText}} variant="body2"><small>Target : {currency} {parseInt(this.state.target[currency])}</small></Typography>
                                <Chart
                                    height={100}
                                    data={this.state.graph[currency]}
                                >
                                    <ArgumentAxis showLabels={false}/>
                                    <ValueAxis showGrid={false} showTicks={true} showLabels={false} showLine={true} />
                                    <AreaSeries name="Last Month" color={this.props.theme.palette.secondary.main} valueField="lastMonth" argumentField="date" /> 
                                    <AreaSeries name="This Month" color={this.props.theme.palette.primary.contrastText} valueField="thisMonth" argumentField="date" /> 
                                    <LineSeries name="This Month's Target" color={this.props.theme.palette.success.main} valueField="target" argumentField="date" /> 
                                </Chart>
                                <center>
                                    <div style={{height: 6, width: 6, marginRight: 6, backgroundColor: this.props.theme.palette.primary.contrastText, display: 'inline-block'}}></div><Typography style={{display:'inline-block', color: this.props.theme.palette.primary.contrastText}} variant="body2"><small>{moment().format("MMM")}</small></Typography>
                                    <div style={{height: 6, width: 6, marginLeft:8, marginRight: 6, backgroundColor: this.props.theme.palette.secondary.main, display: 'inline-block'}}></div><Typography style={{display:'inline-block', color: this.props.theme.palette.primary.contrastText}} variant="body2"><small>{moment().subtract(1,"M").format("MMM")}</small></Typography>
                                    <div style={{height: 6, width: 6, marginLeft:8, marginRight: 6, backgroundColor: this.props.theme.palette.success.main, display: 'inline-block'}}></div><Typography style={{display:'inline-block', color: this.props.theme.palette.primary.contrastText}} variant="body2"><small>Target</small></Typography>
                                </center>
                                <center>
                                    <Typography variant="body1" style={{ color: this.props.theme.palette.primary.contrastText}}>
                                    {this.getKudos(currency)}
                                    </Typography>
                                </center>

                            </div>
                        }
                        return null;
                    })
                }
            </CardContent>
        )
    }

    openApp() {
        const self = this;        
        window.changeWorkspace('apppublish', 'Publish', null);    
    }

    open(option){
        window.analytics.track('Navigation Option Clicked', {'option_name': option});
        
        if(option === "logout")
            utils.logout();
        this.props.changeWorkspace(option,option,this.props.context);
        this.setState({ drawerOpen: false });

    }

    isActiveWorkspace(ws){
        if(this.props.activeHeaderWorkspace === ws)
            return true;
        if(this.props.activeHeaderWorkspace === "root" && (ws === "tabs" || ws==="root"))
            return true;
        return false;
    }

    renderList() {
        const listItemList = [{
            workspace: 'tabs',
            icon: 'build',
            primary: 'App Builder',
            secondary: 'Edit the contents & features of your app',
        },
        {
            workspace: 'apppaymentreport',
            icon: 'account_balance_wallet',
            primary: 'All payments',
            secondary: 'Detailed information of each transaction and refunds',
        }, {
            workspace: 'appanalytics',
            icon: 'bar_chart',
            primary: 'Analytics',
            secondary: 'See how your app is performing and how to improve',
        }, {
            workspace: 'appmarketing',
            icon: 'trending_up',
            primary: 'Marketing',
            secondary: 'Tools and tips for accelerating growth',
        }, {
            workspace: 'appusers',
            icon: 'people',
            primary: 'Manage Users & Notifications',
            secondary: 'Detailed information about all your users, send them notifications',
        }, {
            workspace: 'apptheme',
            icon: 'color_lens',
            primary: 'App Theme',
            secondary: 'Change the look and feel of your app',
        }, {
            workspace: 'apptnc',
            icon: 'call',
            primary: 'Terms of Use',
            secondary: 'By publishing the app you agree to the terms and conditions mentioned here',
        }, {
            workspace: 'logout',
            icon: 'exit_to_app',
            primary: 'Logout',
            secondary: 'See you soon!',
        }];
        return (
            <List style={{padding: '0'}}>
                {listItemList.map(item => (
                    <ListItem button style={{padding: '16px 20px 18px 16px', borderTop: 'solid 1px #251d41', borderBottom: 'solid 1px #251d41', background:  this.isActiveWorkspace(item.workspace) ? '#562eff' : '#1f1936'}} onClick={() => this.open(item.workspace)}>
                        <ListItemIcon style={{minWidth: '0'}}><Icon style={{
                            fontSize: '1.13em',
                            color: '#c1c1c1',
                            border: this.isActiveWorkspace(item.workspace) ? 'solid 1px #c1c1c1' : 'solid 2px rgb(37, 32, 64)',
                            borderRadius: '50%',
                            padding: '8px',
                            marginRight: '16px',
                            background: 'rgba(0, 0, 0, 0.08)'
                        }}>{item.icon}</Icon></ListItemIcon>
                        <ListItemText primaryTypographyProps={{style: {color: '#fff', fontSize: '0.875rem', marginBottom: '8px'}}} secondaryTypographyProps={{style: {color: 'rgba(255, 255, 255, 0.7)', fontSize: '0.75rem'}}} primary={item.primary} secondary={item.secondary}/>
                        { this.isActiveWorkspace(item.workspace) ? <div style={{
                            width: '0', 
                            height: '0', 
                            borderTop: '1ch solid transparent',
                            borderBottom: '1ch solid transparent',
                            borderRight: '1ch solid rgb(242, 242, 242)',
                            position: 'absolute',
                            right: '0',
                        }}/> : null}
                    </ListItem>
                ))}
            </List>
        );
    }

    render(){
        if (this.props.alwaysOpen) {
            return (
                <div style={{width: 'calc(max(19.06vw, 244px))', height: '100vh', overflow: 'scroll'}}>
                    {this.renderList()}
                </div>
            );
        }
        
        return(
            <>
            <AppBar id="toolbar" style={{width: '100%', position: 'fixed', zIndex: this.props.zIndex === 0? 0 : 2,top: 0, margin:'0', left: 0}}>
                    <Toolbar style={{padding: '0', minHeight: '56px'}}>
                        <IconButton style={{padding: '0 19px'}} color="inherit" onClick={() => this.setState({drawerOpen: true}) || window.analytics.track('Navigation Drawer Clicked', {})}>{this.props.icon ? <Icon>{this.props.icon}</Icon> : <Icon><img style={{height: '100%'}} src={HamburgerIcon} /></Icon>}</IconButton>
                        <Typography noWrap={true} style={{ fontWeight: 'bold', overflow: 'hidden', flexGrow: 1, color: "#fff"}} variant="body1" >Creator OS</Typography>
                        {/* {this.props.showPublish?<RoundedButton disabled={this.state.openingApp} className="openapp" color="secondary" variant="contained" onClick={() => this.openApp()} >Publish</RoundedButton>: null} */}

                        {/*<IconButton className="moreoptions" aria-controls="simple-menu" aria-haspopup="true" onClick={(event)=>this.setState({ moreOpen: true, moreMenuAnchorEl: event.currentTarget })} ref="more" edge="end" color="inherit" aria-label="menu">
                            <Icon color="primary">more_vert</Icon>
                        </IconButton>
                        <Menu id="simple-menu" open={Boolean(this.state.moreOpen)} anchorEl={this.state.moreMenuAnchorEl} keepMounted onClose={() => this.setState({ moreOpen: false })}>
                            {(this.props.options || []).map(item => this.getMenuItem(item))}
                            {this.props.helpVideo? <MenuItem color="secondary" onClick={() => this.setState({ helpOpen: true })}><Icon>{this.props.icon || "help"}</Icon> &nbsp;{this.props.helpText || "Need Help?"}</MenuItem>: null}
                        </Menu>     
                        */}               
                    </Toolbar>
                    <Dialog open={this.state.helpOpen}>
                        <DialogTitle>{this.props.helpText}</DialogTitle>
                        <DialogContent> 
                            <video controls autoPlay style={{ width: '100%'}} src={this.props.helpVideo}/>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => this.setState({ helpOpen: false })} color="primary">Close</Button>
                        </DialogActions>
                    </Dialog>
            </AppBar>

            <React.Fragment>
            <SwipeableDrawer PaperProps={{style: {background: '#1f1936'}}} anchor="left" open={this.state.drawerOpen} onClose={() => this.setState({ drawerOpen: false })} onOpen={() => this.setState({ drawerOpen: true })}>
                <div style={{ width: '100%'}}>
                    <IconButton style={{ float: 'right'}} color="primary" onClick={() => this.setState({ drawerOpen: false})}>
                        <Icon style={{color: '#c1c1c1'}}>close</Icon>
                    </IconButton>
                </div>
                <Card style={{width: 'calc(100% - 24px)', margin: 12, maxWidth: 480, overflow:"visible", backgroundColor: this.props.theme.palette.primary.main }}>
                        {this.getGraph()}
                </Card>

                {this.renderList()}



            </SwipeableDrawer>
            </React.Fragment>
            </>
        )
    }
}

export default withTheme(Header);