const axios = require('axios');
const Constants = require('./Constants');
const mime = require('mime-types');
const md5 = require('md5');
const moment = require('moment');

function ServerCall(type, url, data, authenticated, customHeaders, uploadCallback) {
    let finalUrl = url;
    const config = {
        headers: {
            accesstoken : localStorage.getItem('auth_access_token'),
            long_token: localStorage.getItem('auth_long_token'),
            username: localStorage.getItem('auth_username'),
            appId: localStorage.getItem('auth_username'),
            long_expires_at: localStorage.getItem('auth_long_expires_at'),
            'content-type' : 'application/json',
            stage: window.STAGE,
            ... customHeaders
        },
    }
    if(url[0] === '/') {
        finalUrl = Constants.ApiUrl+url;
    }
    if(type === 'get') {
        return axios.get(finalUrl, config);
    }
    else if(type === 'post')
        return axios.post(finalUrl, data, config);
    else if(type === 'put') {
        if(uploadCallback)
            config.onUploadProgress = progressEvent => uploadCallback(progressEvent.loaded, progressEvent.total)
        return axios.put(finalUrl, data, config);
    }
    else if(type === 'delete')
        return axios.delete(finalUrl, config);
    return new Promise((resolve, reject) => {
        reject('unknown type');
    });
}

function uploadToS3(file, uploadCallback) {

    return new Promise( (resolve, reject) => {

        var re = /(?:\.([^.]+))?$/;
        var ext = re.exec(file.name)[1]; 
        const filename = `${md5(localStorage.getItem('appId') + file.name + Date.now())}.${ext}`;
        ServerCall('post', '/utils/s3_sign', { name: filename, type: file.type, realFilename: file.name }).then(response => {
            const url = response.data.uploadUrl;
            const uploadId = response.data.uploadId;
            ServerCall('put',url, file, false, {'content-type': mime.lookup(file.name)}, uploadCallback).then(() => {
                resolve({url: response.data.downloadUrl, uploadId});
            }).catch(e => reject(e));
        });
    });

}

const getAuthToken = code => ServerCall('get', `/auth/tokens/${code}`, false);
const initUser = () => ServerCall('post', '/users/builders', { ref: localStorage.getItem("ref") }, true);
const updateBuilderPhone = (phone) => ServerCall('put', '/users/builders/phone', {phone}, true);
const getBuilder = () => ServerCall('get', '/users/builders/my_user', {}, true);
const updateBuilderPaymentInfo = (paymentInfo) => ServerCall('put', '/users/builders/my_user/paymentInfo', paymentInfo, true);
const applyInstantSettlement = () => ServerCall('put', '/apps/my_app/instantSettlement', true);
const refreshAuthToken = refreshToken => ServerCall('post', '/auth/tokens/refresh_token', { refresh_token: refreshToken }, false);
const updateApp = (name, logo, domain, video, allowAnon) => ServerCall('post', '/apps/my_app', { name, logo, domain, video, allowAnon });
const updateAppDetails = (key, value) => ServerCall('put', `/apps/my_app/details/${key}`, { value });
const updateAppTheme = (theme) => ServerCall('put','/apps/my_app/theme', { theme });
const updateAppPlan = (plan) => ServerCall('put', '/apps/my_app/currentPlan', { plan });
const confirmAppPlan = (paymentId, signature) => ServerCall('put', '/apps/my_app/currentPlan/confirm', { paymentId, signature });
const cancelPlan = () => ServerCall('put', '/apps/my_app/currentPlan/cancel', {});
const updateAppGaCode = (gaCode) => ServerCall('put', '/apps/my_app/gaCode', { gaCode });
const agreeTnC = (gaCode) => ServerCall('put', '/apps/my_app/tnc_agreement', {});
const updateAppCoupon = (coupon) => ServerCall('put', '/apps/my_app/coupon', { coupon });
const getApp = () => ServerCall('get', '/apps/my_app');
const getStatus = () => ServerCall('get', '/status');
const getUpdates = () => ServerCall('get', '/updates/builder');
const setAppSubscriptions = (subscriptions) => ServerCall('put', '/apps/my_app/subscriptions', {subscriptions});
const getAppPayments = (action, cursor) => ServerCall('get', `/apps/my_app/payments?action=${action  || "get"}&timestamp=${Date.now()}&cursor=${encodeURIComponent(cursor)}`, {}, true);
const addPlugin = (name, icon, type) => ServerCall('post', '/apps/my_app/plugins/', { name, icon, type });
const updatePlugin = (pluginId, name, icon, images, title, weight, longDescription, tags) => ServerCall('put', `/apps/my_app/plugins/${pluginId}`, { name, icon, images, title, weight, longDescription, tags });
const updatePluginInfo = (pluginId, info) => ServerCall('put', `/apps/my_app/plugins/${pluginId}/info`, { info });
const updateSubscriptionOptions = (subscriptions) => ServerCall('post', '/apps/my_app/subscriptions', { subscriptions });
const updateEula = (file, description, brandingBanner) => ServerCall('put', '/apps/my_app/eula', { file, description, brandingBanner });
const movePluginToTop = (pluginId) => ServerCall('put', `/apps/my_app/plugins/${pluginId}/position`, {});
const getMediaLibrary = () => ServerCall('get', `/apps/my_app/medialibrary`, {});
const updateMediaLibrary = (uploadId, url) => ServerCall('put', `/apps/my_app/medialibrary/${uploadId}`, { url });
const putAppMarketing = (key, value) => ServerCall('put', `/apps/my_app/marketing/${key}`, { value });

const collaboratorsAdd = (email) => ServerCall('post', '/apps/my_app/collaborators', { email }, true);

const getPlugins = () => ServerCall('get', '/apps/my_app/plugins');
const deletePlugin = (pluginId) => ServerCall('delete', `/apps/my_app/plugins/${pluginId}`);
const getPluginBannerSamples = (pluginId) => ServerCall('get', `/apps/my_app/plugins/${pluginId}/bannersamples`, {});

const getUsers = (appId) => ServerCall('get', `/apps/${appId}/users`);
const addChatroom = (appId, pluginId, chatroom) => ServerCall('post', `/plugingroupchat/apps/${appId}/plugins/${pluginId}/chatrooms`, {action: 'ADD_CHATROOM', ...chatroom}, true);
const updateChatroom = (appId, pluginId, chatroom) => ServerCall('post', `/plugingroupchat/apps/${appId}/plugins/${pluginId}/chatrooms`, {action: 'UPDATE_CHATROOM', ...chatroom}, true);

const pluginpremier_addBundle = (pluginId, bundleId, name, description, imageUrl, price, currency, published, ordering, audience, validity, longDescription, tags, salesPage, pixels ) => ServerCall('post',`/pluginpremier/apps/my_app/plugins/${pluginId}/bundles`, { bundleId, name, description, price, currency, imageUrl, published, ordering, audience, validity,longDescription, tags, salesPage, pixels });
const pluginpremier_updateBundle = (pluginId, bundleId, key, value) => ServerCall('put',`/pluginpremier/apps/my_app/plugins/${pluginId}/bundles/${bundleId}/${key}`, { value });
const pluginpremier_moveBundleToTop = (pluginId, bundleId) => ServerCall('put',`/pluginpremier/apps/my_app/plugins/${pluginId}/bundles/${bundleId}/position`, {});
const pluginpremier_setBundlePrice = (pluginId, bundleId, priceList ) => ServerCall('put',`/pluginpremier/apps/my_app/plugins/${pluginId}/bundles/${bundleId}/price`, { priceList });
const pluginpremier_getItems = pluginId => ServerCall('get', `/pluginpremier/apps/my_app/plugins/${pluginId}/items`);
const pluginpremier_addContentToBundle = ( pluginId, bundleId, contentId, name, description, fileUrl, published, free, type, thumbnail, longDescription, tags, list ) => ServerCall('post', `/pluginpremier/apps/my_app/plugins/${pluginId}/bundles/${bundleId}/contents`, { name, description, fileUrl, contentId, published, free, type, thumbnail, longDescription, tags, list });
const pluginpremier_addLiveContentToBundle = ( pluginId, bundleId, contentId, name, description, fileUrl, published, free, type, thumbnail, longDescription, tags, list , isLiveContent) => ServerCall('post', `/pluginpremier/apps/my_app/plugins/${pluginId}/bundles/${bundleId}/contents`, { name, description, fileUrl, contentId, published, free, type, thumbnail, longDescription, tags, list, isLiveContent });
const pluginpremier_updateContent = ( pluginId, bundleId, contentId, key, value ) => ServerCall('put', `/pluginpremier/apps/my_app/plugins/${pluginId}/bundles/${bundleId}/contents/${contentId}/${key}`, { value });
const pluginpremier_getBundleContents = ( pluginId, bundleId ) => ServerCall('get', `/pluginpremier/apps/my_app/plugins/${pluginId}/bundles/${bundleId}/contents`);
const pluginpremier_getQuizResponses = ( pluginId, bundleId, contentId ) => ServerCall('get', `/pluginpremier/apps/my_app/plugins/${pluginId}/bundles/${bundleId}/contents/${contentId}/quizResponses`);
const pluginpremier_putManualEvaluation = ( pluginId, bundleId, contentId, responseId, obtainedPoints, comment ) => ServerCall('put', `/pluginpremier/apps/my_app/plugins/${pluginId}/bundles/${bundleId}/contents/${contentId}/quizResponses/${responseId}/manual`, {obtainedPoints, comment});
const pluginpremier_setBundleWeight = ( pluginId, bundleId, weight ) => ServerCall('put', `/pluginpremier/apps/my_app/plugins/${pluginId}/bundles/${bundleId}/weight`, {weight});
const pluginpremier_moveContentInBundle = ( pluginId, bundleId, contentId, direction ) => ServerCall('put', `/pluginpremier/apps/my_app/plugins/${pluginId}/bundles/${bundleId}/contents/${contentId}/position?action=move${direction}`, {});
const pluginpremier_setFaqs = ( pluginId, bundleId, faqs ) => ServerCall('put', `/pluginpremier/apps/my_app/plugins/${pluginId}/bundles/${bundleId}/faqs`, { faqs });
const pluginpremier_getSampleBanners = ( pluginId, bundleId ) => ServerCall('get', `/pluginpremier/apps/my_app/plugins/${pluginId}/bundles/${bundleId}/bannersamples`, {});

const pluginbooking_postEvent = (pluginId, event) => ServerCall('post',`/pluginbooking/plugins/${pluginId}/events/`, { event });
const pluginbooking_getEvents = (pluginId) => ServerCall('get', `/pluginbooking/plugins/${pluginId}/events`);
const pluginbooking_getBookings = (pluginId, eventId) => ServerCall('get', `/pluginbooking/plugins/${pluginId}/events/${eventId}/bookings`);
const pluginbooking_deleteBooking = (pluginId, eventId) => ServerCall('delete', `/pluginbooking/plugins/${pluginId}/events/${eventId}`, {});

const pluginchat_postMessage = (pluginId, message ) => ServerCall('post', `/pluginchat/apps/${localStorage.getItem('appId')}/plugins/${pluginId}/messages`, { message });

const pluginchat_getChatroom = (userId) => ServerCall('get', `/pluginchat/chatrooms/apps/${localStorage.getItem('appId')}/users/${userId}`)
const pluginchat_getChatrooms = () => ServerCall('get', `/pluginchat/chatrooms`);
const pluginchat_getVideoToken = (chatroomId ) => ServerCall('post', `/pluginchat/video/chatrooms/${chatroomId}`, {});
const pluginchat_getChatToken = (chatroomId, userId ) => ServerCall('post', `/pluginchat/chat/chatrooms/${chatroomId}`, {userId});
const pluginchat_getViewers = (chatroomId ) => ServerCall('get', `/pluginchat/video/chatrooms/${chatroomId}/users`, {});
const pluginchat_getViewersList = (chatroomId ) => ServerCall('get', `/pluginchat/video/chatrooms/${chatroomId}/users/list`, {});
const pluginchat_addViewer = (chatroomId ) => ServerCall('post', `/pluginchat/video/chatrooms/${chatroomId}/users`, {});
const pluginchat_removeViewer = (chatroomId ) => ServerCall('delete', `/pluginchat/video/chatrooms/${chatroomId}/users`, {});

const pluginlanding_updateContent = (pluginId, content) => ServerCall('post', `/pluginlanding/plugins/${pluginId}`, {content});
const pluginlanding_getContent = (pluginId) => ServerCall('get', `/pluginlanding/plugins/${pluginId}`);

const pluginquery_getQueries = (pluginId) => ServerCall('get', `/pluginquery/plugins/${pluginId}/queries`);
const pluginquery_answerQuery = (pluginId, queryId, answer, answerType) => ServerCall('put', `/pluginquery/plugins/${pluginId}/queries/${queryId}/answer`, { answer, answerType });

const pluginmerch_saveItem = (pluginId, item) => ServerCall('post', `/pluginmerch/apps/my_app/plugins/${pluginId}/items`, { item });
const pluginmerch_getItems = (pluginId) => ServerCall('get', `/pluginmerch/apps/my_app/plugins/${pluginId}/items`);
const pluginmerch_getOrders = (pluginId, period) => ServerCall('get', `/pluginmerch/apps/my_app/plugins/${pluginId}/orders?period=${period}`);



const pluginembed_saveEmbed = (pluginId, url) => ServerCall('put', `/pluginembed/apps/my_app/plugins/${pluginId}`, { url });

const getAppUsers = (cursor) => ServerCall('get', !Boolean(cursor)?`/users/apps/my_app/users`:`/users/apps/my_app/users?appId=${cursor.appId}&userId=${cursor.userId}`);
const getAppUsersCsv = () => ServerCall('get', `/users/apps/my_app/users/csv`);
const getAppAnalytics = () => ServerCall('get', `/apps/my_app/analytics`);
const sendMessage = (userId, title, message, link) => ServerCall('post', `/users/${userId}/messages`, {title, body: message, link});
const scheduleMessage = (utckey, to, title, message, link) => ServerCall('post', `/users/all/messages`, {utckey, title, body: message, link, to});
const getScheduledMessages = () => ServerCall('get', `/users/all/messages`);
const deleteScheduledMessage = (utckey, notifiationId) => ServerCall('delete', `/users/all/messages/${utckey}/${notifiationId}`);

//Survey
const pluginsurvey_setQuestions = (pluginId, questions, welcomeText, currency, price, priceCalc) => ServerCall('post', `/pluginsurvey/apps/my_app/plugins/${pluginId}/questions`, { questions, welcomeText, currency, price, priceCalc });
const pluginsurvey_getResponsesCount = (pluginId) => ServerCall('get', `/pluginsurvey/apps/my_app/plugins/${pluginId}/responses/count`);
const pluginsurvey_getResponses = (pluginId) => ServerCall('get', `/pluginsurvey/apps/my_app/plugins/${pluginId}/responses`);


// feed
const pluginfeed_addPost = (pluginId, text, attachment ) => ServerCall('post', `/pluginfeed/apps/my_app/plugins/${pluginId}/posts`, {text, attachment});
const pluginfeed_deletePost = (pluginId, postId ) => ServerCall('delete', `/pluginfeed/apps/my_app/plugins/${pluginId}/posts/${postId}`);
const pluginfeed_getPosts = (pluginId) => ServerCall('get', `/pluginfeed/apps/my_app/plugins/${pluginId}/posts`);

// forum
const pluginforum_getBoards = (pluginId) => ServerCall('get',`/pluginforum/apps/my_app/plugins/${pluginId}/boards`, {});
const pluginforum_addBoard = (pluginId, name, banner,  rules) => ServerCall('post', `/pluginforum/apps/my_app/plugins/${pluginId}/boards`, { name, banner, rules});
const pluginforum_getPost = (pluginId, boardId, postId, responseId ) => ServerCall('get', `/pluginforum/apps/my_app/plugins/${pluginId}/boards/${boardId}/posts/${postId}/responses/${responseId}`, {});
const pluginforum_getPendingModerationPosts = (pluginId, boardId) => ServerCall('get', `/pluginforum/apps/my_app/plugins/${pluginId}/boards/${boardId}/posts/moderationPending`, {});
const pluginforum_updatePostState = (pluginId, boardId, postId, responseId, state) => ServerCall('put', `/pluginforum/apps/my_app/plugins/${pluginId}/boards/${boardId}/posts/${postId}/responses/${responseId}/state`, { state });

// onlylivestream
const pluginlivestream_addEvent = (pluginId, time, minAudience, description, currency, price) => ServerCall('post', `/pluginlivestream/apps/my_app/plugins/${pluginId}/events`, { time, minAudience, description , currency, price});
const pluginlivestream_updateEvent = (pluginId, time, minAudience, description, currency, price, eventId) => ServerCall('put', `/pluginlivestream/apps/my_app/plugins/${pluginId}/events/${eventId}`, { time, minAudience, description , currency, price});
const pluginlivestream_getLatestEvent = (pluginId) => ServerCall('get', `/pluginlivestream/apps/my_app/plugins/${pluginId}/events/nextEvent`, { });
const pluginlivestream_remind = (pluginId) => ServerCall('post', `/pluginlivestream/apps/my_app/plugins/${pluginId}/events/nextEvent/reminders`, { });



//utils
const getCurrencyConversionToLocalCurrency = fromCurrency => ServerCall('get', `/utils/currency_convert/${fromCurrency}`);
const createYoutubeApp = (link, name, logo) => ServerCall('get', `/utils/score?link=${link}&name=${name}&logo=${logo}`, {}, true);
const getThumbnails = (query, type) => ServerCall('get', `/utils/thumbnails?query=${query}&type=${type || "regular"}`, {  }, true);
const optimizeVideo = (url) => ServerCall('post', '/utils/videos/optimized', { url });
const optimizeVideoStatus = (id) => ServerCall('get', `/utils/videos/optimized/${id}/status`, {});

//users
const updateUserStatus = (userId, status) => ServerCall('put', `/users/apps/my_app/users/${userId}/status`, { status }, {}, true);
const updateUser = (userId, key, value) => ServerCall('put', `/users/apps/my_app/users/${userId}/${key}`, { value }, {}, true);
const paymentsRefund = (logId, reason, type) => ServerCall('put',`/payments/apps/my_app/paymentlogs/${encodeURIComponent(logId)}/refund`, { reason, type });
const paymentsExtend = (logId, days) => ServerCall('put',`/payments/apps/my_app/paymentlogs/${encodeURIComponent(logId)}/validity`, { days });

//third party
const vimeoVerifyLogin = () => axios.get('https://api.vimeo.com', { headers: { 'Authorization' : `bearer ${localStorage.getItem("vimeoAccessToken")}` }})
const vimeoGetVideos = (searchTerm) => axios.get(searchTerm?`https://api.vimeo.com/me/videos?query=${searchTerm}` : 'https://api.vimeo.com/me/videos', { headers: { 'Authorization' : `bearer ${localStorage.getItem("vimeoAccessToken")}` }});
const zoomGetAuthCode = (code) => ServerCall('post', '/auth/zoom', { code });
const zoomGetUser = () => ServerCall('post', '/utils/zoom', { url: 'https://api.zoom.us/v2/users/me', type: 'get', access_token: localStorage.getItem("zoomAccessToken")})//axios.get('https://api.zoom.us/v2/users/me', { headers: { Authorization: `Bearer ${localStorage.getItem("zoomAccessToken")}`}})
const zoomGetWebinars = () => ServerCall('post', '/utils/zoom', { url: 'https://api.zoom.us/v2/users/me/meetings', type: 'get', access_token: localStorage.getItem("zoomAccessToken")})//axios.get('https://api.zoom.us/v2/users/me', { headers: { Authorization: `Bearer ${localStorage.getItem("zoomAccessToken")}`}})
const zoomCreateWebinar = (topic, agenda) => ServerCall('post', '/utils/zoom', { url: 'https://api.zoom.us/v2/users/me/meetings', type: 'post', access_token: localStorage.getItem("zoomAccessToken"), data: { topic, agenda, duration: 60*8, startTime: moment().format(), password: "S123!", type: 1, settings: { approval_type: 0, waiting_room: false } }})
const zoomGetWebinar = (meetingId) => ServerCall('post', '/utils/zoom', { type:'get', url : `https://api.zoom.us/v2/meetings/${meetingId}`, access_token: localStorage.getItem("zoomAccessToken")});
const zoomUninstall = (userId, accountId) => ServerCall('post', '/utils/zoom', { type: 'm_disconnect', data: { user_id: userId, account_id: accountId}});

export {
    getAuthToken,
    initUser,
    updateBuilderPhone,
    updateBuilderPaymentInfo,
    applyInstantSettlement,
    getBuilder,
    refreshAuthToken,
    updateApp,
    updateAppDetails,
    updateAppTheme,
    updateAppPlan,
    confirmAppPlan,
    cancelPlan,
    updateAppGaCode,
    agreeTnC,
    updateAppCoupon,
    uploadToS3,
    getApp,
    getStatus,
    getUpdates,
    setAppSubscriptions,
    getAppPayments,
    updateSubscriptionOptions,
    addPlugin,
    updatePlugin,
    updatePluginInfo,
    getPlugins,
    deletePlugin,
    getPluginBannerSamples,
    pluginpremier_updateBundle,
    updateEula,
    movePluginToTop,
    getMediaLibrary,
    updateMediaLibrary,
    putAppMarketing,
    collaboratorsAdd,
    pluginpremier_addBundle,
    pluginpremier_setBundlePrice,
    pluginpremier_moveBundleToTop,
    pluginpremier_getItems,
    pluginpremier_addContentToBundle,
    pluginpremier_addLiveContentToBundle,
    pluginpremier_updateContent,
    pluginpremier_getBundleContents,
    pluginpremier_getQuizResponses,
    pluginpremier_putManualEvaluation,
    pluginpremier_setBundleWeight,
    pluginpremier_moveContentInBundle,
    pluginpremier_setFaqs,
    pluginpremier_getSampleBanners,
    pluginbooking_postEvent,
    pluginbooking_getEvents,
    pluginbooking_getBookings,
    pluginbooking_deleteBooking,
    pluginchat_getChatroom,
    pluginchat_getChatrooms,
    pluginchat_postMessage,
    pluginchat_getVideoToken,
    pluginchat_getChatToken,
    pluginchat_getViewers,
    pluginchat_getViewersList,
    pluginchat_addViewer,
    pluginchat_removeViewer,
    pluginlanding_updateContent,
    pluginlanding_getContent,
    pluginquery_getQueries,
    pluginquery_answerQuery,
    getAppUsers,
    getAppAnalytics,
    getCurrencyConversionToLocalCurrency,
    pluginembed_saveEmbed,
    sendMessage,
    scheduleMessage,
    getScheduledMessages,
    deleteScheduledMessage,
    getAppUsersCsv,
    pluginmerch_getItems,
    pluginmerch_saveItem,
    pluginmerch_getOrders,
    pluginsurvey_setQuestions,
    pluginsurvey_getResponsesCount,
    pluginsurvey_getResponses,
    pluginfeed_addPost,
    pluginfeed_deletePost,
    pluginfeed_getPosts,
    pluginforum_addBoard,
    pluginforum_getBoards,
    pluginforum_getPost,
    pluginforum_getPendingModerationPosts,
    pluginforum_updatePostState,
    pluginlivestream_addEvent,
    pluginlivestream_updateEvent,
    pluginlivestream_getLatestEvent,
    pluginlivestream_remind,
    createYoutubeApp,
    getThumbnails,
    optimizeVideo,
    optimizeVideoStatus,
    updateUserStatus,
    updateUser,
    paymentsRefund,
    paymentsExtend,

    vimeoVerifyLogin,
    vimeoGetVideos,
    zoomGetAuthCode,
    zoomGetUser,
    zoomGetWebinars,    
    zoomCreateWebinar,
    zoomGetWebinar,
    zoomUninstall,

    addChatroom,
    updateChatroom,
}