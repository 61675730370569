import React from 'react';
import {
  Stepper, Step, StepLabel, StepContent, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Typography, Icon, Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, IconButton, Menu, MenuItem, List, ListItemIcon, ListItemText, ListItem,
} from '@material-ui/core';
import { withTheme } from '@material-ui/styles';
import Theme from '../Components/theme';
import WsHeader from '../Components/wsheader';
import WsFooter from '../Components/wsfooter';

import { theme } from '../Utils/Constants';

import RoundedButton from '../Components/roundedbutton';

const Servercalls = require('../Utils/Servercalls');

const allowedIcons = ['accessible_forward', 'account_balance', 'account_box', 'add_shopping_cart', 'alarm', 'build', 'today', 'check_circle', 'commute', 'code', 'delete', 'done', 'donut_small', 'explore', 'extension', 'face', 'favorite', 'fingerprint', 'grade', 'home', 'hourglass_empty', 'lock', 'offline_bolt', 'question_answer', 'room', 'theaters', 'timeline', 'thumb_up', 'thumb_down', 'trending_up', 'verified_user', 'view_carousel', 'visibility', 'work', 'watch_later', 'equalizer', 'library_books', 'library_music', 'video_library', 'mic', 'movie', 'music_video', 'new_releases', 'play_arrow', 'radio', 'subscriptions', 'video_call', 'call', 'chat', 'create', 'flag', 'inbox', 'waves', 'weekend', 'save', 'send', 'monetization_on', 'cloud', 'folder', 'headset', 'computer', 'smartphone', 'tablet', 'watch', 'speaker', 'camera', 'color_lens', 'palette', 'photo', 'wb_sunny', 'directions_run', 'hotel', 'local_bar', 'local_cafe', 'local_dining', 'local_florist', 'local_pizza', 'local_play', 'ac_unit', 'casino', 'spa', 'cake', 'public', 'school', 'whatshot'];

class WsAddTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 0,
      typeConfirmationOpen: null,
      name: '',
      icon: 'stars',
      type: null,
    };
  }

  componentDidMount() {
  }

  getOptionsTable(options) {
    return (
      <List>
        {options.map((o) => (
          <ListItem
            button
            onClick={() => this.setState({
              type: o.type, activeStep: 1, icon: o.icon, name: o.name,
            })}
          >
            <ListItemIcon><Icon>{o.icon}</Icon></ListItemIcon>
            <ListItemText primary={o.name} secondary={o.helpText} />
          </ListItem>
        ))}
      </List>
    );
  }

  getTypeName() {
    const { type } = this.state;
    if (type === 'premium') return 'Content or Courses Tab';
    if (type === 'Query') return 'Contact Me Tab';
    if (type === 'survey') return 'Interactive Form Tab';
    if (type === 'user') return 'User Profile & Notifications Tab';
    if (type === 'landing') return 'About Me Tab';
    return type;
  }

  save() {
    const self = this;
    self.setState({ saving: true });
    Servercalls.addPlugin(this.state.name, this.state.icon, this.state.type).then((response) => {
      self.setState({ saving: false, saved: true });
      setTimeout(() => this.props.changeWorkspace('tabs'), 3000);
    }).catch((e) => { alert('Something went wrong. Please try again'); this.setState({ saving: false }); });
  }

  render() {
    if (this.state.saved) {
      return (
        <center style={{ margin: 64 }}>
          <Icon color="secondary" style={{ fontSize: 120 }}>check</Icon>
          <Typography variant="body1">
            New tab added.
            <br />
            You'll be redirected shortly
            {' '}
          </Typography>
        </center>
      );
    }
    return (
      <div>
        <WsHeader back={this.props.back} title="Add a Tab" description="Choose from the various types of tabs available" helpText="What are the types of tabs?" helpVideo="hello" />
        <div style={{ margin: 32 }}>
          <Stepper orientation="vertical" activeStep={this.state.activeStep}>
            <Step>
              <StepLabel>Pick a tab-type</StepLabel>
              <StepContent>
                <Typography variant="h6" style={{ marginTop: 24, marginBottom: 12, color: '#b7b7b7' }}>Sell</Typography>
                { this.getOptionsTable([
                  {
                    name: 'Content or Courses', icon: 'amp_stories', type: 'premium', helpTitle: 'What is a content tab?', helpText: 'Pick this for selling a course or a collection of videos. You can add videos, livestreams, PDFs to your collections.',
                  },
                  // { name: 'Just a one time Livestream', icon: 'videocam', type: 'onlylivestream', helpTitle: "What is a livestream tab?", helpText: "Pick this if you are planning to do a one off livestream. If you are planning to be regular" },
                  // { name: 'Merchandise Store', icon: 'shopping_cart', type: 'merch', helpTitle: "What is a merchandise store", helpText: "In this tab you can add physical products that you want to sell. You can manage orders you get from your users."}
                ])}
                <Typography variant="h6" style={{ marginTop: 24, marginBottom: 12, color: '#b7b7b7' }}>Engage with your users</Typography>
                { this.getOptionsTable([
                  {
                    name: 'Group chat', icon: 'message', type: 'chat2', helpTitle: 'What is a Group Chat Tab?', helpText: 'Exclusive group chat for users who have paid on the app',
                  },
                ])}

                <Typography variant="h6" style={{ marginTop: 24, marginBottom: 12, color: '#b7b7b7' }}>Broadcast information</Typography>
                { this.getOptionsTable([
                  {
                    name: 'Sales Page & About Me', icon: 'person', type: 'landing', helpTitle: 'What is an About Me Tab?', helpText: 'Use this landing page to improve your sales. A research driven approach to more revenue.',
                  },
                ])}

                <Typography variant="h6" style={{ marginTop: 24, marginBottom: 12 }}>More tabs coming soon</Typography>
                <Typography variant="body2">
                  Tell us what you want at
                  <a href="mailto:contact@socionity.com">contact@socionity.com</a>
                </Typography>
                <br />
                <RoundedButton disabled={!this.state.type} variant="contained" color="secondary" onClick={() => this.setState({ activeStep: 1 })}>Next</RoundedButton>

              </StepContent>
            </Step>
            <Step>
              <StepLabel>Pick a name for the tab</StepLabel>
              <StepContent>
                <TextField color="secondary" style={{ marginTop: 32, marginBottom: 24 }} value={this.state.name} fullWidth label="Pick a name" onChange={(e) => this.setState({ name: e.target.value })} />
                <br />
                <Button onClick={() => this.setState({ activeStep: 0 })}>Previous</Button>
                <RoundedButton style={{ marginLeft: 12 }} variant="contained" color="secondary" onClick={() => this.setState({ activeStep: 2 })} disabled={this.state.name.length < 2}>Next</RoundedButton>
              </StepContent>
            </Step>
            <Step>
              <StepLabel>Pick an icon for the tab</StepLabel>
              <StepContent>
                <Typography style={{ marginTop: 32, marginBottom: 24 }} variant="body2">This icon will help identify this tab on the navigation bar</Typography>
                <IconButton
                  style={{ marginBottom: 24 }}
                  aria-owns={this.state.anchorEl ? 'simple-menu' : undefined}
                  aria-haspopup="true"
                  onClick={(e) => this.setState({ anchorEl: e.currentTarget })}
                >

                  <Icon fontSize="large">{this.state.icon}</Icon>
                </IconButton>
                <Menu
                  id="simple-menu"
                  anchorEl={this.state.anchorEl}
                  open={Boolean(this.state.anchorEl)}
                  onClose={() => this.setState({ anchorEl: null })}
                >
                  {allowedIcons.map((icon) => (
                    <MenuItem key={icon} selected={this.state.selectedIcon === icon} onClick={() => this.setState({ icon, anchorEl: null })}><Icon>{icon}</Icon></MenuItem>
                  ))}
                </Menu>
                <br />
                <Button onClick={() => this.setState({ activeStep: 1 })}>Previous</Button>
                <RoundedButton style={{ marginLeft: 12 }} variant="contained" color="secondary" onClick={() => this.setState({ activeStep: 3 })}>Next</RoundedButton>

              </StepContent>
            </Step>
            <Step>
              <StepLabel>Review & Publish</StepLabel>
              <StepContent>
                <Typography style={{ marginTop: 32, marginBottom: 24 }} variant="h6">
                  <Icon>{this.state.icon}</Icon>
                  {' '}
&nbsp;
                  {' '}
                  {this.state.name}
                </Typography>
                <br />
                <Button onClick={() => this.setState({ activeStep: 2 })}>Previous</Button>
                <RoundedButton style={{ marginLeft: 12 }} variant="contained" color="secondary" onClick={() => this.save()} disabled={this.state.saving}>Publish</RoundedButton>
              </StepContent>
            </Step>

          </Stepper>
        </div>

        <WsFooter text="Each tab should have it's unique purpose in the app" />
      </div>
    );
  }
}

export default withTheme(WsAddTab);
