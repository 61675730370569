import { Input, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles({
    underline: {
        '&:before': {
            borderBottom: '1px solid #d8d8d8',
        },
    },
});

const StyledInput = (props) => {
    const classes = useStyles();

    if (props.variant && props.variant === 'outlined') {
        return (
            <Input
                disableUnderline
                type='text'
                style={{
                    fontSize: props.alwaysOpen ? 'inherit' : '0.875em',
                    flex: '1',
                    ...props.style,
                }}
                inputProps={{
                    style: {
                        appearance: 'none',
                        WebkitAppearance: 'none',
                        MozAppearance: 'none',
                        background: '#fcfcfc',
                        padding: props.alwaysOpen ? '12px 20px' : '8px 16px',
                        border: '1px solid #dbdbdb',
                        borderRadius:  props.alwaysOpen ? '8px' : '4px',
                        ...props.inputStyle,
                    }
                }}
                placeholder={props.placeholder}
                value={props.value ? props.value : null}
                onChange={props.onChange}
                multiline={props.multiline}
                disabled={props.disabled}
            />
        );
    }    

    return (
        <Input
            classes={{underline: classes.underline}}
            placeholder={props.placeholder}
            inputProps={{ 
                spellCheck: 'false',
                style: {
                    appearance: 'none',
                    WebkitAppearance: 'none',
                    MozAppearance: 'none',
                    marginBottom: props.alwaysOpen ? '16px' : '12px',
                    padding: props.alwaysOpen ? '0 20px' : '0',
                    fontSize: props.alwaysOpen ? 'inherit' : '0.88em',
                    ...props.inputStyle,
                },
            }}
            style={props.style}
            fullWidth={props.disableFullWidth ? false : true}
            value={props.value ? props.value : null}
            onChange={props.onChange}
            multiline={props.multiline}
            disabled={props.disabled}
        />
    )
}

export default StyledInput;