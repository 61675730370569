import { Dialog, Icon, Typography } from '@material-ui/core';
import React from 'react';

const SuccessDialog = (props) => {
    return (
        <Dialog PaperProps={{ style: {borderRadius: '12px', maxWidth: '234px'} }} open={props.open}>
            <div style={{padding: '32px 30px', display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center'}}>
                <Icon color='secondary' style={{fontSize: '4em'}}>{props.failed ? 'cancel' : 'check_circle'}</Icon>
                <Typography style={{fontWeight: 'bold', marginTop: '20px'}} variant='body1'>{props.text || 'Your folder has been saved successfully'}</Typography>
            </div>
        </Dialog>
    )
}

export default SuccessDialog;