import React from 'react';
import Theme from '../../Components/theme';
import WsHeader from '../../Components/wsheader';
import WsFooter from '../../Components/wsfooter';
import FileUploadButton from '../../Components/fileuploadbutton';
import { Typography, TextField, Checkbox, FormControlLabel, Button, Icon, Card, CardContent } from '@material-ui/core';
import MainCTA from '../../Components/MainCTA';
import { currencies } from '../../Utils/Constants';

const Servercalls = require('../../Utils/Servercalls');

export default class WsBundleName extends React.Component {

    constructor(props){
        super(props);
        const bundle = this.props.context;
        this.state = {
            name: bundle.name,
            description: bundle.description,
            bannerSamples: [],
        }
    }

    componentDidMount() {
        const bundle = this.props.context;
        const self = this;
        Servercalls.pluginpremier_getSampleBanners(bundle.pluginId, bundle.itemId).then(response => {
            self.setState({ bannerSamples: response.data });
        })
    }

    async save() {
        const self = this;
        const bundle = this.props.context;
        self.setState({ saving: true });
        try{
            await Servercalls.pluginpremier_updateBundle(bundle.pluginId, bundle.itemId, "name", self.state.name);
            await Servercalls.pluginpremier_updateBundle(bundle.pluginId, bundle.itemId, "description", self.state.description);
            self.props.changeParentContext('name', self.state.name);
            self.props.changeParentContext('description', self.state.description);
            self.setState({ saving: false, saved: true });
            setTimeout(() => {
                self.props.back();
                self.setState({ saved: false });
                
            }, 3000);
        }
        catch(e){
            self.setState({ saving: false });
            alert(e);
        }
    }

    render() {
        if(this.state.saved) return (
            <center style={{ margin: 64}}>
                <Icon style={{ fontSize: 120}} color="secondary">check</Icon>
                <br /><br />
                <Typography variant="body2">Display Details have been saved. You'll be redirected shortly</Typography>
            </center>
        )
        return (
            <Theme>
                <WsHeader back={this.props.back} title="Collection Title & Description" description='Display details are vital information for a user to buy a collection. It is likely they make their purchasing decision with these three pieces of information alone. ' helpText="How to make use of these?" helpVideo="a" />
                <Card style={{ margin: 12 }}>
                    <CardContent>
                        <Typography variant="h6" style={{marginBottom: 12}}>Title & Description</Typography>
                        <TextField fullWidth label="Title" value={this.state.name} onChange={e => this.setState({ name: e.target.value })} />
                        <TextField style={{marginTop: 32, marginBottom: 32 }} fullWidth label="Description" value={this.state.description} onChange={e => this.setState({ description: e.target.value })} />
                    </CardContent>
                </Card>
                <WsFooter text="The collection name and banner image helps gives an overview. This is the first step in convincing the user to buy." />
                <MainCTA 
                    className="bundle_banner_save" 
                    disabled={this.state.saving|| this.state.name.length === 0 || this.state.description.length === 0} 
                    onCTAClick={() => this.save()} 
                    icon="save" 
                    text="Save"
                    settings={[]}
                    help={[
                        {title:"How to choose a good title?", text:"Keep it short. Less than 60 characters. Describe what the collection is about. Avoid brand names. Keep it straight forward. For example, 'Lose 10kg in 40 days', 'Daily live performance at 8pm'"},
                        {title:"How to choose a good description?", text:"Use 200 characters to tell the users in simple words what is the benefit of buying this course. What will change in the buyers life after making this purchase?"}
                    ]} 
                />
            </Theme>
        )
    }
}