import React from 'react';
import Theme from '../../Components/theme';
import WsHeader from '../../Components/wsheader';
import WsFooter from '../../Components/wsfooter';
import MainCTA from '../../Components/MainCTA';
import FileUploadButton from '../../Components/fileuploadbutton';
import { TextField, Button, Icon, Typography, Card,CardContent } from '@material-ui/core';

const Servercalls = require('../../Utils/Servercalls');

export default class WsAppName extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            logo: this.props.context.logo,
            name: this.props.context.name,
        }
    }

    componentDidMount(){
        const self = this;
        Servercalls.getApp().then(response => self.setState({ ...response.data }))
    }

    async save(){
        const self = this;
        self.setState({ saving: true });
        let domain = this.state.name.toLowerCase().replace(/[^0-9a-z]/gmi,"");
        domain = domain.replace(".nity.app","") + '.nity.app';
        try{
            await Servercalls.updateApp(this.state.name, this.state.logo, domain, this.props.context.video || "NONE", false);
            if(this.state.welcomeMessage)
                await Servercalls.updateAppDetails("welcomeMessage", this.state.welcomeMessage)
            if(this.state.creatorImage)
                await Servercalls.updateAppDetails("creatorImage", this.state.creatorImage);
            if(this.state.creatorSign)
                await Servercalls.updateAppDetails("creatorSign", this.state.creatorSign);
            self.setState({ saving: false, saved: true });
            self.props.changeParentContext('name',self.state.name);
            self.props.changeParentContext('logo',self.state.logo);
            self.props.changeParentContext('domain',domain);
            setTimeout(() => {self.props.back(); self.setState({ saved: false})},3000);
        }
        catch(e){
            alert("This name is taken. Please try with another name."); 
            self.setState({saving: false});
        }
    }    

    render() {
        if(this.state.saved) return (
            <center style={{ margin: 64}}>
                <Icon style={{ fontSize: 120}} color="secondary">check</Icon>
                <br /><br />
                <Typography variant="body2">App name and logo have been saved. You'll be redirected shortly</Typography>
            </center>
        )


        return(
            <Theme>
                <WsHeader back={this.props.back} title="Name and Logo" description="This is the identity of your app. When a user installs your app on their device, this is the name and logo they'll see on their home screen" helpText="Why should I customize this?" helpVideo="a" />
                <Card style={{ margin: 12 }}>
                    <CardContent>
                        <Typography variant="h6">App Logo </Typography>
                    <img src={this.state.logo} style={{ height: 100, width: 100, borderRadius: 8, objectFit: 'cover'}} />
                    <FileUploadButton
                        icon="cloud_upload"
                        text="Upload new logo"
                        color="secondary"
                        variant="contained"
                        style={{ marginTop: 12 }}
                        onFail={ e => alert(e)}
                        onUpload={url => this.setState({ logo : url })}
                        extensions={['png', 'jpg','jpeg']}
                        maxSizeMBs={1}
                    />
                    <Typography variant="body2" color="secondaryText">Dimension : 512x512 pixels</Typography>
                        <Typography style={{marginTop:24}} variant="h6">App Name </Typography>
                    
                    <TextField fullWidth label="App Name" style={{ marginTop: 12 }} value={this.state.name} onChange={e => this.setState({ name: e.target.value})} />
                    </CardContent>
                </Card>
                <Typography variant="h6" style={{ margin: 12 }}>Welcome message</Typography>
                <Card style={{ margin: 12 }}>

                    <CardContent>
                        <Typography variant="h6">Your Photo </Typography>
                    {this.state.creatorImage?<img src={this.state.creatorImage} style={{ height: 100, width: 100, borderRadius: 50, objectFit: 'cover'}} />: null}
                    <FileUploadButton
                        icon="cloud_upload"
                        text="Upload your photo"
                        color="secondary"
                        variant="contained"
                        style={{ marginTop: 12 }}
                        onFail={ e => alert(e)}
                        onUpload={url => this.setState({ creatorImage : url })}
                        extensions={['png', 'jpg','jpeg']}
                        maxSizeMBs={1}
                    />
                    <Typography variant="body2" color="secondaryText">Dimension : 512x512 pixels</Typography>
                    <Typography style={{marginTop:24}} variant="h6">Welcome Message</Typography>        
                    <TextField fullWidth helperText="140 char max"  label="Welcome Message" style={{ marginTop: 12 }} value={this.state.welcomeMessage} onChange={e => this.setState({ welcomeMessage: e.target.value})} />
                    {this.state.creatorSign?<img src={this.state.creatorSign} style={{ height: 100}} />: null}
                    <FileUploadButton
                        icon="cloud_upload"
                        text="Upload your autograph"
                        color="secondary"
                        variant="contained"
                        style={{ marginTop: 12 }}
                        onFail={ e => alert(e)}
                        onUpload={url => this.setState({ creatorSign : url })}
                        extensions={['png']}
                        maxSizeMBs={1}
                    />
                    </CardContent>


                </Card>

                <WsFooter text="The App name and Logo helps users quickly identify your app. Make it memorable and simple." />
                <MainCTA settings={[]} help={[]} icon="save" text="Save App Details" disabled={this.state.saving || !this.state.name || this.state.name.length === 0 || !this.state.logo || this.state.logo === '' || this.state.logo === "NONE"} onCTAClick={() => this.save()} />
            </Theme>
        )
    }
}