import React from 'react';
import {
  CircularProgress, Card, Typography, IconButton, Icon, LinearProgress, ButtonBase,
} from '@material-ui/core';
import md5 from 'md5';
import WsFooter from '../Components/wsfooter';
import MainCTA from '../Components/MainCTA';
import BundleItem from './bundleitem';
import AppPublishHeader from '../Components/apppublishheader';
import RoundedButton from '../Components/roundedbutton';
import StyledDrawer from '../Components/StyledDrawer';

import FolderIcon from '../Assets/Icons/Folder.svg';
import VideoIcon from '../Assets/Icons/Video.svg';
import PlusIconSecondary from '../Assets/Icons/PlusSecondary.svg';

const Servercalls = require('../Utils/Servercalls');

export default class WsPremuiumBundles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bundles: [],
      loading: true,
    };
    this.premiumContainer = React.createRef();
  }

  componentDidMount() {
    this.reload();
  }

  getContentThumbnail(bundle, content) {
    const { loadingContent } = this.state;
    const availableWidth = Math.min(800, window.innerWidth) - (8 + 16);
    let width = availableWidth / 2;
    if (availableWidth > 600) {
      width = availableWidth / 4.2;
    }
    width -= 16 / 2.5;
    const height = width;
    return (
      <div style={{ marginLeft: 8, display: 'inline-block' }}>
        <div style={{
          height,
          width,
          backgroundImage: `url(${content.thumbnail || `https://gravatar.com/avatar/${md5(content.contentId)}?d=identicon`})`,
          backgroundPosition: 'center center',
          backgroundSize: 'cover',
        }}
        />
        {loadingContent === content.contentId ? <LinearProgress style={{ width: '100%' }} /> : null}
        <Typography variant="body2">
          {content.name.substr(0, 15)}
          {content.name.length > 15 ? '...' : ''}
        </Typography>
      </div>
    );
  }

  openBundle(bundle) {
    const { changeWorkspace } = this.props;
    changeWorkspace('bundlecontents', bundle.name, bundle);
  }

  reload() {
    const { tab } = this.props;
    const self = this;
    Servercalls.pluginpremier_getItems(tab.pluginId).then((response) => {
      self.setState({ loading: false, bundles: response.data });
    }).catch(() => { self.setState({ loading: false }); });
  }

  moveToTop(bundle) {
    const self = this;
    self.setState({ moving: true });
    Servercalls.pluginpremier_moveBundleToTop(bundle.pluginId, bundle.itemId).then(() => {
      self.reload(); self.setState({ moving: false });
    });
  }

  async createNewCollectionAndOpen(type, data) {
    const { changeWorkspace, tab } = this.props;
    this.premiumContainer.current.offsetParent.scrollTop = 0;
    window.analytics.track('Add Content Clicked ', { content_type: type });
    changeWorkspace('premiumaddbundle', 'Add Content', { pluginId: tab.pluginId, newCollection: true });
  }

  async addLiveCourse() {
    try {
      // event: add live course clicked
      this.props.changeWorkspace('premiumaddlive', 'Add Live Course', { pluginId: this.props.tab.pluginId });
    } catch (e) {
      console.log('Add Live Course', e);
    }
  }

  render() {
    const { loading, bundles, contentDialogOpen } = this.state;
    const { context, changeWorkspace, tab } = this.props;
    // @dhairya will create more readable code by storing bundles as
    // publish/unpublished seperately during refactor

    return (
      <div ref={this.premiumContainer} style={{ display: 'flex', flexDirection: 'column' }}>
        {loading ? (
          <div style={{
            flex: '1', display: 'flex', justifyContent: 'center', alignItems: 'center',
          }}
          >
            <CircularProgress disableShrink fullWidth />
          </div>
        ) : (
          <>
            <AppPublishHeader scrollContainer={this.premiumContainer.current} {...this.props} />
            {!loading && bundles.length === 0
              ? (
                <div style={{ margin: 'auto 12px', display: 'flex', padding: '24px 0' }}>
                  <div style={{
                    display: 'flex', flexDirection: 'column', justifyContent: 'center', flex: '1', alignItems: 'center',
                  }}
                  >
                    <IconButton
                      onClick={() => this.setState({ contentDialogOpen: true }) || window.analytics.track('Add Folder Clicked', { position: 'center floater' })}
                      style={{
                        padding: '22px', marginBottom: '16px', backgroundColor: '#efebff', border: '1px solid #d2caf5',
                      }}
                    >
                      <Icon color="secondary" style={{ fontSize: '1.09em' }}>
                        <img alt="Add" style={{ height: '100%' }} src={PlusIconSecondary} />
                      </Icon>
                    </IconButton>
                    <Typography variant="body2" style={{ fontWeight: 'bold' }}>Add Content to get started</Typography>
                  </div>
                </div>
              )
              : (
                <>
                  <div style={{
                    display: bundles.filter(b => b.published).length ? 'flex' : 'none', margin: context.alwaysOpen ? '16px' : '12px 8px 0px', flexDirection: context.alwaysOpen ? 'row' : 'column', flexWrap: 'wrap',
                  }}
                  >

                    {!loading && context.alwaysOpen && bundles.filter(b => b.published).length ? (
                      <div style={{
                        margin: '0', borderRadius: '12px', maxWidth: '239px', marginRight: '16px', marginBottom: '16px', padding: '0 16px 16px', display: 'flex', justifyContent: 'center',
                      }}
                      >
                        <div style={{
                          display: 'flex', flexDirection: 'column', justifyContent: 'center', flex: '1', alignItems: 'center', padding: '19px',
                        }}
                        >
                          <IconButton
                            onClick={() => this.setState({ contentDialogOpen: true }) || window.analytics.track('Add Folder Clicked', { position: 'center floater' })}
                            style={{
                              padding: '22px', marginBottom: '16px', backgroundColor: '#efebff', border: '1px solid #d2caf5',
                            }}
                          >
                            <Icon color="secondary" style={{ fontSize: '1.09em' }}>
                              <img alt="add" style={{ height: '100%' }} src={PlusIconSecondary} />
                            </Icon>
                          </IconButton>
                          <Typography variant="body2" style={{ fontWeight: 'bold' }}>Add Content to get started</Typography>
                        </div>
                      </div>
                    ) : null}

                    {bundles.filter(b => b.published).length === 0 ? null
                      : (
                        <>
                          {
                                bundles.filter(b => b.published).map((bundle, index) => (
                                  <BundleItem
                                    alwaysOpen={context.alwaysOpen}
                                    moveToTop={() => this.moveToTop(bundle)}
                                    bundle={bundle}
                                    changeWorkspace={changeWorkspace}
                                    reload={() => this.reload()}
                                    style={{ marginBottom: !context.alwaysOpen && index === bundles.filter(b => b.published).length - 1 ? '0' : '12px' }}
                                    addThumbnail={async (url) => {
                                      if (bundle.liveSchedule && bundle.liveSchedule.length) {
                                        return;
                                      }
                                      await Servercalls.pluginpremier_updateBundle(tab.pluginId, bundle.itemId, 'imageUrl', url);

                                      this.reload();
                                    }}
                                  />
                                ))
                            }
                        </>
                      )}

                    <div style={{ height: bundles.filter(b => !b.published).length ? '0' : '52px', marginTop: '16px' }} />

                    {context.alwaysOpen ? null : (
                      <MainCTA
                        alwaysOpen={context.alwaysOpen}
                        className="premium_addcollection"
                        aboveTabs
                        icon="add"
                        text="Add Content"
                        settings={[...tab.options]}
                        help={[]}
                        onCTAClick={() => this.setState({ contentDialogOpen: true }) || window.analytics.track('Add Folder Clicked', { position: 'bottom floater' })}
                      />
                    )}
                  </div>

                  { bundles.filter(b => !b.published).length ? (
                    <Typography className="premiumtab" style={{ margin: !bundles.filter(b => b.published).length ? '16px 16px 0' : '0 16px', color: '#777' }} variant="body2">Your Unpublished Collections:</Typography>
                  ) : null}

                  <div style={{
                    display: bundles.filter(b => !b.published).length ? 'flex' : 'none', margin: context.alwaysOpen ? '16px' : '12px 8px 0px', flexDirection: context.alwaysOpen ? 'row' : 'column', flexWrap: 'wrap',
                  }}
                  >

                    {!loading && context.alwaysOpen
                    && !bundles.filter(b => b.published).length ? (
                      <div style={{
                        margin: '0', borderRadius: '12px', maxWidth: '239px', marginRight: '16px', marginBottom: '16px', padding: '0 16px 16px', display: 'flex', justifyContent: 'center',
                      }}
                      >
                        <div style={{
                          display: 'flex', flexDirection: 'column', justifyContent: 'center', flex: '1', alignItems: 'center', padding: '12px',
                        }}
                        >
                          <IconButton
                            onClick={() => this.setState({ contentDialogOpen: true }) || window.analytics.track('Add Folder Clicked', { position: 'center floater' })}
                            style={{
                              padding: '22px', marginBottom: '16px', backgroundColor: '#efebff', border: '1px solid #d2caf5',
                            }}
                          >
                            <Icon color="secondary" style={{ fontSize: '1.09em' }}>
                              <img alt="add" style={{ height: '100%' }} src={PlusIconSecondary} />
                            </Icon>
                          </IconButton>
                          <Typography variant="body2" style={{ fontWeight: 'bold' }}>Add Content to get started</Typography>
                        </div>
                      </div>
                      ) : null}

                    {bundles.filter(b => !b.published).length === 0 ? null
                      : (
                        <>
                          {
                            bundles.filter(b => !b.published).map((bundle, index) => (
                              <BundleItem
                                alwaysOpen={context.alwaysOpen}
                                moveToTop={() => this.moveToTop(bundle)}
                                bundle={bundle}
                                changeWorkspace={changeWorkspace}
                                reload={() => this.reload()}
                                style={{ marginBottom: !context.alwaysOpen && index === bundles.filter(b => !b.published).length - 1 ? '0' : '12px' }}
                                addThumbnail={async (url) => {
                                  await Servercalls.pluginpremier_updateBundle(tab.pluginId, bundle.itemId, 'imageUrl', url);
                                  this.reload();
                                }}
                              />
                            ))
                          }
                        </>
                      )}

                    <div style={{ height: '52px', marginTop: '16px' }} />

                    {!context.alwaysOpen && !bundles.filter(b => b.published).length ? (
                      <MainCTA
                        alwaysOpen={context.alwaysOpen}
                        className="premium_addcollection"
                        aboveTabs
                        icon="add"
                        text="Add Content"
                        settings={[...tab.options]}
                        help={[]}
                        onCTAClick={() => this.setState({ contentDialogOpen: true }) || window.analytics.track('Add Folder Clicked', { position: 'bottom floater' })}
                      />
                    ) : null}

                  </div>
                </>
              )}
            <WsFooter
              text="This is your content tab. All your content is organized in collections. Users can buy collections"
            />

            <StyledDrawer
              alwaysOpen={context.alwaysOpen}
              open={contentDialogOpen}
              anchor="bottom"
              title="Select an item"
              onClose={() => this.setState({ contentDialogOpen: false })}
            >
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <ButtonBase
                  onClick={() => this.createNewCollectionAndOpen()}
                  style={{
                    display: 'flex', flex: '1', flexDirection: 'column', alignItems: 'center', maxWidth: '160px', textAlign: 'center', borderRadius: '8px', boxShadow: '0 0 16px 0 rgba(0, 0, 0, 0.16)',
                  }}
                >
                  <Icon style={{ fontSize: '4.2em', margin: '16px', color: '#ffbf3b' }}>
                    <img style={{ height: '100%' }} src={FolderIcon} />
                  </Icon>
                  <Typography style={{ fontWeight: 'bold', marginBottom: '4px' }} variant="body1">Folder</Typography>
                  <Typography style={{ color: '#999999', color: '#999999', margin: '0 16px 18px' }} variant="caption">
                    Add different videos, blogs &amp; more, then sell it as a package
                  </Typography>
                  <RoundedButton disableRipple style={{ marginBottom: '16px', padding: '5px 24px', marginTop: 'auto' }} color="secondary" variant="contained">
                    Select
                  </RoundedButton>
                </ButtonBase>
                <ButtonBase
                  onClick={() => this.addLiveCourse()}
                  style={{
                    marginLeft: this.props.context.alwaysOpen ? '24px' : '20px', display: 'flex', flex: '1', flexDirection: 'column', alignItems: 'center', maxWidth: '160px', textAlign: 'center', borderRadius: '8px', boxShadow: '0 0 16px 0 rgba(0, 0, 0, 0.16)',
                  }}
                >
                  <Icon style={{ fontSize: '4.2em', margin: '16px', color: '#ffbf3b' }}>
                    <img style={{ height: '100%' }} src={VideoIcon} />
                  </Icon>
                  <Typography style={{ fontWeight: 'bold', marginBottom: '4px' }} variant="body1">Live Course</Typography>
                  <Typography style={{ color: '#999999', color: '#999999', margin: '0 16px 18px' }} variant="caption">
                    A series of live sessions
                  </Typography>
                  <RoundedButton disableRipple style={{ marginBottom: '16px', padding: '5px 24px', marginTop: 'auto' }} color="secondary" variant="contained">
                    Select
                  </RoundedButton>
                </ButtonBase>
              </div>

            </StyledDrawer>
          </>
        )}
      </div>
    );
  }
}
