import { Card, CircularProgress, Icon, IconButton, Typography } from '@material-ui/core';
import React, {Component} from 'react';
import ConfirmUnsavedDialog from '../Components/ConfirmUnsavedDialog';
import FileUploadButton from '../Components/fileuploadbutton';
import MainCTA from '../Components/MainCTA';
import StyledInput from '../Components/StyledInput';
import WsHeader from '../Components/wsheader';
import RoundedButton from '../Components/roundedbutton';
import md5 from 'md5';
import SuccessDialog from '../Components/SuccessDialog';
import ContainerHeader from '../Testing/UI/ContainerHeader';
import MembersList from '../Testing/UI/MembersList';
import MemberSearch from '../Testing/UI/MemberSearch';

const Servercalls = require('../Utils/Servercalls');

class ChatEdit extends Component {
    state = {
        step: 0,

        successDialogOpen: false,
        confirmUnsavedDialogOpen: false,
        confirmUnsavedResolver: null,
        id: this.props.context.chatroom.chatroomId,
        groupIcon: this.props.context.chatroom.chatroomLogoUrl || 'https://cdn.socionity.com/assets/build.socionity.com/illustrations/nobannerimage.png',
        groupName: this.props.context.chatroom.chatroomName || 'Group name',
        groupManifesto: this.props.context.chatroom.chatManifesto || 'Lorem ipsum dolor sit, ipsum dolor.',
        groupRules: this.props.context.chatroom.chatRules || '',

        loading: true,
        showUsers: true,
        selectedUserIndices: {},
        userList: [],
    }

    async save() {
        const {groupIcon, groupName, groupManifesto, groupRules, id} = this.state;
        let chatroom = {}
        chatroom.chatroomId = id;
        chatroom.chatroomLogoUrl = groupIcon;
        chatroom.chatroomName = groupName;
        chatroom.chatManifesto = groupManifesto;
        chatroom.chatRules = groupRules;
        
        if (this.state.showUsers) {
            const allowedUsers = this.state.userList.filter((user) => this.state.selectedUserIndices[user.index] === true).map(user => user.userId)
            chatroom.rules = [{groupType: 'private', allowUsers: allowedUsers}];
        } else {
            chatroom.rules = [];
        }

        const response = await Servercalls.updateChatroom(this.props.context.appId, this.props.context.pluginId, chatroom);
        console.log(response);
        if (response.data === 'ok')
            this.setState({successDialogOpen: true}, () => setTimeout(() => this.props.back(), 3000))
        else
            this.setState({failureDialogOpen: true}, () => setTimeout(() => this.props.back(), 3000))
    }

    async componentDidMount() {
        const self = this;
        if (this.props.context.chatroom.rules && this.props.context.chatroom.rules.length && this.props.context.chatroom.rules[0].groupType === 'private') {
            const allowedUsers = new Set(this.props.context.chatroom.rules[0].allowUsers);

            self.setState({ cursor: null })
            let cursor = null;
            while(true){
                const response = await Servercalls.getAppUsers(cursor);
                cursor = response.data.cursor;
                self.setState({ userList: self.state.userList.concat( response.data.users )});
                if(!cursor)
                    break;
            }
            const {selectedUserIndices} = this.state;
            this.state.userList.map((user, index) => selectedUserIndices[index] = allowedUsers.has(user.userId))
            const userList = this.state.userList.map((user, index) => ({...user, index}))
            this.setState({ loading: false, selectedUserIndices, userList });
            
            return;
        }

        this.setState({ loading: false, showUsers: false });
    }

    render() {

        if (this.state.memberSelectOpen) {
            return (
                <>
                    <MemberSearch open={true} onBack={() => this.setState({memberSelectOpen: false})} value={this.state.userFilter} onChange={(e) => this.setState({userFilter: e.target.value})} />
                
                    <Card style={{margin: '56px 0 60px', boxShadow: 'none'}}>
                        <MembersList users={this.state.userList.filter((user, index) =>  (
                            !this.state.userFilter || (user.info && user.name && user.info.name.startsWith(this.state.userFilter)) || user.email.startsWith(this.state.userFilter))).map((user) => (
                            {
                                ...user,
                                icon: <img src={ user.info && user.info.picture ? user.info.picture : `https://gravatar.com/avatar/${md5(user.userId)}?d=identicon`} />,
                                name: user.info && user.info.name ? user.info.name : user.email,
                                email: user.email,
                                action: this.state.selectedUserIndices[user.index] ? 'Remove' : 'Add',
                                onClick: this.state.selectedUserIndices[user.index] ? () => {
                                    const {selectedUserIndices} = this.state;
                                    selectedUserIndices[user.index] = false;
                                    this.setState({selectedUserIndices})
                                    if (this.state.userFilter)
                                        this.setState({userFilter: null})
                                } : () => {
                                    const {selectedUserIndices} = this.state;
                                    selectedUserIndices[user.index] = true;
                                    this.setState({selectedUserIndices})
                                    if (this.state.userFilter)
                                        this.setState({userFilter: null})
                                },
                            }
                        ))} />
                    </Card>
                </>
            )
        }
        
        return(
            <>
                <WsHeader style={{position: 'sticky'}} back={async() => {
                    const self = this;
                    let confirmUnsaved = () => new Promise(resolve => self.setState({confirmUnsavedDialogOpen: true, confirmUnsavedResolver: resolve}))
                    const confirmation = await confirmUnsaved();
                    self.setState({confirmUnsavedDialogOpen: false, confirmUnsavedResolver: null});

                    if(!confirmation) {
                        // cannot save here as it will break design incase of incomplete input
                        // this.save()
                        return;
                    } else {
                        this.props.back()
                    }
                    
                }}  title={this.props.context.title || "Edit Group"}/>

                <Card style={{display: 'flex', flexDirection: 'column', boxShadow: 'none'}}>
                    <div style={{display: 'flex', flexDirection: 'column', 'alignItems': 'center'}}>
                        <div style={{margin: '32px'}}>
                            <img style={{borderRadius: '25px', zIndex: '10', boxShadow: 'rgba(0, 0, 0, 0.32) 0px 0px 16px 0px', width: 'calc(min(14.11vh, 92px)', height: 'calc(min(14.11vh, 92px)', objectFit: 'cover'}} src={this.state.groupIcon} />
                            <FileUploadButton
                                containerStyles={{position: 'relative', zIndex: '20', marginTop: '-105%'}}
                                icon="photo_camera"
                                color="secondary"
                                variant="text"
                                style={{border: '1px solid', borderRadius: '25px', background: 'rgba(255, 255, 255, 0.3)',padding: '0', minWidth: '0', height: 'calc(min(14.11vh, 92px)', width: 'calc(min(14.11vh, 92px)'}}
                                onFail={ e => alert(e)}
                                onUpload={url => this.setState({ groupIcon : url})}
                                onEnter={() => window.analytics.track('Logo Image Change Clicked', {})}
                                extensions={['png', 'jpg','jpeg']}
                                maxSizeMBs={1}
                            />
                        </div>
                        <div style={{display: 'flex', margin: '0 20px 20px', flexDirection: 'column', alignSelf: 'stretch'}}>
                            <StyledInput alwaysOpen={this.props.context.alwaysOpen} value={this.state.groupName} onChange={e => this.setState({groupName: e.target.value})} placeholder='Enter Group Name' />
                            <StyledInput alwaysOpen={this.props.context.alwaysOpen} style={{marginTop: '24px'}} value={this.state.groupManifesto} onChange={e => this.setState({groupManifesto: e.target.value})} placeholder='Enter manifesto' />
                            <Typography variant="body1" style={{marginRight: 'auto', fontWeight: 'bold', marginTop: '16px'}}>
                                Community Rules
                            </Typography>
                            <StyledInput
                                alwaysOpen={this.props.context.alwaysOpen}
                                style={{marginTop: '8px'}}
                                inputStyle={{padding: '20px', minHeight: '145px'}}
                                value={this.state.groupRules}
                                onChange={e => this.setState({groupRules: e.target.value})}
                                placeholder='Enter community rules'
                                variant='outlined'
                                multiline
                            />
                        </div>
                    </div>
                </Card>

                
                    {this.state.loading ? (
                        <CircularProgress color='secondary' />
                    ) : this.state.showUsers ? (
                        <Card style={{margin: '12px 0 60px', boxShadow: 'none'}}>
                            <ContainerHeader title='Members' action='Manage' variant='contained' onClick={() => this.setState({memberSelectOpen: true})} />
                            <MembersList users={this.state.userList.filter((user) => this.state.selectedUserIndices[user.index] === true).map((user) => (
                                {
                                    ...user,
                                    icon: <img src={ user.info && user.info.picture ? user.info.picture : `https://gravatar.com/avatar/${md5(user.userId)}?d=identicon`} />,
                                    name: user.info && user.info.name ? user.info.name : user.email,
                                    email: user.email,
                                    action: 'Remove',
                                    onClick: () => {
                                        console.log(user.index);
                                        const {selectedUserIndices} = this.state;
                                        selectedUserIndices[user.index] = false;
                                        this.setState({selectedUserIndices})
                                    },
                                }
                            ))} />
                        </Card>
                    ) : null}

                <MainCTA
                    alwaysOpen={this.props.context.alwaysOpen}
                    square={!this.props.context.alwaysOpen}
                    text="Confirm"
                    onCTAClick={() => this.save()}
                    disabled={this.state.groupName === '' || this.state.groupManifesto === ''}
                    help={[{}]}
                    settings={[]}
                    toast='Please add the group name and manifesto to proceed'
                />

                <SuccessDialog text='Yay! Group has been saved successfully' open={this.state.successDialogOpen}/>
                <SuccessDialog text='We are unable to process your request at this moment, please try again' open={this.state.failureDialogOpen} failed/>

                <ConfirmUnsavedDialog open={this.state.confirmUnsavedDialogOpen} resolve={this.state.confirmUnsavedResolver} body='The group details have not been saved. Any changes made will not be reflected further. Do you wish to proceed without saving?' />
            </>
        )
    }
}

export default ChatEdit;
