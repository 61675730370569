import React from 'react'
import { Typography, Card, CardContent, TextField, Icon, CardActions } from '@material-ui/core';

import Illustration from '../../Components/illustration';
import RoundedButton from '../../Components/roundedbutton';
import MainCTA from '../../Components/MainCTA';
import WsHeader from '../../Components/wsheader';
import SuccessDialog from '../../Components/SuccessDialog';

const Servercalls = require('../../Utils/Servercalls');

export default class WsFaqs extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            faqs : this.props.context.faqs || [],
        }
    }

    save() {
        const self = this;
        self.setState({ saving: true });
        Servercalls.pluginpremier_setFaqs(this.props.context.pluginId, this.props.context.itemId, this.state.faqs).then(response => {
            self.setState({ saving: false, saved: true });
            setTimeout(() => self.setState({ saved: false }) || this.props.back(), 3000);
        }).catch(e => self.setState({ saved: false, saving: false }));
    }

    render() {
        // if(this.state.saved) return (
        //     <center style={{ margin: 64}}>
        //         <Icon style={{ fontSize: 120}} color="secondary">check</Icon>
        //         <br /><br />
        //         <Typography variant="body2">FAQs have been saved. You'll be redirected shortly</Typography>
        //     </center>
        // )

        return (
            <div style={{padding: 0}}>
                <WsHeader back={this.props.back} title="FAQs" description='' />
                <Typography variant="h6">Frequently Asked Questions</Typography>
                {
                    this.state.faqs && this.state.faqs.length > 0 ? (
                        <div>
                            {this.state.faqs.map((faq, index) => (
                                <Card style={{ marginTop: 12}}>
                                    <CardContent>
                                        <Typography variant="h6">#{index + 1}</Typography>
                                        <TextField label="Question" fullWidth style={{marginTop: 12}} value={faq.question} onChange={(event) => { let faqs = this.state.faqs; faqs[index].question = event.target.value; this.setState({ faqs }) }}/>
                                        <TextField label="Answer" fullWidth style={{marginTop: 12}} value={faq.answer} onChange={(event) => { let faqs = this.state.faqs; faqs[index].answer = event.target.value; this.setState({ faqs }) }}/>
                                    </CardContent>
                                </Card>
                            ))}
                            <RoundedButton style={{marginTop: 12}} variant="contained" color="primary" onClick={() => { let faqs = this.state.faqs; faqs.push({ faqId: Date.now(), question: " ", answer: " "  }); this.setState({ faqs })}}>Add Question and Answer</RoundedButton>
                        </div>
                    ) : (
                        <Card style={{ marginTop: 12 }}>
                            <CardContent>
                                <Illustration image="faq" style={{ width: '100%'}} />
                            </CardContent>
                            <CardActions style={{justifyContent: 'center'}}>
                                <RoundedButton style={{marginTop: 12}} variant="contained" color="primary" onClick={() => { let faqs = this.state.faqs; faqs.push({ faqId: Date.now(), question: " ", answer: " "  }); this.setState({ faqs })}}>Add Question and Answer</RoundedButton>
                            </CardActions>
                        </Card>
                    )
                }
                <MainCTA settings={[]} help={[]} text="Save FAQs" onCTAClick={() => this.save()} disabled={this.state.saving} />
                <SuccessDialog open={this.state.saved} />
            </div>
        )
    }
}