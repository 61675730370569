import React from 'react';
import Theme from './theme';
import { Icon, Typography, Button, DialogActions, Dialog, DialogContent, DialogTitle, Card, CardHeader, Avatar, CardContent, CardActions } from '@material-ui/core';
import Joyride from 'react-joyride';
import { withTheme } from '@material-ui/styles';

import RoundedButton from './roundedbutton';

class WsFooter extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            showHelp: false,
        }
    }

    componentDidMount(){
        let isOnboardingComplete = localStorage.getItem(`guidedtour-${this.props.workspace}`);
        if(isOnboardingComplete){
            return;
        }
    }

    closeHelp() {
        this.setState({ showHelp: false });
    }

    handleOnboarding(data) {
        const { lifecycle, index, size } = data;
        if(lifecycle==='complete' && index === size - 1)
            this.closeHelp();
    }

    render() {
        return null;
        return (
            <Card style={{marginLeft: 12, marginRight: 12, marginTop: 16, marginBottom: 72, padding: '8px 16px'}}>
            <CardHeader 
                avatar={<Avatar src="https://socionity.com/assets/uploads/h.jpeg?p=By08uSkp"/>}
                title="Harsha Karamchati"
                subheader="Co-founder, Socionity"
            />
            <CardContent>
                <Typography variant="body1"> Need help? Call me. I'm your personal account manager</Typography>
                <Typography variant="subtitle1" color="textSecondary">
                    +91 901 041 9999
                </Typography>
                <Typography variant="subtitle2" color="textSecondary">
                    harsha@socionity.com
                </Typography>
            </CardContent>
            <CardActions>
                <RoundedButton variant="outlined" color="primary" onClick={() => window.open("https://wa.me/+919010419999", "_blank")}><Icon>call</Icon>&nbsp;WhatsApp</RoundedButton>
                <RoundedButton variant="outlined" color="primary" onClick={() => window.open("mailto:harsha@socionity.com")}><Icon>mail</Icon>&nbsp;Email</RoundedButton>
            </CardActions>          
        </Card>
        )
    }
}

export default withTheme(WsFooter)