import { Card, InputBase, Typography } from '@material-ui/core';
import { makeStyles, withTheme } from '@material-ui/styles';
import React from 'react';

const useStyles = makeStyles(theme => ({
    root: {
        margin: theme.spacing(1),
        padding: theme.spacing(2, 2, 1.25),
        borderRadius: theme.spacing(1.25),
        boxShadow: 'none',
    },
    title: {
        fontWeight: 'bold',
        fontSize: theme.spacing(2.25),
        marginBottom: theme.spacing(0.5),
    },
    subtitle: {
        marginBottom: theme.spacing(2),
    },
    inputBase: {
        border: '1px solid #eaeaea',
        background: '#fafafa',
        borderRadius: theme.spacing(0.75),
        width: '100%',
        padding: theme.spacing(1.625),
        marginBottom: theme.spacing(1),
    },
    input: {
        padding: 0,
        overflow: 'scroll !important',
    },
    wordCount: {
        textAlign: 'end',
        color: 'rgba(77, 79, 118, 0.59)',
    },
}))

const TextInputCard = (props) => {
    const classes = useStyles();
    return (
        <Card className={classes.root}>
            <Typography className={classes.title} variant='h6'>{props.title}</Typography>
            <Typography className={classes.subtitle} variant='body2'>{props.subtitle}</Typography>
            <InputBase
                className={classes.inputBase}
                placeholder={props.placeholder}
                multiline={props.rows ? true : false}
                inputProps={{
                    className: classes.input,
                    maxLength: props.maxLength,
                    rows: props.rows || 1,
                    style: {
                        maxHeight: props.theme.spacing(2.375* (props.rows || 1))
                    }                    
                }}
                style={props.error ? {borderColor: '#f02e2e'} : {}}
                value={props.value}
                onChange={e => props.onChange(e)}
            />
            <div style={{display: 'flex'}}>
                <Typography style={{color: '#f02e2e', marginRight: 'auto'}} variant='body2'>{props.error}</Typography>
                {props.maxLength ? 
                    <Typography className={classes.wordCount} variant='body2'>{props.value.length}/{props.maxLength}</Typography> : null }
            </div>
        </Card>
    )
}

export default withTheme(TextInputCard);