import React from 'react';
import { withStyles, createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import amplitude from 'react-amplitude-js';


import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Snackbar from '@material-ui/core/Snackbar';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import ReactPixel from 'react-facebook-pixel';

import Alert from '@material-ui/lab/Alert';
import Wizard from '../Wizard';
import OnboardingHelper from '../Components/onboardinghelper';

import { getParametersByName } from '../Utils/utils';
import { AppUrl, WebAuthClientId } from '../Utils/Constants';
import { setTimeout } from 'timers';

import AppNameWorkspace from '../Dashboards/AppNameAndLogo/ws-appname';
import AppTncWorkspace from '../Dashboards/TnC/ws-apptnc';
import AppThemeWorkspace from '../Dashboards/AppTheme/ws-apptheme';
import AppAnalyticsWorkspace from '../Dashboards/Analytics/ws-appanalytics';
import AppUsersWorkspace from '../Dashboards/Users/ws-appusers';
import AppUsersScheduleNotificationsWorkspace from '../Dashboards/Notifications/ws-appusersshcedulenotifications';
import AppUsersNotificationsListWorkspace from '../Dashboards/Notifications/ws-appusersnotificationslist';
import AppPublishWorkspace from '../Home/ws-apppublish';
import AppPaymentsWorkspace from '../Dashboards/Payments/ws-payments';
import AppPaymentReportWorkspace from '../Dashboards/Payments/ws-paymentreport';
import AppMarketingWorkspace from '../Dashboards/Marketing/ws-appmarketing';
import AppEngagementWorkspace from '../Dashboards/UserEngagement/ws-appengagement';
import LegacyBuilderWorkspace from '../Dashboards/ws-legacybuilder';
import TabsWorkspace from '../ZTabs/ws-tabs';
import AddTabWorkspace from '../ZTabs/ws-addtab';
import LandingWorkspace from '../TabLanding/ws-landing';
import PremiumAddBundleWorkspace from '../TabPremium/ws-premiumaddbundle';
import TabChangeBannerWorkspace from '../ZTabs/ws-tabchangebanner';
import TabChangeNameWorkspace from '../ZTabs/ws-tabchangename';
import BundleContentsWorkspace from '../TabPremium/Bundle/ws-bundlecontents';
import AddVideoContentWorkspace from '../TabPremium/Bundle/ws-addvideo';
import AddAudioContentWorkspace from '../TabPremium/Bundle/ws-addaudio';
import AddDownloadableContentWorkspace from '../TabPremium/Bundle/ws-adddownloadable';
import AddPdfContentWorkspace from '../TabPremium/Bundle/ws-addpdf';
import AddBlogContentWorkspace from '../TabPremium/Bundle/ws-addblog';
import AddEmbedContentWorkspace from '../TabPremium/Bundle/ws-addembed';
import AddQuizContentWorkspace from '../TabPremium/Bundle/ws-addquiz';
import AddLivestreamContentWorkspace from '../TabPremium/Bundle/ws-addlivestream';
import AddVimeoWorkspace from '../TabPremium/Bundle/ws-addvimeo';
import AddZoomWorkspace from '../TabPremium/Bundle/ws-addzoom';
import AddImageWorkspace from '../TabPremium/Bundle/ws-addimage';
import BundlePriceWorkspace from '../TabPremium/Bundle/ws-bundleprice';
import BundleNameWorkspace from '../TabPremium/Bundle/ws-bundlename';
import BundleBannerWorkspace from '../TabPremium/Bundle/ws-bundlebanner';
import BundleSalespageWorkspace from '../TabPremium/Bundle/ws-bundlesalespage';
import BundleAudienceWorkspace from '../TabPremium/Bundle/ws-bundleaudience';
import BundlePositionWorkspace from '../TabPremium/Bundle/ws-bundleposition';
import BundleFaqsWorkspace from '../TabPremium/Bundle/ws-bundlefaqs';
import RoundedButton from '../Components/roundedbutton';

import Header from '../Components/header';
import Stars from '../Assets/Pictures/stars.png';

import { getWindowDimensions } from '../Utils/utils';

const Servercalls = require('../Utils/Servercalls');
const Constants = require('../Utils/Constants');
const utils = require('../Utils/utils');
const LAYOUT_BREAK_POINT = 500;

class App extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            payments: [],
            notifications: []
        }
    }
    async componentDidMount() {
        const app = this.props.app;
        try{
            const lastLogin = localStorage.getItem("lastlogin");
            const payments = (await Servercalls.getAppPayments("get", null)).data.payments;
            let paymentsSince = {};
            let showNotifications = false;
            for(let i = 0 ; i < payments.length; i += 1){
              const p = payments[i];
              if(p.currency.length > 4) continue;
              
              if(p.timestamp > lastLogin){
                showNotifications = true;
                if(!paymentsSince[p.curreny])
                  paymentsSince[p.currency] = 0;
              }
              paymentsSince[p.currency] += parseInt(p.price);
            }
            
            let currencies = Object.keys(paymentsSince);
            let newPayments = [];
            for(let i = 0 ;  i   < currencies.length; i+= 1){
              const currency = currencies[i];
              if(paymentsSince.hasOwnProperty(currency)){
                newPayments.push({ currency, price: paymentsSince[currency]});
              }
            }
        
            let notifications = [];
            if(app.notifications){
              for(let i = 0 ; i < app.notifications.length; i+= 1){
                const notification = app.notifications[i];
                if(notification.timestamp > lastLogin){
                  showNotifications = true;
                  notifications.push(notification);
                }
              }
            }
            this.setState({ notifications, payments: newPayments, showNotifications });
            localStorage.setItem("lastlogin", Date.now());
          }catch(e) {console.log(e)}        

    }

    getTheme() {
        return createMuiTheme(Constants.theme)
      }
    
    render(){
        return (
            <>
                <Dialog open={this.state.showNotifications} onClose={() => this.setState({ showNotifications: false })}>
                <DialogTitle>Since you were here last ...</DialogTitle>
                <DialogContent>
                    {this.state.payments.map(p => (
                    <Card style={{ marginTop: 12, backgroundColor: this.getTheme().palette.primary.main, color: this.getTheme().palette.primary.contrastText}}>
                        <CardContent>
                        <center>
                            <Typography variant="body1">{p.currency}</Typography>
                            <Typography variant="h3">{p.price}</Typography>
                        </center>
                        </CardContent>
                    </Card>
                    ))}
                    <List>
                    {this.state.notifications.map(n => (
                    <ListItem>
                    <ListItemText primary={n.title} secondary={n.text} />
                    </ListItem>            
                    ))}
                    </List>
                </DialogContent>
                <DialogActions>
                    <RoundedButton variant="outlined" color="primary" onClick={() => this.setState({ showNotifications: false})}>Dismiss</RoundedButton>          
                </DialogActions>
                </Dialog>

            </>
        )
    }

}
export default App;