import { Card, Icon, IconButton } from '@material-ui/core';
import { makeStyles, withTheme } from '@material-ui/styles';
import React from 'react';
import SearchIcon from '../../Assets/Icons/Search.svg';
import StyledInput from '../../Components/StyledInput';

const useStyles = makeStyles(theme => ({
    root: {
        position: 'absolute',
        top: 0,
        zIndex: 3500,
        width: '100%',
        display: 'flex',
        height: theme.spacing(7),
    },
    icon: {
        position: 'absolute',
        top: 0,
        zIndex: 3500,
        right: 0,
        margin: theme.spacing(0.5),
    }
}))

const MemberSearch = (props) => {
    const classes = useStyles();
    if (props.open) {
        return (
            <Card className={classes.root}>
                <IconButton style={{padding: 0}} onClick={() => props.onBack()}><Icon style={{fontWeight: 'bold', fontSize: '15px', padding: '0 20px', color: 'black'}}>arrow_back</Icon></IconButton>
                <StyledInput placeholder='Search for Members' style={{flex: 'initial'}} variant='outlined' value={props.value} onChange={(e) => props.onChange(e)}/>
            </Card>
        )
    }
    return (
        <IconButton onClick={() => props.onClick()} className={classes.icon}><Icon><img src={SearchIcon}/></Icon></IconButton>
    )
}

export default withTheme(MemberSearch);