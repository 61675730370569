import { Dialog, Drawer, Icon, List, makeStyles, Menu, Typography } from '@material-ui/core';
import React from 'react';

const useTabStyles = makeStyles({
    paper: {
        borderTopLeftRadius: '20px',
        borderTopRightRadius: '20px',
    }
});

function StyledDrawer(props) {
    const classes = useTabStyles();
    if (props.alwaysOpen) {
        if(props.anchorEl) {
            return (
                <Menu
                    anchorEl={props.anchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    PaperProps={{
                        style: {padding: '0', margin: '0 0 0 -4px', borderRadius: '8px'}
                    }}
                    
                    open={props.open}
                    onClose={props.onClose}
                >
                    {props.children}
                </Menu>
            )
        }

        return (
            <Dialog PaperProps={{
                style: {
                    borderRadius: '16px',
                    padding: '24px',
                    ...props.style,
                }
            }} style={{borderRadius: '16px'}} {...props}>
                {props.title ? <div style={{display: 'flex', paddingBottom: '24px', alignItems: 'flex-end'}}>
                    <Typography style={{fontWeight: 'bold', marginRight: 'auto'}} variant='body1'>{props.title}</Typography>
                    <Icon style={{cursor: 'pointer'}} onClick={props.onClose}>close</Icon>
                </div> : null}
                {props.children}
            </Dialog>
        )
    }
    return (
        <Drawer
            classes={{paper: classes.paper}}
            {...props}
        >
            <div style={{
                padding: '24px 20px 20px',
                ...props.style,
            }}>
                {props.title ? <div style={{display: 'flex', paddingBottom: '24px', alignItems: 'flex-end'}}>
                    <Typography style={{fontWeight: 'bold', marginRight: 'auto'}} variant='body1'>{props.title}</Typography>
                    <Icon style={{cursor: 'pointer'}} onClick={props.onClose}>close</Icon>
                </div> : null}
                {props.anchorEl ? (
                    <List style={{padding: '0', margin: '-8px -20px -20px'}}>{props.children}</List>
                ) : props.children}
            </div>
            
        </Drawer>
    )
}

export default StyledDrawer;