/* eslint-disable */
import React from 'react';
// import Theme from '../Components/theme';
import WsHeader from '../../Components/wsheader';
import WsFooter from '../../Components/wsfooter';
import MainCTA from '../../Components/MainCTA';
import FileUploadButton from '../../Components/fileuploadbutton';
import { LinearProgress, Button, Icon, Typography, TextField, AppBar, Tabs, Tab, Checkbox, Card, Snackbar, CardContent, IconButton, CardActionArea, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core';
import { Editor as TinyEditor } from '@tinymce/tinymce-react';
const Servercalls = require('../../Utils/Servercalls');
// const utils = require('../utils');
export default class WsQuizContent extends React.Component {

    constructor(props){
        super(props);
        const content = this.props.context;
        const contentData = content.fileUrl;
        this.state = {
            manualGrading: contentData.manualGrading,
            welcomeText: contentData.welcomeText || "",
            questions: contentData.questions || [],
            index: 0,
            responseIndex: 0,
            responses: [],
        }
    }

    componentDidMount(){
        this.reloadResponses();
    }

    saveQuestions() {
        this.setState({ saving: true });
        const self = this;
        const content = this.props.context;
        const quiz = content.fileUrl;
        quiz.questions = this.state.questions;
        quiz.welcomeText = this.state.welcomeText;
        Servercalls.pluginpremier_addContentToBundle('todo', content.bundleId, content.contentId, content.name, content.description, quiz, content.published, content.free, content.type, content.thumbnail, content.londDescription, content.tags, content.list ).then(response => {
            self.setState({ saving: false, saved: true});
            setTimeout(() => self.setState({ saved: false }), 3000);
        }).catch(e => {
            self.setState({ saving: false });
        })
    }

    reloadResponses(){
        const pluginId = 'todo';
        const bundleId = this.props.context.bundleId;
        const content = this.props.context;

        const self = this;
        self.setState({ loadingResponses: true });
        Servercalls.pluginpremier_getQuizResponses(pluginId, bundleId, content.contentId).then(response => {
            self.setState({ responses: response.data, loadingResponses: false, obtainedPoints: response.data[this.state.responseIndex].obtainedPoints, comment: response.data[this.state.responseIndex].comment });
        }).catch(e => {
            console.error(e);
            self.setState({ loadingResponses: false });
        })

    }

    onChange() {
        let questions = this.state.questions.filter(question => question.question.length>0);
        //this.setState({ questions });
    }

    addQuestion(type){
        const question = {
            question: '',
            type,
            points: 1,
        };
        if(type === 'mcq' || type === 'scq'){
            question.options = [{optionText: '', isCorrect: false}];
        }
        if(type === 'text'){
            question.correctAnswer = '';
        }        
        if(type === 'file'){
            question.fileSize = 25 //in MB
        }
        this.setState({ questions: this.state.questions.concat([question]), expanded: `question-${this.state.questions.length}`}, () => this.onChange());
    }

    addOption(questionIndex){
        const {questions} = this.state;
        questions[questionIndex].options.push({ optionText: '', isCorrect: false});
        this.setState({ questions }, () => this.onChange());

    }

    deleteOption(questionIndex, optionIndex){
        const {questions} = this.state;
        questions[questionIndex].options = questions[questionIndex].options.filter((option, index) => index !== optionIndex)
        this.setState({ questions }, () => this.onChange());
    }

    setQuestionText(index, text) {
        const {questions} = this.state;
        questions[index].question = text;
        this.setState({ questions }, () => this.onChange());
    }

    setQuestionPoints(index, points) {
        const {questions} = this.state;
        questions[index].points = parseInt(points);
        this.setState({ questions }, () => this.onChange());
    }

    setQuestionFileSize(index, size){
        const {questions} = this.state;
        questions[index].fileSize = parseInt(size);
        this.setState({ questions }, () => this.onChange());
    }

    setQuestionCorrectAnswer(index, answer) {
        const {questions} = this.state;
        questions[index].correctAnswer = answer;
        this.setState({ questions }, () => this.onChange());
    }

    setOptionIsCorrect(questionIndex, optionIndex, isCorrect) {
        const {questions} = this.state;
        if(questions[questionIndex].type === 'scq'){
            for(let i = 0 ; i < questions[questionIndex].options.length; i+=1){
                questions[questionIndex].options[i].isCorrect = false;
            }
        }
        questions[questionIndex].options[optionIndex].isCorrect = isCorrect;
        this.setState({ questions }, () => this.onChange());
    }



    setOptionText(questionIndex, optionIndex, text) {
        const {questions} = this.state;
        questions[questionIndex].options[optionIndex].optionText = text;
        this.setState({ questions }, () => this.onChange());
    }
    orderUp(index){
        if(index === 0 ) return;
        const {questions } = this.state;
        const temp = questions[index - 1];
        questions[index -1] = questions[index];
        questions[index ] = temp;
        this.setState({ questions }, () => this.onChange());
    }

    orderDown(index) {
        if(index === this.state.questions.length - 1) return;
        const {questions } = this.state;
        const temp = questions[index + 1];
        questions[index +1] = questions[index];
        questions[index ] = temp;
        this.setState({ questions }, () => this.onChange());
    }

    delete(index) {
        let {questions } = this.state;
        questions = questions.filter((q,i) => i!==index);
        this.setState({ questions }, () => this.onChange());
    }

    getQuestion(question, index) {
        let base = (<div></div>);
        const title = {
            mcq: 'Multiple choice question',
            scq: 'Single choice question',
            text: 'Text based question',
            file: 'File submission question'
        }
        if(question.type ==='mcq' || question.type === 'scq'){
            base = (
                <div>
                    <br />
                <table>
                    <tr><td></td><td><Typography variant="body2">Is Correct</Typography></td></tr>
                    {question.options.map((option, optionIndex) => {
                        return (
                            <tr>
                                <td style={{ width:'50%'}}>
                                    <TextField
                                        value={question.options[optionIndex].optionText}                                    
                                        label="Option"
                                        onChange={(event) => this.setOptionText(index, optionIndex, event.target.value)}
                                    />
                                </td>
                                <td style={{ width:'50%'}}>
                                    <Checkbox
                                        checked={option.isCorrect}
                                        onChange={(e) => this.setOptionIsCorrect(index, optionIndex, e.target.checked)}
                                        value={option.isCorrect}
                                        color="primary"
                                        inputProps={{
                                        'aria-label': 'secondary checkbox',
                                        }}
                                    />
                                </td>
                                <td><IconButton onClick={() => this.deleteOption(index, optionIndex)} color="secondary"><Icon>clear</Icon></IconButton></td>
                            </tr>
                        )
                    })}
                </table>
                <Button onClick={() => this.addOption(index)} color="secondary"><Icon>add</Icon> Add Option</Button>
                </div>
            )
        }

        if(question.type === 'text'){
            base = (
                <div>
                    <TextField
                        value={question.correctAnswer}
                        onChange={event => this.setQuestionCorrectAnswer(index, event.target.value)}
                        label="Correct answer"
                    />
                </div>
            )
        }

        if(question.type === 'file'){
            base = (
                <div>
                    <TextField
                        value={question.fileSize}
                        type="number"
                        onChange={event => this.setQuestionFileSize(index, event.target.value)}
                        label="Maximum upload file size in MB"
                    />
                </div>
            )
        }

        
        return (
            <ExpansionPanel expanded={this.state.expanded === `question-${index}`} onChange={() => this.setState({ expanded : `question-${index}`})}>
            <ExpansionPanelSummary
              expandIcon={<Icon>expand_more</Icon>}
              aria-controls="panel1bh-content"
              id={`question-${index}`}
            >
              <Typography style={{fontSize: 15, flexBasis: '33.33%', flexShrink: 0 }}>#{index}. {this.getTitle(question)}</Typography>
              <Typography style={{fontSize: 15 }}>{title[question.type]}</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <div>
                    <TinyEditor
                        initialValue={question.question || "<p>Start typing your question here ...</p>"}
                        init={{
                        images_upload_handler : async (blobInfo, resolve, reject) => {
                            let blob = blobInfo.blob();
                            let f = new File([blob],blobInfo.filename());
                            const response = await Servercalls.uploadToS3(f);
                            resolve(response);
                        },
                        plugins: "image imagetools",
                        imagetools_cors_hosts: ['build.socionity.com', 'cdn.socionity.com'],
                        width: 'calc(100% - 122px)',
                        height: '350px',
                        toolbar: "undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image",
                        imagetools_toolbar: "rotateleft rotateright | flipv fliph | editimage imageoptions"

                        }}
                        onChange={(e) => this.setQuestionText(index, e.target.getContent())}
                    />
                    <br />
                    <TextField
                        label="Points"
                        type="number"
                        value={question.points}
                        onChange={event => this.setQuestionPoints(index, event.target.value)}
                    />
                    
                    <br />
                    {base}
                    <br /><br />
                    <Button disabled={index===0} color="secondary" onClick={() => this.orderUp(index)}><Icon>arrow_upward</Icon></Button>
                    <Button disabled={index === this.state.questions.length - 1} color="secondary" onClick={() => this.orderDown(index)}><Icon>arrow_downward</Icon></Button>
                    <Button color="secondary" onClick={() => this.delete(index)}><Icon>delete_forever</Icon></Button>
                </div>
            </ExpansionPanelDetails>
                
          </ExpansionPanel>
    
        )
    }


    getTitle(question) {
        const temp = document.createElement('div');
        temp.innerHTML = question.question;
        const str = temp.innerText;
        return `${str.substr(0,25)}${str.length > 25?"..." : ""}`
    }


    nextOrPrev(movement){
        let proceed = true;
        if(this.state.unsavedChanges)
            proceed = window.confirm("You have not saved the comments for this quiz response. Continue?");
        if(!proceed) return;
        let responseIndex = this.state.responseIndex + movement;
        if(responseIndex < 0 || responseIndex >= this.state.responses.length) return;
        this.setState({ responseIndex, unsavedChanges: false, obtainedPoints: this.state.responses[responseIndex].obtainedPoints, comment: this.state.responses[responseIndex].comment });
    }

    saveManual(){
        const self = this;
        self.setState({ savingManual : true });
        Servercalls.pluginpremier_putManualEvaluation(this.props.pluginId, this.props.bundleId, this.props.context.contentId, this.state.responses[this.state.responseIndex].responseId, this.state.obtainedPoints, this.state.comment).then(() => {
            self.setState({ savingManual: false, unsavedChanges: false });
            self.reloadResponses();
        }).catch(e => {
            console.error(e);
            alert("Unable to save. Please try again");
            self.setState({ savingManual: false });
        })
    }

    render(){
        if(this.state.saved)
        return (
            <center style={{ margin: 64}}>
                <Icon color="secondary" style={{fontSize: 120}}>check</Icon>
                <br /><br />
                <Typography variant="body1">Questions have been saved. Redirecting you shortly ...</Typography>
            </center>
        )

        return (
            <div style={{ marginTop: 56, marginBottom: 76}}>
                <WsHeader back={this.props.back} title="Edit Quiz" />
                <AppBar position="static" color="default">
                    <Tabs
                    value={this.state.index}
                    onChange={(event, index) => this.setState({ index })}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                    >
                        <Tab label="Edit Quiz" id={"edit"}/>
                        <Tab label="Responses" id={"responses"}/>
                    </Tabs>
              </AppBar>
            {this.state.index === 0?(
                <div>
                <div style={{ marginTop: 8, marginBottom: 8, width: 'calc(100% - 48px)', padding: 16 }}>
                    
                    <TextField
                        onChange={ event => this.setState({ welcomeText: event.target.value }, () => this.onChange())}
                        label="Welcome message"
                        value={this.state.welcomeText}
                        helperText="This message will be displayed before your first question"
                    />
                    

                    
                </div>
                {
                    this.state.questions.map((question, index) => this.getQuestion(question, index))
                }
                <div style={{ marginTop: 24}}>
                    <Typography variant="h6">Add question</Typography>
                    <br />
                    <br />
                    <Button color="secondary" onClick={() => this.addQuestion('scq')}><Icon>radio_button_checked</Icon> Single choice </Button>
                    <Button color="secondary" onClick={() => this.addQuestion('text')}><Icon>title</Icon> Short text</Button>
                    <Button color="secondary" onClick={() => this.addQuestion('mcq')}><Icon>check_box</Icon> Multiple choice</Button>
                    <Button color="secondary" onClick={() => this.addQuestion('file')}><Icon>check_box</Icon> File upload</Button>

                </div>


                <Snackbar
                    anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                    }}
                    open={this.state.message}
                    autoHideDuration={6000}
                    onClose={() => this.setState({ message: null })}
                    ContentProps={{
                    'aria-describedby': 'message-id',
                    }}
                    message={<span id="message-id">{this.state.message}</span>}
                />

                </div>
            ): (
                this.state.responses.length > 0?
                <div>
                    {this.state.loadingResponses?<LinearProgress fullWidth />:null}
                    <table style={{width: '100%'}}>
                        <tr>
                            <td style={{width: '10%'}}><center><IconButton onClick={() => this.nextOrPrev(-1)}><Icon>chevron_left</Icon></IconButton></center></td>
                            <td style={{width: '80%'}}><center><Typography variant="h6">#{this.state.responseIndex + 1} {this.state.responses[this.state.responseIndex].email}</Typography></center></td>
                            <td style={{width: '10%'}}><center><IconButton onClick={() => this.nextOrPrev(1)}><Icon>chevron_right</Icon></IconButton></center></td></tr>                        
                    </table>
                    <div>
                        {this.state.responses[this.state.responseIndex].reportCard.map(r => (
                            <Card style={{ marginTop: 8 }}>
                            <CardContent>
                                <Typography variant="body1"><div dangerouslySetInnerHTML={{ __html: r.question }}/></Typography>
                                <br />
                                <Typography variant="body2">Correct answer: {r.adminAnswer}</Typography>
                                <br />
                                <Typography variant="h6">User's answer: {r.response}</Typography>
                                <br />
                                <Typography variant="h4">{r.obtainedPoints} out of {r.maxPoints}</Typography>
                            </CardContent>
                            </Card>
                        ))}
                        <Card style={{marginTop: 8}}>
                            <CardContent>
                                <TextField
                                    type="number"
                                    value={this.state.obtainedPoints}
                                    label="Total points"
                                    onChange={(event) => this.setState({ unsavedChanges: true, obtainedPoints: event.target.value })}
                                />
                                <br />
                                <br />
                                <TextField
                                    value={this.state.comment}
                                    fullWidth
                                    label="Instructor comment"
                                    onChange={(event) => this.setState({ unsavedChanges: true, comment: event.target.value })}
                                />
                            </CardContent>
                            <CardActionArea>
                                <Button disabled={!this.state.unsavedChanges || this.state.savingManual} onClick={() => this.saveManual()}>Save evaluation</Button>
                            </CardActionArea>
                        </Card>
                        <br /><br />
                        <table style={{width: '100%'}}>
                        <tr>
                            <td style={{width: '10%'}}><center><IconButton onClick={() => this.nextOrPrev(-1)}><Icon>chevron_left</Icon></IconButton></center></td>
                            <td style={{width: '80%'}}><center><Typography variant="h6">#{this.state.responseIndex + 1} {this.state.responses[this.state.responseIndex].email}</Typography></center></td>
                            <td style={{width: '10%'}}><center><IconButton onClick={() => this.nextOrPrev(1)}><Icon>chevron_right</Icon></IconButton></center></td></tr>                        
                        </table>
                        <br /><br /><br /><br />
                    </div>
                </div>:
                <div>
                    <Typography variant="h4">{this.state.loadingResponses?"Loading ...":"No responses yet."}</Typography>
                </div>
            )}
                <WsFooter text=""/>
                <MainCTA settings={[]} help={[]} disabled={this.state.saving} onCTAClick={() => this.saveQuestions()} icon="save" text="Save Questions" />

            </div>
        )
    }
}