import React from 'react';
import Theme from './theme';
import Header from './header';
import { Icon, Typography, Button, DialogActions, Dialog, DialogContent, DialogTitle, Card, CardContent } from '@material-ui/core';
import { withTheme } from '@material-ui/styles';

class WsHeader extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            showVideo: false,
        }
    }
    render() {
        const title = this.props.title || "";
        return (
            <>
                {/* <Header {...this.props}/> */}
                <Card style={{position: window.innerWidth < 800 ? 'absolute' : 'fixed', width: '100%', zIndex: '100', top: '0', borderRadius: '0', ...this.props.style}}>
                    <CardContent style={{display: 'flex', padding: '0', alignItems: 'center'}}>
                        <Icon onClick={() => this.props.back()} style={{padding: window.innerWidth < 800 ? '0 20px' : '0 19px', fontSize: '15px', fontWeight: 'bold', cursor: 'pointer'}}>arrow_back</Icon>
                        <Typography variant="body1" style={{margin: '16px 0', marginRight: 'auto', fontWeight: 'bold'}}>
                            {title}
                        </Typography>
                        
                        {!this.state.saving && this.state.saved ? <>
                            <Icon style={{color: '#08a804', fontSize: '0.8em'}}>check_circle</Icon>
                            <Typography style={{color: '#08a804', margin: '0 16px 0 4px'}} variant='body2'>Saved</Typography>
                        </> : null}
                        
                    </CardContent>
                </Card>
            </>
        )
    }
}

export default withTheme(WsHeader);