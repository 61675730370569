import { FormControl, Icon, Input, MenuItem, Select } from '@material-ui/core';
import React from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import StyledInput from './StyledInput';
const { currencies } = require('../Utils/Constants');

export default class PriceInput extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            currency: this.props.currency || "INR",
            price: this.props.price || null,
            open: false,
        }
        this.selectRef = React.createRef();
    }

    componentDidUpdate(prevProps){
        if(prevProps.currency!==this.props.currency || prevProps.price!==this.props.price){
            this.setState({
                currency: this.props.currency || "INR",
                price: this.props.price || 0, 
            })
        }
    }

    calculateMenuProps() {
        let menuProp = {
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left"
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left"
            },
            getContentAnchorEl: null,
            PaperProps: {
                style: {
                    borderRadius: '0',
                    marginTop: '4px',
                    borderRadius: '5px',
                    border: 'solid 1px rgba(112, 112, 112, 0.12)',
                    boxShadow: 'none',
                }
            },
        };

        let menuListProp = {
            style: {
                padding: '0',
            }
        }

        const itemHeight = '37.63';
        const minElements = 3, totalElements = 4;

        if(!this.selectRef.current) {
            this.setState({menuProp});
            return;
        }

        const selectElementPosition = this.selectRef.current.getBoundingClientRect();
        const windowHeight = window.innerHeight;
        const spaceBelow = windowHeight - selectElementPosition.bottom - 8;

        console.log(spaceBelow, itemHeight*totalElements, itemHeight*minElements);

        if( spaceBelow < itemHeight*totalElements && spaceBelow > itemHeight*minElements) {
            menuListProp.style.maxHeight = itemHeight*minElements;
        }

        if( spaceBelow < itemHeight*totalElements) {
            if (spaceBelow > itemHeight*minElements) {
                menuListProp.style.maxHeight = itemHeight*minElements;
            } else {
                menuProp.anchorOrigin.vertical = 'top';
                menuProp.transformOrigin.vertical = 'bottom';
                menuProp.PaperProps.style.marginTop = '-4px';
            }
        }

        menuProp.MenuListProps = menuListProp;
        this.setState({menuProp});

    }

    render() {
        return (
            <div style={this.props.style}>
                <div style={{display: 'flex'}}>
                    <FormControl style={{background: '#fcfcfc', border: '1px solid #dbdbdb', borderTopLeftRadius: this.props.alwaysOpen ? '8px' : '4px', borderBottomLeftRadius: this.props.alwaysOpen ? '8px' : '4px', display: 'flex', justifyContent: 'center', height: 'min-content'}}>
                        <Select
                            autoWidth
                            value={this.state.currency}
                            onChange={event => {
                                this.setState({ currency: event.target.value});
                                this.props.onChange({ currency : event.target.value, price: this.state.price})
                            }}
                            // open={this.state.open}
                            ref={this.selectRef}
                            disabled={this.props.disabled}
                            onOpen={() => this.calculateMenuProps() || this.setState({open: true})}
                            onClose={() => this.setState({open: false})}
                            style={{fontSize: this.props.alwaysOpen ? 'inherit' : '0.875em', padding: '0', fontWeight: 'bold'}}
                            IconComponent={() => {
                                return (
                                    <Icon color='secondary' style={{
                                        pointerEvents: 'none',
                                        padding: '0',
                                        position: 'absolute',
                                        right: '5px',
                                        transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                                        transform: this.state.open ? 'rotate(180deg)' : 'rotate(0deg)',
                                    }}>expand_more</Icon>
                                )}
                            }

                            SelectDisplayProps={{
                                style: {
                                    overflow: 'visible',
                                    padding: this.props.alwaysOpen ? '12px 32px 12px 20px' : '8px 28px 8px 16px',
                                    width: '100%',
                                }
                           }}
                            disableUnderline
                            // MenuProps={{
                            //     style: {
                            //         // transform: 'translateY(-48px)',
                            //     }
                            // }}
                            
                            MenuProps={this.state.menuProp}
                        >
                            {currencies.map(option => (
                                <MenuItem style={{
                                    padding: this.props.alwaysOpen ? '12px 0px 12px 20px' : '8px 0px 8px 16px',
                                    width: this.selectRef && this.selectRef.current ? this.selectRef.current.getBoundingClientRect().width : '0',
                                    minHeight: '0',
                                    height: '2.688em',
                                    fontSize: this.props.alwaysOpen ? 'inherit' : '0.875em',
                                    fontWeight: 'bold',
                                }} key={option.value} value={option.value}>
                                {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <StyledInput
                        alwaysOpen={this.props.alwaysOpen}
                        disabled={this.props.disabled}
                        variant='outlined'
                        style={{minWidth: '66px'}}
                        inputStyle={{borderRadius: '0', borderTopRightRadius: this.props.alwaysOpen ? '8px' : '4px', borderBottomRightRadius: this.props.alwaysOpen ? '8px' : '4px', borderLeft: 'none'}}
                        placeholder={this.props.text}
                        value={this.state.price}
                        onChange={event => this.setState({ price: event.target.value},this.props.onChange({ currency : this.state.currency, price: event.target.value}))}
                        //todo : type="number"
                    />
                </div>
            </div>
        )
    }
}