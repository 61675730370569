/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import amplitude from 'react-amplitude-js';

import Card from '@material-ui/core/Card';

import AppNameWorkspace from '../Dashboards/AppNameAndLogo/ws-appname';
import AppTncWorkspace from '../Dashboards/TnC/ws-apptnc';
import AppThemeWorkspace from '../Dashboards/AppTheme/ws-apptheme';
import AppAnalyticsWorkspace from '../Dashboards/Analytics/ws-appanalytics';
import AppUsersWorkspace from '../Dashboards/Users/ws-appusers';
import AppUsersScheduleNotificationsWorkspace from '../Dashboards/Notifications/ws-appusersshcedulenotifications';
import AppUsersNotificationsListWorkspace from '../Dashboards/Notifications/ws-appusersnotificationslist';
import AppPublishWorkspace from './ws-apppublish';
import AppPaymentsWorkspace from '../Dashboards/Payments/ws-payments';
import AppPaymentReportWorkspace from '../Dashboards/Payments/ws-paymentreport';
import AppMarketingWorkspace from '../Dashboards/Marketing/ws-appmarketing';
import AppEngagementWorkspace from '../Dashboards/UserEngagement/ws-appengagement';
import LegacyBuilderWorkspace from '../Dashboards/ws-legacybuilder';
import TabsWorkspace from '../ZTabs/ws-tabs';
import AddTabWorkspace from '../ZTabs/ws-addtab';
import LandingWorkspace from '../TabLanding/ws-landing';
import PremiumAddBundleWorkspace from '../TabPremium/ws-premiumaddbundle';
import TabChangeBannerWorkspace from '../ZTabs/ws-tabchangebanner';
import TabChangeNameWorkspace from '../ZTabs/ws-tabchangename';
import BundleContentsWorkspace from '../TabPremium/Bundle/ws-bundlecontents';
import AddVideoContentWorkspace from '../TabPremium/Bundle/ws-addvideo';
import AddAudioContentWorkspace from '../TabPremium/Bundle/ws-addaudio';
import AddDownloadableContentWorkspace from '../TabPremium/Bundle/ws-adddownloadable';
import AddPdfContentWorkspace from '../TabPremium/Bundle/ws-addpdf';
import AddBlogContentWorkspace from '../TabPremium/Bundle/ws-addblog';
import AddEmbedContentWorkspace from '../TabPremium/Bundle/ws-addembed';
import AddQuizContentWorkspace from '../TabPremium/Bundle/ws-addquiz';
import AddLivestreamContentWorkspace from '../TabPremium/Bundle/ws-addlivestream';
import AddVimeoWorkspace from '../TabPremium/Bundle/ws-addvimeo';
import AddZoomWorkspace from '../TabPremium/Bundle/ws-addzoom';
import AddImageWorkspace from '../TabPremium/Bundle/ws-addimage';
import BundlePriceWorkspace from '../TabPremium/Bundle/ws-bundleprice';
import BundleNameWorkspace from '../TabPremium/Bundle/ws-bundlename';
import BundleBannerWorkspace from '../TabPremium/Bundle/ws-bundlebanner';
import BundleSalespageWorkspace from '../TabPremium/Bundle/ws-bundlesalespage';
import BundleAudienceWorkspace from '../TabPremium/Bundle/ws-bundleaudience';
import BundlePositionWorkspace from '../TabPremium/Bundle/ws-bundleposition';
import BundleFaqsWorkspace from '../TabPremium/Bundle/ws-bundlefaqs';
import ChatCreate from '../TabChat/ws-createchat';
import ChatEdit from '../TabChat/ws-editChat';
import AddLiveCourse from '../TabPremium/ws-livecourseadd';
import EditLiveCourse from '../TabPremium/ws-livecourseeidt';


import Header from '../Components/header';

const Constants = require('../Utils/Constants');
const utils = require('../Utils/utils');

class WorkspaceHandler extends React.Component {
  constructor(props) {
    super(props);
    const { app } = this.props;
    this.state = {
      stack: [{
        workspace: 'tabs', options: 'app', title: app.name, context: app,
      }],
      activeHeaderWorkspace: 'root',
    };
    window.changeWorkspace = this.changeWorkspace.bind(this);
  }

  async componentDidMount() {
    window.addEventListener('popstate', (e) => {
      this.popStack();
    });
    window.addEventListener('resize', this.getWindowDimensions);
    this.getWindowDimensions();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.getWindowDimensions);
  }

  static getSelectedWorkspace(ws) {
    if (ws === 'appusers' || ws === 'appusersschedulenotifications' || ws === 'appusersnotificationslist') {
      return 'appusers';
    }
    if (ws === 'apppayments' || ws === 'apppaymentreport') {
      return 'apppaymentreport';
    }
    return ws;
  }

  getWorkspace() {
    const { stack, appState, guidedTourEnded } = this.state;
    if (stack.length === 0) return null;

    const top = stack[stack.length - 1];
    const { workspace, context } = top;
    const { width } = this.state;
    let alwaysOpen = false;
    if (width > 800) alwaysOpen = true;

    const props = {
      context: { ...context, alwaysOpen },
      back: () => this.popStack() || window.analytics.track('Back Button Clicked', { page_name: workspace }),
      changeContext: (key, val) => this.changeContext(key, val),
      changeParentContext: (key, val) => this.changeParentContext(key, val),
      openOptions: () => this.showOptions(),
      changeWorkspace: (ws, title, mContext) => this.changeWorkspace(ws, title, mContext),
      pushStack: (title, mWorkspace, mContext) => this.pushStack(title, mWorkspace, mContext),
      showHelp: localStorage.getItem('guidedtour-app') || guidedTourEnded,
      appState,
    };
    let topWorkspace = null;
    if (workspace === 'appname') topWorkspace = <AppNameWorkspace {...props} />;
    else if (workspace === 'apptnc') topWorkspace = <AppTncWorkspace {...props} />;
    else if (workspace === 'apptheme') topWorkspace = <AppThemeWorkspace {...props} />;
    else if (workspace === 'appanalytics') topWorkspace = <AppAnalyticsWorkspace {...props} />;
    else if (workspace === 'appusers') topWorkspace = <AppUsersWorkspace {...props} />;
    else if (workspace === 'appusersschedulenotifications') topWorkspace = <AppUsersScheduleNotificationsWorkspace {...props} />;
    else if (workspace === 'appusersnotificationslist') topWorkspace = <AppUsersNotificationsListWorkspace {...props} />;
    else if (workspace === 'apppublish') topWorkspace = <AppPublishWorkspace {...props} />;
    else if (workspace === 'apppayments') topWorkspace = <AppPaymentsWorkspace {...props} />;
    else if (workspace === 'apppaymentreport') topWorkspace = <AppPaymentReportWorkspace {...props} />;
    else if (workspace === 'appmarketing') topWorkspace = <AppMarketingWorkspace {...props} />;
    else if (workspace === 'appengagement') topWorkspace = <AppEngagementWorkspace {...props} />;
    else if (workspace === 'legacybuilder') topWorkspace = <LegacyBuilderWorkspace {...props} />;

    else if (workspace === 'tabs') topWorkspace = <TabsWorkspace {...props} />;
    else if (workspace === 'addtab') topWorkspace = <AddTabWorkspace {...props} />;

    else if (workspace === 'landing') topWorkspace = <LandingWorkspace {...props} />;

    else if (workspace === 'tabchangebanner') topWorkspace = <TabChangeBannerWorkspace {...props} />;
    else if (workspace === 'tabchangename') topWorkspace = <TabChangeNameWorkspace {...props} />;

    else if (workspace === 'premiumaddbundle') topWorkspace = <PremiumAddBundleWorkspace {...props} />;
    else if (workspace === 'bundlecontents') topWorkspace = <BundleContentsWorkspace {...props} />;

    else if (workspace === 'premiumaddlive') return <AddLiveCourse {...props} />
    else if (workspace === 'premiumeditlive') return <EditLiveCourse {...props} />

    else if (workspace === 'addvideocontent') topWorkspace = <AddVideoContentWorkspace {...props} />;
    else if (workspace === 'addaudiocontent') topWorkspace = <AddAudioContentWorkspace {...props} />;
    else if (workspace === 'addpdfcontent') topWorkspace = <AddPdfContentWorkspace {...props} />;
    else if (workspace === 'adddownloadablecontent') topWorkspace = <AddDownloadableContentWorkspace {...props} />;
    else if (workspace === 'addblogcontent') topWorkspace = <AddBlogContentWorkspace {...props} />;
    else if (workspace === 'addembedcontent') topWorkspace = <AddEmbedContentWorkspace {...props} />;
    else if (workspace === 'addquizcontent') topWorkspace = <AddQuizContentWorkspace {...props} />;
    else if (workspace === 'addlivestreamcontent') topWorkspace = <AddLivestreamContentWorkspace {...props} />;
    else if (workspace === 'addvimeocontent') topWorkspace = <AddVimeoWorkspace {...props} />;
    else if (workspace === 'addzoomcontent') topWorkspace = <AddZoomWorkspace {...props} />;
    else if (workspace === 'addimagecontent') topWorkspace = <AddImageWorkspace {...props} />;

    else if (workspace === 'bundleprice') topWorkspace = <BundlePriceWorkspace {...props} />;
    else if (workspace === 'bundlename') topWorkspace = <BundleNameWorkspace {...props} />;
    else if (workspace === 'bundlebanner') topWorkspace = <BundleBannerWorkspace {...props} />;
    else if (workspace === 'bundlesalespage') topWorkspace = <BundleSalespageWorkspace {...props} />;
    else if (workspace === 'bundleaudience') topWorkspace = <BundleAudienceWorkspace {...props} />;
    else if (workspace === 'bundleposition') topWorkspace = <BundlePositionWorkspace {...props} />;
    else if (workspace === 'bundlefaqs') topWorkspace = <BundleFaqsWorkspace {...props} />;

    if (workspace === 'createchat') return <ChatCreate {...props} />
    if (workspace === 'editchat') return <ChatEdit {...props} />

    return topWorkspace;
  }

  getWindowDimensions() {
    this.setState({ width: window.innerWidth });
  }

  static getTheme() {
    return createMuiTheme(Constants.theme);
  }

  changeWorkspace(ws, title = '', context = {}) {
    window.scrollTo(0, 0);
    amplitude.getInstance().logEvent(`workspace-${ws}`);
    this.pushStack(title, ws, context);
    this.setState({ activeHeaderWorkspace: WorkspaceHandler.getSelectedWorkspace(ws) });
    utils.globalGetLogin();
  }

  changeContext(key, value) {
    return new Promise((r) => {
      const { stack } = this.state;
      const top = stack[stack.length - 1];
      top.context = { ...top.context, [key]: value };
      stack[stack.length - 1] = top;
      this.setState({ stack }, () => r());
    });
  }

  changeParentContext(key, value) {
    const { stack } = this.state;
    if (stack.length < 2) return;
    const top = stack[stack.length - 2];
    top.context = { ...top.context, [key]: value };
    stack[stack.length - 2] = top;
    this.setState({ stack });
  }

  pushStack(title, workspace, context) {
    window.scrollTo(0, 0);
    amplitude.getInstance().logEvent(`workspace-${workspace}`);
    const { stack } = this.state;
    stack.push({ title, workspace, context });
    window.history.pushState(workspace, title, `/${workspace}`);
    this.setState({ stack, activeHeaderWorkspace: WorkspaceHandler.getSelectedWorkspace(workspace) });
  }

  popStack() {
    window.scrollTo(0, 0);
    const { stack } = this.state;
    stack.pop();
    const top = stack[stack.length - 1];
    window.history.replaceState(top.workspace, top.name, `/${top.workspace}`);
    amplitude.getInstance().logEvent(`workspace-${top.workspace}`);
    this.setState({ activeHeaderWorkspace: WorkspaceHandler.getSelectedWorkspace(top.workspace) });
  }

  render() {
    const { width, activeHeaderWorkspace, stack } = this.state;

    let alwaysOpen = false;
    if (width > 800) alwaysOpen = true;

    const top = stack[stack.length - 1];
    const { workspace } = top;

    let topSpacing = 64;
    if (workspace === 'premiumaddlive') {
      topSpacing = 108;
    }

    const theme = WorkspaceHandler.getTheme();
    return (
      <MuiThemeProvider theme={theme}>
        <div style={{
          height: '100%', width: '100%', position: 'fixed', top: 0, left: 0, backgroundColor: '#1f1936',
        }}
        >
          <Card
            square
            elevation={8}
            style={{
              margin: alwaysOpen ? '0 0 0 auto' : 'auto',
              height: alwaysOpen ? '100%' : 'calc(100% - 64px)',
              width: alwaysOpen ? 'calc(100% - max(19.06vw, 244px))' : 'calc(100% - 16px)',
              top: alwaysOpen ? '0' : topSpacing,
              position: 'relative',
              padding: 0,
              background: '#f2f2f2',
              borderTopLeftRadius: alwaysOpen ? '0' : 12,
              borderTopRightRadius: alwaysOpen ? '0' : 12,
            }}
          >
            <div style={{
              width: '100%', margin: 'auto', position: 'absolute', height: '100%', overflow: 'auto', overflowX: 'hidden',
            }}
            >
              {this.getWorkspace()}
            </div>
          </Card>
        </div>

        <Header
          activeHeaderWorkspace={activeHeaderWorkspace}
          alwaysOpen={alwaysOpen}
          changeWorkspace={(ws, title, context) => this.changeWorkspace(ws, title, context)}
        />
      </MuiThemeProvider>
    );
  }
}

export default WorkspaceHandler;
