import React from 'react';
import Theme from '../../Components/theme';
import WsHeader from '../../Components/wsheader';
import WsFooter from '../../Components/wsfooter';
import FileUploadButton from '../../Components/fileuploadbutton';
import { Typography, TextField, Checkbox, FormControlLabel, Button, Icon, Card, CardContent } from '@material-ui/core';
import MainCTA from '../../Components/MainCTA';
import RoundedButton from '../../Components/roundedbutton';
import Illustration from '../../Components/illustration';
import { currencies } from '../../Utils/Constants';

const Servercalls = require('../../Utils/Servercalls');

export default class WsBundleName extends React.Component {

    constructor(props){
        super(props);
        const bundle = this.props.context;
        this.state = {
            imageUrl: bundle.imageUrl,
            thumbnailUrl : bundle.thumbnailUrl,
            bannerSamples: [],
        }
    }

    componentDidMount() {
        const bundle = this.props.context;
        const self = this;
        Servercalls.pluginpremier_getSampleBanners(bundle.pluginId, bundle.itemId).then(response => {
            self.setState({ bannerSamples: response.data });
        })
    }

    async save() {
        const self = this;
        const bundle = this.props.context;
        self.setState({ saving: true });
        try{
            await Servercalls.pluginpremier_updateBundle(bundle.pluginId, bundle.itemId, "imageUrl", self.state.imageUrl);
            if(this.state.thumbnailUrl)
                await Servercalls.pluginpremier_updateBundle(bundle.pluginId, bundle.itemId, "thumbnailUrl", self.state.thumbnailUrl);
            self.setState({ saving: false, saved: true });
            setTimeout(() => {
                self.props.back();
                self.setState({ saved: false });
                
            }, 3000);
        }
        catch(e){
            self.setState({ saving: false });
            alert(e);
        }
    }

    render() {
        if(this.state.saved) return (
            <center style={{ margin: 64}}>
                <Icon style={{ fontSize: 120}} color="secondary">check</Icon>
                <br /><br />
                <Typography variant="body2">Banner has been saved. You'll be redirected shortly</Typography>
            </center>
        )
        return (
            <Theme>
                <WsHeader back={this.props.back} title="Collection Banner" description='Display details are vital information for a user to buy a collection. It is likely they make their purchasing decision with these three pieces of information alone. ' helpText="How to make use of these?" helpVideo="a" />
                <Card style={{ margin: 12}}>
                    <CardContent>
                        <Typography variant="h6" style={{ marginTop: 12}}>Choose A Collection Banner</Typography>
                        <Typography variant="body2" style={{ marginBottom: 12 }}>This is the first thing that your customers see inside your collection. Make your offering clear in 5 words or less.  </Typography>
                    <div style={{ width: '100%', height: 0, paddingTop: '56.25%', position: 'relative', overflow: 'hidden'}}>
                    {
                        (() => {
                            if(!this.state.imageUrl || this.state.imageUrl.toLowerCase().indexOf("gravatar") > 0)
                                return <Illustration style={{ width: '100%', position: 'absolute', top: 0}} image="nobannerimage" />
                            else if(this.state.imageUrl.endsWith(".mp4") || this.state.imageUrl.endsWith(".webm")){
                                return <video controls preload src={ this.state.imageUrl } style={{ width: '100%', position: 'absolute', top: 0}} />
                            }
                            else{ 
                                return <img src={ this.state.imageUrl } style={{ width: '100%', position: 'absolute', top: 0}} />
                            }
                        })()
                    }
                    </div>
                    <FileUploadButton
                        style={{ marginTop: 32 }}
                        extensions={['png','jpg','jpeg','webp', 'mp4', 'webm', 'mov', 'avi']}
                        maxSizeMBs={25}
                        onFail={e => alert(e)}
                        onUpload={(url) => this.setState({ imageUrl: url })}
                        icon="add_a_photo"
                        text="Upload/Design Banner"
                        color="primary"
                        variant="contained"
                    />
                    <Typography variant="body2">Dimensions 1920x1080 pixels (video of 7 seconds or less, or an image)</Typography>

                    <Typography variant="h6" style={{ marginTop: 12}}>Choose A Collection Thumbnail</Typography>
                    <Typography variant="body2" style={{ marginBottom: 12 }}>A vertical thumbnail, optimized for smaller screens</Typography>
                    {
                        this.state.thumbnailUrl?<img src={this.state.thumbnailUrl} style={{ height: 200, width: 150, borderRadius: 8}}/> : null
                    }
                    <FileUploadButton
                        style={{ marginTop: 32 }}
                        extensions={['png','jpg','jpeg']}
                        maxSizeMBs={2}
                        onFail={e => alert(e)}
                        onUpload={(url) => this.setState({ thumbnailUrl: url })}
                        icon="add_a_photo"
                        text="Upload/Design Thumbnail"
                        color="primary"
                        variant="contained"
                    /> 
                    <Typography variant="body2">Dimensions : 300x400 pixels (vertical) </Typography>

                    {/*!this.state.showTemplates?<RoundedButton onClick={() => this.setState({ showTemplates: true })} variant="outlined" color="secondary" style={{marginTop: 12}}>Use a ready made template</RoundedButton>
                    : <div style={{ marginTop: 12,  height: 190, width: '100%', overflowX: 'scroll', overflowY: 'hidden', whiteSpace: 'noWrap'}}>
                            {this.state.bannerSamples.map(url => (
                                    <img src={url} onClick={() => this.setState({ imageUrl: url })} style={{ height: 172, margin: 4, display: 'inline=block'}} />
                            ))}

                            </div>*/}
                    <br /><br />



                    {/*<Typography className="bundle_banner_canva" style={{ marginTop: 8}} variant="body2">Use well designed Collection Display Image to clearly convey the reason why the user should buy your collection. If you are not a designer, you can use <a href="https://canva.com" target="_blank"> Canva </a> to design your image </Typography>*/}
                    </CardContent>
                                     
                </Card>
                <WsFooter text="The collection name and banner image helps gives an overview. This is the first step in convincing the user to buy." />
                <MainCTA 
                    className="bundle_banner_save" 
                    disabled={this.state.saving} 
                    onCTAClick={() => this.save()} 
                    settings={[]}
                    help={[
                        {title: "How to create my banner image?", text:"You must describe in 40 characters a compelling reason why someone should buy this collection. Be sure to include your photo to add authenticity to the banner. The dimensions of the banner should be 1920x1200"},
                        {title: "How to create my banner video?", text: "You can use your phone camera to create a simple video using the back camera. In 15 seconds or less tell the biggest value the user can expect in this collection. Talk about how will the buyers' life be significantly better after buying this collection. Keep it short, keep it crisp, no need for fancy animation and music. But, recording with a quiet room, uncluttered background and clear voice helps."}
                    ]}
                    icon="save" 
                    text="Save" 
                />
            </Theme>
        )
    }
}