import { Button, Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles, withTheme } from '@material-ui/styles';
import React from 'react';

const useStyles = makeStyles(theme => ({
    root: {
        position: 'fixed',
        bottom: theme.spacing(1.5),
        width: `calc(100% - ${theme.spacing(5)}px)`,
        left: theme.spacing(2.5),
        zIndex: 35,
    },
    rootWeb: {
        position: 'fixed',
        bottom: theme.spacing(1.5),
        width: `calc(100% - ${theme.spacing(33.5)}px)`,
        left: theme.spacing(32),
    },
    button: {
        height: theme.spacing(7),
        borderRadius: theme.spacing(3.5),
        textTransform: 'none',
        fontSize: theme.spacing(2),
        fontWeight: 'bold',
        '&:first-child': {
            display: 'flex',
            flexDirection: 'column',
        },
    },
    subtitle: {
        opacity: 0.7,
        marginTop: theme.spacing(-0.375),
    }
}))

const LargeCTA = (props) => {
    const classes = useStyles();
    const mobileView = useMediaQuery('(max-width:800px)');
    return (
        <div className={mobileView ? classes.root : classes.rootWeb}>
            <Button
                onClick={() => props.onClick()}
                variant='contained'
                color='secondary'
                className={classes.button}
                fullWidth
            >
                <span style={{display: 'flex', flexDirection: 'column'}}>
                    {props.label || 'Next'}
                    {props.subtitle ? <Typography variant='caption' className={classes.subtitle}>{props.subtitle}</Typography> : null }
                </span>
            </Button>
        </div>
    )
}

export default withTheme(LargeCTA);