import { Typography } from '@material-ui/core';
import { makeStyles, withTheme } from '@material-ui/styles';
import React from 'react';
import StyledButton from './StyledButton';
import PlusIcon from '../../Assets/Icons/PlusSecondary.svg';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        padding: theme.spacing(2.5),
        alignItems: 'center',
    },
    title: {
        fontWeight: 'bold',
        flex: '1',
    }
}))

const ContainerHeader = (props) => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Typography className={classes.title} variant='body1'>{props.title}</Typography>
            { props.onClick ? 
                props.action ? <StyledButton onClick={props.onClick} text={props.action} color='secondary' variant={props.variant || 'outlined'} /> : 
                <StyledButton icon={<img src={PlusIcon} />} onClick={props.onClick} text='Create' color='secondary' variant={props.variant || 'outlined'} /> : null}
        </div>
    )
}

export default withTheme(ContainerHeader);