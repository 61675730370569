import React from 'react';
import Theme from './theme';
import { Icon, Typography, Button, DialogActions, Dialog, DialogContent, DialogTitle, Card, CardHeader, Avatar, CardContent, CardActions, createMuiTheme, CircularProgress, IconButton, Input } from '@material-ui/core';
import Joyride from 'react-joyride';
import { withTheme } from '@material-ui/styles';

import RoundedButton from './roundedbutton';
import Tabsettingsbutton from '../ZTabs/tabsettingsbutton';
import EditIcon from '../Assets/Icons/Edit.svg';
import StyledDrawer from './StyledDrawer';
import FileUploadButton from './fileuploadbutton';
import StyledInput from './StyledInput';
import MainCTA from './MainCTA';
import SuccessDialog from './SuccessDialog';
import WsAppTnc from '../Home/ws-apppublish';

const Servercalls = require('../Utils/Servercalls');
const Constants = require('../Utils/Constants');

const getDomainFromName = (name) => {
    let domain = name.toLowerCase().replace(/[^0-9a-z]/gmi,"");
    return (domain.replace(".nity.app","") + '.nity.app');
}

class AppPublishHeader extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            error: null,
            scrollListenerSet: false,
            collapsed: false,

            editHeaderDialogOpen: false,
        };

        this.AppPublishHeaderRef = React.createRef();
    }

    componentDidMount(){
        const self = this;
        Servercalls.getApp().then((response) => {
            if(response.data){
                self.setState({ loading: false, logo: response.data.logo, name: response.data.name, data: response.data, newName: response.data.name, newLogo: response.data.logo });
            }
            else {
                self.setState({ loading: false, error: 'Error fetching app data' });
            }            
        }).catch(e => {
            self.setState({ loading: false, error: e });
        })

        if (this.props.scrollContainer && !this.props.context.alwaysOpen)
            this.setCollapseOnScrollListener();
    }

    componentDidUpdate(prevProps) {
        // scroll container from prev screen is not ready when this component is mounted
        if (this.props.scrollContainer && !this.state.scrollListenerSet && !this.props.context.alwaysOpen) {
            this.setCollapseOnScrollListener();
        }
    }

    setCollapseOnScrollListener() {
        const element = this.AppPublishHeaderRef.current;
        const offset = 96;
        if (element && element.offsetParent) {
            
            this.setState({scrollListenerSet: true});
            element.offsetParent.onscroll = () => {
                let inView = true;
                
                const rect = element.getBoundingClientRect();
                if (rect.bottom - offset <= 0) {
                    inView = false;
                }

                if (inView && this.state.collapsed) {
                    this.setState({collapsed: false})
                }
                else if (!inView && !this.state.collapsed) {
                    this.setState({collapsed: true})
                }
            }
        }
    }

    async save(){
        const self = this;
        self.setState({ saving: true });

        
        let name = this.state.name;
        if(this.state.newName) name = this.state.newName;
        let logo = this.state.logo;
        if (this.state.newLogo) logo = this.state.newLogo;

        
        let domain = getDomainFromName(name);

        try{
            await Servercalls.updateApp(name, logo, domain, this.props.context.video || "NONE", false);
            // if(this.state.welcomeMessage)
            //     await Servercalls.updateAppDetails("welcomeMessage", this.state.welcomeMessage)
            // if(this.state.creatorImage)
            //     await Servercalls.updateAppDetails("creatorImage", this.state.creatorImage);
            // if(this.state.creatorSign)
            //     await Servercalls.updateAppDetails("creatorSign", this.state.creatorSign);
            // self.setState({ saving: false, saved: true });
            // self.props.changeParentContext('name',self.state.name);
            // self.props.changeParentContext('logo',self.state.logo);
            // self.props.changeParentContext('domain',domain);
            // setTimeout(() => {self.props.back(); self.setState({ saved: false})},3000);
            window.analytics.track('Save Logo Changes Clicked', {})
            this.setState({newName: name, newLogo: logo, editHeaderDialogOpen: false, name, logo});
        }
        catch(e){
            alert("This name is taken. Please try with another name."); 
            self.setState({saving: false});
        }
    }

    renderPublishButton() {
        return (
            <RoundedButton
                style={{marginRight: '8px'}}
                disabled={this.state.openingApp}
                className="openapp"
                color="secondary"
                variant="contained"
                onClick={() => this.setState({publishDialogOpen: true}) || window.analytics.track('Publish App Clicked', {'page_name': 'Homescreen'})}
            >
                Publish
            </RoundedButton>
        )
    }

    renderPreviewButton() {
        return (
            <RoundedButton 
                style={this.props.context.alwaysOpen ? {margin: '0 22px'} : this.state.collapsed ? {marginRight: '16px'} : {marginRight: '20px'}}
                disabled={this.state.openingApp}
                className="openapp"
                color="secondary"
                variant="outlined"
                onClick={() => { window.open(`https://${this.state.data.domain}`); window.analytics.track('Preview App Clicked', {'page_name': 'Homescreen'})} }
            >
                Preview
            </RoundedButton>
        )
    }

    render() {
        const theme = createMuiTheme(Constants.theme);
        const { tab } = this.props;
        // if (this.state.loading) {
        //     return (
        //         <Card style={{marginLeft: 8, marginRight: 8, marginTop: 8, marginBottom: 0}}>
        //             <CardContent><CircularProgress /></CardContent>
        //         </Card>
        //     )
        // }
        if (this.state.error) {
            return (
                <Card style={{marginLeft: 8, marginRight: 8, marginTop: 8, marginBottom: 0}}>
                    <CardContent>{this.state.error}</CardContent>
                </Card>
            )
        }
        return (<>

            {this.props.context.alwaysOpen ? (
                <Card style={{marginLeft: 16, marginRight: 16, marginTop: 16, marginBottom: 0, borderRadius: '16px', overflow: 'initial', zIndex: '1600'}}>
                    <CardContent style={{display: 'flex', flexDirection: 'row', 'alignItems': 'center', padding: '24px'}}>
                        <div style={{display: 'flex', marginRight: '20px', alignSelf: 'stretch', alignItems: 'center'}}>
                            <img style={{borderRadius: '25px', zIndex: '10', boxShadow: 'rgba(0, 0, 0, 0.32) 0px 0px 16px 0px', width: 'calc(min(14.11vh, 92px)', height: 'calc(min(14.11vh, 92px)', objectFit: 'cover'}} src={this.state.logo} />
                        </div>
                        <div style={{marginRight: 'auto'}}>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <Typography variant="h6" style={{marginBottom: '6px', fontWeight: 'bold', fontSize: '1.5em'}}>
                                    {this.state.name}
                                </Typography>
                                <Icon style={{marginLeft: '8px', marginBottom: '6px', cursor: 'pointer'}} onClick={() => this.setState({editHeaderDialogOpen: true})}><img src={EditIcon} style={{height: '100%'}} /></Icon>
                            </div>
                            {/* <Typography variant="body1" style={{color: '#333333'}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, labore et dolore magna aliqua.</Typography> */}
                        </div>
                        {this.renderPreviewButton()}
                        {this.renderPublishButton()}
                        
                        
                    </CardContent>
                </Card>
            ) : (
                <>
                    <Card ref={this.AppPublishHeaderRef} style={{marginLeft: 8, marginRight: 8, marginTop: 8, marginBottom: 0, borderRadius: '12px', overflow: 'initial'}}>
                        <CardContent style={{display: 'flex', flexDirection: 'column', 'alignItems': 'center', padding: '32px', paddingTop: '0'}}>
                            <img style={{margin: '36px 32px 24px',borderRadius: '25px', zIndex: '10', height: 'calc( min(30vw, 120px))', width: 'calc( min(30vw, 120px))', boxShadow: 'rgba(0, 0, 0, 0.32) 0px 0px 16px 0px' }} src={this.state.logo} />
                            <Typography variant="h6" style={{marginBottom: '24px', fontWeight: 'bold', fontSize: '1.5em'}}>{this.state.name}</Typography>
                            <div>
                                {this.renderPreviewButton()}
                                {this.renderPublishButton()}
                            </div>
                            <IconButton onClick={() => this.setState({editHeaderDialogOpen: true})} style={{padding: '12px', position: 'absolute', right: '8px', margin: '11px', cursor: 'pointer'}}><Icon><img src={EditIcon} style={{height: '100%'}} /></Icon></IconButton>
                        </CardContent>
                    </Card>
                    {this.state.collapsed ? (
                        <Card style={{borderRadius: '0', borderTopLeftRadius: '12px', borderTopRightRadius: '12px', overflow: 'initial', position: 'fixed', zIndex: '50', width: 'calc(100% - 16px)', maxWidth: '800px'}}>
                            <CardContent style={{display: 'flex', flexDirection: 'row', 'alignItems': 'center', padding: '12px 16px'}}>
                                <img style={{marginRight: 'auto', borderRadius: '50%', zIndex: '10', height: 'calc( min(13.61vw, 49px))', width: 'calc( min(13.61vw, 49px))', boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)' }} src={this.state.logo} />
                                {/* <Typography variant="h6" style={{marginBottom: '24px', fontWeight: 'bold', fontSize: '1.5em'}}>{this.state.name}</Typography> */}
                                
                                {this.renderPreviewButton()}
                                {this.renderPublishButton()}
                            
                            </CardContent>
                        </Card>
                    ) : null}
                </>
            )}

            <WsAppTnc open={this.state.publishDialogOpen} onClose={() => this.setState({publishDialogOpen: false})} renderAsDialog/>
            
            <StyledDrawer alwaysOpen={this.props.context.alwaysOpen} open={this.state.editHeaderDialogOpen} anchor='bottom' style={{paddingBottom: '0'}}>
                <div style={{display: 'flex', flexDirection: 'column', 'alignItems': 'center'}}>
                    <div style={{marginTop: '8px', marginBottom: '24px'}}>
                        <img style={{filter: 'blur(2px)', borderRadius: '25px', zIndex: '10', boxShadow: 'rgba(0, 0, 0, 0.32) 0px 0px 16px 0px', width: 'calc(max(14.11vh, 92px)', height: 'calc(max(14.11vh, 92px)', objectFit: 'cover', maxWidth: '138px', maxHeight: '138px'}} src={this.state.newLogo} />
                        <FileUploadButton
                            containerStyles={{position: 'relative', zIndex: '20', marginTop: '-105%'}}
                            icon="photo_camera"
                            color="secondary"
                            variant="text"
                            style={{border: '1px solid', borderRadius: '25px', background: 'rgba(255, 255, 255, 0.3)',padding: '0', minWidth: '0', height: 'max(14.11vh, 92px)', width: 'max(14.11vh, 92px)', maxWidth: '138px', maxHeight: '138px'}}
                            onFail={ e => alert(e)}
                            onUpload={url => this.setState({ newLogo : url})}
                            onEnter={() => window.analytics.track('Logo Image Change Clicked', {})}
                            extensions={['png', 'jpg','jpeg']}
                            maxSizeMBs={1}
                        />
                    </div>
                    
                    {/* <img style={{margin: '32px', marginBottom: '0', borderRadius: '10%', zIndex: '10', height: 'calc( min(22.7vw, 120px))', width: 'calc( min(22.7vw, 120px))', boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)' }} src={this.state.newLogo} /> */}
                    {/* <Icon style={{marginBottom: '24px', background: 'rgba(255, 255, 255, 0.5)', marginTop: '-0.5em', zIndex: '20', borderRadius: '50%'}} color='secondary'>add_circle_outline</Icon>
                     */}
                    
                    <StyledInput placeholder="Name your App" value={this.state.newName} onChange={e => this.setState({ newName: e.target.value})} style={{marginTop: '16px'}} inputStyle={{fontWeight: 'bold', fontSize: '1.5em', textAlign: 'center'}} />
                    {/* <Input placeholder="Name your App" inputProps={{ 'aria-label': 'new name', style: {marginBottom: '12px', padding: '0', fontWeight: 'bold', fontSize: '1.3em', textAlign: 'center'}}} fullWidth style={{marginTop: '16px'}} value={this.state.newName} onChange={e => this.setState({ newName: e.target.value})} /> */}
                    {/* <RoundedButton style={{marginTop: '25px'}} disabled={this.state.openingApp} className="openapp" color="secondary" variant="outlined" onClick={() => this.save()} >Save</RoundedButton> */}
                    <MainCTA onCTAClick={() => this.save()} disabled={this.state.openingApp || (this.state.newName === this.state.name && this.state.newLogo === this.state.logo)} square text='Save Changes' style={{position: 'relative', width: 'calc(100% + 48px)', marginTop: '32px'}}/>

                    <Icon style={{padding: '12px', position: 'absolute', right: '0px', margin: '11px', top: '0', cursor: 'pointer'}} onClick={() => this.setState({editHeaderDialogOpen: false, cursor: 'pointer'})}>close</Icon>
                </div>
            </StyledDrawer>

            {/* todo, create seprate workspace for croping? */}
            {/* {this.state.saveLogoDialogOpen ? <>
                <div style={{zIndex: '30000', backgroundColor: 'black', position: 'absolute', height: '100vw'}}>
                    <img src={this.state.newLogo} />
                    <Icon style={{padding: '12px', position: 'absolute', right: '0px', margin: '11px'}} onClick={() => this.setState({editHeaderDialogOpen: false, cursor: 'pointer', saveLogoDialogOpen: false})}>close</Icon>
                </div>
            
            </> : null} */}
            
            </>
        )
    }
}

export default withTheme(AppPublishHeader)