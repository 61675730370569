import React from 'react';
import Theme from '../../Components/theme';
import WsHeader from '../../Components/wsheader';
import WsFooter from '../../Components/wsfooter';
import { Typography, TextField, Checkbox, FormControlLabel, Button, Icon } from '@material-ui/core';

import { currencies } from '../../Utils/Constants';

const Servercalls = require('../../Utils/Servercalls');

export default class WsBundlePrice extends React.Component {

    constructor(props){
        super(props);
        const bundle = this.props.context;
        this.state = {
            weight: bundle.weight || 0
        }
    }

    save() {
        const self = this;
        const bundle = this.props.context;
        self.setState({ saving: true });
        Servercalls.pluginpremier_setBundleWeight( bundle.pluginId, bundle.itemId, this.state.weight ).then(response => {
            self.props.changeParentContext('weight', self.state.weight);
            self.setState({ saving: false, saved: true });
            setTimeout(() => {
                self.props.back();
                self.setState({ saved: false });
            }, 3000);
        }).catch(e =>{ alert(e); self.setState({ saving: false })});
    }

    render() {
        if(this.state.saved) return (
            <center style={{ margin: 64}}>
                <Icon style={{ fontSize: 120}} color="secondary">check</Icon>
                <br /><br />
                <Typography variant="body2">Position has been saved. You'll be redirected shortly</Typography>
            </center>
        )
        return (
            <Theme>
                <WsHeader back={this.props.back} title="Importance of collection" description='The highest importance collection appears on the top' helpText="What is importance?" helpVideo="a" />
                <div style={{ margin: 32 }}>
                <TextField
                    label="Importance"
                    value={this.state.weight}
                    onChange={event => this.setState({ weight: event.target.value})}
                    margin="normal"
                    type="number"
                    style={{ marginRight: 8 }}
                    helperText="Highest importance collection will appear on the top"
                    />                    
                    <Button style={{marginTop: 48 }} disabled={this.state.saving} onClick={() => this.save()} variant="contained" color="secondary">Save Pricing</Button>
                                     
                </div>
                <WsFooter text="Tip: Most people underprice. When in doubt, double the price." />
            </Theme>
        )
    }
}