import React from 'react';
import CommonAddContent from './ws-commonaddcontent';

export default class WsAddVideoContent extends React.Component {
    render() {
        return (
            <CommonAddContent
                title="Upload an Image"
                description="Will show up " 
                helpText="Know more about Image content"
                helpVideo="a"
                filetype="image"
                {...this.props}
            />
        )
    }
}