/* eslint-disable */
import React from 'react';
import { withTheme } from '@material-ui/styles';
import { Card, CardActionArea, Icon, Typography, CardActions, MenuItem, Badge, Menu } from '@material-ui/core';
import RoundedButton from '../../Components/roundedbutton';
import md5 from 'md5';

class ContentItem extends React.Component {

    constructor(props){
        super(props);
        this.state = {

        }
    }

    getSettingErrorCount() {
        const { content } = this.props;
        if(!content.thumbnail ||content.thumbnail.toLowerCase().indexOf("gravatar") > 0)
            return 1;
        return 0;
    }

    getOpenButton(){
        const {content} = this.props;
        if(content.type==='livestream'){
            return <RoundedButton onClick={() => this.props.openContent("contentlivestream")} style={{margin: 12}} variant="contained" color="primary"><Icon>videocam</Icon>&nbsp;Start Livestream</RoundedButton>
        }
        let text = "Edit Content"
        let workspace = "contentdownloadable";
        let count = 0 ;
        if(content.type === 'blog'){
            text = "Edit blog";
            workspace = "contentblog";
            if(content.fileUrl === "<p></p>"){
                count = 1;
            }
        }
        else if(content.type==='videolist'){
            text = "Update videos";
            workspace = "contentvideolist";
            if(!content.fileUrl && content.fileUrl.length === 0){
                count = 1
            }
        }
        else if(content.type === "quiz"){
            text = "edit questions & see responses";
            workspace = "contentquiz";
            if(!content.fileUrl || !content.fileUrl.questions || content.fileUrl.questions.length === 0)
                count = 1
        }    
        else if(content.type === "vimeo"){
            text = "edit vimeo video";
            workspace = "contentvimeo";
        }
        else if(content.type === "zoom"){
            text = "edit zoom webinar";
            workspace = "contentzoom";
        }
        else if(content.type === "image"){
            text = "replace image";
            workspace = "contentimage";
        }

        else if(content.type === "videostream"){
            text = "Edit Stream";
            workspace = "contentvideostream"
        }
        else if(content.type === "video" || content.fileUrl.toLowerCase().trim().endsWith(".mov") || content.fileUrl.toLowerCase().trim().endsWith(".mp4") || content.fileUrl.toLowerCase().trim().endsWith(".webm")){
            text = "Change video";
            workspace = "contentvideo";
        }
        else if(content.fileUrl.toLowerCase().endsWith(".mp3") || content.fileUrl.toLowerCase().endsWith(".ogg")){
            text = "Change audio";
            workspace = "contentaudio";
        }
        else if(content.fileUrl.toLowerCase().endsWith(".pdf")){
            text = "Change PDF"
            workspace = "contentpdf";
        }
        return <Badge color="primary" style={{margin: 12}} badgeContent={count} invisible={count===0}><RoundedButton onClick={() => this.props.openContent(workspace)} variant="outlined" color="primary">{text}</RoundedButton></Badge>
    }
    render() {
        const {content} = this.props;
        return(
            <Card square style={{margin: 12, opacity: content.published?1:0.5}}>
                <img onClick={ () => this.openContent('contentthumbnail', content)} src={content.thumbnail || `https://gravatar.com/avatar/${md5(content.contentId)}?d=identicon` } style={{ height: 100, width:100, objectFit:'cover', float: 'left'}}/>
                <div style={{ float: 'left', width: `calc(100% - 124px)`, margin: 12}}>
                    <Typography variant="body2" color="primary">{content.free ? "":"$"} {content.name.toUpperCase()} </Typography>
                    <Typography variant="body1">{content.description} ({content.type})</Typography>
                    <Typography variant="body1" style={{ marginTop: 24, color: "#777" }}>{content.free?"Can be opened without paying": "Paid content"}</Typography>
                </div>
                <div style={{ clear: 'both', width: '100%'}}>
                    {this.getOpenButton()}
                    <Badge color="secondary" style={{margin: 12}} badgeContent={this.getSettingErrorCount()} invisible={this.getSettingErrorCount() === 0}><RoundedButton color="secondary" variant="outlined" onClick={event => this.setState({ settingsEl: event.currentTarget})} ><Icon>settings</Icon>&nbsp;Settings</RoundedButton></Badge>
                </div>
                <Menu anchorEl={this.state.settingsEl} open={this.state.settingsEl} onClose={() => this.setState({ settingsEl: null })}>
                    <MenuItem button onClick={() => this.props.openContent("contentthumbnail")}>
                        <Icon>add_photo</Icon>
                        &nbsp;Edit Thumbnail
                        &nbsp;{!content.thumbnail || content.thumbnail.toLowerCase().indexOf("www.gravatar") > 0? <Icon color="primary">error</Icon>: null}
                    </MenuItem>
                    <MenuItem button onClick={() => this.props.openContent("contenttitle")}>
                        <Icon></Icon>
                        &nbsp;Edit Title & Description
                    </MenuItem>
                    <MenuItem button onClick={() => this.props.openContent("contentaccess") }>
                        <Icon></Icon>
                        &nbsp;{content.free?"Make this paid" : "Make this free"}
                    </MenuItem>
                    <MenuItem button onClick={() => this.props.openContent("contentpublish")}>
                        <Icon></Icon>
                        &nbsp;{!content.published?"Publish":"Unpublish"}
                    </MenuItem>

                    <MenuItem button onClick={() => {this.props.move("up"); this.setState({ settingsEl: null})}}>
                        <Icon></Icon>
                        &nbsp;Move up
                    </MenuItem>
                    <MenuItem button onClick={() => {this.props.move("down"); this.setState({ settingsEl: null})}}>
                        <Icon></Icon>
                        &nbsp;Move down
                    </MenuItem>


                </Menu>


            </Card>
        );
    }
}

export default withTheme(ContentItem)