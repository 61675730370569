import React from 'react';

import {
  CircularProgress, Card, Icon, Typography, Button, Tabs, Tab, CardContent, makeStyles,
} from '@material-ui/core';
import { withTheme } from '@material-ui/styles';
import RoundedButton from '../Components/roundedbutton';
import Illustration from '../Components/illustration';
import WsPremiumBundles from '../TabPremium/ws-premiumbundles';
import WsChat from '../TabChat/index';
import WsLanding from '../TabLanding/ws-landing';
import TabSettingsButton from './tabsettingsbutton';
import ChatCreate from '../TabChat/ws-createchat';

const Servercalls = require('../Utils/Servercalls');

const useTabStyles = makeStyles({
  textColorSecondary: {
    color: 'rgba(54, 54, 54, 0.56)',
  },
});

function StyledTabs(props) {
  const classes = useTabStyles();
  console.log(classes);
  return (
    <Tabs
      TabScrollButtonProps={{ classes: { disabled: classes.disabled, root: classes.root } }}
      TabIndicatorProps={{ style: { display: 'none' } }}
      value={props.value}
      onChange={props.onChange}

      style={{ marginRight: props.alwaysOpen ? '0' : 'auto' }}
      scrollButtons="off"
      variant="scrollable"

      textColor="secondary"
      tabsPropList={['value']}
    >
      {props.plugins.map((plugin, index) => (
        <Tab
          className={`tabs_${plugin.type}`}
          classes={{
            textColorSecondary: classes.textColorSecondary,
          }}
          style={{
            minWidth: 0, padding: '20px 34.5px', border: props.alwaysOpen ? 'solid 0.9px #eeeeee' : 'none', borderLeft: 'none',
          }}
          icon={(
            <TabSettingsButton
              disableContextMenu={index !== props.currentTab}
              icon={plugin.icon}
              iconStyle={{ fontSize: '1.83em' }}
              tab={props.tab}
              setCurrentTab={props.setCurrentTab}
              reloadTabs={props.reloadTabs}
              style={{ float: 'right' }}
              changeWorkspace={props.changeWorkspace}
            />
                      )}
        />
      ))}
    </Tabs>
  );
}

class WsTabs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabs: [],
      loading: true,
      banner: 'bundle_contents',
      currentTab: this.props.context.currentTab || 0,
      bottomTabsIsVisible: true,
    };
  }

  componentDidMount() {
    this.reload();
  }

  async loadBanner(plugins) {
    if (plugins.length === 0) {
      return this.setState({ banner: 'wizard' });
    }
    let premiumPlugin = null;
    for (let i = 0; i < plugins.length; i += 1) {
      if (plugins[i].type === 'premium') {
        if (!premiumPlugin) return; // Show banner only if there's only one premium tab
        premiumPlugin = plugins[i];
      }
    }
    if (!premiumPlugin) return this.setState({ banner: 'wizard' });

    const bundles = (await Servercalls.pluginpremier_getItems(premiumPlugin.pluginId)).data;
    if (bundles.length === 0) return this.setState({ banner: 'wizard' });
    if (bundles.length === 1) {
      return this.setState({ banner: 'bundle_contents', bannerPlugin: premiumPlugin, bannerBundle: bundles[0] });
    }
  }

  reload(callback) {
    const self = this;
    Servercalls.getPlugins().then((response) => {
      let currentTab = self.props.context.currentTab || 0;
      if (currentTab >= response.data.length) currentTab = 0;
      self.setState({ tabs: response.data.sort((a, b) => (b.weight || 0) - (a.weight || 0)), currentTab }, () => {

      });
      self.props.changeContext('tabs', response.data);
      self.setState({ loading: false });
      self.loadBanner(response.data);
    }).catch((e) => {
      self.setState({ loading: false });
    });
  }

  openTab(tab, from) {
    if (tab.type === 'premium') this.props.pushStack(tab.name, 'tabpremium', 'premiumbundles', 'premiumbundles', tab);
    if (tab.type === 'landing') this.props.pushStack(tab.name, 'tablanding', 'landing', 'landing', tab);
    if (tab.type === 'query') this.props.pushStack(tab.name, 'tabquery', 'queryrespond', 'queryrespond', tab);
    if (tab.type === 'survey') this.props.pushStack(tab.name, 'tabsurvey', 'surveyquestions', 'surveyquestions', tab);
    if (tab.type === 'user') this.props.pushStack(tab.name, 'tabuser', 'user', 'user', tab);
    if (tab.type === 'chat2') this.props.pushStack(tab.name, 'tabchat2', 'chat2', 'chat2', tab);
    if (tab.type === 'forum') this.props.pushStack(tab.name, 'forumboards', tab);
    if (tab.type === 'booking') this.props.pushStack(tab.name, 'booking', tab);
    if (tab.type === 'onlylivestream') this.props.pushStack(tab.name, 'onlylivestream', tab);
  }

  openContents() {
    const { bannerPlugin, bannerBundle } = this.state;
    this.openTab(bannerPlugin);
    setTimeout(() => this.props.pushStack(bannerBundle.name, 'bundle', 'bundlecontents', 'bundlecontents', bannerBundle), 500);
  }

  changeBanner() {
    this.props.changeWorkspace('tabchangebanner', 'Change Tab Banner', this.state.tabs[this.state.currentTab]);
  }

  moveToTop(tab) {
    const self = this;
    self.setState({ moving: true });
    Servercalls.movePluginToTop(tab.pluginId).then(() => { self.reload(); self.setState({ moving: false }); });
  }

  getTab() {
    let base = <div />;
    let tab = {};
    if (this.state.currentTab > -1) tab = this.state.tabs[this.state.currentTab];
    if (this.state.currentTab >= this.state.tabs.length) return null;

    tab.options = [];
    tab.reloadTabs = this.reload;
    if (tab.type === 'premium') base = <WsPremiumBundles setBottomTabsVisibility={(boolean) => this.setState({ bottomTabsIsVisible: boolean })} tab={tab} {...this.props} />;
    if (tab.type === 'landing') base = <WsLanding tab={tab} {...this.props} />;
    if (tab.type === 'chat2') base = <WsChat tab={tab} {...this.props} />;
    // if(tab.type === 'chatnew')
    //     base = <WsChat tab={tab} {...this.props} />

    return (
      <div style={{
        position: 'absolute', height: !this.props.context.alwaysOpen && this.state.bottomTabsIsVisible ? 'calc(100% - 48px)' : '100%', width: '100%', overflow: 'auto',
      }}
      >
        {base}
      </div>
    );
  }

  render() {
    const plugins = this.state.tabs;
    if (this.state.loading) {
      return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          <div style={{
            flex: '1', display: 'flex', justifyContent: 'center', alignItems: 'center',
          }}
          >
            <CircularProgress fullWidth color="primary" />
          </div>
        </div>
      );
    }
    let tab = { title: '' };
    if (plugins.length > 0 && this.state.currentTab > -1) tab = plugins[this.state.currentTab];
    if (plugins.length === 0) {
      return (
        <div style={{ height: 'calc(100% - 72px)', width: '100%' }}>
          <Card style={{ width: 'calc(100% - 24px)', margin: 12 }}>
            <CardContent>
              <Typography variant="h6">Add your first tab to get started</Typography>
              <Typography style={{ margin: 12 }} variant="body1">You haven't added any tabs. Your app is categorized into tabs. Each tab may have it's own functionality.</Typography>
              <RoundedButton onClick={() => this.props.changeWorkspace('addtab', 'addtab', this.props.context)} style={{ marginTop: 12, marginBottom: 12 }} variant="contained" color="primary">
                <Icon>add</Icon>
&nbsp;Add a Tab
              </RoundedButton>
              <Illustration image="addcontent" style={{ width: '100%' }} />
            </CardContent>
          </Card>

        </div>

      );
    }
    return (
      <div style={{ display: 'flex', flex: '1' }}>

        {this.getTab()}

        {this.state.bottomTabsIsVisible ? (
          <div style={{
            width: '100%', position: 'absolute', bottom: this.props.context.alwaysOpen ? '10px' : 0, display: 'flex', justifyContent: 'center',
          }}
          >
            <Card
              className="tabs"
              elevation={8}
              square
              style={{

                width: this.props.context.alwaysOpen ? 'fit-content' : '100%',
                borderRadius: this.props.context.alwaysOpen ? '14px' : '0',
                display: 'flex',
                zIndex: '1500',
              }}
            >
              <StyledTabs
                value={this.state.currentTab}
                onChange={(event, index) => {
                  this.setState({ currentTab: index });
                  this.props.changeContext('currentTab', index);
                  window.analytics.track('Tab Clicked', { tab_name: this.state.tabs[index].type });
                }}
                alwaysOpen={this.props.context.alwaysOpen}
                plugins={plugins}
                tab={tab}
                currentTab={this.state.currentTab}
                setCurrentTab={(index) => this.setState({ currentTab: index })}
                reloadTabs={this.reload.bind(this)}
                changeWorkspace={this.props.changeWorkspace.bind(this)}
              />
              <Button
                style={{
                  padding: '6px 0',
                  backgroundColor: '#e4ddff',
                  borderRadius: '0',
                  borderTopLeftRadius: this.props.context.alwaysOpen ? '0' : '15px',
                  borderTop: this.props.context.alwaysOpen ? 'none' : 'solid 1px #d2caf5',
                  borderLeft: this.props.context.alwaysOpen ? 'none' : 'solid 1px #d2caf5',
                }}
                onClick={() => this.props.changeWorkspace('addtab', 'addtab', this.props.context) || window.analytics.track('Tab Clicked', { tab_name: 'add_tab' })}
                color="secondary"
              >
                <Icon>add</Icon>
              </Button>
            </Card>
          </div>
        )
          : null}

      </div>
    );
  }
}

export default withTheme(WsTabs);
