import { ButtonBase, Card, IconButton, InputBase, Menu, MenuItem, Popover, Popper, Switch, Typography } from '@material-ui/core';
import { makeStyles, withStyles, withTheme } from '@material-ui/styles';
import React, { createRef } from 'react';

const useStyles = makeStyles(theme => ({
    root: {
        margin: theme.spacing(1),
        padding: theme.spacing(2, 2, 2.625),
        borderRadius: theme.spacing(1.25),
        boxShadow: 'none',
    },
    title: {
        fontWeight: 'bold',
        fontSize: theme.spacing(2.25),
        marginBottom: theme.spacing(1.625),
    },
    subtitle: {
        textAlign: 'left',
        color: theme.palette.secondary.main,
        marginBottom: theme.spacing(1.5),
    },
}))

const AntSwitch = withStyles((theme) => ({
    root: {
      width: 43,
      height: 24,
      padding: 0,
      display: 'flex',
    },
    switchBase: {
      padding: 2,
      color: theme.palette.common.white,
      '&$checked': {
        transform: 'translateX(20px)',
        color: theme.palette.common.white,
        '& + $track': {
          opacity: 1,
          backgroundColor: theme.palette.secondary.main,
          borderColor: theme.palette.secondary.main,
        },
      },
    },
    thumb: {
      width: 19,
      height: 19,
      boxShadow: 'none',
    },
    track: {
      // border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 24,
      opacity: 1,
      backgroundColor: '#d9e5ff',
      // boxSizing: 'border-box',
    },
    checked: {},
  }))(Switch);

const DiscountToggleCard = (props) => {
    const classes = useStyles();
    return (
        <Card className={classes.root}>
            <div style={{display: 'flex'}}>
                <Typography className={classes.title} variant='h6'>{'Allow user to share and get discount'}</Typography>
                <AntSwitch checked={props.discountCheck} onChange={e => props.setDiscountCheck(e.target.checked)} />
            </div>
            <Typography className={classes.subtitle2} variant='body2'>
                {'This feature will allow your users to avail special discounts on sharing your course link with their friends. Know More'}
            </Typography>
        </Card>
    )
}

export default withTheme(DiscountToggleCard);