/* eslint-disable max-len */
import {
  Button, ButtonBase, Card, Icon, IconButton, InputBase, Menu, MenuItem, Typography,
} from '@material-ui/core';
import { makeStyles, withTheme } from '@material-ui/styles';
import { duration } from 'moment';
import React from 'react';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(1),
    borderRadius: theme.spacing(1.25),
    display: 'flex',
    padding: theme.spacing(2.5, 2.25, 1.25),
    boxShadow: 'none',
    flexDirection: 'column',
  },
  title: {
    fontWeight: 'bold',
    fontSize: theme.spacing(2.25),
    marginBottom: theme.spacing(2),
  },
  subtitle: {
    margin: theme.spacing(1.75, 0, 1.875),
  },
  buttons: {
    display: 'flex',
    marginBottom: theme.spacing(2.25),
    '& > *': {
      flex: 1,
      marginRight: theme.spacing(1),
      textTransform: 'none',
      borderRadius: theme.spacing(0.75),
      fontWeight: 'bold',
    },
    '& > *:last-child': {
      marginRight: 0,
    },
  },
  hr: {
    width: '100%',
    height: '1px',
    background: '#707070',
    opacity: '0.1',
    marginLeft: '13.6px',
  },
  inputBase2: {
    // border: '1px solid #eaeaea',
    background: '#fafafa',
    borderRadius: theme.spacing(0.75),
    width: '100%',
    padding: theme.spacing(1.5, 2.25),
    marginBottom: theme.spacing(1),
  },
  inputBase: {
    // border: '1px solid #eaeaea',
    background: '#fafafa',
    borderRadius: theme.spacing(0.75),
    width: '100%',
    padding: theme.spacing(1.5, 2.25),
    marginLeft: theme.spacing(0.375),
  },
  inputBaseActive: {
    // border: '1px solid #eaeaea',
    background: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    borderRadius: theme.spacing(0.75),
    width: '100%',
    padding: theme.spacing(1.5, 2.25),
    marginLeft: theme.spacing(0.375),
  },
  input: {
    fontSize: theme.spacing(1.75),
    padding: 0,
  },
  menuItem: {
    fontSize: theme.spacing(1.75),
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  sessionCount: {
    color: '#707070',
    textAlign: 'right',
    lineHeight: 1.23,
  },
}));

const formatDays = (days) => {
  if (days === 1) return '1 Day';
  if (days === 7) return '7 Days';
  if (days === 14) return '2 weeks';
  if (days === 28) return '4 weeks';
  if (days === 56) return '8 weeks';
  if (days === 84) return '3 months';
  if (days === 168) return '6 months';
  if (days === 336) return '1 year';
  return '';
};

const CourseDurationCard = (props) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [anchorEl2, setAnchorEl2] = React.useState(null);

  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const [anchorEl3, setAnchorEl3] = React.useState(null);

  const handleClick3 = (event) => {
    setAnchorEl3(event.currentTarget);
  };

  const handleClose3 = () => {
    setAnchorEl3(null);
  };

  const open = Boolean(anchorEl);
  const open2 = Boolean(anchorEl2);
  const open3 = Boolean(anchorEl3);

  return (
    <Card className={classes.root}>
      <Typography className={classes.title} variant="h6">Repeat</Typography>

      <ButtonBase className={classes.inputBase2} onClick={handleClick}>
        <Typography variant="body2" style={{ flex: 1, textAlign: 'left' }}>{props.repeat}</Typography>
        <Icon>expand_more</Icon>
      </ButtonBase>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        MenuListProps={{
          style: {
            padding: 0,
          },
        }}
        PaperProps={{
          style: {
            // marginTop: '48px',
            width: `calc(100% - ${props.theme.spacing(8.625)}px)`,
            borderRadius: 0,
          },
        }}
      >
        <MenuItem className={classes.menuItem} onClick={() => handleClose() || props.setRepeat('Once') || props.setDays(1) || props.setValidFor(1)}>Once</MenuItem>
        <MenuItem className={classes.menuItem} onClick={() => handleClose() || props.setRepeat('Daily') || props.setDays(7) || props.setValidFor(7)}>Daily</MenuItem>
        <MenuItem className={classes.menuItem} onClick={() => handleClose() || props.setRepeat('Custom') || props.setDays(7) || props.setValidFor(7)}>Custom</MenuItem>
      </Menu>

      {props.repeat !== 'Once' ? (
        <>
          <Typography variant="caption" className={classes.subtitle}>For</Typography>

          <ButtonBase className={classes.inputBase2} onClick={handleClick2}>
            <Typography variant="body2" style={{ flex: 1, textAlign: 'left' }}>{formatDays(props.days)}</Typography>
            <Icon>expand_more</Icon>
          </ButtonBase>

          <Menu
            id="simple-menu"
            anchorEl={anchorEl2}
            keepMounted
            open={open2}
            onClose={handleClose2}
            MenuListProps={{
              style: {
                padding: 0,
              },
            }}
            PaperProps={{
              style: {
                // marginTop: '48px',
                width: `calc(100% - ${props.theme.spacing(8.625)}px)`,
                borderRadius: 0,
              },
            }}
          >
            <MenuItem className={classes.menuItem} onClick={() => handleClose2() || props.setDays(7) || props.setValidFor(7)}>7 days</MenuItem>
            <MenuItem className={classes.menuItem} onClick={() => handleClose2() || props.setDays(14) || props.setValidFor(14)}>2 weeks</MenuItem>
            <MenuItem className={classes.menuItem} onClick={() => handleClose2() || props.setDays(28) || props.setValidFor(28)}>4 weeks</MenuItem>
            <MenuItem className={classes.menuItem} onClick={() => handleClose2() || props.setDays(56) || props.setValidFor(56)}>8 weeks</MenuItem>
            <MenuItem className={classes.menuItem} onClick={() => handleClose2() || props.setDays(84) || props.setValidFor(84)}>3 months</MenuItem>
            <MenuItem className={classes.menuItem} onClick={() => handleClose2() || props.setDays(168) || props.setValidFor(168)}>6 months</MenuItem>
            <MenuItem className={classes.menuItem} onClick={() => handleClose2() || props.setDays(336) || props.setValidFor(336)}>1 year</MenuItem>
          </Menu>

          {props.repeat === 'Custom' ? (
            <>
              <Typography variant="caption" className={classes.subtitle}>Days</Typography>
              <div style={{
                display: 'flex', alignItems: 'center', overflow: 'scroll', marginBottom: props.theme.spacing(1.25),
              }}
              >
                <ButtonBase onClick={() => props.toggleDay(0)} className={props.daysOfWeek[0] ? classes.inputBaseActive : classes.inputBase}>S</ButtonBase>
                <ButtonBase onClick={() => props.toggleDay(1)} className={props.daysOfWeek[1] ? classes.inputBaseActive : classes.inputBase}>M</ButtonBase>
                <ButtonBase onClick={() => props.toggleDay(2)} className={props.daysOfWeek[2] ? classes.inputBaseActive : classes.inputBase}>T</ButtonBase>
                <ButtonBase onClick={() => props.toggleDay(3)} className={props.daysOfWeek[3] ? classes.inputBaseActive : classes.inputBase}>W</ButtonBase>
                <ButtonBase onClick={() => props.toggleDay(4)} className={props.daysOfWeek[4] ? classes.inputBaseActive : classes.inputBase}>T</ButtonBase>
                <ButtonBase onClick={() => props.toggleDay(5)} className={props.daysOfWeek[5] ? classes.inputBaseActive : classes.inputBase}>F</ButtonBase>
                <ButtonBase onClick={() => props.toggleDay(6)} className={props.daysOfWeek[6] ? classes.inputBaseActive : classes.inputBase}>S</ButtonBase>
              </div>
            </>
          ) : null}
        </>
      ) : null}

      <Typography variant="caption" className={classes.subtitle}>Valid For</Typography>

      <ButtonBase className={classes.inputBase2} onClick={handleClick3}>
        <Typography variant="body2" style={{ flex: 1, textAlign: 'left' }}>{formatDays(props.validFor)}</Typography>
        <Icon>expand_more</Icon>
      </ButtonBase>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl3}
        keepMounted
        open={open3}
        onClose={handleClose3}
        MenuListProps={{
          style: {
            padding: 0,
          },
        }}
        PaperProps={{
          style: {
            // marginTop: '48px',
            width: `calc(100% - ${props.theme.spacing(8.625)}px)`,
            borderRadius: 0,
          },
        }}
      >
        {props.days >= 1 ? <MenuItem className={classes.menuItem} onClick={() => handleClose3() || props.setValidFor(1)}>1 day</MenuItem> : null}
        {props.days >= 7 ? <MenuItem className={classes.menuItem} onClick={() => handleClose3() || props.setValidFor(7)}>7 days</MenuItem> : null}
        {props.days >= 14 ? <MenuItem className={classes.menuItem} onClick={() => handleClose3() || props.setValidFor(14)}>2 weeks</MenuItem> : null}
        {props.days >= 28 ? <MenuItem className={classes.menuItem} onClick={() => handleClose3() || props.setValidFor(28)}>4 weeks</MenuItem> : null}
        {props.days >= 56 ? <MenuItem className={classes.menuItem} onClick={() => handleClose3() || props.setValidFor(56)}>8 weeks</MenuItem> : null}
        {props.days >= 84 ? <MenuItem className={classes.menuItem} onClick={() => handleClose3() || props.setValidFor(84)}>3 months</MenuItem> : null}
        {props.days >= 168 ? <MenuItem className={classes.menuItem} onClick={() => handleClose3() || props.setValidFor(168)}>6 months</MenuItem> : null}
        {props.days >= 336 ? <MenuItem className={classes.menuItem} onClick={() => handleClose3() || props.setValidFor(336)}>1 year</MenuItem> : null}
      </Menu>

      <Typography variant="caption" className={classes.sessionCount}>
        {props.repeat === 'Custom' ? props.validFor > 1 ? Object.values(props.daysOfWeek).filter(val => val).length * (props.validFor / 7) : 1 : props.validFor}
        {' '}
        Session
        {props.repeat === 'Once' ? '' : 's'}
      </Typography>
    </Card>
  );
};

export default withTheme(CourseDurationCard);
