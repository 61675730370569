const moment = require('moment');
const Constants = require('./Constants');
const Servercalls = require('./Servercalls');
exports.getParametersByName = function (name, mUrl) {
    let url = mUrl;
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

exports.getWindowDimensions = () => {
    if(window.innerWidth > 500){
        let height = window.innerHeight * 0.7536;
        let width = height * 540.0 / 890;
        let marginTop = window.innerHeight * 0.11685;
        let marginLeft = (window.innerWidth - width) / 2;
        return { height, width, marginTop, marginLeft}
    }
    return { height: window.innerHeight, width: window.innerWidth, marginTop: 0, marginLeft: 0 }
  
}

exports.logout = () => {
    localStorage.removeItem('auth_username');
    localStorage.removeItem('auth_long_expires_at');
    localStorage.removeItem('auth_long_token');
    localStorage.removeItem('auth_access_token');
    localStorage.removeItem('auth_expires_at');
    localStorage.removeItem('auth_refresh_token');
    window.location.href = `${Constants.WebAuthUrl}?response_type=code&redirect_uri=${Constants.AppUrl}&client_id=${Constants.WebAuthClientId}`;
}

function globalAuth (auth) {
    return new Promise((resolve, reject) => {
        if (!auth.error) {
            const access_token = auth.access_token;
            const expires_at = moment().add(auth.expires_in, 'seconds').toString();
            localStorage.setItem('auth_username', auth.username);
            localStorage.setItem('auth_long_expires_at', auth.long_expires_at);
            localStorage.setItem('auth_long_token', auth.long_token);
            localStorage.setItem('auth_access_token', access_token);
            localStorage.setItem('auth_expires_at', expires_at);

            const refresh_token = auth.refresh_token;
            if (refresh_token)
                localStorage.setItem('auth_refresh_token', refresh_token);
            resolve();

        }
        else {
            reject();
        }

    });

};

exports.globalGetLogin = () => new Promise((resolve, reject) => {
    const access_token = localStorage.getItem('auth_access_token');
    const expires_at = localStorage.getItem('auth_expires_at');
    const refresh_token = localStorage.getItem('auth_refresh_token');
    const WebAuthUrl = `${Constants.WebAuthUrl}?response_type=code&redirect_uri=${Constants.AppUrl}&client_id=${Constants.WebAuthClientId}`;
    Servercalls.getBuilder().then(user => {
        resolve(user.data);
    }).catch((e) => {
        console.log(e);
        if (expires_at && expires_at !== 'null') {
            const timeLeft = moment(expires_at) - moment();
            if (timeLeft <= 3600 * 1000 * 0.1) {
                Servercalls.refreshAuthToken(refresh_token)
                    .then(response => {
                        globalAuth(response.data)
                        .then(() => Servercalls.getBuilder().then(user => {
                            resolve(user.data);
                        })).catch(e => {
                            window.location.href = WebAuthUrl    
                        })
                    })
                    .catch((e) => {
                        window.location.href = WebAuthUrl
                    });
            }
            else {
                window.location.href = WebAuthUrl;
            }
        }
        else{
            window.location.href = WebAuthUrl
        }
    });
});

exports.globalAuth = globalAuth;