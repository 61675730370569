import { ButtonBase, Icon, Typography } from '@material-ui/core';
import { makeStyles, withTheme } from '@material-ui/styles';
import React from 'react';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(0, 2.375),
        height: theme.spacing(7),
        minWidth: theme.spacing(7.9375),
    },
    outlined: {
        border: '1px solid',
        borderColor: theme.palette.secondary.main,
        borderRadius: theme.spacing(2.5),
        padding: theme.spacing(0.4375, 1.5, 0.4375, 1),
        minWidth: theme.spacing(7.9375),
    },
    contained: {
        backgroundColor: theme.palette.secondary.main,
        borderRadius: theme.spacing(2.5),
        padding: theme.spacing(0.4375, 1.5, 0.4375, 1),
        minWidth: theme.spacing(7.9375),
    },
    title: {
        marginLeft: theme.spacing(0.5),
    },
    icon: {
        height: theme.spacing(2),
        width: theme.spacing(2),
        lineHeight: 0,
    }
}))

const StyledButton = (props) => {
    const classes = useStyles();

    if (props.variant === 'contained') {
        return (
            <ButtonBase onClick={() => props.onClick()} style={{color: props.color === 'secondary' ? props.theme.palette.secondary.contrastText : '', ...props.style}} className={classes.contained}>
                {props.icon ? <Icon className={classes.icon}>{props.icon}</Icon> : null}
                <Typography className={classes.title} variant='body2'>{props.text}</Typography>
            </ButtonBase>
        )
    }

    if (props.variant === 'outlined') {
        return (
            <ButtonBase onClick={() => props.onClick()} style={{color: props.color === 'secondary' ? props.theme.palette.secondary.main : '', ...props.style}} className={classes.outlined}>
                {props.icon ? <Icon className={classes.icon}>{props.icon}</Icon> : null}
                <Typography className={classes.title} variant='body2'>{props.text}</Typography>
            </ButtonBase>
        )
    }

    return(
        <ButtonBase style={{...props.style}} onClick={() => props.onClick()}>
            {props.icon ? <Icon className={classes.icon}>{props.icon}</Icon> : null}
            <Typography className={classes.title} variant='body2'>{props.text}</Typography>
        </ButtonBase>
    )
}

export default withTheme(StyledButton);