import React from 'react';
import Theme from '../../Components/theme';
import WsHeader from '../../Components/wsheader';
import WsFooter from '../../Components/wsfooter';
import { TextField, Button, Icon, Typography, CardContent, CardActions, Card, LinearProgress } from '@material-ui/core';

const Servercalls = require('../../Utils/Servercalls');

export default class WsAppAnalytics extends React.Component {


    constructor(props){
        super(props);
        this.state = {
            gaCode : this.props.context.gaCode,
            loading : true,
        }
    }

    componentDidMount() {
        this.reload();
    }

    reload() {
        const self = this;
        Servercalls.getAppAnalytics().then(response => {
            self.setState({ ...response.data, loading: false});
        }).catch(e => self.setState({ loading: false }));
    }

    save() {
        const self = this;
        self.setState({ saving: true });
        Servercalls.updateAppGaCode(this.state.gaCode).then(() => {
            self.setState({ saving: false, saved: true });
            self.props.changeParentContext('gaCode', self.state.gaCode);
            setTimeout(() => self.props.back() , 3000);
        });
    }

    render() {
        if(this.state.loading) return <LinearProgress />
        if(this.state.saved) return (
            <center style={{ margin: 64}}>
                <Icon style={{ fontSize: 120}} color="secondary">check</Icon>
                <br /><br />
                <Typography variant="body2">Google Code has been updated. You can now see all live updates on your google analytics dashboard. You'll be redirected shortly</Typography>
            </center>
        )


        return (
            <Theme>
                <WsHeader  back={this.props.back} title="Analytics" description="Use Google Analytics to see what your users are doing on your app. Google Analytics is the most detailed analytics dashboard in the market." helpText="How to integrate Google Analytics?" helpVideo="a" />
                <div style={{ margin: 32 }}>
                    {this.state.gaCode !== 'NONE' && this.state.gaCode ? <Button color="primary" onClick={() => window.open("https://analytics.google.com", "_blank")} style={{ marginTop: 32, marginBottom: 32}} color="secondary">Open Google Analytics</Button>:null}
                    <TextField label="Paste Google Analytics code here" fullWidth value={this.state.gaCode} onChange={e => this.setState({ gaCode: e.target.value })}  />
                    <Button style={{ marginTop: 12, marginBottom: 32 }} variant="contained" color="secondary" onClick={() => this.save()} disabled={this.state.saving}>Save Code</Button>
                    {
                        Object.keys(this.state.collections).map(currency => (
                            <Card style={{ margin: 4, width: `calc(100% - 8px)` }}>
                                <CardContent>
                                    <center>
                                        <Typography variant="body1">Payments ({currency}) - all time</Typography>
                                        <Typography variant="h3">{this.state.collections[currency]}</Typography>
                                    </center>
                                </CardContent>
                                <CardActions>
                                    <Button onClick={() => this.props.changeWorkspace('apppaymentreport','Payment Reports', {})} color="primary">Detailed Report</Button>
                                    <Button disabled>Know More</Button>
                                </CardActions>
                            </Card>
    
                        ))
                    }
                    {
                        Object.keys(this.state.monthlyCollections).map(currency => (
                            <Card style={{ margin: 4, width: `calc(100% - 8px)` }}>
                                <CardContent>
                                    <center>
                                        <Typography variant="body1">Payments ({currency}) - Last 30 days</Typography>
                                        <Typography variant="h3">{this.state.monthlyCollections[currency]}</Typography>
                                    </center>
                                </CardContent>
                                <CardActions>
                                    <Button onClick={() => this.props.changeWorkspace('apppaymentreport','Payment Reports', {})} color="primary">Detailed Report</Button>
                                    <Button disabled>Know More</Button>
                                </CardActions>
                            </Card>
    
                        ))
                    }

                    {/*<Card style={{ margin: 4, width: `calc(100% - 8px)` }}>
                        <CardContent>
                            <center>
                                <Typography variant="body1">Users on the app right now</Typography>
                                <Typography variant="h1">{this.state.currentUsersCount}</Typography>
                            </center>
                        </CardContent>
                        <CardActions>
                            <Button disabled>Know More</Button>
                        </CardActions>
                    </Card>
                    <Card style={{ margin: 4, width: `calc(100% - 8px)` }}>
                        <CardContent>
                            <center>
                                <Typography variant="body1">New users today</Typography>
                                <Typography variant="h1">{this.state.newTodayUsersCount}</Typography>
                            </center>
                        </CardContent>
                        <CardActions>
                            <Button disabled>Know More</Button>
                        </CardActions>
                    </Card>
                    <Card style={{ margin: 4, width: `calc(100% - 8px)` }}>
                        <CardContent>
                            <center>
                                <Typography variant="body1">Retention - Paying users</Typography>
                                <Typography variant="h1">{Math.ceil(100 * this.state.paidDau / (this.state.paidMau + 1))}%</Typography>
                            </center>
                        </CardContent>
                        <CardActions>
                            <Button disabled>Know More</Button>
                        </CardActions>
                    </Card>
                    <Card style={{ margin: 4, width: `calc(100% - 8px)` }}>
                        <CardContent>
                            <center>
                                <Typography variant="body1">Retention - Signed in users</Typography>
                                <Typography variant="h1">{Math.ceil(this.state.registeredDau * 100 / (this.state.registeredMau + 1))}%</Typography>
                            </center>
                        </CardContent>
                        <CardActions>
                            <Button disabled>Know More</Button>
                        </CardActions>
                    </Card>
                    <Card style={{ margin: 4, width: `calc(100% - 8px)` }}>
                        <CardContent>
                            <center>
                                <Typography variant="body1">Retention - All users</Typography>
                                <Typography variant="h1">{Math.ceil(this.state.allDau * 100 / (this.state.allMau + 1))}%</Typography>
                            </center>
                        </CardContent>
                        <CardActions>
                            <Button disabled>Know More</Button>
                        </CardActions>
                </Card>*/}

                    <Card style={{ margin: 4, width: `calc(100% - 8px)` }}>
                        <CardContent>
                            <center>
                                <Typography variant="body1">Total users</Typography>
                                <Typography variant="h3">{this.state.allUsersCount}</Typography>
                            </center>
                        </CardContent>
                        <CardActions>
                            <Button disabled>Know More</Button>
                        </CardActions>
                    </Card>
                    <Card style={{ margin: 4, width: `calc(100% - 8px)` }}>
                        <CardContent>
                            <center>
                                <Typography variant="body1">Signed in users</Typography>
                                <Typography variant="h3">{this.state.registeredUsersCount}</Typography>
                            </center>
                        </CardContent>
                        <CardActions>
                            <Button disabled>Know More</Button>
                        </CardActions>
                    </Card>
                    <Card style={{ margin: 4, width: `calc(100% - 8px)` }}>
                        <CardContent>
                            <center>
                                <Typography variant="body1">Users who have paid</Typography>
                                <Typography variant="h3">{this.state.paidUsersCount}</Typography>
                            </center>
                        </CardContent>
                        <CardActions>
                            <Button disabled>Know More</Button>
                        </CardActions>
                    </Card>

                </div>
                <WsFooter text="Analytics is important track these numbers regularly. Contact us at contact@socionity.com for help in analysing these numbers." />
            </Theme>
        )
    }
}