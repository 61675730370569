import React from 'react';
import CommonAddContent from './ws-commonaddcontent';

export default class WsAddVideoContent extends React.Component {
    render() {
        return (
            <CommonAddContent
                title="Upload a video file"
                description="Video files will play in the Secure Socionity media player. This includes protection from downloading and sharing. It also prevents privacy by dynamic watermarking." 
                helpText="Know more about video content"
                helpVideo="a"
                filetype="video"
                {...this.props}
            />
        )
    }
}